import { AdvisorDto, AdvisorSponsorPlansDto } from '@/models';
import {
  AdvisorMeta,
  CreateAdvisorDto,
  EditAdvisorDto
} from '@/models/AdvisorDTO.model';
import AdvisorUserInvitesDto from '@/models/AdvisorUserInvitesDTO.model';
import AdvisorUserInvitesInfo, {
  AdvisorUserInviteRelationships
} from '@/models/AdvisorUserInvitesInfo.model';
import { UserInvite } from '@/models/UserInvite.model';
import ApiService from '@/services/Api.service';

class AdvisorService {
  static async getById(advisorId: string | number): Promise<AdvisorDto> {
    const dto = await ApiService.getJson<AdvisorDto>(`/advisors/${advisorId}`);

    if (!dto) {
      throw new Error(
        `invalid JSON received from backend for advisorId ${advisorId}`
      );
    }

    return dto;
  }

  static async getInfoById(advisorId: string | number): Promise<AdvisorMeta> {
    return ApiService.getJson<AdvisorMeta>(`/advisor/${advisorId}/info`);
  }

  static async createUserAdvisorRelationship(
    userId: number | string,
    body: { entityId: number }
  ): Promise<{ rowsAdded: number }[]> {
    const dto = await ApiService.postJson<
      { entityId: number },
      { rowsAdded: number }[]
    >(`/users/${userId}/relationships/advisors`, body);

    if (!dto) {
      throw new Error(
        `invalid JSON received from backend for userId=${userId}`
      );
    }
    return dto;
  }

  static async deleteUserAdvisorRelationship(
    userId: number | string,
    body: { entityId: number }
  ): Promise<{ rowsRemoved: number; exceptions: [] }[]> {
    const dto = await ApiService.patchJson<
      { entityId: number },
      { rowsRemoved: number; exceptions: [] }[]
    >(`/users/${userId}/relationships/advisors`, body);

    if (!dto) {
      throw new Error(
        `invalid JSON received from backend for userId=${userId}`
      );
    }

    return dto;
  }

  static async getAdvisorUserInvites(
    advisorId: number | string,
    searchByEmail?: string,
    includeRegistrationUrl?: boolean
  ): Promise<AdvisorUserInvitesInfo> {
    const dto = (await ApiService.getJson(
      `/advisors/${advisorId}/user-invites`,
      { includeRegistrationUrl }
    )) as AdvisorUserInvitesDto;

    if (!dto || !dto.data) {
      throw new Error(
        `invalid JSON received from backend for getAdvisorUserInvites advisorId=${advisorId}`
      );
    }

    let userInvite;
    const sortedInvites = [...dto.data].sort(
      (a, b) =>
        new Date(b.attributes.expiresAt).getTime() -
        new Date(a.attributes.expiresAt).getTime()
    );

    if (!searchByEmail) {
      userInvite = sortedInvites[0];
    } else {
      userInvite = sortedInvites.filter(
        invite => invite.attributes.email === searchByEmail
      )[0];
    }

    return {
      advisorUserInviteCode: userInvite?.attributes?.inviteCode || null,
      advisorUserInviteExpiresAt: userInvite?.attributes?.expiresAt || null,
      advisorUserInvites: dto.data,
      registrationUrl: dto.registrationUrl,
      relationships: userInvite?.relationships || null
    };
  }

  static async createAdvisor(dto: CreateAdvisorDto): Promise<AdvisorDto> {
    return ApiService.postJson(`/advisors`, dto);
  }

  static async editAdvisor(dto: EditAdvisorDto): Promise<AdvisorDto> {
    return ApiService.patchJson(`/advisors/${dto.data.id}`, dto);
  }

  static async createAdvisorUserInvite(
    advisorId: number | string,
    email: string,
    userRoles: string[] | undefined
  ): Promise<{ data: UserInvite<AdvisorUserInviteRelationships> }> {
    const dto: { data: UserInvite<AdvisorUserInviteRelationships> } =
      await ApiService.postJson(`/advisors/${advisorId}/user-invites`, {
        deliveryMethod: 'email',
        email,
        rolesGranted: userRoles
      });

    if (!dto) {
      throw new Error(
        `invalid JSON received from backend for createAdvisorUserInvite advisorId=${advisorId}`
      );
    }
    return dto;
  }

  static async sendAdvisorUserInvite(
    advisorId: number | string,
    email: string,
    inviteCode: string
  ): Promise<{ data: UserInvite<AdvisorUserInviteRelationships> }> {
    const dto: { data: UserInvite<AdvisorUserInviteRelationships> } =
      await ApiService.postJson(`/advisors/${advisorId}/send/user-invites`, {
        email,
        inviteCode
      });

    if (!dto) {
      throw new Error(
        `invalid JSON received from backend for sendAdvisorUserInvite advisorId=${advisorId}`
      );
    }
    return dto;
  }

  static async getAdvisorSponsorPlans({
    advisorId,
    page,
    size,
    sortBy,
    sortDirection = 'ASC',
    filter = ''
  }: {
    advisorId: number | string;
    page?: number;
    size?: number;
    sortBy?: string;
    sortDirection?: 'ASC' | 'DESC';
    filter?: string;
  }): Promise<AdvisorSponsorPlansDto> {
    return ApiService.getJson(`/advisors/${advisorId}/sponsor-plans`, {
      filter,
      page,
      size,
      sortBy,
      sortDirection
    });
  }
}

export default AdvisorService;
