import DatePickerForm from '@/components/date-picker/DatePickerForm';
import { useDialog } from '@/contexts/DialogContext';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { PlanService } from '@/services/Plan.service';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import dayjs from 'dayjs';
import { Form, Formik } from 'formik';
import React from 'react';
import * as yup from 'yup';

const updateDetailsValidationSchema: yup.AnyObjectSchema = yup.object().shape({
  effectiveDate: yup.string().required('Required')
});

interface DocDetailsDialogProps {
  groupId: number;
  planId: number;
  categoryId: number;
  effectiveDate?: string;
  uploadHistoryId?: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    datePicker: {
      width: 220
    },
    dialogContent: {
      paddingBottom: theme.spacing(8),
      width: 400
    }
  })
);

const DocDetailsDialog = (props: DocDetailsDialogProps): JSX.Element => {
  const { effectiveDate, groupId, uploadHistoryId, planId, categoryId } = props;

  const classes = useStyles();
  const { closeDialog } = useDialog();
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const initialValues = {
    effectiveDate: effectiveDate
      ? dayjs(effectiveDate).format('MM/DD/YYYY')
      : ''
  };

  const updateDocGroupDetails = useMutation(
    ['PlanService.update'],
    (newEffectiveDate: string) => {
      if (!uploadHistoryId) {
        throw new Error('no doc id provided');
      }

      return PlanService.updateDocGroupDetails(
        planId,
        uploadHistoryId,
        groupId,
        { effectiveDate: newEffectiveDate }
      );
    },
    {
      onError: () => {
        showSnackbar({
          message: `Failed!`,
          severity: 'error'
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries([
          'PlanService.getAllPlanDocuments',
          planId
        ]);
        queryClient.invalidateQueries([
          'PlanService.getDocumentCategoryGroupings',
          planId,
          categoryId
        ]);

        showSnackbar({
          message: `Success! Document updated`,
          severity: 'success'
        });
      }
    }
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async values => {
        await updateDocGroupDetails.mutateAsync(values.effectiveDate);
        closeDialog();
      }}
      validationSchema={updateDetailsValidationSchema}>
      {({ values, isValid, isSubmitting }) => {
        return (
          <Form>
            <DialogTitle>Edit Document Details</DialogTitle>
            <DialogContent className={classes.dialogContent}>
              <DatePickerForm
                className={classes.datePicker}
                data-testid='update-effective-date'
                format='MM/DD/YYYY'
                inputProps={{
                  autoComplete: 'off'
                }}
                label='Effective Date'
                name='effectiveDate'
                onChange={() => {}}
                value={values.effectiveDate}
                variant='outlined'
              />
            </DialogContent>
            <DialogActions>
              <Button
                disabled={updateDocGroupDetails.isLoading || isSubmitting}
                onClick={closeDialog}>
                Cancel
              </Button>
              <Button
                disabled={
                  !isValid ||
                  !values.effectiveDate ||
                  dayjs(values.effectiveDate).isSame(
                    dayjs(initialValues.effectiveDate)
                  ) ||
                  updateDocGroupDetails.isLoading ||
                  isSubmitting
                }
                type='submit'>
                Save
              </Button>
            </DialogActions>
          </Form>
        );
      }}
    </Formik>
  );
};

export default DocDetailsDialog;
