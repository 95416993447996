import Card, {
  CardContent,
  CardHeader,
  CardPlaceholder
} from '@/components/card';
import DataTable, {
  DataTableStackCell
} from '@/components/data-table/DataTable.component';
import DownloadCSVButton from '@/components/download-csv-button/DownloadCSVButton.component';
import OpenInNewIcon from '@/components/icon/OpenInNewIcon';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { SubAccountApiIncludeOption } from '@/models/ops/accounts/SubAccountApiIncludeOption.model';
import { SubAccountSearchRequest } from '@/models/ops/accounts/SubAccountSearchRequest.model';
import { BalanceTotalsDto } from '@/models/ops/balance-totals/BalanceTotalsDTO.model';
import { BalanceTotalsGetRequest } from '@/models/ops/balance-totals/BalanceTotalsGetRequest.model';
import FundingSource from '@/models/ops/FundingSourceEnum.model';
import { SubAccountDto } from '@/models/ops/SubAccountDTO.model';
import SubAccountService from '@/services/ops/accounts/SubAccount.service';
import BalanceTotalsService from '@/services/ops/balance-totals/BalanceTotals.service';
import VestwellTheme from '@/theme/Vestwell.theme';
import formatters from '@/utils/Formatters';
import { useUrlStateParams } from '@/utils/Url';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Link,
  OutlinedInput,
  Stack,
  Switch
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { ColDef } from 'ag-grid-community';
import Decimal from 'decimal.js';
import { Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';

type SearchFormValues = {
  query: string;
};

type OperationalAccountsContentProps = {
  accountId: string;
};

type SearchSubAccountsContentProps = {
  accountId?: string;
  hideFilters?: boolean;
  customDefaultFilters?: Partial<SubAccountSearchRequest>;
  searchOnInitialLoad?: boolean;
  includeColumns?: string[];
  onQueryChange?: (request: SubAccountSearchRequest) => void;
};

export const OperationalAccountsContent = ({
  accountId
}: OperationalAccountsContentProps): JSX.Element => {
  const { showSnackbar } = useSnackbar();

  const getBalanceTotalsQuery = useQuery(
    ['BalanceTotalsService.get', accountId],
    () => {
      const request: BalanceTotalsGetRequest = {
        parentAccountId: accountId
      };
      return BalanceTotalsService.get(request);
    },
    {
      keepPreviousData: true,
      onError: (err: any) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Failed to fetch operational account balances: ${message}`,
          severity: 'error'
        });
      },
      staleTime: Infinity
    }
  );

  const columnDefs: ColDef[] = [
    {
      cellRenderer: ({ data }: { data: BalanceTotalsDto }) => (
        <Link
          href={`/ops/accounts/${accountId}/sub-accounts/${data.accountId}`}
          target='_blank'>
          {formatters.displayCase(data.accountType)}
          <Box
            component='span'
            sx={{
              lineHeight: 1,
              pl: 0.5
            }}>
            <OpenInNewIcon sx={{ fontSize: 14 }} />
          </Box>
        </Link>
      ),
      field: 'accountType',
      headerName: 'Type',
      sortable: true,
      suppressMenu: true
    },
    {
      cellRenderer: ({ data }: { data: BalanceTotalsDto }) =>
        formatters.formatDollars(
          new Decimal(data.confirmedCashBalance).toNumber() +
            new Decimal(data.confirmedPositionBalance).toNumber()
        ),
      headerName: 'Market Value',
      sortable: true,
      suppressMenu: true,
      type: 'numericColumn'
    },
    {
      field: 'confirmedCashBalance',
      headerName: 'Cash Balance',
      sortable: true,
      suppressMenu: true,
      type: 'numericColumn',
      valueFormatter: ({ value }: { value: string }) =>
        formatters.formatDollars(value)
    }
  ];

  return (
    <CardContent disablePadding loading={getBalanceTotalsQuery.isFetching}>
      <DataTable
        columnDefs={columnDefs}
        columnSizing='auto'
        data-testid='data-operational-accounts'
        getRowStyle={params =>
          params.data.confirmedCashBalance &&
          new Decimal(params.data.confirmedCashBalance).lessThan(
            new Decimal(0)
          ) && {
            backgroundColor: VestwellTheme.palette.warning.lightBg
          }
        }
        rowData={getBalanceTotalsQuery.data || []}
        sort={[{ colId: 'accountType', sort: 'asc' }]}
      />
    </CardContent>
  );
};

export const SearchSubAccountsContent = ({
  accountId,
  hideFilters = false,
  customDefaultFilters,
  includeColumns,
  onQueryChange
}: SearchSubAccountsContentProps): JSX.Element => {
  const { showSnackbar } = useSnackbar();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [orderBy, setOrderBy] = useState<keyof SubAccountDto | undefined>();
  const [orderByDirection, setOrderByDirection] = useState<
    'asc' | 'desc' | undefined
  >();

  const [hasDebitSubAccounts, setHasDebitSubAccounts] = useUrlStateParams(
    false,
    'filterDebitSubAccounts',
    value => String(value),
    value => value.toLowerCase() === 'true'
  );

  const handleDebitAccountsToggle = () => {
    setHasDebitSubAccounts(!hasDebitSubAccounts);
    if (!hasDebitSubAccounts) {
      // resetting page right after setting this value to false
      // to avoid querying "page 2 of debit cash balance sub accounts"
      // immediately after attempting to filter them
      setPage(1);
    }
  };

  const defaultQuery: SubAccountSearchRequest = {
    query: '',
    ...customDefaultFilters // overwrites the above defaults
  };

  const [query, setQuery] = useState<SubAccountSearchRequest>(defaultQuery);

  const searchSubAccountsQuery = useQuery(
    [
      'SubAccountService.searchSubAccounts',
      accountId,
      hasDebitSubAccounts,
      query,
      page,
      pageSize,
      orderBy,
      orderByDirection
    ],
    () => {
      const request: SubAccountSearchRequest = {
        ...query,
        hasDebitSubAccounts: !hasDebitSubAccounts ? undefined : true,
        include: [
          SubAccountApiIncludeOption.cashBalance,
          SubAccountApiIncludeOption.totalMarketValue
        ],
        page,
        pageSize,
        parentAccountId: accountId
      };
      if (orderBy) {
        request.orderBy = orderBy;
      }
      if (orderByDirection) {
        request.orderByDirection = orderByDirection;
      }
      return SubAccountService.searchSubAccounts(request);
    },
    {
      keepPreviousData: true,
      onError: (err: any) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Sub Account search failed: ${message}`,
          severity: 'error'
        });
      },
      staleTime: Infinity
    }
  );

  useEffect(() => {
    if (!onQueryChange) return;
    onQueryChange({
      ...query,
      include: [
        SubAccountApiIncludeOption.cashBalance,
        SubAccountApiIncludeOption.totalMarketValue
      ],
      page,
      pageSize,
      parentAccountId: accountId
    });
  }, [query, page, pageSize, accountId]);

  const handleSubmit = (values: SearchFormValues) => {
    setPage(1);
    setQuery(values);
  };

  const participantAccountsColDefs: ColDef[] = [
    {
      cellRenderer: (cellData: { data: SubAccountDto }) => (
        <Link
          href={`/ops/accounts/${cellData.data.parentAccount.parentAccountId}/sub-accounts/${cellData.data.subAccountId}`}
          target='_blank'>
          {cellData.data.subAccountId}
          <Box
            component='span'
            sx={{
              lineHeight: 1,
              pl: 0.5
            }}>
            <OpenInNewIcon sx={{ fontSize: 14 }} />
          </Box>
        </Link>
      ),
      field: 'id',
      headerName: 'Sub Account ID',
      maxWidth: 180,
      minWidth: 180,
      sortable: true
    },

    {
      field: 'investorId',
      headerName: 'Part ID',
      maxWidth: 260,
      sortable: true
    },
    {
      cellRenderer: (cellData: { data: SubAccountDto }) => {
        let participantName = '';
        if (
          cellData.data.participant?.firstName ||
          cellData.data.participant?.lastName
        ) {
          participantName = `${cellData.data.participant?.firstName} ${cellData.data.participant?.lastName}`;
        }
        return (
          <Link
            href={`/participants/${cellData.data?.investorId}/personal`}
            target='_blank'>
            {participantName ? `${participantName}` : '\u2014'}
            <Box
              component='span'
              sx={{
                lineHeight: 1,
                pl: 0.5
              }}>
              <OpenInNewIcon sx={{ fontSize: 14 }} />
            </Box>
          </Link>
        );
      },
      headerName: 'Participant Name',
      maxWidth: 260,
      minWidth: 260
      // this data is available only after paginating, so we need to keep the sorting disabled
    },
    {
      cellRenderer: (cellData: { data: SubAccountDto }) => {
        let fundingSource = '';
        if (cellData?.data?.fundingSource) {
          fundingSource =
            FundingSource[
              cellData?.data?.fundingSource as keyof typeof FundingSource
            ];
        }
        return <span>{fundingSource || '\u2014'}</span>;
      },
      field: 'fundingSource',
      headerName: 'Funding Source',
      maxWidth: 170,
      minWidth: 170
      // this data is available only after paginating, so we need to keep the sorting disabled
    },
    {
      field: 'totalMarketValue',
      headerName: 'Market Value',
      maxWidth: 260,
      type: 'numericColumn',
      valueFormatter: ({ value }) => formatters.formatDollars(value)
      // this is a calculated field, so we can't sort by it
    },
    {
      field: 'cashBalance',
      headerName: 'Cash Balance',
      maxWidth: 260,
      type: 'numericColumn',
      valueFormatter: ({ value }) => formatters.formatDollars(value)
      // this is a calculated field, so we can't sort by it
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      maxWidth: 190,
      minWidth: 190,
      sortable: true,
      valueFormatter: ({ value }: { value: string }) =>
        formatters.formatFromIsoDate(value)
    }
  ];

  if (!accountId) {
    // searching all accounts so show the parent account column
    participantAccountsColDefs.splice(2, 0, {
      autoHeight: true,
      cellRenderer: (cellData: { data: SubAccountDto }) => {
        const name =
          cellData.data.parentAccount.accountType === 'SuperOmnibus' ||
          cellData.data.parentAccount.accountType === 'House'
            ? cellData.data.parentAccount.accountName
            : cellData.data.parentAccount.plan?.name;

        return (
          <DataTableStackCell
            primary={name || ''}
            secondary={
              cellData.data.parentAccount.parentAccountId
                ? `ID: ${cellData.data.parentAccount.parentAccountId}`
                : ''
            }
          />
        );
      },
      field: 'parentAccount.parentAccountId',
      headerName: 'Parent Account',
      maxWidth: 330,
      minWidth: 330,
      sortable: true
    });
  }

  // some fields are named differently in the API than in the UI regarding sorting
  const sortColIdMap: Record<string, string> = {
    'parentAccount.parentAccountId': 'parentAccountId'
  };
  const reverseSortColIdMap: Record<string, string> = {};
  Object.keys(sortColIdMap).forEach(key => {
    reverseSortColIdMap[sortColIdMap[key]] = key;
  });

  const handleSortChanged = (
    newSort: { colId: string; sort?: 'asc' | 'desc' }[]
  ) => {
    if (!newSort || newSort.length === 0) {
      setOrderBy(undefined);
      setOrderByDirection(undefined);
    } else {
      const sortColId = sortColIdMap[newSort[0].colId] || newSort[0].colId;
      setOrderBy(sortColId as keyof SubAccountDto);
      setOrderByDirection(newSort[0].sort);
    }
  };

  const handlePageChanged = (newPage: number) => {
    setPage(newPage);
  };

  const handlePageSizeChanged = (newPageSize: number) => {
    setPageSize(newPageSize);
  };

  return (
    <>
      {!hideFilters && (
        <CardContent>
          <Formik
            initialValues={{
              query: ''
            }}
            onSubmit={(values: SearchFormValues) => handleSubmit(values)}>
            <Form>
              <Stack alignItems='flex-start' direction='row' gap={2}>
                <FormControl fullWidth variant='outlined'>
                  <Field
                    as={OutlinedInput}
                    autoComplete='off'
                    data-testid='query-input'
                    endAdornment={<SearchIcon color='primary' />}
                    inputProps={{
                      'aria-labelledby': 'search-accounts-heading'
                    }}
                    name='query'
                    placeholder='Account ID, VW Core Account ID, participant name, or Participant ID'
                    size='small'
                  />
                </FormControl>

                <Button
                  data-testid='all-sub-accounts-apply-button'
                  type='submit'
                  variant='outlined'>
                  Apply
                </Button>

                <FormControlLabel
                  control={
                    <Switch
                      checked={hasDebitSubAccounts}
                      onChange={handleDebitAccountsToggle}
                    />
                  }
                  data-testid='filter-debit-sub-accounts-switch'
                  label='View accounts in debit only'
                  sx={{
                    minWidth: 260
                  }}
                />
              </Stack>
            </Form>
          </Formik>
        </CardContent>
      )}
      <CardContent disablePadding loading={searchSubAccountsQuery.isFetching}>
        <DataTable
          columnDefs={participantAccountsColDefs.filter(
            // include columns with no field or if a column include list was provided, include columns that are in the list
            col =>
              !col.field ||
              !includeColumns ||
              includeColumns.includes(col.field)
          )}
          data-testid='data-sub-accounts'
          emptyPlaceholderComponent={
            <Stack
              alignItems='center'
              data-testid='no-data-sub-accounts'
              justifyContent='center'
              sx={{ height: '100%' }}>
              <CardPlaceholder
                icon={<SearchIcon fontSize='inherit' />}
                subtitle={
                  searchSubAccountsQuery.data?.results
                    ? 'Search results will be displayed here.'
                    : 'No results found.'
                }
              />
            </Stack>
          }
          getRowStyle={params =>
            params.data.cashBalance &&
            new Decimal(params.data.cashBalance).lessThan(new Decimal(0)) && {
              backgroundColor: VestwellTheme.palette.warning.lightBg
            }
          }
          onPageChanged={handlePageChanged}
          onPageSizeChanged={handlePageSizeChanged}
          onSortChanged={handleSortChanged}
          page={page}
          pageSize={pageSize}
          pagination
          paginationSource='server'
          paginationTotal={searchSubAccountsQuery.data?.pagination?.total}
          rowData={searchSubAccountsQuery.data?.results || []}
          sort={
            orderBy
              ? [
                  {
                    colId: reverseSortColIdMap[orderBy] || orderBy,
                    sort: orderByDirection
                  }
                ]
              : []
          }
        />
      </CardContent>
    </>
  );
};

type AllSubAccountsProps = {
  accountId?: string;
  hideFilters?: boolean;
  hideHeader?: boolean;
  customDefaultFilters?: Partial<SubAccountSearchRequest>;
  searchOnInitialLoad?: boolean;
  includeColumns?: string[];
};
const AllSubAccounts = ({
  accountId,
  hideHeader = false,
  hideFilters = false,
  customDefaultFilters,
  searchOnInitialLoad = false,
  includeColumns
}: AllSubAccountsProps): JSX.Element | null => {
  const defaultQuery: SubAccountSearchRequest = {
    query: '',
    ...customDefaultFilters // overwrites the above defaults
  };

  const [query, setQuery] = useState<SubAccountSearchRequest>(defaultQuery);

  const formatSubAccounts = (subAccounts: SubAccountDto[]) => {
    return subAccounts.map(subAccount => {
      return {
        'Sub Account ID': subAccount.subAccountId,
        // eslint-disable-next-line sort-keys-plus/sort-keys
        'Part ID': subAccount.investorId,
        'Participant Name':
          subAccount.participant?.firstName || subAccount.participant?.lastName
            ? `${subAccount.participant?.firstName} ${subAccount.participant?.lastName}`
            : '',
        // eslint-disable-next-line sort-keys-plus/sort-keys
        'Funding Source': subAccount.fundingSource
          ? FundingSource[subAccount.fundingSource]
          : '',
        'Market Value': subAccount.totalMarketValue,
        // eslint-disable-next-line sort-keys-plus/sort-keys
        'Cash Balance': subAccount.cashBalance,
        'Created At': formatters.formatFromIsoDate(subAccount.createdAt)
      };
    });
  };

  const exportSubAccounts = async () => {
    const SubAccountsResponse = await SubAccountService.searchSubAccounts({
      ...query,
      page: 1,
      pageSize: 0
    });
    return formatSubAccounts(SubAccountsResponse.results);
  };

  return (
    <Stack
      alignItems={{ lg: 'start', xs: 'stretch' }}
      direction={{ lg: 'row', xs: 'column' }}
      spacing={2}>
      <Card data-testid='operational-sub-accounts' sx={{ minWidth: 470 }}>
        {!hideHeader && <CardHeader title='Operational Sub Accounts' />}
        {accountId && <OperationalAccountsContent accountId={accountId} />}
      </Card>
      <Card data-testid='all-sub-accounts' sx={{ flex: 7 }}>
        {!hideHeader && (
          <CardHeader title='Participant Sub Accounts'>
            <DownloadCSVButton
              buttonProps={{
                sx: {
                  fontSize: 'small'
                },
                variant: 'outlined'
              }}
              fileName={`SubAccounts-${accountId}`}
              getInfo={exportSubAccounts}
              text='EXPORT CSV'
            />
          </CardHeader>
        )}
        <SearchSubAccountsContent
          accountId={accountId}
          customDefaultFilters={customDefaultFilters}
          hideFilters={hideFilters}
          includeColumns={includeColumns}
          onQueryChange={request => setQuery(request)}
          searchOnInitialLoad={searchOnInitialLoad}
        />
      </Card>
    </Stack>
  );
};

export default AllSubAccounts;
