import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { WorkflowContext } from '@/routes/ops/conductor/common/WorkflowContext';
import formatters from '@/utils/Formatters';

import { useContext } from 'react';

export const DefaultInputFields = () => {
  const workflow = useContext(WorkflowContext);

  return (
    <TextStack direction='column' divider>
      {workflow?.planId && workflow.plan.name && (
        <TextStackItem>
          <TextLabel>Plan</TextLabel>
          <TextValue
            links={[
              {
                label: `ID: ${workflow.planId}`,
                target: '_blank',
                to: `/plans/${workflow.planId}/plan`
              }
            ]}>
            {workflow.plan.name}
          </TextValue>
        </TextStackItem>
      )}

      <TextStackItem>
        <TextLabel>Created</TextLabel>
        <TextValue>
          {formatters.formatFromIsoDate(workflow.createdAt)}
        </TextValue>
      </TextStackItem>
    </TextStack>
  );
};
