import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogActionsProps,
  DialogContent,
  DialogContentProps,
  DialogContentText,
  DialogContentTextProps,
  DialogProps,
  DialogTitle,
  DialogTitleProps,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import { blue } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';

import {
  FieldAttributes,
  Form,
  Formik,
  FormikErrors,
  FormikHelpers
} from 'formik';
import { isEmpty, isEqual } from 'lodash';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer
} from 'react';
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';
import Lazy from 'yup/lib/Lazy';
import Reference from 'yup/lib/Reference';

import { FromFields } from '../components/form-fields/FormFields.component';

type StepDirection = 'forward' | 'backward';

type Action =
  | { type: 'open'; payload: DialogOptions | DialogOptions[] }
  | { type: 'close' }
  | { type: 'reset' }
  | { type: 'step-forward' }
  | { type: 'step-back' }
  | { type: 'toggle-checkbox' };

type State = {
  open: boolean;
  stepNumber: number;
  isMultiStep: boolean;
} & DialogOptions;
type DialogProviderProps = { children: React.ReactNode };

const DialogContext = createContext<
  | {
      openDialog: (options: DialogOptions) => void;
      closeDialog: () => void;
    }
  | undefined
>(undefined);

const initialState: State = {
  actionButtons: {
    cancelButton: { children: 'Cancel' },
    submitButton: { children: 'Save' }
  },
  customContent: undefined,
  dialogProps: {
    fullWidth: true,
    maxWidth: 'sm'
  },
  isMultiStep: false,
  onSubmit: () => Promise.resolve(),
  open: false,
  stepNumber: 0,
  steps: [{ contentText: '', fields: {}, title: '' }],
  subcomponentProps: {
    dialogActionsProps: {},
    dialogContentProps: {},
    dialogContentTextProps: {},
    dialogTitleProps: {}
  }
};

function dialogReducer(state: State, action: Action) {
  switch (action.type) {
    case 'open': {
      const isMultiStep =
        'steps' in action.payload &&
        Array.isArray(action.payload.steps) &&
        action.payload.steps.length > 1;

      const newState = {
        ...state,
        ...action.payload,
        isMultiStep,
        open: true
      };

      return newState;
    }
    case 'step-forward': {
      return {
        ...state,
        ...{ stepNumber: state.stepNumber + 1 }
      };
    }
    case 'step-back':
      return {
        ...state,
        ...{ stepNumber: state.stepNumber - 1 }
      };
    case 'close':
      return { ...state, open: false };
    case 'reset':
      return initialState;
    case 'toggle-checkbox': {
      const newState = { ...state };
      return { ...newState };
    }
    default:
      throw new Error('Unhandled action type');
  }
}

type ActionButtonOptions =
  | false
  | {
      type?: 'submit' | 'cancel';
      children: string | React.ReactNode;
      props?: ButtonProps;
      disabled?: boolean;
    }
  | {
      type?: 'submit' | 'cancel';
      component: React.ReactNode;
    };

export type FieldOptionsConfig = {
  initialValue?: any;
  label: string;
  validate?: (
    maxLength: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => boolean;
  maxLength?: number;
  isFieldDisabled?: boolean;
  isFieldRequired?: boolean;
  isFieldAlwaysHidden?: boolean;
  hidden?: boolean;
  fieldProps?: FieldAttributes<any>;
  component?:
    | React.ReactNode
    | ((
        formVaues: Record<string, any>,
        formErrors: Record<string, any>
      ) => React.ReactNode);
  showWhen?: (values: any) => boolean;
};

export type FieldOptions<T extends string = string> = Record<
  T,
  FieldOptionsConfig
>;

type YupObjectShape<T extends string> = Record<
  T,
  yup.AnySchema | Reference | Lazy<any, any>
>;

type ActionButtons = {
  submitButton: ActionButtonOptions;

  [key: string]: ActionButtonOptions;
};

type BaseStep = {
  title: string | React.ReactNode;
};

export type FormStep<FieldNames extends string = string> = BaseStep &
  (
    | {
        fields: FieldOptions<FieldNames>;
        stepValidationSchema?: yup.ObjectSchema<YupObjectShape<FieldNames>>;
      }
    | {
        contentText: React.ReactNode;
        headerText?: React.ReactNode;
      }
  );

export type DialogOptions<
  FieldNames extends string = string,
  Fields = FieldOptions<FieldNames>,
  Values = Record<keyof Fields, string>
> = Partial<{
  steps: (FormStep<FieldNames> & { dialogDescription?: string })[];
  validationSchema: yup.ObjectSchema<YupObjectShape<FieldNames>>;
  dialogDescription?: string;
  customContent?: React.ReactNode;
  actionButtons: ActionButtons;
  preSubmitValidation?: (
    values: Values,
    formikHelpers: FormikHelpers<Values>
  ) => Promise<boolean>;
  onSubmit: (
    values: Values,
    formikHelpers: FormikHelpers<Values>
  ) => Promise<any> | any;
  dialogProps: Omit<DialogProps, 'open'>;
  subcomponentProps: {
    dialogTitleProps?: DialogTitleProps;
    dialogContentProps?: DialogContentProps;
    dialogContentTextProps?: DialogContentTextProps;
    dialogActionsProps?: DialogActionsProps;
  };
  disableValueChangeCheck?: boolean;
  includeSubmitButtonInFormValues?: boolean;
  hideBackOnFirstStep?: boolean;
  disableContinueWhenInvalid?: boolean;
  disableSubmitWhenInvalid?: boolean;
}>;

const useStyles = makeStyles(theme => ({
  buttonRow: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1)
  },
  cancelButton: {
    color: blue[500]
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  stepBackwardButton: {
    color: blue[500]
  },
  stepForwardButton: {
    color: blue[500]
  },
  submitButton: {
    '&:hover': {
      backgroundColor: blue[700]
    },
    backgroundColor: blue[500],
    color: theme.palette.getContrastText(blue[500]),
    marginLeft: theme.spacing(1)
  },
  textGrid: {
    margin: theme.spacing(1, 0, 1, 0),
    padding: theme.spacing(0, 3.5, 0, 3.5),
    textAlign: 'justify'
  }
}));

type FormButtonState = {
  isSingleStep: boolean;
  isMultiStep: boolean;
  isLastStep: boolean;
  isFirstStep: boolean;
  isMiddleStep: boolean;
  isValid: boolean;
  isSubmitting: boolean;
};

type MultiStepFormActionProps = {
  buttonStates: FormButtonState;
  closeDialog: () => void;
  handleStep: (step: StepDirection) => void;
  validateForm: () => Promise<FormikErrors<Record<string, any>>>;
  actionButtons?: ActionButtons;
  unchangedValues: boolean;
  disableValueChangeCheck?: boolean;
  hideBackOnFirstStep?: boolean;
  disableContinueWhenInvalid?: boolean;
  disableSubmitWhenInvalid?: boolean;
};

type CustomizableFormActionButtonProps = {
  closeDialog: () => void;
  validateForm?: () => Promise<FormikErrors<Record<string, any>>>;
  isSubmitting: boolean;
  isValid: boolean;
  unchangedValues: boolean;
  disableValueChangeCheck?: boolean;
  disableSubmitWhenInvalid?: boolean;
} & (
  | {
      type: 'cancel';
      override?: ActionButtons['cancelButton'];
    }
  | {
      type: 'submit';
      override?: ActionButtons['submitButton'];
    }
);

const CustomizableFormActionButton = (
  props: CustomizableFormActionButtonProps
): JSX.Element => {
  const classes = useStyles();

  const {
    closeDialog,
    validateForm = () => Promise.resolve(true),
    type,
    override,
    isSubmitting,
    unchangedValues,
    disableValueChangeCheck,
    disableSubmitWhenInvalid,
    isValid
  } = props;

  const isCancel = type === 'cancel';
  const isSubmit = type === 'submit';

  const buttonText = isCancel ? 'Cancel' : 'Submit';
  const defaultClass = isCancel ? classes.cancelButton : classes.submitButton;

  const buttonPropType = isSubmit ? 'submit' : 'button';

  const actionHandler = isSubmit ? validateForm : closeDialog;
  let disabled =
    isSubmitting ||
    (!disableValueChangeCheck && unchangedValues) ||
    (disableSubmitWhenInvalid && !isValid);

  if (isCancel) {
    disabled = isSubmitting;
  }
  const defaultButton = (
    <Button
      className={defaultClass}
      disabled={disabled}
      onClick={actionHandler}
      type={buttonPropType}
      variant={isCancel ? 'text' : 'contained'}>
      {buttonText}
    </Button>
  );

  // no button override, use default
  if (!override) {
    return defaultButton;
  }

  // custom component provided, use that
  if ('component' in override) {
    return <>{override.component}</>;
  }

  // custom props/children provided, use them
  return (
    <Button
      className={defaultClass}
      color='primary'
      disabled={disabled}
      onClick={() => actionHandler()}
      type={buttonPropType}
      variant={isCancel ? 'text' : 'contained'}
      {...override.props}>
      {override.children}
    </Button>
  );
};

const MultiStepFormActions = (props: MultiStepFormActionProps): JSX.Element => {
  const {
    closeDialog,
    handleStep,
    validateForm,
    actionButtons,
    buttonStates,
    unchangedValues,
    disableValueChangeCheck,
    hideBackOnFirstStep = false,
    disableContinueWhenInvalid = false,
    disableSubmitWhenInvalid = false
  } = props;

  const { isValid, isLastStep, isFirstStep, isSubmitting } = buttonStates;

  const classes = useStyles();

  const cancelButton = (
    <CustomizableFormActionButton
      closeDialog={closeDialog}
      disableValueChangeCheck={disableValueChangeCheck}
      isSubmitting={isSubmitting}
      isValid={isValid}
      override={actionButtons?.cancelButton}
      type='cancel'
      unchangedValues={unchangedValues}
    />
  );

  const backButton = (
    <Button
      disabled={isFirstStep}
      onClick={() => {
        handleStep('backward');
      }}
      variant='outlined'
      // moshe: use 'div' to prevent the browser from auto-submitting the form
      component='div'>
      Back
    </Button>
  );

  return (
    <>
      {/* always render a cancel button */}
      <Grid item>
        {hideBackOnFirstStep && isFirstStep ? null : cancelButton}
      </Grid>
      <Grid item>
        {hideBackOnFirstStep && isFirstStep && cancelButton}
        {hideBackOnFirstStep && isFirstStep ? null : backButton}
        {!isLastStep && (
          <Button
            className={classes.submitButton}
            // moshe: use 'div' to prevent the browser from auto-submitting the form
            component='div'
            disabled={
              unchangedValues || (disableContinueWhenInvalid && !isValid)
            }
            onClick={() =>
              validateForm()
                .then(errors => {
                  if (isEmpty(errors)) {
                    handleStep('forward');
                  } else {
                    console.error(errors);
                  }
                })
                .catch(e => {
                  console.error(e);
                })
            }
            variant='contained'>
            Continue
          </Button>
        )}
        {isLastStep && (
          <CustomizableFormActionButton
            closeDialog={closeDialog}
            disableSubmitWhenInvalid={disableSubmitWhenInvalid}
            disableValueChangeCheck={disableValueChangeCheck}
            isSubmitting={isSubmitting}
            isValid={isValid}
            override={actionButtons?.submitButton}
            type='submit'
            unchangedValues={unchangedValues}
            validateForm={validateForm}
          />
        )}
      </Grid>
    </>
  );
};

const getStepInitialValues = (fields: any) =>
  Object.fromEntries(
    Object.entries(fields ?? {}).map(([name, fieldOptions]) => [
      name,
      (fieldOptions as any).initialValue
    ])
  );

function DialogProvider({ children }: DialogProviderProps): JSX.Element {
  const classes = useStyles();
  const [state, dispatch] = useReducer(dialogReducer, initialState);
  const {
    open,
    steps,
    stepNumber,
    isMultiStep,
    dialogDescription,
    validationSchema,
    actionButtons,
    preSubmitValidation,
    onSubmit,
    dialogProps,
    customContent,
    disableValueChangeCheck,
    includeSubmitButtonInFormValues,
    hideBackOnFirstStep,
    disableContinueWhenInvalid = false,
    disableSubmitWhenInvalid = false
  } = state;
  const location = useLocation();

  useEffect(() => {
    if (open) {
      dispatch({ type: 'close' });
    }
    // The dialog should close if the location changes and the dialog is open
  }, [location]);

  if (!steps) {
    throw new Error(`todo fix the Partial type that makes steps optional`);
  }

  const handleStep = (direction: StepDirection) => {
    if (direction === 'forward') {
      return dispatch({ type: 'step-forward' });
    }

    return dispatch({ type: 'step-back' });
  };

  const step = steps[stepNumber];

  const { title } = step;
  const fields = 'fields' in step ? step.fields : {};
  const stepValidationSchema =
    'stepValidationSchema' in step ? step.stepValidationSchema : null;
  const contentText = 'contentText' in step ? step.contentText : '';
  const headerText = 'headerText' in step ? step.headerText : '';

  const isLastStep = stepNumber === steps.length - 1;
  const isFirstStep = stepNumber === 0;

  const a: Record<string, any> = {};
  const allTheValues = steps.flatMap(s =>
    getStepInitialValues('fields' in s ? s.fields : {})
  );

  allTheValues.forEach(obj => {
    Object.keys(obj).forEach(k => {
      a[k] = obj[k];
    });
  });

  const initialValues = a;

  const openDialog = useCallback((options: DialogOptions) => {
    // ensure any dialog state is clear any time we open one
    dispatch({ type: 'reset' });
    return dispatch({ payload: options, type: 'open' });
  }, []);
  const closeDialog = useCallback(() => dispatch({ type: 'close' }), []);
  const handleOnExited = () => dispatch({ type: 'reset' });
  const handleSubmit = async (
    values: typeof initialValues,
    formikHelpers: FormikHelpers<typeof initialValues>
  ) => {
    if (!onSubmit) {
      return;
    }

    const preSubmitResult =
      preSubmitValidation && (await preSubmitValidation(values, formikHelpers));
    if (preSubmitResult) {
      return;
    }

    const errors = await formikHelpers.validateForm(values);

    if (isEmpty(errors)) {
      try {
        await onSubmit(values, formikHelpers);
      } catch (e) {
        // moshe: log.error since otherwise react logs it as a warning
        console.error(e);
        throw e;
      }
      closeDialog();
    }
  };

  const value = useMemo(
    () => ({ closeDialog, openDialog }),
    [openDialog, closeDialog]
  );

  return (
    <DialogContext.Provider value={value}>
      {children}
      <Dialog
        TransitionProps={{ onExited: handleOnExited }}
        aria-describedby='dialog-description'
        aria-labelledby='dialog-title'
        onClose={closeDialog}
        open={open}
        {...dialogProps}>
        {customContent ?? (
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validateOnBlur
            validationSchema={stepValidationSchema || validationSchema}>
            {formProps => {
              const buttonStates: FormButtonState = {
                isFirstStep,
                isLastStep,
                isMiddleStep: !isFirstStep && !isLastStep,
                isMultiStep,
                isSingleStep: !isMultiStep,
                isSubmitting: formProps.isSubmitting,
                isValid: formProps.isValid && !formProps.isValidating
              };

              const getStepCurrentValues = fields => {
                return Object.fromEntries(
                  Object.keys(fields).map(key => [
                    key,
                    formProps.getFieldProps(key).value
                  ])
                );
              };

              const unchangedValues =
                !isEmpty(fields) &&
                isEqual(
                  getStepInitialValues(fields),
                  getStepCurrentValues(fields)
                );

              const handleFormSubmit: React.FormEventHandler<
                HTMLFormElement
              > = e => {
                const submitEvent = e.nativeEvent as SubmitEvent;
                if (includeSubmitButtonInFormValues && submitEvent.submitter) {
                  formProps.setFieldValue(
                    'submitBtn',
                    submitEvent.submitter.id
                  );
                }
                return formProps.handleSubmit(e);
              };

              return (
                <>
                  <DialogTitle id='dialog-title'>{title}</DialogTitle>
                  <DialogContent className={classes.dialogContent}>
                    <>
                      {headerText && (
                        <DialogContentText sx={{ mb: 2 }}>
                          {headerText}
                        </DialogContentText>
                      )}

                      <FromFields fields={fields} stepNumber={stepNumber} />
                      {contentText && (
                        <DialogContentText>{contentText}</DialogContentText>
                      )}
                    </>
                  </DialogContent>
                  <Form onSubmit={handleFormSubmit}>
                    <Divider />
                    <DialogActions>
                      <Grid
                        className={classes.buttonRow}
                        container
                        justifyContent={
                          buttonStates.isSingleStep
                            ? 'flex-end'
                            : 'space-between'
                        }>
                        {buttonStates.isMultiStep && (
                          <MultiStepFormActions
                            actionButtons={actionButtons}
                            buttonStates={buttonStates}
                            closeDialog={closeDialog}
                            disableContinueWhenInvalid={
                              disableContinueWhenInvalid
                            }
                            disableSubmitWhenInvalid={disableSubmitWhenInvalid}
                            disableValueChangeCheck={disableValueChangeCheck}
                            handleStep={handleStep}
                            hideBackOnFirstStep={hideBackOnFirstStep}
                            unchangedValues={unchangedValues}
                            validateForm={() => {
                              formProps.setTouched(
                                Object.fromEntries(
                                  Object.keys(fields).map(k => [k, true])
                                )
                              );

                              return formProps.validateForm();
                            }}
                          />
                        )}
                        {buttonStates.isSingleStep &&
                          actionButtons &&
                          Object.entries(actionButtons).map(
                            ([button, buttonConfig]) => (
                              <Grid item key={button}>
                                <CustomizableFormActionButton
                                  closeDialog={closeDialog}
                                  disableSubmitWhenInvalid={
                                    disableSubmitWhenInvalid
                                  }
                                  disableValueChangeCheck={
                                    disableValueChangeCheck
                                  }
                                  isSubmitting={buttonStates.isSubmitting}
                                  isValid={buttonStates.isValid}
                                  override={buttonConfig}
                                  type={
                                    buttonConfig && buttonConfig.type
                                      ? buttonConfig.type
                                      : button === 'submitButton'
                                        ? 'submit'
                                        : 'cancel'
                                  }
                                  unchangedValues={unchangedValues}
                                  validateForm={formProps.validateForm}
                                />
                              </Grid>
                            )
                          )}
                      </Grid>
                    </DialogActions>

                    {dialogDescription ||
                      (step.dialogDescription && (
                        <Grid className={classes.textGrid} container>
                          <Grid item xs>
                            <Typography
                              color='textSecondary'
                              data-testid='dialog-description'
                              variant='caption'>
                              {dialogDescription || step.dialogDescription}
                            </Typography>
                          </Grid>
                        </Grid>
                      ))}
                  </Form>
                </>
              );
            }}
          </Formik>
        )}
      </Dialog>
    </DialogContext.Provider>
  );
}

function useDialog(): {
  openDialog: (options: DialogOptions) => void;
  closeDialog: () => void;
} {
  const context = useContext(DialogContext);
  if (context === undefined) {
    throw new Error('useDialog must be used within a DialogProvider');
  }
  return context;
}

export { DialogProvider, useDialog };
