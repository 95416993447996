import { CardPlaceholder } from '@/components/card';
import SimpleDropdown from '@/components/simple-dropdown';
import { useDialog } from '@/contexts/DialogContext';
import { useSnackbar } from '@/contexts/SnackBarContext';
import {
  CreateRothDetailsDto,
  RothDetailsDto,
  RothDetailsResponseDto
} from '@/models';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import ParticipantService from '@/services/Participant.service';
import { userService } from '@/services/User.service';
import formatters from '@/utils/Formatters';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import {
  Box,
  Button,
  Card,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Paper,
  TextField,
  Typography
} from '@mui/material';
import {
  DataGridPro,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridValidRowModel,
  MuiEvent
} from '@mui/x-data-grid-pro';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useMutation, useQuery } from '@tanstack/react-query';

import dayjs, { Dayjs } from 'dayjs';
import { Form, Formik } from 'formik';
import { isEqual } from 'lodash';
import { useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';

interface RothDetailsProps {
  participantId: string;
}

const RothDetails = (props: RothDetailsProps): JSX.Element => {
  const { showSnackbar } = useSnackbar();
  const { openDialog, closeDialog } = useDialog();
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const validationSchema = yup.object({
    detailsType: yup
      .string()
      .oneOf(['Conversion', 'Rollover', 'Distribution', 'Other'])
      .required('Required'),
    otherDetailsType: yup.string().when('detailsType', {
      is: (detailsType: string) => detailsType === 'Other',
      then: value => value.required('Required')
    }),
    rothCostBasis: yup.string().matches(/^(?:[1-9]\d*|0)\.?\d*$/, {
      excludeEmptyString: false,
      message: 'Value must be valid number'
    }),
    rothEstablishedDate: yup.date()
  });

  const rothDetailsQuery = useQuery<RothDetailsResponseDto>(
    ['ParticipantService.getRothDetails', +props.participantId],
    () => {
      return ParticipantService.getRothDetails(+props.participantId);
    },
    {
      enabled: Boolean(+props.participantId),
      staleTime: Infinity
    }
  );

  const hasWritePermissions = userService.hasPermission(
    FeatureLevelPermissions.WRITE_ROTH_DETAILS
  );

  const sendRothDetails = useMutation(
    ['ParticipantService.createRothDetails'],
    (dto: CreateRothDetailsDto) => {
      return ParticipantService.createRothDetails(+props.participantId, dto);
    },
    {
      onError: () => {
        showSnackbar({
          message: 'Failed to create roth details',
          severity: 'error'
        });
      },
      onSuccess: () => {
        showSnackbar({
          message: 'Created roth details',
          severity: 'success'
        });
        rothDetailsQuery.refetch();
      }
    }
  );

  const updateRothDetails = useMutation(
    ['ParticipantService.updateRothDetails'],
    (dto: CreateRothDetailsDto) => {
      return ParticipantService.updateRothDetails(+props.participantId, dto);
    },
    {
      onError: () => {
        showSnackbar({
          message: 'Failed to update roth details',
          severity: 'error'
        });
      },
      onSuccess: () => {
        showSnackbar({
          message: 'Updated roth details successfully',
          severity: 'success'
        });
        rothDetailsQuery.refetch();
      }
    }
  );

  const deleteRothDetails = useMutation(
    ['ParticipantService.deleteRothDetails'],
    (detailsId: number) => {
      return ParticipantService.deleteRothDetails(
        +props.participantId,
        detailsId
      );
    },
    {
      onError: () => {
        showSnackbar({
          message: 'Failed to delete roth details',
          severity: 'error'
        });
      },
      onSuccess: () => {
        showSnackbar({
          message: 'Deleted roth details successfully',
          severity: 'success'
        });
        rothDetailsQuery.refetch();
      }
    }
  );

  const rothDetailsTableData = useMemo(() => {
    return (
      rothDetailsQuery.data?.data.map((rothDetails: RothDetailsDto) => {
        return {
          id: rothDetails.data.id ?? uuidv4(),
          ...rothDetails.data.attributes
        };
      }) ?? []
    );
  }, [rothDetailsQuery.data]);

  if (rothDetailsQuery.isFetching) {
    return <p>Loading....</p>;
  }

  if (!rothDetailsQuery.isSuccess) {
    return <p>Error</p>;
  }

  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (
    params,
    event
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    const detailsRow = rothDetailsTableData.find((row: any) => row.id === id);

    openDialog({
      customContent: (
        <>
          <DialogTitle>
            Are you sure you want to remove Roth Details?
          </DialogTitle>
          <DialogContent>
            <Box sx={{ marginBottom: theme => theme.spacing(1) }}>
              <Typography sx={{ fontWeight: 'bold' }} variant='body1'>
                ID
              </Typography>
              <Typography variant='body1'>{detailsRow?.id}</Typography>
            </Box>
            <Box sx={{ marginBottom: theme => theme.spacing(1) }}>
              <Typography sx={{ fontWeight: 'bold' }} variant='body1'>
                Type
              </Typography>
              <Typography variant='body1'>{detailsRow?.detailsType}</Typography>
            </Box>
            <Box sx={{ marginBottom: theme => theme.spacing(1) }}>
              <Typography sx={{ fontWeight: 'bold' }} variant='body1'>
                Roth Cost Basis
              </Typography>
              <Typography variant='body1'>
                {formatters.formatDollars(detailsRow?.rothCostBasis)}
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontWeight: 'bold' }} variant='body1'>
                Roth Established Date
              </Typography>
              <Typography variant='body1'>
                {detailsRow?.rothEstablishedDate
                  ? formatters.formatFromIsoDateCustom(
                      detailsRow?.rothEstablishedDate,
                      'MMMM D, YYYY'
                    )
                  : detailsRow?.rothEstablishedDate}
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog}>Close</Button>
            <Button
              onClick={() => {
                if (detailsRow) {
                  deleteRothDetails.mutateAsync(+detailsRow?.id);
                }
                closeDialog();
              }}
              variant='contained'>
              Remove
            </Button>
          </DialogActions>
        </>
      )
    });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { ignoreModifications: true, mode: GridRowModes.View }
    });
  };

  const processRowUpdate = async (newRow: GridValidRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    await updateRothDetails.mutateAsync({
      data: {
        attributes: {
          detailsType: newRow.detailsType,
          rothCostBasis: +newRow.rothCostBasis,
          rothEstablishedDate: newRow.rothEstablishedDate
        },
        id: newRow.id,
        relationships: {
          participant: {
            data: {
              id: +props.participantId,
              type: 'participant'
            }
          }
        },
        type: 'roth-details'
      }
    } as CreateRothDetailsDto);
    return updatedRow;
  };

  const dataGridColumns: GridColDef[] = [
    {
      field: 'id',
      flex: 0.3,
      headerName: 'ID',
      valueFormatter: ({ value }) => {
        return isNaN(value) ? '' : value;
      }
    },
    {
      field: 'detailsType',
      flex: 1,
      headerName: 'Type'
    },
    {
      editable: true,
      field: 'rothEstablishedDate',
      flex: 1,
      headerName: 'Roth Established Date',
      type: 'date',
      valueFormatter: ({ value }) => {
        return formatters.formatFromIsoDateCustom(value, 'MMMM D, YYYY');
      }
    },
    {
      editable: true,
      field: 'rothCostBasis',
      flex: 1,
      headerName: 'Roth Cost Basis',
      type: 'number',
      valueFormatter: ({ value }) => {
        return formatters.formatDollars(value);
      }
    },
    {
      field: 'actions',
      flex: 0.5,
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        const rothDetail = rothDetailsTableData.find(
          (row: any) => row.id === id
        );

        if (
          (rothDetail &&
            rothDetail.detailsType === 'Roth contributions on Vestwell') ||
          !hasWritePermissions
        ) {
          return [];
        }

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              key='Save'
              label='Save'
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              className='textPrimary'
              color='inherit'
              icon={<ClearIcon />}
              key='Cancel'
              label='Cancel'
              onClick={handleCancelClick(id)}
            />
          ];
        }

        return [
          <GridActionsCellItem
            className='textPrimary'
            color='inherit'
            icon={<EditIcon />}
            key='Edit'
            label='Edit'
            onClick={handleEditClick(id)}
          />,
          <GridActionsCellItem
            color='inherit'
            icon={<DeleteIcon />}
            key='Delete'
            label='Delete'
            onClick={handleDeleteClick(id)}
          />
        ];
      },
      headerName: 'Action',
      type: 'actions'
    }
  ];

  return (
    <>
      {hasWritePermissions && (
        <Grid alignItems='flex-end' container direction='column'>
          <Grid item>
            <Button
              onClick={() => {
                openDialog({
                  customContent: (
                    <Formik
                      initialValues={{
                        detailsType: '',
                        otherDetailsType: '',
                        rothCostBasis: '',
                        rothEstablishedDate: ''
                      }}
                      onSubmit={async ({
                        detailsType,
                        otherDetailsType,
                        rothCostBasis,
                        rothEstablishedDate
                      }) => {
                        await sendRothDetails.mutateAsync({
                          data: {
                            attributes: {
                              detailsType:
                                detailsType === 'Other'
                                  ? otherDetailsType
                                  : detailsType,
                              rothCostBasis: +rothCostBasis,
                              rothEstablishedDate
                            },
                            relationships: {
                              participant: {
                                data: {
                                  id: +props.participantId,
                                  type: 'participant'
                                }
                              }
                            },
                            type: 'roth-details'
                          }
                        } as CreateRothDetailsDto);
                        closeDialog();
                      }}
                      validateOnBlur={false}
                      validationSchema={validationSchema}>
                      {formProps => {
                        const unchangedValues =
                          isEqual(formProps.initialValues, formProps.values) &&
                          Object.keys(formProps.initialValues).length > 0 &&
                          Object.keys(formProps.values).length > 0;
                        const saveDisabled =
                          formProps.isSubmitting ||
                          unchangedValues ||
                          !formProps.isValid;
                        return (
                          <Form>
                            <DialogTitle>Add Roth Details</DialogTitle>
                            <DialogContent
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: theme => theme.spacing(2),
                                marginBottom: theme => theme.spacing(2)
                              }}>
                              <SimpleDropdown
                                fieldId='detailsType'
                                fieldName='Type'
                                fieldValues={[
                                  'Conversion',
                                  'Rollover',
                                  'Distribution',
                                  'Other'
                                ]}
                              />
                              {formProps.values.detailsType === 'Other' && (
                                <FormControl
                                  error={Boolean(
                                    formProps.errors.otherDetailsType
                                  )}>
                                  <TextField
                                    error={Boolean(
                                      formProps.errors.otherDetailsType
                                    )}
                                    fullWidth
                                    helperText={
                                      formProps.errors.otherDetailsType
                                    }
                                    id='otherDetailsType'
                                    label='Other Type'
                                    name='otherDetailsType'
                                    onChange={formProps.handleChange}
                                    value={formProps.values.otherDetailsType}
                                  />
                                </FormControl>
                              )}
                              <FormControl
                                error={Boolean(formProps.errors.rothCostBasis)}>
                                <TextField
                                  error={Boolean(
                                    formProps.errors.rothCostBasis
                                  )}
                                  fullWidth
                                  helperText={formProps.errors.rothCostBasis}
                                  id='rothCostBasis'
                                  label='Roth Cost Basis'
                                  name='rothCostBasis'
                                  onChange={formProps.handleChange}
                                  value={formProps.values.rothCostBasis}
                                />
                              </FormControl>
                              <FormControl sx={{ width: 220 }}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}>
                                  <DatePicker
                                    label='Roth Established Date'
                                    onChange={(newValue: Dayjs | null) => {
                                      formProps.setFieldValue(
                                        'rothEstablishedDate',
                                        newValue
                                      );
                                    }}
                                    slotProps={{
                                      textField: {
                                        InputLabelProps: {
                                          shrink: true
                                        },
                                        //@ts-expect-error
                                        'data-testid': 'date-input',
                                        error:
                                          !!formProps.errors
                                            .rothEstablishedDate,
                                        helperText: formProps.errors
                                          .rothEstablishedDate
                                          ? 'Invalid date'
                                          : ''
                                      }
                                    }}
                                    value={dayjs(
                                      formProps.values.rothEstablishedDate
                                    )}
                                  />
                                </LocalizationProvider>
                              </FormControl>
                            </DialogContent>
                            <DialogActions>
                              <Button
                                disabled={formProps.isSubmitting}
                                onClick={closeDialog}>
                                Cancel
                              </Button>
                              <Button
                                disabled={saveDisabled}
                                type='submit'
                                variant='contained'>
                                Save
                              </Button>
                            </DialogActions>
                          </Form>
                        );
                      }}
                    </Formik>
                  )
                });
              }}
              sx={{ marginBottom: theme => theme.spacing(2) }}
              variant='contained'>
              Add Roth Detail
            </Button>
          </Grid>
        </Grid>
      )}
      {!rothDetailsTableData.length ? (
        <Card
          sx={{
            fontSize: theme => theme.spacing(2.4),
            padding: theme => theme.spacing(2),
            textAlign: 'center'
          }}>
          <Typography
            sx={{
              marginBottom: theme => theme.spacing(1),
              textAlign: 'left'
            }}
            variant='h5'>
            Roth Details
          </Typography>
          <CardPlaceholder
            data-testid='no-data-roth-participant'
            subtitle='No data for this participant'
          />
        </Card>
      ) : (
        <Paper
          elevation={0}
          sx={{ marginBottom: theme => theme.spacing(2), width: '100%' }}
          variant='outlined'>
          <Typography
            component='div'
            id='tableTitle'
            sx={{ padding: theme => theme.spacing(2) }}
            variant='h5'>
            Roth Details
          </Typography>
          <DataGridPro
            autoHeight
            columns={dataGridColumns}
            editMode='row'
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            onRowModesModelChange={newModel => setRowModesModel(newModel)}
            processRowUpdate={processRowUpdate}
            rowModesModel={rowModesModel}
            rows={rothDetailsTableData}
          />
        </Paper>
      )}
    </>
  );
};

export default RothDetails;
