export enum PlanFilterType {
  PLAN_ID = 'plan_id',
  PLAN_NAME = 'plan_name',
  FIRM = 'firm',
  PROGRAM = 'program'
}

export interface PlanFilters {
  filterType: PlanFilterType;
  search: string;
  statuses: string[];
  types?: string[];
}

export const statusesOptions = [
  'Abandoned',
  'Active',
  'Deconversion Requested',
  'Deconverted',
  'Deferred',
  'OB Cancelled',
  'Onboarding',
  'Pending',
  'Ready - Awaiting Effective Date',
  'Terminated',
  'Termination Requested'
];

export const pooledStatusesOptions = ['Confirmed', 'Pending'];

export const pooledMainTypesOptions = ['MEP', 'GOP', 'PEP'];

export const planMainTypesOptions = [
  'cash_balance',
  'Starter 401k',
  '403b',
  '401k',
  'State IRA'
];

export const initialPlansFilters = {
  filterType: PlanFilterType.PLAN_ID,
  search: '',
  statuses: [],
  types: []
};
