import Badge from '@/components/badge';
import { redirectToErrorPage } from '@/components/error-detail/ErrorDetailPage.component';
import ErrorMessage from '@/components/error-message';
import LinearLoading from '@/components/linear-loading';
import { QueueErrorCard } from '@/components/queue-error-card/QueueErrorCard.component';
import SimpleTextarea from '@/components/simple-textarea';
import { useDialog } from '@/contexts/DialogContext';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { useUserToken } from '@/contexts/UserTokenContext';
import { useParticipantSuspiciousActivity } from '@/hooks/useParticipantSuspiciousActivity.hook';
import {
  LoanDetailsResponseDto,
  ParticipantAccountsDto,
  ParticipantInfo
} from '@/models';
import {
  LoanStatus,
  LoanTradeRequestDto,
  UpdateLoanDto
} from '@/models/LoanDTO.model';
import { PlanV2Dto } from '@/models/PlanV2DTO.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import LoanDestinationCard from '@/routes/participants/participant-detail/Loans/LoanDestinationCard.component';
import ParticipantService from '@/services/Participant.service';
import { PlanService } from '@/services/Plan.service';
import { userService } from '@/services/User.service';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import {
  Box,
  Button,
  Grid,
  Modal,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';

import { isEmpty } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';

import { NotesInfoCard } from '../NotesInfoCard.component';
import FraudInfoCard from '../Withdrawals/FraudInfoCard.component';
import ParticipantInfoCard from '../Withdrawals/ParticipantInfoCard.component';
import LoansAmortizationSchedule from './LoanAmortizationSchedule.component';
import LoanDetailsDataGridsCard from './LoanDetailsDataGrids/LoanDetailsDataGridsCard.component';
import LoanInfoCard from './LoanInfoCard.component';

type LoanDetailRouteProps = {
  participantId: string;
  loanId: string;
};

type FraudNotesMutationParams = {
  calloutToParticipant?: boolean;
  fraudNotes?: string;
  latestFraudRank?: string;
};

const LoanDetailsRoute: React.FunctionComponent<LoanDetailRouteProps> = () => {
  const { participantId, loanId } = useParams<LoanDetailRouteProps>();
  const { userHasValidToken } = useUserToken();
  const { showSnackbar } = useSnackbar();
  const { openDialog } = useDialog();

  const suspiciousQuery = useParticipantSuspiciousActivity(participantId);

  const participantQuery = useQuery<ParticipantInfo>(
    ['ParticipantService.getParticipantById', participantId?.toString()],
    async () => ParticipantService.getParticipantById(participantId),
    {
      enabled: Boolean(participantId && userHasValidToken),
      staleTime: Infinity
    }
  );

  const planQuery = useQuery<PlanV2Dto>(
    [
      'PlanService.getPlanById',
      participantQuery.data?.sponsorPlanId?.toString()
    ],
    () => {
      return participantQuery.data?.sponsorPlanId
        ? PlanService.getPlanById(participantQuery.data.sponsorPlanId)
        : ({} as PlanV2Dto);
    },
    {
      enabled: Boolean(
        participantId &&
          userHasValidToken &&
          participantQuery.data?.sponsorPlanId
      ),
      staleTime: Infinity
    }
  );

  const loanQuery = useQuery<LoanDetailsResponseDto>(
    ['ParticipantService.getLoan', participantId, loanId, true],
    async () => ParticipantService.getLoan(+participantId, +loanId, true),
    { enabled: !!participantId && !!loanId }
  );

  const accountsQuery = useQuery<ParticipantAccountsDto>(
    ['ParticipantService.getParticipantAccounts', participantId],
    () => ParticipantService.getParticipantAccounts(participantId),
    {
      enabled: Boolean(participantId && userHasValidToken),
      staleTime: Infinity
    }
  );

  const tradeRequests = useQuery<LoanTradeRequestDto>(
    ['ParticipantService.getLoanTradeRequests', participantId, loanId],
    () => {
      return ParticipantService.getLoanTradeRequests(+participantId, +loanId);
    },
    { enabled: !!participantId && !!loanId }
  );

  const loanStatusMutation = useMutation(
    ['ParticipantService.updateLoan', participantId, loanId],
    (status: LoanStatus) =>
      ParticipantService.updateLoan(+participantId, +loanId, {
        attributes: { status },
        id: +loanId
      } as UpdateLoanDto),
    {
      onSuccess: () => {
        loanQuery.refetch();
      }
    }
  );

  const { mutate: notesMutation } = useMutation(
    ['ParticipantService.Notes', participantId, loanId],
    (notes: string) =>
      ParticipantService.updateLoan(+participantId, +loanId, {
        attributes: { notes },
        id: +loanId
      } as UpdateLoanDto),
    {
      onError: () => {
        showSnackbar({
          message: 'Error updating loan notes',
          severity: 'error'
        });
      },
      onSuccess: () => {
        loanQuery.refetch();

        showSnackbar({
          message: 'Notes updated successfully!',
          severity: 'success'
        });
      }
    }
  );

  const { mutate: fraudNotesMutation } = useMutation(
    ['ParticipantService.updateLoan', participantId, loanId],
    (params: FraudNotesMutationParams) =>
      ParticipantService.updateLoan(+participantId, +loanId, {
        attributes: { ...params },
        id: +loanId
      } as UpdateLoanDto),
    {
      onError: () => {
        showSnackbar({
          message: 'Error updating loan fraud data',
          severity: 'error'
        });
      },
      onSuccess: () => {
        loanQuery.refetch();

        showSnackbar({
          message: 'Fraud data updated successfully!',
          severity: 'success'
        });
      }
    }
  );

  const isVestwellSubaccounting = useMemo(
    () =>
      planQuery.data?.data.attributes.recordkeeper ===
      'Vestwell Sub-Accounting Platform',
    [planQuery.data?.data.attributes.recordkeeper]
  );

  const openEditNotesModal = useCallback(
    () =>
      openDialog({
        actionButtons: {
          cancelButton: {
            children: 'Cancel'
          },
          submitButton: {
            children: 'Save'
          }
        },
        onSubmit: async values => {
          await notesMutation(values.notes);
        },
        steps: [
          {
            fields: {
              notes: {
                component: (
                  <SimpleTextarea label='' name='notes' placeholder='Notes' />
                ),
                initialValue: loanQuery.data.data.attributes.notes,
                label: 'Notes'
              }
            },
            title: 'Edit Notes'
          }
        ]
      }),
    [loanQuery]
  );

  const [newStatus, setNewStatus]: [
    LoanStatus | undefined,
    React.Dispatch<React.SetStateAction<LoanStatus | undefined>>
  ] = useState();
  const loanActionsEnabled = useMemo(() => {
    return (
      userService.hasPermission(FeatureLevelPermissions.WRITE_LOANS_ACTION) &&
      isVestwellSubaccounting
    );
  }, [isVestwellSubaccounting]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const suspiciousActivity = suspiciousQuery.data?.hasSuspiciousActivity;

  const error = participantQuery.error || planQuery.error;

  const planBlackoutActive =
    (planQuery.data?.data.attributes.isBlackout ||
      planQuery.data?.data.attributes.isOffboardingBlackoutActive) ??
    false;

  const missingParticipantAddressinfo = [
    participantQuery.data?.address.address1,
    participantQuery.data?.address.city,
    participantQuery.data?.address.state,
    participantQuery.data?.address.zip
  ].some(isEmpty);

  if (error) {
    return redirectToErrorPage(error as Error);
  }

  const requestError =
    participantQuery.error || (planQuery.error as any | undefined);

  const isLoading = participantQuery.isFetching || planQuery.isFetching;

  const isSuccess =
    participantQuery.isSuccess && planQuery.isSuccess && loanQuery.isSuccess;

  const loanStatus = loanQuery.data?.data.attributes.loanStatus ?? '';

  const colorByStatus: Record<
    string,
    | 'success'
    | 'neutral'
    | 'warning'
    | 'info'
    | 'lightWarning'
    | 'error'
    | undefined
  > = {
    'Awaiting Participant Acceptance': 'neutral',
    Declined: 'warning',
    Default: 'neutral',
    'Disbursement Pending': 'neutral',
    'Fraud Check Pending': 'warning',
    Live: 'success',
    Pending: 'neutral',
    Processing: 'neutral',
    Rejected: 'info'
  };

  return (
    <>
      {isLoading && <LinearLoading />}
      <Button
        component={RouterLink}
        startIcon={<ArrowBackOutlinedIcon />}
        to={`/participants/${participantId}/loans`}
        variant='text'>
        Back to loans
      </Button>
      <Stack direction='row' pb={2} spacing={2}>
        <Typography variant='h6'>
          Loan Request ID: {loanQuery.data?.data.id}
        </Typography>
        <Badge color={colorByStatus[loanStatus] || 'neutral'}>
          {loanStatus}
        </Badge>
      </Stack>
      {isSuccess && (
        <Grid container direction='column' spacing={4}>
          <Grid item>
            <Grid
              container
              direction='row'
              sx={{
                justifyContent: 'space-between'
              }}>
              {loanActionsEnabled && (
                <Grid item>
                  {loanStatus === 'Fraud Check Pending' && (
                    <Tooltip
                      disableHoverListener={
                        !planBlackoutActive && !missingParticipantAddressinfo
                      }
                      title={
                        planBlackoutActive
                          ? 'Plan is in blackout period.'
                          : missingParticipantAddressinfo
                            ? 'Participant is missing address info.'
                            : ''
                      }>
                      <span>
                        <Button
                          color='primary'
                          disabled={
                            planBlackoutActive ||
                            suspiciousActivity ||
                            missingParticipantAddressinfo
                          }
                          onClick={() => {
                            setNewStatus('Disbursement Pending');
                            setIsModalOpen(true);
                          }}
                          sx={{
                            display: 'inline-flex',
                            marginRight: theme => theme.spacing(2)
                          }}
                          variant='outlined'>
                          Approve
                        </Button>
                      </span>
                    </Tooltip>
                  )}
                  {[
                    'Awaiting Participant Acceptance',
                    'Fraud Check Pending'
                  ].includes(loanStatus) && (
                    <>
                      <Button
                        color='primary'
                        onClick={() => {
                          setNewStatus('Rejected');
                          setIsModalOpen(true);
                        }}
                        sx={{
                          display: 'inline-flex',
                          marginRight: theme => theme.spacing(2)
                        }}
                        variant='outlined'>
                        Reject
                      </Button>
                      <Button
                        color='error'
                        onClick={() => {
                          setNewStatus('Canceled');
                          setIsModalOpen(true);
                        }}
                        sx={{
                          display: 'inline-flex'
                        }}
                        variant='outlined'>
                        Cancel
                      </Button>
                    </>
                  )}
                  {['Past Due', 'Deemed'].includes(loanStatus) && (
                    <Button
                      color='primary'
                      onClick={() => {
                        setNewStatus('Offset');
                        setIsModalOpen(true);
                      }}
                      sx={{
                        display: 'inline-flex',
                        marginRight: theme => theme.spacing(2)
                      }}
                      variant='outlined'>
                      Offset
                    </Button>
                  )}
                  {['Live', 'Past Due', 'Deemed'].includes(loanStatus) && (
                    <Button
                      color='primary'
                      onClick={() => {
                        setNewStatus('Paid in Full');
                        setIsModalOpen(true);
                      }}
                      sx={{
                        display: 'inline-flex',
                        marginRight: theme => theme.spacing(2)
                      }}
                      variant='outlined'>
                      Mark as Paid
                    </Button>
                  )}
                  {loanStatus === 'Live' && (
                    <>
                      <Button
                        color='primary'
                        onClick={() => {
                          setNewStatus('Past Due');
                          setIsModalOpen(true);
                        }}
                        sx={{
                          display: 'inline-flex',
                          marginRight: theme => theme.spacing(2)
                        }}
                        variant='outlined'>
                        Mark as Past Due
                      </Button>
                      <Button
                        color='primary'
                        onClick={() => {
                          setNewStatus('Reamortized');
                          setIsModalOpen(true);
                        }}
                        sx={{ display: 'inline-flex' }}
                        variant='outlined'>
                        Mark as Reamortized
                      </Button>
                    </>
                  )}
                  {loanStatus === 'Past Due' && (
                    <Button
                      color='primary'
                      onClick={() => {
                        setNewStatus('Deemed');
                        setIsModalOpen(true);
                      }}
                      sx={{
                        display: 'inline-flex',
                        marginRight: theme => theme.spacing(2)
                      }}
                      variant='outlined'>
                      Deemed
                    </Button>
                  )}
                  {'Disbursement Pending' === loanStatus &&
                    (!tradeRequests.data ||
                      ['SUBA_ABORTED', 'SUBA_FAILED'].includes(
                        tradeRequests.data?.status
                      )) && (
                      <Button
                        color='error'
                        onClick={() => {
                          setNewStatus('Canceled');
                          setIsModalOpen(true);
                        }}
                        sx={{
                          display: 'inline-flex'
                        }}
                        variant='outlined'>
                        Cancel
                      </Button>
                    )}
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid item>
            <Grid
              columnSpacing={{ md: 2, sm: 2, xs: 1 }}
              container
              rowSpacing={2}>
              <Grid item xs={8}>
                <Stack spacing={2}>
                  <LoanInfoCard loanDetails={loanQuery.data} />
                  <LoanDestinationCard loanDetails={loanQuery.data} />
                  {loanQuery.data.included && (
                    <LoansAmortizationSchedule
                      loanExpectedPaymentData={loanQuery.data.included}
                    />
                  )}
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Grid
                  columnSpacing={{ md: 3, sm: 2, xs: 1 }}
                  container
                  rowSpacing={2}>
                  <Grid item xs={12}>
                    <NotesInfoCard
                      notes={loanQuery.data.data.attributes.notes}
                      onClickEdit={openEditNotesModal}
                      title='loan'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ParticipantInfoCard
                      balance={{
                        total: accountsQuery.data?.stats.balance.total,
                        vestedTotal:
                          accountsQuery.data?.stats.vesting.vestedTotal
                      }}
                      participant={participantQuery.data}
                      participantId={participantId}
                      sponsorPlan={planQuery?.data?.data}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FraudInfoCard
                      callOutToParticipant={
                        loanQuery.data.data.attributes.calloutToParticipant
                      }
                      creationDate={loanQuery.data.data.attributes.createdAt}
                      fraudNotes={loanQuery.data.data.attributes.fraudNotes}
                      fraudrankerResult={
                        loanQuery.data.data.attributes.fraudRankResult
                      }
                      mutate={fraudNotesMutation}
                      participantInfo={participantQuery.data}
                      updatedFraudrankResult={
                        loanQuery.data?.data.attributes.updatedFraudRanking
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <QueueErrorCard sx={{ my: 2 }} type='Loan' value={loanId} />
            </Grid>
            {isVestwellSubaccounting && (
              <Grid item>
                <LoanDetailsDataGridsCard tradeRequests={tradeRequests.data} />
              </Grid>
            )}
          </Grid>
          <Modal onClose={() => setIsModalOpen(false)} open={isModalOpen}>
            <Box
              sx={{
                backgroundColor: '#FAFAFA',
                boxShadow: '24px',
                left: '50%',
                padding: theme => theme.spacing(4),
                position: 'absolute',
                top: '50%',
                transform: 'translate(-50%, -50%)'
              }}>
              <Typography component='div' variant='h5'>
                Update Loan
              </Typography>
              <Typography
                sx={{
                  marginBottom: theme => theme.spacing(4),
                  marginTop: theme => theme.spacing(4)
                }}
                variant='body1'>
                Are you sure you want to change the status to {newStatus}?
              </Typography>
              <Button
                disabled={!newStatus}
                onClick={() => {
                  if (newStatus) loanStatusMutation.mutate(newStatus);
                  setIsModalOpen(false);
                }}
                variant='contained'>
                CONFIRM
              </Button>
              <Button
                data-testid='tpa-plans-modal-cancel-btn'
                onClick={() => setIsModalOpen(false)}>
                CANCEL
              </Button>
            </Box>
          </Modal>
        </Grid>
      )}
      {requestError && (
        <ErrorMessage
          error={requestError.message || 'An Unknown Error Occurred'}
        />
      )}
    </>
  );
};

export default LoanDetailsRoute;
