import CusipTickerSearch from '@/components/cusip-ticker-search/CusipTickerSearch';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { ConfirmPendingTransactionRequest } from '@/models/ops/transactions/ConfirmPendingTransactionRequest.model';
import { TransactionDto } from '@/models/ops/transactions/TransactionDTO.model';
import PendingTransactionService from '@/services/ops/pending-transactions/PendingTransaction.service';
import formatters from '@/utils/Formatters';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack
} from '@mui/material';
import Divider from '@mui/material/Divider';
import OutlinedInput from '@mui/material/OutlinedInput';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AccountLevel } from '@vestwell-sub-accounting/models/accountsAndLedgers/AccountLevel';
import { SecurityOrderType } from '@vestwell-sub-accounting/models/common/SecurityOrderType';
import { TransactionBaseType } from '@vestwell-sub-accounting/models/common/TransactionBaseType';
import { TransactionTypeCode } from '@vestwell-sub-accounting/models/common/TransactionTypeCode';

import dayjs from 'dayjs';
import { Formik } from 'formik';
import React, { ReactNode, useRef, useState } from 'react';
import { isTradingDay } from 'sub-accounting-calendar-utility';
import * as yup from 'yup';

import TransactionAccountSummary from './TransactionAccountSummary.component';

type ConfirmTransactionDialogProps = {
  open: boolean;
  onClose: () => void;
  transaction?: TransactionDto;
  onConfirmedTransaction?: () => void;
  accountId: string;
  accountLevel: AccountLevel;
};

const ConfirmTransactionDialog = ({
  open,
  onClose,
  transaction,
  onConfirmedTransaction,
  accountId,
  accountLevel
}: ConfirmTransactionDialogProps): JSX.Element => {
  const { showSnackbar } = useSnackbar();
  const [isFormValid, setIsFormValid] = useState(false);

  const queryClient = useQueryClient();
  const updatePendingTransactionMutation = useMutation(
    ['PendingTransactionService.confirm'],
    (data: ConfirmPendingTransactionRequest) => {
      return PendingTransactionService.confirm(
        transaction?.sourceTransactionId,
        data
      );
    },
    {
      onError: () => {
        showSnackbar({
          message: `Error confirming Transaction ${transaction?.sourceTransactionId}`,
          severity: 'error'
        });
      },
      onSuccess: () => {
        showSnackbar({
          message: `Transaction ${transaction?.sourceTransactionId} confirmed`,
          severity: 'success'
        });
        // Invalidate all queries to ensure any related components reflect these changes
        queryClient.invalidateQueries();
        onClose();
        if (typeof onConfirmedTransaction === 'function') {
          onConfirmedTransaction();
        }
      }
    }
  );
  const testDecimals = (numberOfDecimals: number) => {
    return (value?: number) => {
      const valueString = value !== undefined ? value.toString() : '';
      const regexString = `^-?\\s*(?=.*[0-9])\\d*(?:\\.\\d{1,${numberOfDecimals}})?\\s*$`;
      return new RegExp(regexString).test(valueString);
    };
  };

  const isBuyOrSell =
    transaction?.transactionBaseType === TransactionBaseType.Buy ||
    transaction?.transactionBaseType === TransactionBaseType.Sell;
  const isDepositOrWithdrawal =
    transaction?.transactionBaseType === TransactionBaseType.Deposit ||
    transaction?.transactionBaseType === TransactionBaseType.Withdrawal;

  let validationSchema;
  let initialValues;
  if (isBuyOrSell) {
    initialValues = {
      amount: Math.abs(transaction?.amount || 0).toFixed(2),
      fee: (Number(transaction?.fee) || 0).toFixed(2),
      securityUnitPrice: (transaction?.securityUnitPrice || 0).toFixed(2),
      units: Math.abs(transaction?.units || 0).toFixed(3)
    };
    validationSchema = yup.object({
      amount: yup
        .number()
        .moreThan(-0.0001, 'Amount must be a positive number')
        .required('Amount is required')
        .test('is-decimal', 'Please use two decimal places', testDecimals(2)),
      fee: yup
        .number()
        .moreThan(-0.0001, 'Fee must be a positive number')
        .required('Fee is required')
        .test('is-decimal', 'Please use two decimal places', testDecimals(2)),
      securityUnitPrice: yup
        .number()
        .moreThan(-0.0001, 'Price must be a positive number')
        .required('Price is required')
        .test('is-decimal', 'Please use two decimal places', testDecimals(2)),
      units: yup
        .number()
        .moreThan(-0.0001, 'Units must be a positive number')
        .required('Units is required')
        .test('is-decimal', 'Please use three decimal places', testDecimals(3))
    });
  } else {
    initialValues = {
      amount: Math.abs(transaction?.amount || 0).toFixed(2),
      tradeDate: transaction?.tradeDate
        ? dayjs(transaction?.tradeDate)
        : dayjs()
    };
    validationSchema = yup.object({
      amount: yup
        .number()
        .moreThan(-0.0001, 'Amount must be a positive number')
        .required('Amount is required')
        .test('is-decimal', 'Please use two decimal places', testDecimals(2)),
      tradeDate: yup
        .date()
        .required('Trade Date is required')
        .test('is-trading-day', 'Trade Date must be a trading day', value => {
          return isTradingDay(dayjs(value).format('YYYY-MM-DD'));
        })
    });
  }

  const formRef = useRef<any>();

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth='md'
      onClose={() => {
        onClose();
      }}
      open={open}>
      <DialogTitle
        sx={{
          pb: 1 // there is an unknown rule somewhere setting 8px important padding to the top of DialogContent so we have to compensate here
        }}>
        Update Transaction to Confirmed
      </DialogTitle>
      {transaction && (
        <DialogContent sx={{ p: 0 }}>
          <TransactionAccountSummary
            accountId={accountId}
            accountLevel={accountLevel}
          />
          <Stack direction='column' m='1.5rem' spacing={3}>
            <Box display='grid' gap={2} gridTemplateColumns='repeat(4, 1fr)'>
              <FormControl size='small'>
                <InputLabel id='base-type-label'>Base Type</InputLabel>
                <Select
                  disabled
                  id='base-type'
                  label='Base Type'
                  labelId='base-type-label'
                  value={transaction.transactionBaseType}>
                  {Object.values(TransactionBaseType).map(value => {
                    const displayBaseType = formatters.getValueKey(
                      TransactionBaseType,
                      value
                    );
                    return (
                      <MenuItem key={value} value={value}>
                        {formatters.displayCase(displayBaseType)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <Box gridColumn='span 2'>
                <FormControl fullWidth size='small'>
                  <InputLabel id='transaction-type-label'>
                    Transaction Type
                  </InputLabel>
                  <Select
                    disabled
                    id='transaction-type'
                    label='Transaction Type'
                    labelId='transaction-type-label'
                    value={transaction.transactionTypeCode}>
                    {Object.values(TransactionTypeCode).map(value => (
                      <MenuItem key={value} value={value}>
                        {formatters.displayCase(value)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>

            <Divider textAlign='left'>Trade Info</Divider>

            {/** only show the security details if this not a deposit or withdrawal transaction and there is a symbol to show */}
            {!isDepositOrWithdrawal &&
              transaction?.symbol !== undefined &&
              transaction?.symbol.length > 0 && (
                <CusipTickerSearch
                  FormControlProps={{
                    sx: { width: 200 }
                  }}
                  disabled
                  initialValue={transaction?.symbol || ''}
                  summary
                />
              )}

            <div>
              <Formik
                initialValues={initialValues}
                innerRef={formRef}
                onSubmit={values => {
                  const request: ConfirmPendingTransactionRequest = {};

                  if (isBuyOrSell) {
                    if (
                      transaction.securityOrderType ===
                        SecurityOrderType.UNIT_ALL ||
                      transaction.securityOrderType ===
                        SecurityOrderType.UNIT_ORDER
                    ) {
                      // only buy and sell transactions with unit based SecurityOrderType allow editing amount
                      request.amount = values.amount
                        ? Number(values.amount).toFixed(2)
                        : '0.00';
                    }
                    if (
                      transaction.securityOrderType ===
                        SecurityOrderType.DOLLAR_ALL ||
                      transaction.securityOrderType ===
                        SecurityOrderType.DOLLAR_ORDER
                    ) {
                      // only buy and sell transactions with dollar based SecurityOrderType allow editing units
                      request.units = values.units
                        ? Number(values.units).toFixed(3)
                        : '0.00';
                    }

                    // only buy and sell transactions allow editing price and fee
                    request.securityUnitPrice = values.securityUnitPrice
                      ? Number(values.securityUnitPrice).toFixed(2)
                      : '0.00';
                    request.fee = values.fee
                      ? Number(values.fee).toFixed(2)
                      : '0.00';
                  }

                  if (isDepositOrWithdrawal) {
                    // only deposit and withdrawal transactions allow editing trade date
                    request.tradeDate = values.tradeDate
                      ? values.tradeDate.format('YYYY-MM-DD')
                      : '';
                  }

                  updatePendingTransactionMutation.mutate(request);
                }}
                validateOnMount
                validationSchema={validationSchema}>
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                  isValid

                  /* and other goodies */
                }) => {
                  setIsFormValid(isValid);

                  return (
                    <form>
                      {isBuyOrSell && (
                        <Box
                          display='grid'
                          gap={2}
                          gridTemplateColumns='repeat(4, 1fr)'>
                          <FormControl
                            error={
                              touched.securityUnitPrice &&
                              Boolean(errors.securityUnitPrice)
                            }
                            size='small'>
                            <InputLabel htmlFor='securityUnitPrice'>
                              Price
                            </InputLabel>
                            <OutlinedInput
                              id='securityUnitPrice'
                              inputProps={{ step: '0.01' }}
                              label='Price'
                              onBlur={handleBlur}
                              onChange={handleChange}
                              startAdornment={
                                <InputAdornment position='start'>
                                  $
                                </InputAdornment>
                              }
                              type='number'
                              value={values.securityUnitPrice}
                            />

                            <FormHelperText error>
                              {errors.securityUnitPrice as ReactNode}
                            </FormHelperText>
                          </FormControl>
                          <FormControl
                            error={touched.units && Boolean(errors.units)}
                            size='small'>
                            <InputLabel htmlFor='units'>Units</InputLabel>
                            <OutlinedInput
                              disabled={
                                !(
                                  transaction.securityOrderType ===
                                    SecurityOrderType.DOLLAR_ORDER ||
                                  transaction.securityOrderType ===
                                    SecurityOrderType.DOLLAR_ALL
                                )
                              }
                              id='units'
                              inputProps={{ step: '0.001' }}
                              label='Units'
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type='number'
                              value={values.units}
                            />

                            <FormHelperText error>
                              {errors.units as ReactNode}
                            </FormHelperText>
                          </FormControl>
                          <FormControl
                            error={touched.amount && Boolean(errors.amount)}
                            size='small'>
                            <InputLabel htmlFor='amount'>Amount</InputLabel>
                            <OutlinedInput
                              disabled={
                                !(
                                  transaction.securityOrderType ===
                                    SecurityOrderType.UNIT_ORDER ||
                                  transaction.securityOrderType ===
                                    SecurityOrderType.UNIT_ALL
                                )
                              }
                              id='amount'
                              inputProps={{ step: '0.01' }}
                              label='Amount'
                              onBlur={handleBlur}
                              onChange={handleChange}
                              startAdornment={
                                <InputAdornment position='start'>
                                  $
                                </InputAdornment>
                              }
                              type='number'
                              value={values.amount}
                            />

                            <FormHelperText error>
                              {errors.amount as ReactNode}
                            </FormHelperText>
                          </FormControl>
                          <FormControl
                            error={touched.fee && Boolean(errors.fee)}
                            size='small'>
                            <InputLabel htmlFor='amount'>Fee</InputLabel>
                            <OutlinedInput
                              id='fee'
                              inputProps={{ step: '0.01' }}
                              label='Fee'
                              onBlur={handleBlur}
                              onChange={handleChange}
                              startAdornment={
                                <InputAdornment position='start'>
                                  $
                                </InputAdornment>
                              }
                              type='number'
                              value={values.fee}
                            />

                            <FormHelperText error>
                              {errors.fee as ReactNode}
                            </FormHelperText>
                          </FormControl>
                        </Box>
                      )}
                      {isDepositOrWithdrawal && (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Box
                            display='grid'
                            gap={2}
                            gridTemplateColumns='repeat(4, 1fr)'>
                            <DatePicker
                              disableFuture
                              disabled={!isDepositOrWithdrawal}
                              format='MM/DD/YYYY'
                              label='Trade Date'
                              onChange={value =>
                                setFieldValue('tradeDate', value)
                              }
                              shouldDisableDate={date => {
                                return date
                                  ? !isTradingDay(date.format('YYYY-MM-DD'))
                                  : false;
                              }}
                              slotProps={{
                                textField: {
                                  error: !!errors.tradeDate,
                                  helperText: errors.tradeDate as ReactNode,
                                  id: 'tradeDate',
                                  size: 'small',
                                  variant: 'outlined'
                                }
                              }}
                              value={dayjs(values.tradeDate)}
                            />

                            <DatePicker
                              disabled
                              format='MM/DD/YYYY'
                              label='Control Date'
                              onChange={() => {}}
                              slotProps={{
                                textField: {
                                  size: 'small',
                                  variant: 'outlined'
                                }
                              }}
                              value={dayjs(transaction.createdAt)}
                            />

                            <FormControl
                              error={touched.amount && Boolean(errors.amount)}
                              size='small'>
                              <InputLabel htmlFor='amount'>Amount</InputLabel>
                              <OutlinedInput
                                disabled
                                id='amount'
                                inputProps={{ step: '0.01' }}
                                label='Amount'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                startAdornment={
                                  <InputAdornment position='start'>
                                    $
                                  </InputAdornment>
                                }
                                type='number'
                                value={values.amount}
                              />
                              <FormHelperText error>
                                {errors.amount as ReactNode}
                              </FormHelperText>
                            </FormControl>
                          </Box>
                        </LocalizationProvider>
                      )}
                    </form>
                  );
                }}
              </Formik>
            </div>
          </Stack>
        </DialogContent>
      )}
      <Divider />
      <DialogActions
        sx={{
          px: 3,
          py: 2.25
        }}>
        <Button
          onClick={() => {
            onClose();
          }}>
          Cancel
        </Button>

        <LoadingButton
          disabled={!isFormValid}
          loading={updatePendingTransactionMutation.isLoading}
          onClick={handleSubmit}
          variant='contained'>
          Update
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmTransactionDialog;
