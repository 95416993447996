import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import {
  ContributionForTable,
  ParticipantContributionData,
  ParticipantContributionDataForTable,
  SponsorUserInvitesInfo
} from '@/models';
import { NormalizedFileContents } from '@/models/ContributionFileContents.model';
import FundingSource from '@/models/ops/FundingSourceEnum.model';
import { SubAccountDto } from '@/models/ops/SubAccountDTO.model';
import { Beneficiary } from '@/models/ParticipantBeneficiary.model';
import { ParticipantEligibilityReportData } from '@/models/ParticipantEligibilityReportData.model';
import {
  Address,
  EligibilityRequirementDetails
} from '@/models/ParticipantInfo.model';
import { FormattedPendingTransaction } from '@/models/PendingTransactionsDTO';
import { WithdrawalReason } from '@/models/WithdrawalsDTO.model';
import { DeferralChangeDtoWithUUID } from '@/routes/participants/participant-detail/ParticipantDetail.route';
import { SubAccountType } from '@vestwell-sub-accounting/models/common/SubAccountType';
import { TransactionBaseType } from '@vestwell-sub-accounting/models/common/TransactionBaseType';

import dayjs from 'dayjs';
import Decimal from 'decimal.js';
import lodash, {
  camelCase,
  isBoolean,
  isEmpty,
  isNaN,
  isNumber,
  isUndefined,
  memoize
} from 'lodash';

const USD_FORMATTER = new Intl.NumberFormat('en-US', {
  currency: 'USD',
  style: 'currency'
});

const USD_FORMATTER_CUSTOM_DECIMALS = (decimals: number) => {
  return new Intl.NumberFormat('en-US', {
    currency: 'USD',
    minimumFractionDigits: decimals,
    style: 'currency'
  });
};

type FormattedEligibilityString =
  | 'Met'
  | 'Unmet'
  | typeof EMPTY_FIELD_PLACEHOLDER;

type ReturnTypeBasedOnType<
  T extends boolean | number | string | ArrayType[],
  ArrayType
> = T extends ArrayType[] ? ArrayType[] : string;

/**
 * Returns a value ready to be displayed to the user or an EMPTY_FIELD_PLACEHOLDER (--) if value is undefined or null, in case on being an string or array
 * will check if the length is more than 0 if is not will return "--" or ["--"]
 * @example displayValueOrEmptyFieldPlaceholder('test') // returns 'test'
 * @example displayValueOrEmptyFieldPlaceholder(['test1','test2']) // returns ['test1','test2']
 * @example displayValueOrEmptyFieldPlaceholder(undefined) // returns '--'
 * @example displayValueOrEmptyFieldPlaceholder('') // returns '--'
 * @example displayValueOrEmptyFieldPlaceholder([]) // returns ['--']
 * @example displayValueOrEmptyFieldPlaceholder(true) // returns 'Yes'
 * @example displayValueOrEmptyFieldPlaceholder(false) // returns 'No'
 * @example displayValueOrEmptyFieldPlaceholder(false,'this is true','this is false') // returns 'this is false'
 */
const displayValueOrEmptyFieldPlaceholder = <
  T extends boolean | number | string | ArrayType[],
  ArrayType
>(
  value?: T | ArrayType[] | null,
  trueReturn = 'Yes',
  falseReturn = 'No'
): ReturnTypeBasedOnType<T, ArrayType> => {
  if (isNumber(value)) {
    return `${value}` as ReturnTypeBasedOnType<T, ArrayType>;
  }
  if (isBoolean(value)) {
    return (value ? trueReturn : falseReturn) as ReturnTypeBasedOnType<
      T,
      ArrayType
    >;
  }
  if (!value || !value?.length || !value?.toString().trim().length) {
    return typeof value !== 'object'
      ? (EMPTY_FIELD_PLACEHOLDER as ReturnTypeBasedOnType<T, ArrayType>)
      : ([EMPTY_FIELD_PLACEHOLDER] as ReturnTypeBasedOnType<T, ArrayType>);
  }
  return value as ReturnTypeBasedOnType<T, ArrayType>;
};

const checkIfGlobalSearchResultIsClicked = (e: Event): boolean | undefined => {
  const target = e.target as HTMLElement;
  return (
    target.id.includes('global-search') ||
    target.parentElement?.id.includes('global-search')
  );
};

const checkIfUserEmailSearchResultIsClicked = (
  e: Event
): boolean | undefined => {
  const target = e.target as HTMLElement;
  return (
    target.id.includes('user-management-email-result') ||
    target.parentElement?.id.includes('user-management-email-result')
  );
};

const mapTransactionBaseType: Record<TransactionBaseType, string> = {
  [TransactionBaseType.Buy]: 'Buy',
  [TransactionBaseType.Sell]: 'Sell',
  [TransactionBaseType.Deposit]: 'Deposit',
  [TransactionBaseType.Withdrawal]: 'Withdrawal',
  [TransactionBaseType.IncomeIn]: 'Income in',
  [TransactionBaseType.IncomeOut]: 'Income out',
  [TransactionBaseType.TransferIn]: 'Transfer in',
  [TransactionBaseType.TransferOut]: 'Transfer out',
  [TransactionBaseType.Fee]: 'Fee',
  [TransactionBaseType.FeeReversal]: 'Fee reversal'
};

const formatTransactionBaseType = (transactionBaseType: TransactionBaseType) =>
  mapTransactionBaseType[transactionBaseType];

/**
 * Converts machine-friendly (camelCase, PascalCase, lowercase) values to something more presentable to users
 * @example displayCase('superomni') // returns Superomni
 * @example displayCase('SuperOmnibus') // returns Super Omnibus
 * @example displayCase('QACA') // returns QACA
 * @example displayCase('IRAConduent') // returns IRA Conduent
 */
const displayCase = (rawString: string | null | undefined): string => {
  if (!rawString) return '';
  if (isSnakeCase(rawString)) rawString = snakeToCamelCase(rawString);
  if (!/[a-z]/.test(rawString)) return rawString;

  const string = rawString.replace('Sellfor', 'SellFor'); // TODO: update data to avoid, see SUB-1822

  return (
    string.charAt(0).toUpperCase() +
    string
      .slice(1)
      .replace(/([A-Z]+)/g, ' $1')
      .replace(/([A-Z][a-z])/g, ' $1')
      .replace(/\s+/g, ' ') // counter multiple spaces from line above
      .replace(/[-_][a-z]/gi, match => match.replace(/[-_]/, ' ').toUpperCase())
      .trim()
  );
};

const isSnakeCase = (str: string): boolean => {
  return str.split('_').length > 1;
};

const formatAddress = (address?: Address): string[] => {
  // In case that all of the properties are empty strings return []
  if (
    isEmpty(address) ||
    (!address.address1?.trim().length &&
      !address.address2?.trim().length &&
      !address.city?.trim().length &&
      !address.state?.trim().length &&
      !address.zip?.trim().length)
  ) {
    return [EMPTY_FIELD_PLACEHOLDER];
  }
  return [
    address.address1,
    address.address2,
    `${address.city}, ${address.state}, ${address.zip}`
  ];
};

const formatFromIsoDate = (isoDate: string): string => {
  const date = dayjs(isoDate).format('M/DD/YY HH:mm:ss');
  return date;
};

const formatDateWithFromNow = (isoDate: string): string => {
  const date = dayjs(isoDate);
  return `${date.format('MM/DD/YYYY')} (${date.fromNow()})`;
};

const formatFromIsoDateCustom = (isoDate: string, format: string): string => {
  const date = dayjs(isoDate).format(format);
  return date;
};

const isNumeral = memoize(
  (c: string) => c.trim().length && isNumber(+c) && !isNaN(+c)
);
const extractNumerals = memoize((raw: string, count = 10) =>
  raw.split('').filter(isNumeral).slice(0, count).join('')
);

const formatPhone = memoize((raw: string) => {
  const numerals = extractNumerals(raw);

  let out = '';
  if (numerals.length === 0) {
    out = '';
  }

  // '808' -> '(808'
  else if (numerals.length < 4) {
    out = `(${numerals}`;
  }
  // '808224' -> '(808) 224'
  else if (numerals.length < 7) {
    out = `(${numerals.slice(0, 3)}) ${numerals.slice(3, 6)}`;
  } else {
    // '8082248989' -> '(808) 224-8989'
    out = `(${numerals.slice(0, 3)}) ${numerals.slice(3, 6)}-${numerals.slice(
      6
    )}`;
  }

  return out;
});

const formatPendingTransactionStatus = (
  str: string,
  record: FormattedPendingTransaction
): string => {
  switch (str) {
    case 'TRADING_CONTRIBUTION':
      return 'Trading...';
    case 'PROCESSING_DISTRIBUTION':
      return 'Processing...';
    case 'DISBURSED_DISTRIBUTION':
      return 'Disbursed';
    case 'AWAITING_FUNDS_ROLLOVER':
      return 'Awaiting Funds';
    case 'EXPIRED_ROLLOVER':
      return formatFromIsoDate(record.updatedDate || '');
    case 'CANCELED_ROLLOVER':
      return `Canceled ${formatFromIsoDate(record.updatedDate || '')}`;
    default:
      return 'Processing...';
  }
};

const getKeyValue =
  <U extends keyof T, T extends Record<string, string>>(key: U): any =>
  (obj: T) =>
    obj[key];

const getValueKey = (obj: Record<string, unknown>, value: unknown): string => {
  // fetch key from the provided object value
  const indexOfValue = Object.values(obj).indexOf(value);
  const key = Object.keys(obj)[indexOfValue];
  // if key not found, just return the original value
  return key || String(value);
};

const formatDollars = (
  dollarAmount?: number | string,
  decimalPrecision?: number
): string => {
  if (typeof dollarAmount === 'undefined') {
    return USD_FORMATTER.format(0);
  }

  let parsedDollar = dollarAmount;
  if (typeof dollarAmount === 'string') {
    parsedDollar = parseFloat(dollarAmount);
  }

  if (typeof decimalPrecision !== 'undefined') {
    return USD_FORMATTER_CUSTOM_DECIMALS(decimalPrecision).format(
      parsedDollar as number
    );
  }

  if (+dollarAmount < 0 && Math.abs(+formatDecimal(+dollarAmount, 2)) === 0) {
    return '$0.00';
  }

  /** no decimal precision provided so just use the default precision of the USD_FORMATTER (2) */
  return USD_FORMATTER.format(parsedDollar as number);
};

const formatDecimal = (
  value?: number | string,
  decimalPrecision?: number
): string => {
  if (typeof value === 'undefined') {
    return '';
  }

  // ensure we are working with a number
  let numberValue = value;
  if (!isNumber(numberValue)) {
    numberValue = parseFloat(numberValue);
  }

  const formatterOptions: Intl.NumberFormatOptions = {
    style: 'decimal'
  };
  if (typeof decimalPrecision === 'number') {
    formatterOptions.minimumFractionDigits = decimalPrecision;
    formatterOptions.maximumFractionDigits = decimalPrecision;
  }

  const formatter = new Intl.NumberFormat('en-US', formatterOptions);

  if (+numberValue < 0 && Math.abs(+formatter.format(numberValue)) === 0) {
    return Math.abs(+formatter.format(numberValue)).toFixed(decimalPrecision);
  }

  return formatter.format(numberValue);
};

const formatStateIraPerEmployerStatus = (status?: string): string => {
  if (!status) return EMPTY_FIELD_PLACEHOLDER;

  if (status.endsWith('optout')) {
    return 'Opted Out';
  }

  if (status.endsWith('enrolled')) {
    return 'Active';
  }

  return status;
};

const formatDeferralStatus = (status: string): string => {
  switch (status) {
    case 'IGNORED':
      return 'Not processed';
    case 'MANUAL_IGNORE':
      return 'Not processed';
    case 'PROCESSED':
      return 'Processed';
    case 'NEW':
      return 'New - not processed yet';
    default:
      return status;
  }
};

const formatWithdrawalReason = (reason: string): WithdrawalReason | string => {
  switch (reason) {
    case 'RETIREMENT_AGE':
      return WithdrawalReason.RETIREMENT_AGE;
    case 'ROLLOVER':
      return WithdrawalReason.ROLLOVER;
    case 'HARDSHIP':
      return WithdrawalReason.HARDSHIP;
    case 'CHILD':
      return WithdrawalReason.CHILD;
    case 'DISABILITY':
      return WithdrawalReason.DISABILITY;
    case 'PLAN_TERMINATION':
      return WithdrawalReason.PLAN_TERMINATION;
    case 'PARTICIPANT_TERMINATION':
      return WithdrawalReason.PARTICIPANT_TERMINATION;
    default:
      return reason;
  }
};

const formatDeferral = (
  deferralType: string | undefined,
  deferralAmount: number
): string => {
  if (!lodash.isNumber(deferralAmount)) {
    return EMPTY_FIELD_PLACEHOLDER;
  }
  if (deferralType === '$') {
    return USD_FORMATTER_CUSTOM_DECIMALS(0).format(deferralAmount);
  }
  if (deferralType === '%') {
    return `${deferralAmount.toString()}${deferralType}`;
  }
  if (deferralType === '') {
    return EMPTY_FIELD_PLACEHOLDER;
  }
  return `${deferralAmount}`;
};

const formatEinOrSdat = (ein: string | undefined): string => {
  if (!ein) return '--';
  const convertedEIN = ein.replace('-', '').split('');
  convertedEIN.splice(2, 0, '-');
  return convertedEIN.join('');
};

const maskBankNumber = (bankNumber: string): string => {
  const numberOfHiddenNumbers = bankNumber.slice(0, -4).length;
  return 'X'.repeat(numberOfHiddenNumbers) + bankNumber.slice(-4);
};

const maskSSN = (convertedSSN: string, suppressError?: boolean): string => {
  if (
    !lodash.isString(convertedSSN) ||
    convertedSSN.length !== 11 ||
    !convertedSSN.includes('-')
  ) {
    if (suppressError) {
      return '';
    }
    throw new Error('Invalid Converted SSN');
  }
  return convertedSSN
    .split('')
    .map((item, index) => {
      if (index < 6 && item !== '-') return 'X';
      return item;
    })
    .join('');
};

const formatEmployerStatus = (info: SponsorUserInvitesInfo): string => {
  return info.relationships.user ? 'Registered' : 'Not Registered';
};

const formatEligibility = (
  eligibility?: EligibilityRequirementDetails
): { age: FormattedEligibilityString; service: FormattedEligibilityString } => {
  if (eligibility == null) {
    return {
      age: EMPTY_FIELD_PLACEHOLDER,
      service: EMPTY_FIELD_PLACEHOLDER
    };
  }

  return {
    age: eligibility.isAgeRequirementMet ? 'Met' : 'Unmet',
    service: eligibility.isServiceRequirementMet ? 'Met' : 'Unmet'
  };
};

const formatPaymentMethod = (method: string | undefined): string => {
  switch (method) {
    case 'paper_check':
      return 'Paper Check';
    case 'ach_push':
      return 'ACH Push';
    default:
      return 'ACH Pull';
  }
};

const formatMetUnmet = (isMet: boolean | undefined): string => {
  return isMet ? 'Met' : 'Unmet';
};

const formatContributionStatus = (s: string): string => {
  const status: Record<string, string> = {
    ACH_EXECUTED: 'ACH Executed',
    ACH_REQUESTED: 'ACH Requested',
    AWAITING_FUNDS: 'Awaiting Funds',
    COMPLETE: 'Completed',
    COMPLETED: 'Completed',
    DELIVERED: 'Delivered',
    FUNDING_CONFIRMED: 'Funds Received',
    FUNDS_INVESTED: 'Funds Invested',
    FUNDS_TRADED: 'Funds Traded',
    INVESTMENT_PENDING: 'Investment Pending',
    PAYMENT_PROCESSED: 'Payment Processed',
    PROCESSED: 'Processed',
    PROCESSING: 'Processing',
    PROCESSING_PAYMENT: 'Processing Payment',
    SUBMISSION_STARTED: 'Submission Started',
    SUBMITTED: 'Submitted'
  };

  return status[s] ? status[s] : s;
};

const sortDeferralChangesByDate = (
  defChanges: DeferralChangeDtoWithUUID[],
  sortCriteria: string
): DeferralChangeDtoWithUUID[] => {
  switch (sortCriteria) {
    case 'asc':
      return lodash.sortBy(defChanges, [
        change => new Date(change.createdAt).getTime()
      ]);
    case 'desc':
      return lodash
        .sortBy(defChanges, [change => new Date(change.createdAt).getTime()])
        .reverse();
    default:
      return defChanges;
  }
};

const formatContributionStatusHeader = (status: string): string => {
  if (status === '--') {
    return status;
  }
  const statusArray = status.split('_').map(value => {
    return value[0] + value.slice(1).toLowerCase();
  });
  return statusArray.join(' ');
};

const formatContributionStatusesTable = (status: string): string => {
  if (status === '--') {
    return status;
  }
  return status[0] + status.slice(1).toLowerCase().split('_').join(' ');
};

const formatContributionRole = (role: string): string => {
  if (role === 'Sponsor') {
    return 'Employer';
  }
  return role;
};

const formatContributionAmount = (amount: number): string => {
  if (amount >= 0) {
    return `$${amount}`;
  }
  return `-$${amount.toString().slice(1)}`;
};

const formatContributionsResponse = (
  contributions: ContributionForTable[]
): any[] =>
  contributions
    .sort((a, b) =>
      new Date(a.actualPayrollDate) < new Date(b.actualPayrollDate) ? 1 : -1
    )
    .map(c => ({
      ...c,
      processingStatus: c.processingStatus
        ? `${formatContributionStatus(c.processingStatus)}`
        : c.payrollDueStatus,
      total: formatDollars(
        Decimal.sum(c.total ?? 0, c.correctionsTotal ?? 0).toNumber()
      ),
      totalAfterTax: formatDollars(c.totalAfterTax),
      totalCompany: formatDollars(
        c.totalEmployerDiscretionaryMatch +
          c.totalSafeHarbour +
          c.totalProfitShare
      ),
      totalEmployee: formatDollars(
        c.totalPreTax + c.totalRoth + c.totalAfterTax
      ),
      totalEmployerDiscretionaryMatch: formatDollars(
        c.totalEmployerDiscretionaryMatch
      ),
      totalEsa: formatDollars(
        c.totalEsaInitialDepositBonus +
          c.totalEsaMilestoneBonus +
          c.totalEsaEmployerMatch +
          c.totalEsaEmployeeDeferral
      ),
      totalEsaEmployee: formatDollars(c.totalEsaEmployeeDeferral),
      totalEsaEmployer: formatDollars(
        c.totalEsaInitialDepositBonus +
          c.totalEsaMilestoneBonus +
          c.totalEsaEmployerMatch
      ),
      totalLoan: formatDollars(c.totalLoan),
      totalPreTax: formatDollars(c.totalPreTax),
      totalProfitShare: formatDollars(c.totalProfitShare),
      totalRoth: formatDollars(c.totalRoth),
      totalSafeHarbour: formatDollars(c.totalSafeHarbour),
      transaction: `${c.expectedPayrollDate} ${
        {
          correction: 'Correction',
          loan_payment: 'Loan Repayment',
          'off-cycle_supplemental_pay': 'Off-Cycle',
          regular: 'Contribution'
        }[c.flowSubtype] || '--'
      }`
    }));

const formatContributionsResponseForOpsGrid = (
  contributions: ContributionForTable[]
): any[] => {
  return contributions
    .sort((a, b) => {
      const dateA = new Date(a.actualPayrollDate);
      const dateB = new Date(b.actualPayrollDate);
      return dateA < dateB ? 1 : -1;
    })
    .map(c => ({
      ...c,
      processingStatus: c.processingStatus
        ? `${formatContributionStatus(c.processingStatus)}`
        : c.payrollDueStatus,
      total: Decimal.sum(c.total ?? 0, c.correctionsTotal ?? 0).toNumber()
    }));
};

const formatParticipantContributionData = (
  participantsData: ParticipantContributionData[]
): ParticipantContributionDataForTable[] => {
  return lodash
    .sortBy(participantsData, [participant => participant.lastName])
    .map(data => ({
      afterTax: formatDollars(data.accountMeta.at),
      companyContribution: formatDollars(
        data.accountMeta.sh +
          data.accountMeta.em +
          data.accountMeta.ps +
          data.accountMeta.qc
      ),
      discretionaryMatch: formatDollars(data.accountMeta.em),
      employee: `${data.lastName}, ${data.firstName}`,
      employeeContribution: formatDollars(
        data.accountMeta.rc + data.accountMeta.sd + data.accountMeta.at
      ),
      esaEmployeeGroup: data.esaEmployeeGroup,
      isVestwellSubaccounting: data.isVestwellSubaccounting,
      loanRepayment: formatDollars(data.accountMeta.ln),
      participantId: data.participantId,
      payrollDate: data.payrollDate,
      preTax: formatDollars(data.accountMeta.sd),
      profitSharing: formatDollars(data.accountMeta.ps),
      psaId: data.psaId,
      qmac: formatDollars(data.accountMeta.qm),
      qnec: formatDollars(data.accountMeta.qc),
      roth: formatDollars(data.accountMeta.rc),
      safeHarbor: formatDollars(data.accountMeta.sh),
      total: formatDollars(
        Math.round(
          (data.accountMeta.sh +
            data.accountMeta.em +
            data.accountMeta.ps +
            data.accountMeta.qc +
            data.accountMeta.rc +
            data.accountMeta.sd +
            data.accountMeta.at +
            data.accountMeta.ln) *
            100
        ) / 100
      ),
      ...(data.esaEmployerContributions?.length
        ? data.esaEmployerContributions?.reduce(
            (acc, current) => ({
              ...acc,
              [camelCase(current.contributionType)]: formatDollars(
                current.contributionValue ?? 0
              )
            }),
            {
              employeeDeferral: formatDollars(0),
              employerMatch: formatDollars(0),
              initialDepositBonus: formatDollars(0),
              milestoneBonus: formatDollars(0)
            }
          )
        : {
            employeeDeferral: formatDollars(0),
            employerMatch: formatDollars(0),
            initialDepositBonus: formatDollars(0),
            milestoneBonus: formatDollars(0)
          })
    }));
};

const calculateContributionsSum = (
  contributions: any[],
  types: string[]
): number => {
  if (types.length > 1) {
    return contributions
      .reduce((acc, c) => {
        return [...acc, ...types.map(type => c.accountMeta[type])];
      }, [])
      .reduce(
        (acc: number, c: number) => new Decimal(acc).plus(new Decimal(c)),
        new Decimal(0)
      );
  }
  return contributions
    .map(el => el.accountMeta[types[0]])
    .reduce((acc, current) => new Decimal(acc).plus(new Decimal(current)), 0);
};

const formatDeleteCorrectionField = (
  field: string | undefined,
  getValueByKey: (valuesObject: Record<string, unknown>, key: string) => string,
  dataKeys: Record<string, unknown>
): string => {
  if (field) {
    let result = field.replace(/[\][]/g, '');
    result = result
      .split(', ')
      .map(correction => getValueByKey(dataKeys, correction))
      .join(', ');
    return result;
  }
  return '--';
};

const formatContributionCorrectionFields = (field: string): string => {
  const verboseKeys: Record<string, string> = {
    at: 'Employee After Tax',
    em: 'Employer Match',
    ln: 'Loan',
    ps: 'PC',
    qc: 'QNEC',
    qm: 'QMAC',
    rc: 'Employee Roth',
    rcCatchup: 'Employee Roth Catch Up',
    sd: 'Employee Pretax',
    sdCatchup: 'Employee Pretax Catch Up',
    sh: 'Safe Harbor',
    ssn: 'SSN'
  };

  return verboseKeys[field] || lodash.startCase(field);
};

const chopStringLongerThan = (
  string: string | undefined,
  longerThan: number
): string => {
  if (!string) return '';
  if (string.length > longerThan) {
    const result = string.substring(0, 80);
    return result + '...';
  }
  return string;
};

const flattenFileContentsForCSV = (
  dto: NormalizedFileContents
): Record<string, string | number>[] => {
  if (isEmpty(dto.fileContent)) {
    return [];
  }

  const keys = Object.keys(dto.fileContent);
  const records: Record<string, string | number>[] = [];
  const { length } = dto.fileContent[keys[0]];

  for (let i = 0; i < length; i += 1) {
    const record: Record<string, string | number> = {};

    keys.forEach(k => {
      record[k] = encodeURIComponent(dto.fileContent[k][i]);
    });

    records.push(record);
  }

  return records;
};

const capitalizeFirstChar = (str: string | undefined): string => {
  if (!str) return '--';
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const calculateWaitingPeriod = (createdAt: string | undefined): string => {
  if (!createdAt) return '--';

  const startDate = dayjs(createdAt);
  const endDate = startDate.add(31, 'day');
  const daysLeft = Math.round(endDate.diff(dayjs(), 'day', true));

  if (daysLeft <= 0) {
    return `Met on ${endDate.format('M/D/YYYY')}`;
  }

  if (daysLeft === 1) {
    return `1 day left`;
  }

  return `${daysLeft} days left`;
};

const parseLtError = (rawError: string): Record<string, unknown> => {
  try {
    const LTErrorRegex = /^Error: (\{.+\})/gms;
    const parsedError = LTErrorRegex.exec(rawError);
    if (parsedError && parsedError.length > 0) {
      const [, match] = parsedError;
      return JSON.parse(match);
    }
    return {};
  } catch (e) {
    return {};
  }
};

const safeParseInt = (n?: string | number): number => {
  if (isUndefined(n)) {
    return -1;
  }
  if (isNumber(n)) {
    return n;
  }
  return parseInt(n, 10);
};

const unformatCurrency = (currency: string): number => {
  return Number(currency.replace('$', '').replaceAll(',', ''));
};

const textToDataTestId = (text: string): string => {
  return text.replaceAll(' ', '-').toLowerCase();
};

const snakeToCamelCase = (text: string): string => {
  return text
    .toLowerCase()
    .replace(/([-_][a-z0-9])/g, group =>
      group.toUpperCase().replace('-', '').replace('_', '')
    );
};

const parseDecimalInput = (
  value: string,
  allowNegative = false,
  precision = -1
): number | string => {
  const inputValueRegEx = allowNegative ? /[^0-9.-]/g : /[^0-9.]/g;

  let result = value.replace(/(?<=\..*)\./g, '');
  if (allowNegative) {
    result = result.replace(/(?<=\..*)-/g, '');
  }
  result = result.replace(inputValueRegEx, '');

  if (
    (allowNegative && /-?\.?/g.test(result)) ||
    result === '.' ||
    !isNaN(+result)
  ) {
    const currentPrecision = result.split('.')[1]?.length;
    if (result.includes('.') && currentPrecision) {
      if (~precision && currentPrecision > precision) {
        return Number(
          Math.trunc(+result * Math.pow(10, precision)) /
            Math.pow(10, precision)
        ).toFixed(precision);
      }
      return Number(result).toFixed(currentPrecision);
    }
    return result;
  }
  return NaN;
};

const formatSubAccountName = (subAccount?: Partial<SubAccountDto>): string => {
  let subAccountName = '';
  if (subAccount) {
    const isParticipant =
      subAccount.accountType === SubAccountType.participantStandard ||
      subAccount.accountType === SubAccountType.participantOutsideInvestment ||
      subAccount.accountType === SubAccountType.participantCash;

    if (isParticipant) {
      const nameParts = [];
      if (subAccount.accountType) {
        nameParts.push(displayCase(subAccount.accountType));
      }
      if (subAccount.fundingSource) {
        nameParts.push(
          FundingSource[subAccount?.fundingSource as keyof typeof FundingSource]
        );
      }
      if (
        subAccount.participant?.firstName ||
        subAccount.participant?.lastName
      ) {
        nameParts.push(
          `${subAccount.participant?.firstName} ${subAccount.participant?.lastName}`
        );
      }
      subAccountName = nameParts.join(' - ');
    } else {
      subAccountName = displayCase(subAccount.accountType);
    }
  }

  return subAccountName;
};

const formatSecurityName = (
  symbol?: string | null,
  cusip?: string | null
): string => {
  const nameParts = [];
  if (symbol) {
    nameParts.push(symbol);
  }
  if (cusip && cusip !== symbol) {
    nameParts.push(cusip);
  }

  return nameParts.join(' | ');
};

/**
 * Compares two objects and returns the properties/values that are different
 *
 * @param obj1 The object to compare
 * @param obj2 The object to compare against
 * @returns the properties/values of obj1 that are different from obj2
 */
function objectDiff<T extends object>(obj1: T, obj2: T): Partial<T> {
  // remove any values from obj1 that are the same as obj2
  const diff = Object.keys(obj1).reduce(
    (acc, key) => ({
      ...acc,
      ...(JSON.stringify(obj1[key as keyof T]) !==
      JSON.stringify(obj2[key as keyof T])
        ? { [key]: obj1[key as keyof T] }
        : {})
    }),
    {}
  );
  return diff;
}

const formatPayrollSchedule = (frequency: string, date: string): string => {
  if (frequency === 'Weekly' || frequency === 'Bi-Weekly') {
    return `${frequency} on ${dayjs(date).format('dddd')}`;
  }

  return `${frequency}`;
};

const getAmountOfDaysBeforePayrollDate = (date: string | undefined): string => {
  const diff = Math.ceil(dayjs(date).diff(dayjs(), 'day', true));
  return diff === 0
    ? '(today)'
    : diff < 0
      ? '(in the past)'
      : `(in ${diff} days)`;
};

const calculateDifference = (
  valueA?: string | number | null,
  valueB?: string | number | null
): number => {
  return Number(valueA || 0) - Number(valueB || 0);
};

const formatEligibilityReportInfo = (
  participant: ParticipantEligibilityReportData
) => {
  const pretaxAmount = formatters.formatDeferral(
    participant.pretaxType,
    participant.pretaxAmount ?? 0
  );

  const rothAmount = formatters.formatDeferral(
    participant.rothType,
    participant.rothAmount ?? 0
  );

  return {
    'Entry Date': participant.entryDate || '',
    'First Name': participant.firstName,
    'Last Name': participant.lastName,
    'Pre-Tax Deferral ($)': participant.pretaxType === '$' ? pretaxAmount : '',
    'Pre-Tax Deferral (%)': participant.pretaxType === '%' ? pretaxAmount : '',
    'Registration Date': participant.createdAt,
    'Roth Deferral ($)': participant.rothType === '$' ? rothAmount : '',
    'Roth Deferral (%)': participant.rothType === '%' ? rothAmount : '',
    SSN: participant.ssn,
    Status: participant.status || '',
    'YTD Employee Contribution': participant.employeeYTDContribution || '',
    'YTD Employer Contribution': participant.employerYTDContribution || ''
  };
};

const toFixedIfNecessary = (value: number, maxDecimals: number) => {
  return +parseFloat(`${value}`).toFixed(maxDecimals);
};

const formatPercent = (
  value: number,
  options?: { decimals?: number; fixed?: boolean; round?: boolean }
) =>
  options?.fixed
    ? `${(options?.round ? Math.round(value) : value).toFixed(options?.decimals || 2)}%`
    : `${toFixedIfNecessary(options?.round ? Math.round(value) : value, options?.decimals || 2)}%`;

const getBeneficiaryName = (beneficiary: Beneficiary) => {
  let name = '';

  switch (beneficiary.attributes.beneficiaryType) {
    case 'person':
      name = `${beneficiary.attributes.firstName} ${beneficiary.attributes.lastName}`;
      break;
    case 'trust':
      name = beneficiary.attributes.trustName;
      break;
    case 'organization':
      name = beneficiary.attributes.businessName;
      break;
    default:
      break;
  }
  return name;
};

const formatters = {
  calculateContributionsSum,
  calculateDifference,
  calculateWaitingPeriod,
  capitalizeFirstChar,
  checkIfGlobalSearchResultIsClicked,
  checkIfUserEmailSearchResultIsClicked,
  chopStringLongerThan,
  displayCase,
  displayValueOrEmptyFieldPlaceholder,
  extractNumerals,
  flattenFileContentsForCSV,
  formatAddress,
  formatContributionAmount,
  formatContributionCorrectionFields,
  formatContributionRole,
  formatContributionStatus,
  formatContributionStatusHeader,
  formatContributionStatusesTable,
  formatContributionsResponse,
  formatContributionsResponseForOpsGrid,
  formatDateWithFromNow,
  formatDecimal,
  formatDeferral,
  formatDeferralStatus,
  formatDeleteCorrectionField,
  formatDollars,
  formatEinOrSdat,
  formatEligibility,
  formatEligibilityReportInfo,
  formatEmployerStatus,
  formatFromIsoDate,
  formatFromIsoDateCustom,
  formatMetUnmet,
  formatParticipantContributionData,
  formatPaymentMethod,
  formatPayrollSchedule,
  formatPendingTransactionStatus,
  formatPercent,
  formatPhone,
  formatSecurityName,
  formatStateIraPerEmployerStatus,
  formatSubAccountName,
  formatTransactionBaseType,
  formatWithdrawalReason,
  getAmountOfDaysBeforePayrollDate,
  getBeneficiaryName,
  getKeyValue,
  getValueKey,
  maskBankNumber,
  maskSSN,
  objectDiff,
  parseDecimalInput,
  parseLtError,
  safeParseInt,
  snakeToCamelCase,
  sortDeferralChangesByDate,
  textToDataTestId,
  toFixedIfNecessary,
  unformatCurrency
};

export default formatters;
