import React from 'react';
import ReactDOMServer from 'react-dom/server';

const JSXToHTML = (jsxElement: React.ReactElement): string => {
  if (!jsxElement) return '';
  // render the jsx
  return ReactDOMServer.renderToStaticMarkup(jsxElement);
};

const HTMLToTextContent = (html: string): string => {
  if (!html) return '';
  // Create a new div element
  const tempDivElement = document.createElement('div');

  // Set the HTML content with the given value
  tempDivElement.innerHTML = html;

  // Retrieve the text property of the element (cross-browser)
  return tempDivElement.textContent || tempDivElement.innerText || '';
};

const JSXToTextContent = (
  jsxElement: React.ReactElement | React.ReactNode
): string => {
  if (!React.isValidElement(jsxElement)) return '';
  const html = JSXToHTML(jsxElement);
  return HTMLToTextContent(html);
};

const triggerDownload = (fileContents: string, fileName: string): void => {
  const blob = new Blob([fileContents], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  link.click();
  window.URL.revokeObjectURL(url);
};

const DOMInteraction = {
  HTMLToTextContent,
  JSXToHTML,
  JSXToTextContent,
  triggerDownload
};

export default DOMInteraction;
