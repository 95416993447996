import AccessControl from '@/components/access-control/AccessControl.component';
import useHasPermissions from '@/components/access-control/useHasPermissions.hook';
import Badge from '@/components/badge';
import { CardPlaceholder } from '@/components/card';
import { useDialog } from '@/contexts/DialogContext';
import { useSnackbar } from '@/contexts/SnackBarContext';
import {
  CommunicationFilters,
  Message
} from '@/models/PlanCommunicationsDTO.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { PlanService } from '@/services/Plan.service';
import { Search, WarningAmber } from '@mui/icons-material';
import MailIcon from '@mui/icons-material/Mail';
import {
  Box,
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Dispatch, SetStateAction, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import EmailMessagePreviewDialog from './EmailMessagePreviewDialog.component';
import { getInitiatorLabel, getLabelAndColor } from './utils';

interface PlanCommunicationsTableProps {
  emailsData?: Message[];
  totalEmails?: number;
  filters: CommunicationFilters;
  setFilters: Dispatch<SetStateAction<CommunicationFilters>>;
  pageNumber: number;
  setPageNumber: Dispatch<SetStateAction<number>>;
  rowsPerPage: number;
  setRowsPerPage: Dispatch<SetStateAction<number>>;
  isLoading?: boolean;
  isError?: boolean;
  isSponsor?: boolean;
}

const PlanCommunicationsTable = (
  props: PlanCommunicationsTableProps
): JSX.Element => {
  const { openDialog } = useDialog();

  const actionColumnsPermissions = useHasPermissions({
    requiresOneOf: [
      FeatureLevelPermissions.WRITE_COMMUNICATIONS_RESEND_EMAILS,
      FeatureLevelPermissions.WORK_IN_PROGRESS
    ]
  });

  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  const sendEmails = useMutation(
    ['CommentService.add'],
    (ids: number[]) => {
      return PlanService.resendEmails(ids);
    },
    {
      onError: () => {
        showSnackbar({
          message: `Resend attempt failed`,
          severity: 'error'
        });
      },
      onSuccess: () => {
        showSnackbar({
          message: `Resend attempt successful`,
          severity: 'success'
        });
        // Invalidate the comment search query so that we get fresh results
        queryClient.invalidateQueries({
          predicate: ({ queryHash }) =>
            /PlanService.getPlanParticipantsCommunications/.test(queryHash)
        });
        queryClient.invalidateQueries({
          predicate: ({ queryHash }) =>
            /PlanService.getPlanSponsorCommunications/.test(queryHash)
        });
      }
    }
  );

  const columns: GridColDef[] = useMemo(() => {
    const headers: GridColDef[] = [
      {
        field: 'email',
        flex: 0.75,
        headerName: 'Email',
        sortable: false
      },
      {
        field: 'templateName',
        headerName: 'Category',
        minWidth: 240,
        renderCell: params => (
          <Box>
            <Typography>
              <Link
                data-testid={`category-link-email-preview-${params.row.id}`}
                onClick={() => {
                  openDialog({
                    customContent: (
                      <EmailMessagePreviewDialog messageId={params.row.id} />
                    ),
                    dialogProps: { fullWidth: true, maxWidth: 'md' }
                  });
                }}
                sx={{
                  '&:hover': {
                    cursor: 'pointer'
                  }
                }}>
                {params.row.templateName}
              </Link>
            </Typography>
            <Typography variant='body2'>{params.row.subject}</Typography>
          </Box>
        ),
        sortable: false
      },
      {
        field: 'eventType',
        flex: 0.4,
        headerName: 'Email Status',
        renderCell: params => (
          <Badge color={getLabelAndColor(params.row.eventType).color}>
            {getLabelAndColor(params.row.eventType).label}
          </Badge>
        ),
        sortable: false
      },
      {
        field: 'initiator',
        flex: 0.25,
        headerName: 'Initiator',
        sortable: false,
        valueGetter: params =>
          getInitiatorLabel(
            params.row?.initiator?.idType,
            params.row?.initiator?.id
          )
      },
      {
        field: 'sendDate',
        flex: 0.5,
        headerName: 'Send Date'
      },
      {
        field: 'actions',
        flex: 0.5,
        headerName: 'Actions',
        renderCell: params => (
          <AccessControl
            requiresOneOf={[
              FeatureLevelPermissions.WRITE_COMMUNICATIONS_RESEND_EMAILS,
              FeatureLevelPermissions.WORK_IN_PROGRESS
            ]}>
            <Tooltip title='Resend Email'>
              <IconButton
                aria-label='resend email'
                data-testId={`resend-email-button-message-${params.row.id}`}
                disabled={(params.row?.attempts || 0) > 1}
                onClick={() => sendEmails.mutateAsync([params.row.id])}>
                <MailIcon />
              </IconButton>
            </Tooltip>
          </AccessControl>
        )
      }
    ];

    if (!props.isSponsor) {
      headers.unshift({
        field: 'participant',
        flex: 0.35,
        headerName: 'Participant',
        renderCell: params => (
          <Box>
            <Link
              component={RouterLink}
              sx={{ fontSize: '16px' }}
              to={`/participants/${params.row.entityId}`}
              underline='hover'>
              {params.row.lastName}, {params.row.firstName}
            </Link>
            <Typography variant='body2'>ID: {params.row.entityId}</Typography>
          </Box>
        ),
        sortable: false
      });
    }
    return headers.filter(header =>
      !actionColumnsPermissions.isAllowed ? header.field !== 'actions' : true
    );
  }, [props.isSponsor]);

  return (
    <Box overflow='auto' width='100%'>
      <DataGridPro
        columns={columns}
        data-testid='plan-communications-table'
        density='comfortable'
        disableColumnMenu
        disableRowSelectionOnClick
        getRowHeight={() => 'auto'}
        getRowId={row => row.id}
        loading={props.isLoading}
        onPaginationModelChange={model => {
          props.setPageNumber(model.page);
          props.setRowsPerPage(model.pageSize);
        }}
        onSortModelChange={model => {
          props.setFilters({ ...props.filters, order: model[0].sort });
          props.setPageNumber(0);
        }}
        pageSizeOptions={[10, 25, 50]}
        pagination
        paginationMode='server'
        paginationModel={{
          page: props.pageNumber,
          pageSize: props.rowsPerPage
        }}
        rowCount={props.totalEmails || 0}
        rows={props.emailsData || []}
        slots={{
          noRowsOverlay: () => (
            <Stack
              alignItems='center'
              data-testid='no-data-communication-table'
              height='100%'
              justifyContent='center'>
              <CardPlaceholder
                icon={
                  props.isError ? (
                    <WarningAmber fontSize='inherit' />
                  ) : (
                    <Search fontSize='inherit' />
                  )
                }
                subtitle={
                  props.isError
                    ? 'Error retrieving emails'
                    : 'No emails have been sent.'
                }
              />
            </Stack>
          )
        }}
        sortModel={[{ field: 'sendDate', sort: props.filters.order }]}
        sortingOrder={['desc', 'asc']}
        sx={{
          '.MuiDataGrid-columnSeparator': {
            display: 'none'
          },
          border: '0px !important'
        }}
      />
    </Box>
  );
};

export default PlanCommunicationsTable;
