import { CardPlaceholder } from '@/components/card';
import CollapsibleTable from '@/components/collapsible-table';
import {
  FormattedLoan,
  LoanDto,
  LoanResponseDto
} from '@/models/LoanDTO.model';
import LoansTableCell from '@/routes/participants/participant-detail/Loans/LoanTableCell.component';
import { PlanService } from '@/services/Plan.service';
import { Card, CardHeader, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

interface LoanRequestsProps {
  sponsorPlanId: number;
  recordkeeper: string;
  setQueryData: React.Dispatch<React.SetStateAction<Record<string, any>>>;
}

const PlanLoansTab = (props: LoanRequestsProps): JSX.Element => {
  const headCells = [
    { field: 'id', headerName: 'Loan ID' },
    { field: 'participant', headerName: 'Participant' },

    {
      field: 'status',
      headerName: 'Status'
    },
    {
      field: 'createdAt',
      headerName: 'Created Date'
    },
    {
      field: 'startDate',
      headerName: 'Start Date'
    },
    {
      field: 'originalAmount',
      headerName: 'Original Amount'
    },
    {
      field: 'outstandingBalance',
      headerName: 'Outstanding Balance'
    }
  ];

  const { sponsorPlanId, recordkeeper } = props;

  const LoansQuery = useQuery<LoanResponseDto>(
    ['PlanService.getLoans', sponsorPlanId],
    () => {
      return PlanService.getLoans(sponsorPlanId);
    },
    {
      enabled: Boolean(sponsorPlanId),
      staleTime: Infinity
    }
  );

  if (LoansQuery.isFetching) {
    return <p>Loading....</p>;
  }

  if (!LoansQuery.isSuccess) {
    return <p>Error</p>;
  }

  if (!LoansQuery.data?.data.length) {
    return (
      <Card
      elevation={0}
        sx={{
          fontSize: theme => theme.spacing(2.4),
          padding: theme => theme.spacing(2),
          textAlign: 'center'
        }}>
        <Typography
          sx={{
            marginBottom: theme => theme.spacing(1),
            textAlign: 'left'
          }}
          variant='h5'>
          Loans
        </Typography>
        <CardPlaceholder
          data-testid='no-data-loans-plan'
          subtitle='No data for this plan'
        />
      </Card>
    );
  }

  const formatLoan = (loan: LoanDto): FormattedLoan => {
    return {
      createdAt: loan.attributes.createdAt,
      id: loan.id,
      originalAmount: loan.attributes.originalLoanAmount,
      originationDate: loan.attributes.originationDate,
      outstandingBalance:
        loan.attributes.outstandingPrincipalDue +
        loan.attributes.overdueInterest,
      participantFirstName: loan.attributes.participantFirstName,
      participantId: loan?.relationships?.participant?.data.id,
      participantLastName: loan.attributes.participantLastName,
      startDate:
        recordkeeper === 'LT'
          ? loan.attributes.startDate
          : loan.attributes.firstPaymentDueDate,
      status: loan.attributes.loanStatus
    } as FormattedLoan;
  };

  const loans = LoansQuery.data.data.map(formatLoan);

  return (
    <>
      <Card elevation={0}>
        <CardHeader title='Loans' />
        <CollapsibleTable
          backgroundPaperElevation={0}
          cellComponent={LoansTableCell}
          columns={headCells}
          rootPaperElevation={0}
          tableData={loans || []}
        />
      </Card>
    </>
  );
};

export default PlanLoansTab;
