import { CurrentDatesAndWindowsDto } from '@/models/ops/current-dates-and-windows/CurrentDatesAndWindowsDTO.model';
import ApiService from '@/services/Api.service';

class CurrentDatesAndWindowsService {
  static async get(): Promise<CurrentDatesAndWindowsDto> {
    return ApiService.getJson<CurrentDatesAndWindowsDto>(
      `/current-dates-and-windows`
    );
  }
}

export default CurrentDatesAndWindowsService;
