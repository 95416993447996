import type {
  PostContributionPayload,
  PostContributionResponse,
  PostIngestionFlowStartPayload,
  PostIngestionFlowStartResponse
} from '@/models/Contribution.model';
import ContributionDetailsDto from '@/models/ContributionDetailsDTO.model';
import ContributionDto from '@/models/ContributionDTO.model';
import type { CreateReversalsPayload } from '@/models/ContributionReversals.model';
import ApiService from '@/services/Api.service';

export default class ContributionService {
  static async putSkipSubmission(data: {
    sponsorPlanId: number;
    sponsorId: number;
    actualPayDate: string;
    skippingReason: string;
    division: string;
  }): Promise<unknown> {
    return ApiService.putJson(`/contributions/skip`, data);
  }

  static async postContributionCorrections(
    data: CreateReversalsPayload
  ): Promise<unknown> {
    return ApiService.postJson(`/contributions/${data.ucid}/corrections`, data);
  }

  static async postContribution(
    data: PostContributionPayload
  ): Promise<PostContributionResponse> {
    return ApiService.postJson('/contributions', data);
  }

  static async postIngestionFlowStart(
    data: PostIngestionFlowStartPayload
  ): Promise<PostIngestionFlowStartResponse> {
    return ApiService.postJson(`/contributions/${data.ucid}/start`, data);
  }

  static async getContributionDetails(data: {
    ucid: string;
    planId: number | string | undefined;
    sponsorId: number | string | undefined;
    includeCorrections?: boolean;
    search?: string;
    filterZeros?: boolean;
    pageNumber?: number;
    pageSize?: number;
    sort?: string;
  }): Promise<ContributionDetailsDto> {
    return ApiService.getJson(`/contributions/${data.ucid}/details`, data);
  }

  static async putContribution(data: {
    ucid: string;
    planId: number;
    sponsorId: number;
    expectedPayrollDate: string;
  }): Promise<any> {
    return ApiService.putJson(`/contributions/${data.ucid}`, data);
  }

  static async getContributions(query: {
    planId: number;
    sponsorId: number;
    version?: number;
    division?: string;
    nonCompleted?: boolean;
  }): Promise<ContributionDto[]> {
    return ApiService.getJson(`/contributions`, query);
  }

  static async getSynonymMapping(): Promise<Record<string, string[]>> {
    return ApiService.getJson('/contributions/synonym-mapping');
  }

  static async postContributionCompletion(
    params: { ucid: string },
    data: { reason: string; additionalInfo: string }
  ): Promise<unknown> {
    return ApiService.postJson(
      `/contributions/${params.ucid}/completion`,
      data
    );
  }

  static async getDownloadContributionTemplate(
    params: {
      ucid: string;
    },
    query: {
      sponsorId: number;
      sponsorPlanId: number;
      test: boolean;
    }
  ): Promise<unknown> {
    return ApiService.getJson(
      `/contributions/${params.ucid}/template.xlsx`,
      query
    );
  }

  static async postCancelSubmission(
    params: {
      ucid: string;
    },
    body: {
      sponsorId: number;
      sponsorPlanId: number;
      cancellationReason: string;
    }
  ): Promise<unknown> {
    return ApiService.postJson(`/contributions/${params.ucid}/cancel`, body);
  }

  static async postContributionS3FileContents(
    params: {
      ucid: string;
    },
    body: {
      fileContent: string;
      documentCategoryId: number;
      fileName: string;
      sponsorId: number;
      sponsorPlanId: number;
    }
  ): Promise<unknown> {
    return ApiService.postJson(`/contributions/${params.ucid}/s3`, body);
  }

  static async getHeaderValidations(
    params: {
      ucid: string;
    },
    query: {
      sponsorId: number;
      sponsorPlanId: number;
    }
  ): Promise<unknown> {
    return ApiService.getJson(`/contributions/${params.ucid}/headers`, query);
  }

  static async postContributionHeaders(
    params: {
      ucid: string;
    },
    body: {
      sponsorId: number;
      sponsorPlanId: number;
      headers: any;
    }
  ): Promise<unknown> {
    return ApiService.postJson(
      `/contributions/${params.ucid}/headers/mapping`,
      body
    );
  }

  static async getSubmission(
    params: {
      ucid: string;
    },
    query: {
      planId: number;
      recordkeeperName: string;
      sponsorId: number;
      populate: 'participants' | 'normalized';
    }
  ): Promise<any> {
    return ApiService.getJson(
      `/contributions/${params.ucid}/submission`,
      query
    );
  }

  static async getContributionValidations(
    params: { ucid: string },
    query: {
      sponsorId: number;
      sponsorPlanId: number;
    }
  ): Promise<any> {
    return ApiService.getJson(
      `/contributions/${params.ucid}/validations`,
      query
    );
  }

  static async postContributionConfirmation(
    params: {
      ucid: string;
    },
    body: {
      sponsorId: number;
      sponsorPlanId: number;
      doNotAchEmployer?: boolean;
    }
  ): Promise<unknown> {
    return ApiService.postJson(
      `/contributions/${params.ucid}/confirmation`,
      body
    );
  }

  static async getContributionCorrections(
    params: { ucid: string },
    query: {
      sponsorId: number;
      sponsorPlanId: number;
    }
  ): Promise<any> {
    return ApiService.getJson(
      `/contributions/${params.ucid}/corrections`,
      query
    );
  }

  static async postContributionNormalizationCorrections(
    params: {
      ucid: string;
    },
    body: {
      sponsorId: number;
      sponsorPlanId: number;
      data: unknown;
      initiator: string;
    }
  ): Promise<any> {
    return ApiService.postJson(
      `/contributions/${params.ucid}/contribution-corrections`,
      body
    );
  }

  static async getForfeiture(
    params: { ucid: string },
    query: {
      sponsorId: number;
      sponsorPlanId: number;
    }
  ): Promise<any> {
    return ApiService.getJson(
      `/contributions/${params.ucid}/forfeiture`,
      query
    );
  }

  static async postForfeiture(
    params: {
      ucid: string;
    },
    body: {
      forfeitureAmount: number;
      sponsorId: number;
      sponsorPlanId: number;
    }
  ): Promise<any> {
    return ApiService.postJson(
      `/contributions/${params.ucid}/forfeiture`,
      body
    );
  }

  static async postResume(body: {
    ucid: string;
    sponsorId: number;
    sponsorPlanId: number;
  }): Promise<any> {
    return ApiService.postJson(`/contributions/resume`, body);
  }

  static async postContributionAction(
    params: {
      ucid: string;
    },
    body: {
      actionCode: string;
    }
  ): Promise<unknown> {
    return ApiService.postJson(`/contributions/${params.ucid}/action`, body);
  }

  static async getParticipantsLoans(query: {
    sponsorId: number;
    sponsorPlanId: number;
  }): Promise<any> {
    return ApiService.getJson(`/contributions/loans`, query);
  }
}
