import AccessControl from '@/components/access-control/AccessControl.component';
import { DataTableMenuWithIcons } from '@/components/data-table/DataTable.component';
import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import { useDialog } from '@/contexts/DialogContext';
import { useSnackbar } from '@/contexts/SnackBarContext';
import PlanDocumentCategoryGroup, {
  PlanDocument
} from '@/models/PlanDocumentCategoryGroupDTO.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import InMemoryFileDownloadService from '@/services/InMemoryFileDownloadService.service';
import { PlanService } from '@/services/Plan.service';
import formatters from '@/utils/Formatters';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import HistoryIcon from '@mui/icons-material/History';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import {
  Box,
  Button,
  Link,
  ListItemIcon,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from '@mui/material';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation } from '@tanstack/react-query';

import React, { useState } from 'react';

import DeleteDocDialog from './delete_doc/DeleteDocDialog.component';
import DocDetailsDialog from './doc_details/DocDetailsDialog.component';
import DocUploadHistoryDialog from './upload_history/DocUploadHistoryDialog.component';
import UploadPlanDialog from './UploadPlanDialog.component';

interface PlanDocumentsCardProps {
  documents: PlanDocument[];
  documentGroups?: PlanDocumentCategoryGroup[];
  sponsorPlanId: number;
  useEffectiveDate: boolean;
  groupId: number;
  categoryId: number;
}

const useStyles = makeStyles(() => ({
  buttonIcon: {
    '& .MuiButton-startIcon': {
      margin: 0
    },
    '&:hover': {
      backgroundColor: 'white'
    },
    color: grey[500],
    justifyContent: 'flex-start',
    minWidth: 'auto'
  }
}));

const PlanDocumentsTable: React.FunctionComponent<PlanDocumentsCardProps> = ({
  documents = [],
  documentGroups = [],
  sponsorPlanId,
  useEffectiveDate,
  groupId,
  categoryId
}) => {
  const { openDialog } = useDialog();
  const { showSnackbar } = useSnackbar();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  let hasDocuments: boolean;
  const { mutate: downloadDocument } = useMutation(
    ['PlanService.getDocumentForPlanId', sponsorPlanId],
    (documentId: number) => {
      return PlanService.getDocumentForPlanId(sponsorPlanId, documentId);
    },
    {
      onError: () => {
        showSnackbar({
          message: 'Something went wrong with the document download',
          severity: 'error'
        });
      },
      onSuccess: data => {
        InMemoryFileDownloadService.triggerFileDownload(
          data.base64Data,
          data.originalFileName
        );
      }
    }
  );

  return (
    <TableContainer data-testid='plan-documents-table'>
      <Table aria-label='simple table' sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow
            sx={{ '& .MuiTableCell-head ': { pb: '10px', pt: '10px' } }}>
            <TableCell
              sx={{
                borderRight: `1px solid ${grey[300]}`
              }}>
              File
            </TableCell>
            <TableCell>
              {useEffectiveDate ? 'Effective Date' : 'Effective Year '}
            </TableCell>
            <TableCell>File Name</TableCell>
            <TableCell>Uploaded At</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {documents.map((document, index) => {
            hasDocuments = Boolean(document.fileName);
            const effectiveDateValue = !document.effectiveDate
              ? '--'
              : useEffectiveDate
                ? document.effectiveDate
                : formatters.formatFromIsoDateCustom(
                    document.effectiveDate,
                    'YYYY'
                  );

            const documentGroupAndName = document.documentKey.split('/');
            const documentName = (
              documentGroupAndName[documentGroupAndName.length - 1] ||
              document.documentKey
            ).trim();

            return (
              <TableRow
                data-testid={`documents-table-row-document-${
                  document.uploadHistoryId || documentName
                }`}
                key={`${documentName}${index}`}
                sx={{
                  '& .MuiTableCell-body': { p: '6px 16px' },
                  '&:last-child td, &:last-child th': { borderBottom: 0 }
                }}>
                <TableCell
                  component='th'
                  scope='row'
                  sx={{
                    borderRight: `1px solid ${grey[300]}`,
                    height: '40px',
                    maxWidth: '380px',
                    padding: '0px'
                  }}>
                  <Box
                    alignItems='center'
                    display='flex'
                    justifyContent='space-between'>
                    <Box
                      data-testid='document-name-row'
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                      }}>
                      {documentName}
                    </Box>
                    <Box
                      alignItems='center'
                      data-testid='document-action-box'
                      display='flex'
                      sx={{ mr: '-8px' }}>
                      {hasDocuments ? (
                        <DataTableMenuWithIcons
                          data-testid='document-action-menu-button'
                          menuItems={[
                            {
                              component: (
                                <MenuItem
                                  data-testid='document-upload-history-menu-item'
                                  onClick={() => {
                                    openDialog({
                                      customContent: (
                                        <DocUploadHistoryDialog
                                          categoryId={categoryId}
                                          documentKey={document.documentKey}
                                          documentName={documentName}
                                          groupId={groupId}
                                          planId={sponsorPlanId}
                                          uploadHistoryId={
                                            document.uploadHistoryId
                                          }
                                        />
                                      ),
                                      dialogProps: {
                                        fullWidth: true,
                                        maxWidth: 'md'
                                      }
                                    });
                                  }}>
                                  <ListItemIcon>
                                    <HistoryIcon fontSize='small' />
                                  </ListItemIcon>
                                  View Upload History
                                </MenuItem>
                              )
                            },
                            {
                              component: (
                                <AccessControl
                                  requires={[
                                    FeatureLevelPermissions.WRITE_DOCUMENTS_UPLOAD
                                  ]}>
                                  <MenuItem
                                    data-testid='document-upload-menu-item'
                                    onClick={() =>
                                      openDialog({
                                        customContent: (
                                          <UploadPlanDialog
                                            categoryId={categoryId}
                                            documentGroups={documentGroups}
                                            documentKey={document.documentKey}
                                            documentName={documentName}
                                            groupingId={groupId}
                                            planId={sponsorPlanId}
                                            setOpen={setOpen}
                                          />
                                        )
                                      })
                                    }>
                                    <ListItemIcon>
                                      <FileUploadOutlinedIcon />
                                    </ListItemIcon>
                                    Upload New Document
                                  </MenuItem>
                                </AccessControl>
                              ),
                              isHidden: groupId === -1
                            },
                            {
                              component: (
                                <AccessControl
                                  requires={[
                                    FeatureLevelPermissions.WRITE_DOCUMENTS_DELETE
                                  ]}>
                                  <MenuItem
                                    data-testid='document-delete-menu-item'
                                    onClick={() => {
                                      openDialog({
                                        customContent: (
                                          <DeleteDocDialog
                                            categoryId={categoryId}
                                            docId={document.uploadHistoryId}
                                            documentKey={document.documentKey}
                                            groupId={groupId}
                                            planId={sponsorPlanId}
                                          />
                                        )
                                      });
                                    }}>
                                    <ListItemIcon>
                                      <DeleteOutlineOutlinedIcon fontSize='small' />
                                    </ListItemIcon>
                                    Delete Document
                                  </MenuItem>
                                </AccessControl>
                              )
                            },
                            {
                              component: (
                                <AccessControl
                                  requires={[
                                    FeatureLevelPermissions.WRITE_DOCUMENTS_UPLOAD
                                  ]}>
                                  <MenuItem
                                    data-testid='document-edit-effective-date-menu-item'
                                    onClick={() =>
                                      openDialog({
                                        customContent: (
                                          <DocDetailsDialog
                                            categoryId={categoryId}
                                            effectiveDate={
                                              document.effectiveDate
                                            }
                                            groupId={groupId}
                                            planId={sponsorPlanId}
                                            uploadHistoryId={
                                              document.uploadHistoryId
                                            }
                                          />
                                        ),
                                        dialogProps: {
                                          fullWidth: false
                                        }
                                      })
                                    }>
                                    <ListItemIcon>
                                      <ModeEditOutlineOutlinedIcon fontSize='small' />
                                    </ListItemIcon>
                                    Edit Effective Date
                                  </MenuItem>
                                </AccessControl>
                              ),
                              isHidden: groupId === -1 || !useEffectiveDate
                            }
                          ]}
                          open={open}
                        />
                      ) : (
                        <AccessControl
                          requires={[
                            FeatureLevelPermissions.WRITE_DOCUMENTS_UPLOAD
                          ]}>
                          <Tooltip title='Upload'>
                            <Button
                              className={classes.buttonIcon}
                              data-testid='document-action-upload-button'
                              onClick={() => {
                                openDialog({
                                  customContent: (
                                    <UploadPlanDialog
                                      categoryId={categoryId}
                                      documentKey={document.documentKey}
                                      documentName={documentName}
                                      groupingId={groupId}
                                      planId={sponsorPlanId}
                                      setOpen={setOpen}
                                    />
                                  )
                                });
                              }}
                              size='large'
                              startIcon={
                                <FileUploadOutlinedIcon fontSize='small' />
                              }
                            />
                          </Tooltip>
                        </AccessControl>
                      )}
                    </Box>
                  </Box>
                </TableCell>
                <TableCell>
                  {document.fileName
                    ? effectiveDateValue
                    : EMPTY_FIELD_PLACEHOLDER}
                </TableCell>
                <TableCell>
                  {document.fileName ? (
                    <Tooltip title={`Click to download ${document.fileName}`}>
                      <Link
                        color='primary'
                        onClick={() =>
                          document.uploadHistoryId
                            ? downloadDocument(document.uploadHistoryId)
                            : null
                        }
                        sx={{ cursor: 'pointer' }}>
                        {document.fileName}
                      </Link>
                    </Tooltip>
                  ) : (
                    EMPTY_FIELD_PLACEHOLDER
                  )}
                </TableCell>
                <TableCell>
                  {document.createdAt
                    ? formatters.formatFromIsoDateCustom(
                        document.createdAt,
                        'MM/DD/YYYY hh:mm:ss A'
                      )
                    : EMPTY_FIELD_PLACEHOLDER}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default PlanDocumentsTable;
