import fedHolidays from '@18f/us-federal-holidays';
import DatePicker from '@/components/date-picker/DatePicker';
import {
  FUND,
  MANAGED,
  RISK,
  TARGET
} from '@/models/ops/investments/Program.model';
import TickerAutocomplete, {
  TickerOption
} from '@/routes/ops/investments/common/TickerAutocomplete.component';
import {
  InvestmentOption,
  InvestmentOptionLabel,
  ProgramFund
} from '@/routes/ops/investments/FundChangesTab/types';
import { TickerLookupSearchResult } from '@/routes/ops/investments/investment-table/TickerSelect.component';
import ArrowForward from '@mui/icons-material/ArrowForward';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TableCell,
  TableRow
} from '@mui/material';

import { difference, without } from 'lodash';
import {
  Fragment,
  SyntheticEvent,
  useCallback,
  useMemo,
  useState
} from 'react';

const fedHolidayOptions = {
  shiftSaturdayHolidays: true,
  shiftSundayHolidays: true
};
const isAHoliday = (myDate: any) => {
  const date = myDate.utc().toISOString();
  return fedHolidays.isAHoliday(date, fedHolidayOptions);
};

export type ProgramFundChangeRowProps = {
  error?: any;
  funds: ProgramFund[];
  availableOptions: InvestmentOptionLabel[];
  tradeDate?: string;
  oldFund?: string;
  newFund?: string;
  investmentOptions: InvestmentOption[];
  rowIndex: number;
  onChange: (event: {
    target: {
      name: string;
      value: string | InvestmentOption[];
    };
  }) => void;
  onRemoveFundChangePreview: (index: number) => void;
  isRemovable: boolean;
  lookupTickerCallback: (
    ticker: string,
    numberOfResults: number
  ) => Promise<TickerLookupSearchResult[]>;
  setFieldError: (field: string, value: string) => void;
};

const NUMBER_OF_SEARCH_RESULTS = 5;

const getOptionName = (option: InvestmentOptionLabel): InvestmentOption => {
  switch (option) {
    case TARGET:
      return 'targetSeries';
    case RISK:
      return 'riskSeries';
    case MANAGED:
      return 'goalSeries';
    case FUND:
    default:
      return 'fundLineup';
  }
};

export const ProgramFundChangeRow = (
  props: ProgramFundChangeRowProps
): JSX.Element => {
  const {
    availableOptions,
    error,
    setFieldError,
    funds,
    investmentOptions = [],
    lookupTickerCallback,
    rowIndex,
    onChange,
    isRemovable
  } = props;

  const allOptions: InvestmentOption[] = useMemo(() => {
    return availableOptions.map(getOptionName);
  }, [availableOptions]);

  const setValue = useCallback(
    (key: string, value: string | InvestmentOption[]) => {
      onChange({
        target: {
          name: key,
          value
        }
      });
    },
    [onChange]
  );

  const handleChange = useCallback(
    (event: SyntheticEvent, newValue) => {
      setValue(
        `fundChangesPreview[${rowIndex}].newFund`,
        newValue?.cusip || ''
      );
    },
    [rowIndex, setValue]
  );

  const [tickerOptions, setTickerOptions] = useState<TickerOption[]>([]);

  const loadOptions = useCallback(
    async (event: React.SyntheticEvent) => {
      const target = event.target as HTMLInputElement;

      if (target.value && target.value.length >= 2) {
        const results = await lookupTickerCallback(
          target.value,
          NUMBER_OF_SEARCH_RESULTS
        );
        if (results.length === 1 && results[0].symbol === target.value) {
          const newValue = {
            ...results[0],
            cusip: results[0].cusip,
            symbol: target.value
          };
          handleChange(event, newValue);
        }
        const options = results
          .filter(t => t.cusip.length > 0) // TODO: remove when this is implemented in scala secmaster
          .slice(0, 5)
          .map<TickerOption>(r => {
            return r;
          });
        setTickerOptions(options);
      }
    },
    [lookupTickerCallback, handleChange]
  );

  const onOptionsSwitchChange = useCallback(
    (event: SyntheticEvent, checked: boolean) => {
      setValue(
        `fundChangesPreview[${rowIndex}].investmentOptions`,
        checked ? allOptions : []
      );
    },
    [rowIndex, allOptions, setValue]
  );

  const onInvestmentOptionChange = useCallback(
    (checked: boolean, option: InvestmentOptionLabel) => {
      setValue(
        `fundChangesPreview[${rowIndex}].investmentOptions`,
        checked
          ? [...investmentOptions, getOptionName(option)]
          : without(investmentOptions, getOptionName(option))
      );
    },
    [rowIndex, investmentOptions, setValue]
  );

  return (
    <TableRow
      sx={{
        backgroundColor: error ? 'rgba(211, 47, 47, 0.04)' : 'inherit',
        verticalAlign: 'top'
      }}>
      {isRemovable && (
        <TableCell>
          <IconButton
            color='primary'
            onClick={() => {
              props.onRemoveFundChangePreview(rowIndex);
            }}>
            <RemoveCircleOutlineIcon />
          </IconButton>
        </TableCell>
      )}
      <TableCell>
        <DatePicker
          data-testid={`tradeDate-${rowIndex}`}
          disablePast
          error={!!error?.tradeDate}
          errorMessage={error?.tradeDate}
          fullWidth
          handleError={error => {
            if (['shouldDisableDate', 'invalidDate'].includes(error)) {
              setFieldError(
                `fundChangesPreview[${rowIndex}].tradeDate`,
                error === 'shouldDisableDate'
                  ? 'Cannot input fund change date that falls on a weekend or bank holiday. Try again.'
                  : 'Please enter a valid date as mm/dd/yyyy'
              );
            }
          }}
          label='Fund Change Date'
          name={`fundChangesPreview[${rowIndex}].tradeDate`}
          onChange={onChange}
          shouldDisableDate={date => {
            if (date.day() === 0 || date.day() === 6 || isAHoliday(date)) {
              return true;
            }

            return false;
          }}
          size='small'
          variant='outlined'
        />
      </TableCell>
      <TableCell>
        <FormControl error={!!error?.oldFund} fullWidth>
          <InputLabel id={`oldFund-${rowIndex}`} size='small'>
            Ticker / CUSIP
          </InputLabel>
          <Select
            data-testid={`oldFund-${rowIndex}`}
            label='Ticker / CUSIP'
            labelId={`oldFund-${rowIndex}`}
            name={`fundChangesPreview[${rowIndex}].oldFund`}
            onChange={onChange}
            size='small'
            value={props.oldFund || ''}>
            {funds.map((fund, index) => {
              return (
                <MenuItem key={`${fund.cusip}-${index}`} value={fund.cusip}>
                  {fund.symbol}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText>{error?.oldFund}</FormHelperText>
        </FormControl>
      </TableCell>
      <TableCell
        sx={{ px: 0, py: 3, textAlign: 'center', verticalAlign: 'top' }}>
        <ArrowForward />
      </TableCell>
      <TableCell>
        <TickerAutocomplete
          data-testid={`newFund-${rowIndex}`}
          error={!!error?.newFund}
          helperText={error?.newFund}
          label='Ticker / CUSIP'
          onBlur={loadOptions}
          onChange={handleChange}
          onInputChange={loadOptions}
          options={tickerOptions}
          size='small'
        />
      </TableCell>
      <TableCell>
        <Stack direction='row'>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={
                    difference(allOptions, investmentOptions).length === 0
                  }
                  data-testid={`investmentOption-allOptions-${rowIndex}`}
                  onChange={onOptionsSwitchChange}
                />
              }
              label='All Options'
              sx={{ whiteSpace: 'nowrap' }}
            />
          </FormGroup>
          {difference(allOptions, investmentOptions).length !== 0 && (
            <FormGroup row>
              {[FUND, MANAGED, RISK, TARGET].map((option, index) => {
                if (!availableOptions.includes(option))
                  return <Fragment key={`${option}-${index}`} />;
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={investmentOptions.includes(
                          getOptionName(option)
                        )}
                        data-testid={`investmentOption-${getOptionName(
                          option
                        )}-${rowIndex}`}
                        onChange={(event, checked) => {
                          onInvestmentOptionChange(checked, option);
                        }}
                      />
                    }
                    key={`${option}-${index}`}
                    label={option}
                    sx={theme => ({
                      color: error?.investmentOptions
                        ? theme.palette.error.main
                        : 'inherit',
                      whiteSpace: 'nowrap'
                    })}
                  />
                );
              })}
            </FormGroup>
          )}
        </Stack>
      </TableCell>
    </TableRow>
  );
};

ProgramFundChangeRow.displayName = 'ProgramFundChangeRow';
