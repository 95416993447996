import { useSnackbar } from '@/contexts/SnackBarContext';
import ContributionService from '@/services/Contribution.service';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';

import { useCallback, useState } from 'react';
import type { FC } from 'react';
import { useToggle, useUpdateEffect } from 'react-use';

import { FLOW_SUBTYPES } from './consts';

type ReleaseContributionProps = {
  ucid: string;
  sponsorId: number;
  sponsorPlanId: number;
  flowSubtype?: string;
};

export const ReleaseContribution: FC<ReleaseContributionProps> = props => {
  const [isOpen, toggleIsOpen] = useToggle(false);
  const [isRefetch, toggleRefetch] = useToggle(false);
  const [refetchCount, setRefetchCount] = useState(0);
  const snackbar = useSnackbar();

  const contributionDetails = useQuery(
    [
      ContributionService.getContributionDetails.name,
      props.sponsorPlanId,
      props.ucid
    ],
    async () => {
      return ContributionService.getContributionDetails({
        includeCorrections: true,
        planId: props.sponsorPlanId,
        sponsorId: props.sponsorId,
        ucid: props.ucid
      });
    },
    {
      enabled: isRefetch,
      refetchInterval: 3000
    }
  );

  useUpdateEffect(() => {
    if (
      contributionDetails.data?.pngStatuses?.[
        contributionDetails.data?.pngStatuses?.length - 1
      ]?.status !== 'HELD'
    ) {
      window.location.reload();
      return;
    }

    if (refetchCount < 10) {
      setRefetchCount(prev => prev + 1);
      return;
    }

    toggleRefetch(false);
    toggleIsOpen(false);
  }, [contributionDetails.data]);

  const releaseContribution = useMutation(
    () =>
      ContributionService.postContributionAction(
        {
          ucid: props.ucid
        },
        {
          actionCode: 'APPROVE'
        }
      ),
    {
      onError: () => {
        snackbar.showSnackbar({
          message: 'Failed to release contribution from hold!',
          severity: 'error'
        });
      },
      onSuccess: () => {
        snackbar.showSnackbar({
          message: 'Contribution successfully released from hold. Please wait!',
          severity: 'success'
        });

        setRefetchCount(0);
        toggleRefetch();
      }
    }
  );

  const onSubmit = useCallback(() => releaseContribution.mutateAsync(), []);

  return (
    <>
      <LoadingButton
        data-testid='releaseFromHoldButton'
        loading={releaseContribution.isLoading || isRefetch}
        onClick={toggleIsOpen}
        variant='outlined'>
        Release from HOLD
      </LoadingButton>
      <Dialog
        data-testid='releaseFromHold-dialog'
        fullWidth
        maxWidth='xs'
        onClose={toggleIsOpen}
        open={isOpen}>
        <DialogTitle data-testid='releaseFromHold-dialog-title'>
          Release {FLOW_SUBTYPES[props.flowSubtype] ?? ''} Contribution From
          Hold
        </DialogTitle>
        <DialogContent data-testid='releaseFromHold-dialog-content'>
          <Typography>
            Are you sure you want to release the contribution from hold?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Grid display='flex'>
            <Grid mr={2}>
              <Button
                data-testid='releaseFromHold-dialog-closeButton'
                onClick={toggleIsOpen}>
                Close
              </Button>
            </Grid>
            <Grid>
              <LoadingButton
                color='error'
                data-testid='releaseFromHold-dialog-submitButton'
                loading={releaseContribution.isLoading || isRefetch}
                onClick={onSubmit}
                variant='contained'>
                Confirm
              </LoadingButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};
