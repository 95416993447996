export const initialPlanRolloverFilters = {
  createdAtDateEnd: '',
  createdAtDateStart: '',
  preTaxAmountEnd: '',
  preTaxAmountStart: '',
  rothAmountEnd: '',
  rothAmountStart: '',
  statuses: [],
  updatedAtDateEnd: '',
  updatedAtDateStart: '',
  originalAmountStart: '',
  originalAmountEnd: '',
  outstandingBalanceStart: '',
  outstandingBalanceEnd: '',
  startDateStart: '',
  startDateEnd: ''
};

export interface PlanRolloverFilters {
  createdAtDateEnd: string;
  createdAtDateStart: string;
  preTaxAmountEnd: string;
  preTaxAmountStart: string;
  rothAmountEnd: string;
  rothAmountStart: string;
  statuses: string[],
  updatedAtDateEnd: string;
  updatedAtDateStart: string;
  originalAmountStart: string;
  originalAmountEnd: string;
  outstandingBalanceStart: string;
  outstandingBalanceEnd: string;
  startDateStart: string;
  startDateEnd: string;
}