import { FormControl, TextFieldProps } from '@mui/material';
import { DatePickerProps, DateValidationError } from '@mui/x-date-pickers';

import dayjs from 'dayjs';
import { Field, FormikProps } from 'formik';
import { FunctionComponent } from 'react';

import DatePicker from './DatePicker';

interface MuiDatePickerProps
  extends Omit<DatePickerProps<any>, 'onChange' | 'renderInput' | 'value'> {
  'data-testid'?: string;
  label?: string;
  name: string;
  value?: string | null;
  onChange: (event: {
    target: {
      name: string;
      value: string;
    };
  }) => void;
  handleError?: (
    errorReason: DateValidationError,
    value: dayjs.Dayjs | null
  ) => void;
}

export const DatePickerForm: FunctionComponent<
  MuiDatePickerProps & TextFieldProps
> = props => {
  return (
    <FormControl sx={props.sx}>
      <Field name={props.name}>
        {(fieldProps: { form: FormikProps<any> }) => {
          return (
            <>
              <DatePicker
                {...props}
                errorMessage={fieldProps.form.errors[props.name]}
                handleError={(error, date) => {
                  if (props.handleError) props.handleError(error, date);
                }}
                onChange={event => {
                  fieldProps.form.setFieldValue(props.name, event.target.value);
                  props.onChange(event);
                }}
              />
            </>
          );
        }}
      </Field>
    </FormControl>
  );
};

export default DatePickerForm;
export type { MuiDatePickerProps };
