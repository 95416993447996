import AccessControl from '@/components/access-control/AccessControl.component';
import CircularLoading from '@/components/circular-loading';
import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import constants from '@/consts/planDesign.constants';
import { useDialog } from '@/contexts/DialogContext';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { PlanDesignDto } from '@/models';
import {
  OverrideVestedPercentDto,
  ParticipantAccountDetails,
  ParticipantAccountsDto
} from '@/models/ParticipantAccountsDTO.model';
import { PsaDto } from '@/models/Psa.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import ParticipantService from '@/services/Participant.service';
import formatters from '@/utils/Formatters';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Button,
  Card,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import { styled } from '@mui/styles';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import Decimal from 'decimal.js';
import { Form, Formik } from 'formik';
import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { useToggle } from 'react-use';
import * as yup from 'yup';

interface ParticipantVestingDetailsCardProps {
  accountInfo?: ParticipantAccountsDto;
  participantId: string;
  planDesign?: PlanDesignDto;
  psaData?: PsaDto;
  isLoading?: boolean;
  isEditing: boolean;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
}

const StyledTableCellHeader = styled(TableCell)(({ theme }) => ({
  color: theme.palette.grey[600],
  fontWeight: 700
}));

const StyledTableCellBody = styled(TableCell)(() => ({
  borderBottom: 'none',
  fontWeight: 700,
  justifyContent: 'right'
}));

const ParticipantVestingDetailsCard = (
  props: ParticipantVestingDetailsCardProps
): JSX.Element => {
  const {
    accountInfo,
    participantId,
    planDesign,
    psaData,
    isLoading = false
  } = props;
  const [displayLoanAndWithdrawal, toggleDisplayLoanAndWithdrawal] =
    useToggle(false);
  const planDesignVestingData = planDesign?.data.vestingPreferences;
  const whitelistedFundingSources = [
    'Profit Sharing',
    'Employer Match',
    'QACA SH Match',
    'QACA SH Nonelective'
  ];
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { closeDialog, openDialog } = useDialog();

  const vestingPercentOverrideEnabled = useMemo(
    () =>
      (psaData?.yearsOfVesting === undefined ||
        psaData?.yearsOfVesting === '0' ||
        psaData?.yearsOfVesting === 0) &&
      !props.isEditing,
    [props.isEditing, psaData?.yearsOfVesting]
  );

  const { mutateAsync: toggleFreezeVesting } = useMutation(
    ['ParticipantService.updateAccountFreezeVesting'],
    (accountId: string) => {
      return ParticipantService.updateAccountFreezeVesting(
        +participantId,
        +accountId
      );
    }
  );

  const { mutateAsync: setOverrideVestedPct } = useMutation(
    ['ParticipantService.overrideVestedPercent'],
    ({
      accountId,
      data
    }: {
      accountId: string;
      data: OverrideVestedPercentDto;
    }) => {
      return ParticipantService.overrideVestedPercent(
        +participantId,
        +accountId,
        data
      );
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries([
          'ParticipantService.getParticipantAccounts',
          participantId
        ]);
      }
    }
  );

  const { mutateAsync: recalculateVesting } = useMutation(
    ['ParticipantService.recalculateVesting'],
    () => ParticipantService.recalculateVesting(+participantId),
    {
      onError: () => {
        showSnackbar({
          message: 'Failed recalculate vesting, please try again later.',
          severity: 'error'
        });
      },
      onSuccess: () => {
        showSnackbar({
          message:
            'Successfully triggered vesting recalculation. Please refresh this page after a few minutes to view updated data.',
          severity: 'success'
        });
      }
    }
  );

  if (accountInfo?.data.length === 0) {
    return (
      <Typography data-testid='participant-vesting-details-card-no-data'>
        This participant does not have vesting data.
      </Typography>
    );
  }

  const removeLoans = (account: ParticipantAccountDetails) =>
    account.attributes.code !== 'LN';

  const includeValidAccounts = (account: ParticipantAccountDetails) =>
    account.attributes.vestedBalance > 0 ||
    account.attributes.balance > 0 ||
    whitelistedFundingSources.includes(account.attributes.sourceCode);

  const filteredAccounts = accountInfo?.data
    .filter(removeLoans)
    .filter(includeValidAccounts);

  const totalVestedAmount = filteredAccounts?.reduce((acc, curr) => {
    return Decimal.sum(acc, curr?.attributes?.vestedBalance || 0).toNumber();
  }, 0);

  const totalBalanceAmount = accountInfo?.stats?.balance?.total || 0;

  const calculateVestingPercent = (
    fundingSourcePct: string,
    fundingSource: string
  ) => {
    if (whitelistedFundingSources.includes(fundingSource)) {
      return fundingSourcePct.concat('%');
    }
    if (!whitelistedFundingSources.includes(fundingSource)) {
      return '100%';
    }
  };

  const onCloseDialog = () => {
    props.setIsEditing(false);
    closeDialog();
  };

  return (
    <div data-testid='participant-vesting-details-card'>
      <TableContainer
        component={Card}
        elevation={0}
        sx={{ width: theme => theme.spacing(130) }}
        variant='outlined'>
        <Grid alignItems='flex-end' container justifyContent='space-between'>
          <Grid item justifyContent='flex-end'>
            <Grid alignItems='flex-end' container>
              <Typography
                sx={theme => ({
                  marginBottom: theme.spacing(1),
                  marginLeft: theme.spacing(2),
                  marginTop: theme.spacing(2)
                })}
                variant='h5'>
                Details
              </Typography>
              <Typography
                color='textSecondary'
                data-testid='vesting-details-as-of-date-heading'
                sx={theme => ({
                  marginBottom: theme.spacing(1.5),
                  marginLeft: theme.spacing(1),
                  marginTop: theme.spacing(2)
                })}
                variant='caption'>
                As-of Date:{' '}
                {accountInfo?.stats.vesting.dateOf || EMPTY_FIELD_PLACEHOLDER}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Stack direction='row' spacing={2}>
              <FormControlLabel
                control={
                  <Switch
                    checked={displayLoanAndWithdrawal}
                    onChange={toggleDisplayLoanAndWithdrawal}
                  />
                }
                label='Display loan & withdrawals'
              />
              <Box>
                <Button
                  onClick={() => recalculateVesting()}
                  role='button'
                  sx={theme => ({
                    marginBottom: theme.spacing(1),
                    marginRight: theme.spacing(1)
                  })}
                  type='submit'
                  variant='text'>
                  Recalculate
                </Button>
              </Box>
            </Stack>
          </Grid>
        </Grid>
        <Table size='small'>
          <TableHead>
            <TableRow key='container'>
              <StyledTableCellHeader>Funding Source</StyledTableCellHeader>
              {displayLoanAndWithdrawal && (
                <>
                  <StyledTableCellHeader align='right'>
                    Outstanding Loan Balance
                  </StyledTableCellHeader>
                  <StyledTableCellHeader align='right'>
                    Prior Withdrawals
                  </StyledTableCellHeader>
                </>
              )}
              <StyledTableCellHeader align='right'>
                Vested
              </StyledTableCellHeader>
              <StyledTableCellHeader align='right'>
                Unvested
              </StyledTableCellHeader>
              <StyledTableCellHeader align='right'>Total</StyledTableCellHeader>
              <StyledTableCellHeader align='right'>
                Vested %
              </StyledTableCellHeader>
              <StyledTableCellHeader align='right'>
                Years of Vesting
              </StyledTableCellHeader>
              <StyledTableCellHeader align='right'>
                Override Vested %
              </StyledTableCellHeader>
              <StyledTableCellHeader align='right'>
                Freeze
              </StyledTableCellHeader>
            </TableRow>
          </TableHead>

          <TableBody data-testid='vesting-details-table-body'>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={8}>
                  <div
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                      minHeight: '400px'
                    }}>
                    <CircularLoading size='40px' />
                  </div>
                </TableCell>
              </TableRow>
            )}
            {!isLoading && (
              <>
                {filteredAccounts?.map((account: ParticipantAccountDetails) => {
                  const vestingScheduleCache: { [index: string]: string } = {};
                  if (planDesignVestingData?.fundingSources?.length) {
                    planDesignVestingData.fundingSources.forEach(source => {
                      const existingFundingSourceNames =
                        planDesignVestingData.fundingSources &&
                        planDesignVestingData.fundingSources.map(
                          obj => obj.fundingSourceName
                        );

                      if (
                        source.fundingSourceName &&
                        !existingFundingSourceNames?.includes(
                          account.attributes.sourceCode
                        )
                      ) {
                        const filteredSources =
                          whitelistedFundingSources.filter(
                            s => !existingFundingSourceNames?.includes(s)
                          );

                        filteredSources.forEach(name => {
                          vestingScheduleCache[name] = EMPTY_FIELD_PLACEHOLDER;
                        });
                      }

                      if (
                        source.fundingSourceName &&
                        whitelistedFundingSources.includes(
                          source.fundingSourceName
                        )
                      ) {
                        const vestingSchedule =
                          constants.vestingSchedules.find(constant => {
                            return source.vestingScheduleId === constant.id;
                          })?.name ?? EMPTY_FIELD_PLACEHOLDER;

                        vestingScheduleCache[source.fundingSourceName] =
                          vestingSchedule;
                      }
                    });
                  }

                  return (
                    <TableRow
                      data-testid={`participant-vesting-details-card-row-${formatters.textToDataTestId(
                        account.attributes.sourceCode
                      )}`}
                      key={account.id}>
                      <TableCell
                        sx={{
                          color: theme =>
                            account.attributes.freezeVesting
                              ? theme.palette.grey[500]
                              : undefined,
                          fontSize: 15
                        }}>
                        {account.attributes.sourceCode}
                        <Typography
                          sx={{
                            color: theme => theme.palette.grey[600],
                            fontSize: 12
                          }}>
                          {vestingScheduleCache[account.attributes.sourceCode]}
                        </Typography>
                      </TableCell>

                      {displayLoanAndWithdrawal && (
                        <>
                          <TableCell align='right'>
                            {formatters.formatDollars(
                              account.attributes.loanOutstandingBalance
                            )}
                          </TableCell>
                          <TableCell align='right'>
                            {formatters.formatDollars(
                              account.attributes.withdrawalBalance
                            )}
                          </TableCell>
                        </>
                      )}
                      <TableCell
                        align='right'
                        sx={{
                          color: theme =>
                            account.attributes.freezeVesting
                              ? theme.palette.grey[500]
                              : undefined
                        }}>
                        {formatters.formatDollars(
                          account.attributes.vestedBalance
                        )}
                      </TableCell>
                      <TableCell
                        align='right'
                        sx={{
                          color: theme =>
                            account.attributes.freezeVesting
                              ? theme.palette.grey[500]
                              : undefined
                        }}>
                        {formatters.formatDollars(
                          account.attributes.balance -
                            account.attributes.vestedBalance
                        )}
                      </TableCell>
                      <TableCell
                        align='right'
                        sx={{
                          color: theme =>
                            account.attributes.freezeVesting
                              ? theme.palette.grey[500]
                              : undefined
                        }}>
                        {formatters.formatDollars(account.attributes.balance)}
                      </TableCell>
                      <TableCell
                        align='right'
                        sx={{
                          color: theme =>
                            account.attributes.freezeVesting
                              ? theme.palette.grey[500]
                              : undefined
                        }}>
                        {account.attributes.vestedPercent !== undefined
                          ? calculateVestingPercent(
                              account.attributes.vestedPercent.toString(),
                              account.attributes.sourceCode
                            )
                          : EMPTY_FIELD_PLACEHOLDER}
                      </TableCell>
                      <TableCell
                        align='right'
                        sx={{
                          color: theme =>
                            account.attributes.freezeVesting
                              ? theme.palette.grey[500]
                              : undefined
                        }}>
                        {(whitelistedFundingSources.includes(
                          account.attributes.sourceCode
                        ) &&
                          account.attributes.yearsOfVesting !== undefined) ||
                        account.attributes.yearsOfVesting !== undefined
                          ? account.attributes.yearsOfVesting
                          : EMPTY_FIELD_PLACEHOLDER}
                      </TableCell>
                      <TableCell
                        align='right'
                        sx={{
                          '& .MuiIconButton-root': {
                            display: 'none'
                          },
                          '&:hover .MuiIconButton-root': {
                            display: 'initial'
                          }
                        }}>
                        <AccessControl
                          requires={[FeatureLevelPermissions.WRITE_VESTING]}>
                          {['PS', 'EM', 'QSHM', 'QSHNE'].includes(
                            account.attributes.code
                          ) && (
                            <>
                              {account.attributes.vestedPercentOverrideValue !==
                              undefined
                                ? formatters.formatDecimal(
                                    account.attributes
                                      .vestedPercentOverrideValue,
                                    0
                                  ) + '%'
                                : EMPTY_FIELD_PLACEHOLDER}
                              {vestingPercentOverrideEnabled && (
                                <IconButton
                                  aria-label='edit'
                                  onClick={() => {
                                    const validationSchema = yup.object({
                                      vestedPercentOverrideValue: yup
                                        .number()
                                        .integer()
                                        .min(0, 'Value can not be less than 0')
                                        .max(
                                          100,
                                          'Value can not be more than 100'
                                        )
                                        .required()
                                    });

                                    openDialog({
                                      customContent: (
                                        <Formik
                                          initialValues={{
                                            vestedPercentOverrideValue:
                                              account.attributes
                                                .vestedPercentOverrideValue ??
                                              ''
                                          }}
                                          onSubmit={async ({
                                            vestedPercentOverrideValue
                                          }) => {
                                            const data = {
                                              vestedPctOverrideEnabled: true,
                                              vestedPctOverrideValue:
                                                +vestedPercentOverrideValue
                                            } as OverrideVestedPercentDto;

                                            try {
                                              await setOverrideVestedPct({
                                                accountId: account.id,
                                                data
                                              });

                                              showSnackbar({
                                                message: `Successfully override Vested Percent for ${account.attributes.sourceCode}`,
                                                severity: 'success'
                                              });
                                            } catch {
                                              showSnackbar({
                                                message: `Failed to override Vested Percent for ${account.attributes.sourceCode}`,
                                                severity: 'error'
                                              });
                                            } finally {
                                              props.setIsEditing(false);
                                              closeDialog();
                                            }
                                          }}
                                          validationSchema={validationSchema}>
                                          {formProps => {
                                            return (
                                              <Form>
                                                <DialogTitle
                                                  sx={{ paddingBottom: 0 }}>
                                                  Override Vested Percent
                                                </DialogTitle>
                                                <Typography
                                                  pl={3}
                                                  variant='body2'>
                                                  Funding Source:{' '}
                                                  {
                                                    account.attributes
                                                      .sourceCode
                                                  }
                                                </Typography>
                                                <DialogContent
                                                  sx={{
                                                    paddingTop: 3
                                                  }}>
                                                  <FormControl
                                                    error={Boolean(
                                                      formProps.errors
                                                        .vestedPercentOverrideValue
                                                    )}>
                                                    <TextField
                                                      InputProps={{
                                                        endAdornment: (
                                                          <InputAdornment position='end'>
                                                            %
                                                          </InputAdornment>
                                                        )
                                                      }}
                                                      error={Boolean(
                                                        formProps.errors
                                                          .vestedPercentOverrideValue
                                                      )}
                                                      helperText={
                                                        formProps.errors
                                                          .vestedPercentOverrideValue
                                                      }
                                                      id='vestedPercentOverrideValue'
                                                      name='vestedPercentOverrideValue'
                                                      onChange={
                                                        formProps.handleChange
                                                      }
                                                      size='small'
                                                      value={
                                                        formProps.values
                                                          .vestedPercentOverrideValue
                                                      }
                                                    />
                                                  </FormControl>
                                                </DialogContent>
                                                <Typography
                                                  pl={3}
                                                  sx={theme => ({
                                                    fontSize: theme.spacing(1.5)
                                                  })}
                                                  variant='body2'>
                                                  After saving, click
                                                  “Recalculate” to trigger an
                                                  update of vesting information
                                                  at this time. Otherwise, the
                                                  vested balance will update the
                                                  following day.
                                                </Typography>
                                                <DialogActions>
                                                  <Stack
                                                    direction='row'
                                                    justifyContent='space-between'
                                                    sx={{ width: '100%' }}>
                                                    <Box>
                                                      {account.attributes
                                                        .vestedPercentOverrideValue && (
                                                        <Button
                                                          onClick={async () => {
                                                            const data = {
                                                              vestedPctOverrideEnabled:
                                                                false
                                                            } as OverrideVestedPercentDto;

                                                            try {
                                                              await setOverrideVestedPct(
                                                                {
                                                                  accountId:
                                                                    account.id,
                                                                  data
                                                                }
                                                              );
                                                              showSnackbar({
                                                                message: `Successfully remove Vested Percent override for ${account.attributes.sourceCode}`,
                                                                severity:
                                                                  'success'
                                                              });
                                                            } catch {
                                                              showSnackbar({
                                                                message: `Failed to remove Vested Percent override for ${account.attributes.sourceCode}`,
                                                                severity:
                                                                  'error'
                                                              });
                                                            } finally {
                                                              props.setIsEditing(
                                                                false
                                                              );
                                                              closeDialog();
                                                            }
                                                          }}>
                                                          Remove Override
                                                        </Button>
                                                      )}
                                                    </Box>
                                                    <Box>
                                                      <Button
                                                        disabled={
                                                          formProps.isSubmitting
                                                        }
                                                        onClick={onCloseDialog}>
                                                        Cancel
                                                      </Button>
                                                      <Button type='submit'>
                                                        Override
                                                      </Button>
                                                    </Box>
                                                  </Stack>
                                                </DialogActions>
                                              </Form>
                                            );
                                          }}
                                        </Formik>
                                      ),
                                      dialogProps: {
                                        onClose: onCloseDialog
                                      }
                                    });
                                    props.setIsEditing(true);
                                  }}
                                  size='small'>
                                  <EditIcon fontSize='inherit' />
                                </IconButton>
                              )}
                            </>
                          )}
                        </AccessControl>
                      </TableCell>
                      <TableCell align='right'>
                        <AccessControl
                          requires={[FeatureLevelPermissions.WRITE_VESTING]}>
                          {['PS', 'EM', 'QSHM', 'QSHNE'].includes(
                            account.attributes.code
                          ) && (
                            <Checkbox
                              checked={account.attributes.freezeVesting}
                              onChange={async () => {
                                try {
                                  await toggleFreezeVesting(account.id);
                                  queryClient.refetchQueries([
                                    'ParticipantService.getParticipantAccounts',
                                    participantId
                                  ]);
                                  showSnackbar({
                                    message: 'Updated freeze vesting',
                                    severity: 'success'
                                  });
                                } catch (error) {
                                  showSnackbar({
                                    message: `Failed to update freeze vesting`,
                                    severity: 'error'
                                  });
                                }
                              }}
                            />
                          )}
                        </AccessControl>
                      </TableCell>
                    </TableRow>
                  );
                })}
                <TableRow
                  data-testid='vesting-details-table-summary'
                  sx={{ backgroundColor: theme => theme.palette.grey[100] }}>
                  <StyledTableCellBody>Total</StyledTableCellBody>
                  {displayLoanAndWithdrawal && (
                    <>
                      {/* these two table cells are here to extend the gray color of the bottom total line for loan and withdrawal */}
                      <StyledTableCellBody></StyledTableCellBody>
                      <StyledTableCellBody></StyledTableCellBody>
                    </>
                  )}
                  <StyledTableCellBody align='right'>
                    {formatters.formatDollars(totalVestedAmount)}
                  </StyledTableCellBody>
                  <StyledTableCellBody align='right'>
                    {formatters.formatDollars(
                      Decimal.sub(
                        totalBalanceAmount,
                        totalVestedAmount || 0
                      ).toNumber()
                    )}
                  </StyledTableCellBody>
                  <StyledTableCellBody align='right'>
                    {formatters.formatDollars(totalBalanceAmount)}
                  </StyledTableCellBody>
                  {/* these four table cells are here to extend the gray color of the bottom total line for vested % and years of vesting */}
                  <StyledTableCellBody></StyledTableCellBody>
                  <StyledTableCellBody></StyledTableCellBody>
                  <StyledTableCellBody></StyledTableCellBody>
                  <StyledTableCellBody></StyledTableCellBody>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ParticipantVestingDetailsCard;
