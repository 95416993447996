import Card, {
  CardContent,
  CardHeader,
  CardPlaceholder
} from '@/components/card';
import CircularLoading from '@/components/circular-loading';
import DataTable, {
  DataTableStackCell
} from '@/components/data-table/DataTable.component';
import DatePicker from '@/components/date-picker/DatePicker';
import DownloadCSVButton from '@/components/download-csv-button/DownloadCSVButton.component';
import OpenInNewIcon from '@/components/icon/OpenInNewIcon';
import { useSnackbar } from '@/contexts/SnackBarContext';
import useAccountValue, { DisplayBalance } from '@/hooks/useAccountValue.hook';
import { BalanceGetRequest } from '@/models/ops/balances/BalanceGetRequest.model';
import BalanceService from '@/services/ops/balances/Balance.service';
import VestwellTheme from '@/theme/Vestwell.theme';
import DOMInteraction from '@/utils/DOMInteraction';
import formatters from '@/utils/Formatters';
import { json2csvParser } from '@/utils/Json2csvParser';
import { useUrlStateParams } from '@/utils/Url';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grow,
  InputLabel,
  Link,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Select,
  Stack,
  Switch,
  Typography
} from '@mui/material';
import { DateValidationError } from '@mui/x-date-pickers';
import { useQuery } from '@tanstack/react-query';
import { PositionDateType } from '@vestwell-sub-accounting/models/accountsAndLedgers/PositionDateType';

import { ColDef } from 'ag-grid-community';
import dayjs, { Dayjs } from 'dayjs';
import Decimal from 'decimal.js';
import { Field, Form, Formik } from 'formik';
import { kebabCase } from 'lodash';
import { useMemo, useRef, useState } from 'react';
import { isTradingDay } from 'sub-accounting-calendar-utility';
import * as yup from 'yup';

export type AccountResult = {
  confirmedValue: number;
  displayBalance: DisplayBalance[];
};

const Holdings = ({
  accountId,
  onlyExportSubAccounts = false
}: {
  accountId: string;
  onlyExportSubAccounts?: boolean;
}): JSX.Element => {
  const { showSnackbar } = useSnackbar();

  const [hasShortPositionSubAccounts, setHasShortPositionSubAccounts] =
    useUrlStateParams(
      false,
      'filterShortPositions',
      value => String(value),
      value => value.toLowerCase() === 'true'
    );

  const [query, setQuery] = useState<BalanceGetRequest>({
    date: dayjs().format('YYYY-MM-DD'),
    dateType: PositionDateType.Trade
  });
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const getBalanceQuery = useQuery(
    ['BalanceService.get', accountId, hasShortPositionSubAccounts, query],
    () =>
      BalanceService.get(accountId, {
        ...query,
        hasShortPositions: !hasShortPositionSubAccounts ? undefined : true
      }),
    {
      keepPreviousData: true,
      onError: (err: any) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Balance request failed: ${message}`,
          severity: 'error'
        });
      },
      staleTime: Infinity
    }
  );

  const { confirmedValue, displayBalance } = useAccountValue(
    getBalanceQuery.data
  );

  const columnDefs: ColDef[] = [
    {
      field: 'confirmedUnits',
      headerName: `${
        query.dateType
          ? {
              [PositionDateType.Trade]: 'Confirmed',
              [PositionDateType.Settlement]: 'Settled',
              [PositionDateType.Control]: 'Control',
              [PositionDateType.Dividend]: 'Dividend'
            }[query.dateType] + ' '
          : ''
      }Units`,
      maxWidth: 175,
      sortable: true,
      type: 'numericColumn',
      valueFormatter: ({ value }: { value: number }) =>
        value !== null && value !== undefined
          ? formatters.formatDecimal(value, 3)
          : ''
    },
    {
      field: 'price',
      headerName: 'Price',
      maxWidth: 175,
      sortable: true,
      type: 'numericColumn',
      valueFormatter: ({ value }: { value: string }) =>
        value !== null && value !== undefined
          ? formatters.formatDollars(value)
          : ''
    },
    {
      field: 'confirmedValue',
      headerName: `${
        query.dateType
          ? {
              [PositionDateType.Trade]: 'Confirmed ',
              [PositionDateType.Settlement]: '',
              [PositionDateType.Control]: '',
              [PositionDateType.Dividend]: ''
            }[query.dateType]
          : ''
      }Value`,
      maxWidth: 180,
      minWidth: 180,
      sortable: true,
      type: 'numericColumn',
      valueFormatter: ({ value }: { value: string }) =>
        value !== null && value !== undefined
          ? formatters.formatDollars(value)
          : ''
    }
  ];

  if (query.dateType === PositionDateType.Trade) {
    // only Trade date type displays pending columns
    columnDefs.push({
      field: 'pendingUnits',
      headerName: 'Pending Units',
      maxWidth: 175,
      sortable: true,
      type: 'numericColumn',
      valueFormatter: ({ value }: { value: number }) =>
        value !== null && value !== undefined
          ? formatters.formatDecimal(value, 3)
          : ''
    });
    columnDefs.push({
      field: 'pendingValue',
      headerName: 'Pending Value',
      maxWidth: 175,
      sortable: true,
      type: 'numericColumn',
      valueFormatter: ({ value }: { value: string }) =>
        value !== null && value !== undefined
          ? formatters.formatDollars(value)
          : ''
    });
  }

  const [errorMessage, setErrorMessage] = useState('');

  const autoGroupColumnDef = useMemo(() => {
    return {
      autoHeight: true,
      cellRendererParams: {
        innerRenderer: (cellData: { data: DisplayBalance }) => {
          /**
           * Only sub account rows will have an accountId set so we can use that to
           * conditionally render the sub account name in place of the security details
           */
          return cellData.data.accountId ? (
            <Link
              href={`/ops/accounts/${accountId}/sub-accounts/${cellData.data.accountId}`}
              target='_blank'>
              {cellData.data.accountId}
              <Box
                component='span'
                sx={{
                  lineHeight: 1,
                  pl: 0.5
                }}>
                <OpenInNewIcon sx={{ fontSize: 14 }} />
              </Box>
            </Link>
          ) : (
            <DataTableStackCell
              primary={
                formatters.formatSecurityName(
                  cellData.data.security?.symbol,
                  cellData.data.security?.cusip
                ) || '\u2014'
              }
              secondary={cellData.data.security?.description}
            />
          );
        },
        suppressCount: true
      },
      field: 'security.symbol',
      headerName: 'Security',
      minWidth: 300,
      resizable: true,
      sortable: true
    };
  }, [accountId]);

  const getDataPath = useMemo(() => {
    return (getDataPathData: DisplayBalance) => {
      return getDataPathData.orgHierarchy;
    };
  }, []);

  const validationSchema = yup.object({
    date: yup.date().required('As of date is required')
  });

  const handleError = (
    errorReason: DateValidationError,
    value: dayjs.Dayjs | null
  ) => {
    if (!errorReason) {
      setErrorMessage('');
      return;
    }

    if (errorReason === 'shouldDisableDate') {
      if (!isTradingDay(value.format('YYYY-MM-DD'))) {
        setErrorMessage('As of date must be a trading day');
      }
    }
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      event.target instanceof HTMLElement &&
      anchorRef.current.contains(event.target)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleSubmit = (formData: BalanceGetRequest) => {
    setQuery(formData);
  };

  const exportParentAccountsHoldings = async () => {
    return displayBalance
      .filter(balance => balance.subAccountPositions !== undefined)
      .map(balance => {
        return {
          Symbol: balance.security?.symbol,
          // eslint-disable-next-line sort-keys-plus/sort-keys
          CUSIP: balance.security?.cusip,
          Description: balance.security?.description,
          // eslint-disable-next-line sort-keys-plus/sort-keys
          'Confirmed Units': balance.confirmedUnits,
          Price: balance.price,
          // eslint-disable-next-line sort-keys-plus/sort-keys
          'Confirmed Value': balance.confirmedValue,
          'Pending Units': balance.pendingUnits,
          'Pending Value': balance.pendingValue
        };
      });
  };

  const exportSubaccountsHoldings = async () => {
    return displayBalance
      .filter(balance => balance.subAccountPositions === undefined)
      .map(balance => {
        return {
          Symbol: balance.security?.symbol,
          // eslint-disable-next-line sort-keys-plus/sort-keys
          CUSIP: balance.security?.cusip,
          Description: balance.security?.description,
          'SubAccount Id': balance.accountId,
          // eslint-disable-next-line sort-keys-plus/sort-keys
          'Confirmed Units': balance.confirmedUnits,
          Price: balance.price,
          // eslint-disable-next-line sort-keys-plus/sort-keys
          'Confirmed Value': balance.confirmedValue,
          'Pending Units': balance.pendingUnits,
          'Pending Value': balance.pendingValue
        };
      });
  };

  const exportSubAccounts = (
    <DownloadCSVButton
      buttonProps={{
        sx: {
          fontSize: 'small'
        },
        variant: 'outlined'
      }}
      fileName={`Holding-accounts-subaccounts-${accountId}`}
      getInfo={exportSubaccountsHoldings}
      key='parent-accounts-subaccounts-holdings-csv'
      text='Export CSV'
    />
  );

  const downloadCSV = async (index: number) => {
    setLoading(true);

    const { getInfo, fileName } = exportOptions[index];

    try {
      const allInfo = await getInfo();
      const csv = await json2csvParser(allInfo);
      DOMInteraction.triggerDownload(csv, `${fileName}.csv`);
    } catch (e) {
      showSnackbar({
        message: 'Failed to download, please try again',
        severity: 'error'
      });
    }
    setLoading(false);
  };

  const exportOptions: {
    name: string;
    getInfo: () => Promise<object[]>;
    fileName: string;
  }[] = [
    {
      fileName: `Holding-accounts-${accountId}`,
      getInfo: exportParentAccountsHoldings,
      name: 'Parent Accounts'
    },
    {
      fileName: `Holding-accounts-subaccounts-${accountId}`,
      getInfo: exportSubaccountsHoldings,
      name: 'Sub Accounts'
    }
  ];

  return (
    <Card>
      <CardHeader data-testid='holdings-header' title='All Holdings'>
        {onlyExportSubAccounts ? (
          exportSubAccounts
        ) : (
          <Box>
            <ButtonGroup
              data-testid='export-csv-menu-button'
              ref={anchorRef}
              variant='contained'>
              <LoadingButton
                loading={loading}
                onClick={handleToggle}
                sx={{
                  fontSize: 'small'
                }}
                variant='outlined'>
                EXPORT CSV
              </LoadingButton>
              <Button
                aria-controls={open ? 'split-button-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup='menu'
                aria-label='select merge strategy'
                onClick={handleToggle}
                size='small'
                variant='outlined'>
                <ArrowDropDownIcon />
              </Button>
            </ButtonGroup>
            <Popper
              anchorEl={anchorRef.current}
              disablePortal
              open={open}
              role={undefined}
              sx={{
                zIndex: 1
              }}
              transition>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom' ? 'center top' : 'center bottom'
                  }}>
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList autoFocusItem id='split-button-menu'>
                        {exportOptions.map((option, index) => (
                          <MenuItem
                            data-testid={`export-csv-${kebabCase(option.name)}-menu-item`}
                            key={`${option.name}-${index}`}
                            onClick={() => downloadCSV(index)}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Box>
        )}
      </CardHeader>
      <CardContent
        disablePadding
        overlayLoading={getBalanceQuery.isInitialLoading}>
        <Formik
          initialValues={{
            date: dayjs().format('YYYY-MM-DD'),
            dateType: PositionDateType.Trade
          }}
          onSubmit={(values: BalanceGetRequest) => handleSubmit(values)}
          validationSchema={validationSchema}>
          {({ handleSubmit: handleFormSubmit, isValid, errors, touched }) => (
            <Form data-testid='filter-form'>
              <Stack
                alignItems='center'
                direction='row'
                justifyContent='space-between'>
                <Stack
                  alignItems='flex-start'
                  direction='row'
                  justifyContent='flex-start'
                  spacing={2}
                  sx={{ minHeight: 84, p: 2, pb: 0 }}>
                  <FormControl error={touched.date && Boolean(errors.date)}>
                    <Field
                      as={DatePicker}
                      autoComplete='off'
                      data-testid='asOfDate'
                      disableFuture
                      errorMessage={errorMessage}
                      handleError={handleError}
                      label='As of'
                      name='date'
                      shouldDisableDate={(date: Dayjs) =>
                        !isTradingDay(date.format('YYYY-MM-DD'))
                      }
                      size='small' // FormControl doesn't pass to our DatePicker
                      sx={{ width: 240 }}
                      variant='outlined'
                    />
                    <FormHelperText>
                      {(!errorMessage && touched.date && errors.date) || ' '}
                    </FormHelperText>
                  </FormControl>
                  <FormControl size='small'>
                    <InputLabel id='date-type-label'>Date Type</InputLabel>
                    <Field
                      MenuProps={{
                        'data-testid': 'menu-dateType'
                      }}
                      as={Select}
                      error={touched.dateType && Boolean(errors.dateType)}
                      label='Date Type'
                      labelId='date-type-label'
                      name='dateType'
                      sx={{ width: 240 }}>
                      {Object.values(PositionDateType).map(value => (
                        <MenuItem key={value} value={value}>
                          {formatters.capitalizeFirstChar(value.toLowerCase())}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                  <Button
                    data-testid='submit'
                    disabled={!isValid}
                    onClick={() => handleFormSubmit()}
                    sx={{ py: 0.875 }}
                    type='submit'
                    variant='outlined'>
                    Apply
                  </Button>
                </Stack>
                <FormControlLabel
                  control={
                    <Switch
                      checked={hasShortPositionSubAccounts}
                      onChange={(_, newValue) => {
                        setHasShortPositionSubAccounts(
                          !hasShortPositionSubAccounts
                        );
                        setQuery({
                          ...query,
                          hasShortPositions: !newValue ? undefined : true
                        });
                      }}
                    />
                  }
                  label='View short positions only'
                  sx={{
                    minWidth: 260
                  }}
                />
              </Stack>
            </Form>
          )}
        </Formik>
        <Divider />
        <Stack
          direction='row'
          divider={<Divider flexItem orientation='vertical' />}
          sx={{ minHeight: 596 }}>
          <Box sx={{ p: 2, width: 240 }}>
            <Typography sx={{ mb: 2 }} variant='h6'>
              Account Value
            </Typography>
            <Box data-testid='account-value' sx={{ mb: 2.5 }}>
              <Typography sx={{ fontSize: 14, lineHeight: 1.43, mb: 0.5 }}>
                Total
              </Typography>
              <Typography variant='h5'>
                {formatters.formatDollars(confirmedValue)}
              </Typography>
            </Box>
            <Typography sx={{ mb: 2 }} variant='h6'>
              Balances
            </Typography>
            {[PositionDateType.Trade].includes(
              query.dateType as PositionDateType
            ) &&
              dayjs().format('YYYY-MM-DD') === query.date && ( // don't show pending balances if not Trade date type or today's date
                <Box data-testid='pending-balance-total' sx={{ mb: 2.5 }}>
                  <Typography sx={{ fontSize: 14, lineHeight: 1.43, mb: 0.5 }}>
                    Pending Balance
                  </Typography>
                  <Typography variant='h5'>
                    {formatters.formatDollars(
                      getBalanceQuery.data?.cash.pending
                    )}
                  </Typography>
                </Box>
              )}
            {[PositionDateType.Trade, PositionDateType.Settlement].includes(
              query.dateType as PositionDateType
            ) && ( // only Trade and Settlement date types display confirmed balances
              <Box data-testid='confirmed-balance-total'>
                <Typography sx={{ fontSize: 14, lineHeight: 1.43, mb: 0.5 }}>
                  {query.dateType
                    ? {
                        [PositionDateType.Trade]: 'Confirmed',
                        [PositionDateType.Settlement]: 'Settled',
                        [PositionDateType.Control]: undefined,
                        [PositionDateType.Dividend]: undefined
                      }[query.dateType]
                    : undefined}{' '}
                  Balance
                </Typography>
                <Typography variant='h5'>
                  {formatters.formatDollars(
                    getBalanceQuery.data?.cash.confirmed
                  )}
                </Typography>
              </Box>
            )}
            {[PositionDateType.Control, PositionDateType.Dividend].includes(
              query.dateType as PositionDateType
            ) && (
              <Stack
                alignItems='center'
                data-testid='no-balance-totals'
                justifyContent='center'
                sx={{ py: 1 }}>
                <Stack
                  sx={{
                    color: theme => theme.palette.grey[300],
                    fontSize: 42,
                    mb: 1
                  }}>
                  <InfoOutlinedIcon fontSize='inherit' />
                </Stack>
                <Typography
                  sx={{ color: theme => theme.palette.grey[700], mt: 0.25 }}
                  variant='body2'>
                  Not applicable
                </Typography>
              </Stack>
            )}
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            {getBalanceQuery.isFetching ? (
              <Stack
                alignItems='center'
                data-testid='holdings-table-loader'
                justifyContent='center'
                sx={{ height: '100%' }}>
                <CircularLoading size='40px' />
              </Stack>
            ) : (
              <DataTable
                autoGroupColumnDef={autoGroupColumnDef}
                columnDefs={columnDefs}
                data-testid='data-holdings-table'
                emptyPlaceholderComponent={
                  <Stack
                    alignItems='center'
                    data-testid='no-data-holdings-table'
                    justifyContent='center'
                    sx={{ height: '100%' }}>
                    <CardPlaceholder
                      icon={<SearchIcon fontSize='inherit' />}
                      subtitle='No results found.'
                    />
                  </Stack>
                }
                getDataPath={getDataPath}
                getRowStyle={params =>
                  params.data.confirmedUnits &&
                  new Decimal(params.data.confirmedUnits).lessThan(
                    new Decimal(0)
                  ) && {
                    backgroundColor: VestwellTheme.palette.warning.lightBg
                  }
                }
                rowData={displayBalance}
              />
            )}
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default Holdings;
