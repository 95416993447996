import Card, {
  CardContent,
  CardContentProps,
  CardHeader,
  CardHeaderProps,
  CardPlaceholder,
  CardPlaceholderProps,
  CardProps
} from './Card.component';

export { CardContent, CardHeader, CardPlaceholder };

export type {
  CardContentProps,
  CardHeaderProps,
  CardPlaceholderProps,
  CardProps
};
export default Card;
