import { CardPlaceholder } from '@/components/card';
import LinearLoading from '@/components/linear-loading';
import { Program, ProgramFund } from '@/models/ops/investments/Program.model';
import { SubaPlanFundStatusesDto } from '@/models/SubaccountingPlanFund.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { ProgramService } from '@/services/ops/investments/Program.service';
import SubAccountingService from '@/services/SubAccounting.service';
import { userService } from '@/services/User.service';
import { Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import React from 'react';

import PlanInvestmentsTabs from './PlanInvestmentsTabs.component';

interface PlanInvestmentsTabProps {
  planId: number;
  programId: number;
  custodianAccountNumber?: string;
}

const PlanInvestmentsTab = (props: PlanInvestmentsTabProps): JSX.Element => {
  const { planId, programId, custodianAccountNumber } = props;
  const isInvestmentsUser =
    userService.hasPermission(FeatureLevelPermissions.READ_INVESTMENTS) ||
    userService.hasPermission(FeatureLevelPermissions.WRITE_INVESTMENTS_MODIFY);
  const isCustodian = isInvestmentsUser && Boolean(custodianAccountNumber);

  const programQuery = useQuery<Program>(
    ['ProgramService.getProgram', programId],
    () => ProgramService.getProgram(programId),
    {
      staleTime: Infinity
    }
  );

  const programFundsQuery = useQuery<ProgramFund[]>(
    ['ProgramService.getProgramFunds', programId],
    () => ProgramService.getProgramFunds(programId),
    { enabled: isCustodian }
  );

  const planFundsStatusQuery = useQuery<SubaPlanFundStatusesDto>(
    ['SubAccountingService.getPlanFundsAndStatuses', planId],
    () => SubAccountingService.getPlanFundsAndStatuses(planId),
    { enabled: isCustodian }
  );

  if (
    programQuery.isFetching ||
    programFundsQuery.isFetching ||
    planFundsStatusQuery.isFetching
  ) {
    return <LinearLoading />;
  }

  if (programQuery.isError) {
    return (
      <Typography className='Plan-Investments_Error'>
        Error retrieving investments
      </Typography>
    );
  }
  if (programQuery.data === undefined) {
    return (
      <CardPlaceholder
        data-testid='no-data-investments-plan'
        subtitle='No data for this plan'
      />
    );
  }

  const riskSeriesId = programQuery.data?.riskSeriesId;
  const fundLineupId = programQuery.data?.fundLineupId;
  const targetDateSeriesId = programQuery.data?.targetDateSeriesId;
  const goalSeriesId = programQuery.data?.goalSeriesId;

  const program = programQuery.data;
  const programFunds = programFundsQuery.data || [];
  const planFundsStatus = planFundsStatusQuery.data?.data || [];

  return (
    <PlanInvestmentsTabs
      fundLineupId={fundLineupId}
      goalSeriesId={goalSeriesId}
      isCustodian={isCustodian}
      planFundsStatusData={planFundsStatus}
      planId={planId}
      programData={program}
      programFundsData={programFunds}
      programId={programId}
      riskSeriesId={riskSeriesId}
      targetDateSeriesId={targetDateSeriesId}
    />
  );
};

export default PlanInvestmentsTab;
