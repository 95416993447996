import Card, {
  CardContent,
  CardHeader
} from '@/components/card/Card.component';
import DataTable, {
  DataTableBadgeCell,
  DataTableStackCell
} from '@/components/data-table/DataTable.component';
import type { FundChangeApprovalDto } from '@/models/FundChangeDTO.model';
import {
  FUND,
  MANAGED,
  RISK,
  TARGET
} from '@/models/ops/investments/Program.model';
import { SecurityDto } from '@/models/SecurityMaster.model';
import {
  InvestmentOption,
  InvestmentOptionLabel
} from '@/routes/ops/investments/FundChangesTab/types';
import type { SponsorPlan } from '@/services/ops/investments/Program.service';
import DOMInteraction from '@/utils/DOMInteraction';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { List, ListItem, ListItemProps } from '@mui/material';
import { styled } from '@mui/material/styles';

import { difference, reverse, sortBy } from 'lodash';
import { useCallback, useMemo } from 'react';

import { FundChangeHistoryDetail } from './FundChangeHistoryDetail.component';

const StyledList = styled(List)(() => ({
  listStylePosition: 'inside',
  listStyleType: 'disc'
}));
const StyledListItem = styled(ListItem, {
  shouldForwardProp: () => true
})<ListItemProps>(props => ({
  display: 'list-item',
  fontSize: props.theme.spacing(1.5)
}));

const getOptionName = (option: InvestmentOptionLabel): InvestmentOption => {
  switch (option) {
    case TARGET:
      return 'targetSeries';
    case RISK:
      return 'riskSeries';
    case MANAGED:
      return 'goalSeries';
    case FUND:
    default:
      return 'fundLineup';
  }
};

const getOption = (option: InvestmentOption) => {
  switch (option) {
    case 'targetSeries':
      return TARGET;
    case 'riskSeries':
      return RISK;
    case 'goalSeries':
      return MANAGED;
    case 'fundLineup':
    default:
      return FUND;
  }
};

export type FundChangeHistoryCardProps = {
  availableOptions: InvestmentOptionLabel[];
  fundChanges: {
    approveInfo?: FundChangeApprovalDto;
    investmentOptions: InvestmentOption[];
    newFund: SecurityDto;
    oldFund: SecurityDto;
    plansAffected: SponsorPlan[];
    fundChangeId: number;
    tradeDate: string;
  }[];
  onSubmitFundChanges: () => void;
};

export const FundChangeHistoryCard = (
  props: FundChangeHistoryCardProps
): JSX.Element => {
  const { availableOptions, fundChanges } = props;

  const allOptions = useMemo(() => {
    return availableOptions.map(getOptionName);
  }, [availableOptions]);

  const detailCellRenderer = useCallback(cellParams => {
    return (
      <FundChangeHistoryDetail
        cellParams={cellParams}
        onSubmitFundChanges={props.onSubmitFundChanges}
      />
    );
  }, []);

  return (
    <Card>
      <CardHeader title='Fund Change History' />
      <CardContent disablePadding>
        <StyledList>
          <StyledListItem dense>
            ETFs submitted and approved before ~15:49 ET will receive same day
            pricing.
          </StyledListItem>
          <StyledListItem dense>
            Mutual Funds submitted and approved before ~16:00 ET will receive
            same day pricing.
          </StyledListItem>
          <StyledListItem dense>
            The fund change request may get queued if there are pending fund
            changes in the participant account.
          </StyledListItem>
        </StyledList>
        <DataTable
          columnDefs={[
            {
              cellRenderer: () => <></>,
              field: 'tradeDate',
              headerName: '',
              maxWidth: 55,
              minWidth: 55
            },
            {
              field: 'tradeDate',
              flex: 2,
              headerName: 'Fund Change Effective Date',
              minWidth: 200
            },
            {
              cellRenderer: params => {
                return (
                  <DataTableBadgeCell
                    color={
                      params.data.approveInfo?.approved
                        ? 'success'
                        : params.data.approveInfo?.approved === false
                          ? 'neutral'
                          : 'warning'
                    }
                    data-testid={`status-${
                      params.data.approveInfo?.approved
                        ? 'approved'
                        : params.data.approveInfo?.approved === false
                          ? 'rejected'
                          : 'pending'
                    }`}>
                    {params.data.approveInfo?.approved
                      ? 'Approved'
                      : params.data.approveInfo?.approved === false
                        ? 'Rejected'
                        : 'Pending Approval'}
                  </DataTableBadgeCell>
                );
              },
              field: 'status',
              flex: 2,
              headerName: 'Status',
              minWidth: 200
            },
            {
              cellRenderer: ({
                data
              }: {
                data: {
                  oldFund: {
                    symbol: string;
                    cusip: string;
                    fundName: string;
                  };
                };
              }) => {
                return (
                  <DataTableStackCell
                    primary={`${data.oldFund?.symbol} | ${data.oldFund?.cusip}`}
                    secondary={data.oldFund?.fundName}
                  />
                );
              },
              field: 'oldFund',
              flex: 3,
              headerName: 'Old Fund',
              minWidth: 430
            },
            {
              cellRenderer: ({
                data
              }: {
                data: {
                  newFund: {
                    symbol: string;
                    cusip: string;
                    fundName: string;
                  };
                };
              }) => {
                return (
                  <DataTableStackCell
                    primary={`${data.newFund?.symbol} | ${data.newFund?.cusip}`}
                    secondary={data.newFund?.fundName}
                  />
                );
              },
              field: 'newFund',
              flex: 3,
              headerName: 'New Fund',
              minWidth: 430
            },
            {
              field: 'investmentOptions',
              flex: 3,
              headerName: 'Investment Options',
              minWidth: 430,
              valueFormatter: (params: { value: InvestmentOption[] }) => {
                if (difference(allOptions, params.value).length > 0) {
                  return params.value
                    .map(option => getOption(option))
                    .join(', ')
                    .toString();
                }
                return 'All Options';
              }
            }
          ]}
          defaultColDef={{
            editable: false,
            suppressMenu: true,
            suppressMovable: true
          }}
          detailCellRenderer={detailCellRenderer}
          detailColumn={0}
          detailRowAutoHeight
          icons={{
            groupContracted: DOMInteraction.JSXToHTML(
              <ArrowRightIcon
                className='mui-group-icon'
                color='disabled'
                data-testid='group-icon-collapsed'
                fontSize='small'
              />
            ),
            groupExpanded: DOMInteraction.JSXToHTML(
              <ArrowDropDownIcon
                className='mui-group-icon'
                color='disabled'
                data-testid='group-icon-expanded'
                fontSize='small'
              />
            )
          }}
          pagination
          paginationPosition='bottom'
          rowData={reverse(
            sortBy(
              fundChanges,
              e => !('approved' in e.approveInfo),
              'tradeDate'
            )
          )}
          rowHeight={56}
        />
      </CardContent>
    </Card>
  );
};
