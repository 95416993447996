import { useSnackbar } from '@/contexts/SnackBarContext';
import SubAccountingService from '@/services/SubAccounting.service';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';

import { FC, useCallback, useState } from 'react';
import type { ApiResponseDataRebalanceRequestResponse } from 'scala-sdk';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary
} from './reportComponents';

export const BulkRebalanceTab: FC = () => {
  const [rowBulkData, setRowBulkData] = useState('');
  const [participantIds, setParticipantIds] = useState<Array<string>>([]);
  const [bulkRebalanceResults, setBulkRebalanceResults] =
    useState<ApiResponseDataRebalanceRequestResponse>();
  const snackbar = useSnackbar();

  const bulkRebalance = useMutation(
    (ids: string[]) => SubAccountingService.bulkRebalance(ids),
    {
      onError: () => {
        snackbar.showSnackbar({
          message: 'Error! Rebalance request could not be submitted',
          severity: 'error'
        });
      },
      onSuccess: res => {
        if (!res.data.failed.length) {
          snackbar.showSnackbar({
            message: 'The rebalance request(s) have been submitted successfully'
          });
        }
      }
    }
  );

  const handleSubmit = useCallback(async () => {
    const res = await bulkRebalance.mutateAsync(participantIds);
    setBulkRebalanceResults(res);
  }, [participantIds]);

  return (
    <>
      <Typography variant='h4'>Bulk Rebalance</Typography>
      <Grid container mt={2} spacing={2}>
        <Grid alignItems='flex-start' container direction='column' item xs={2}>
          <Typography variant='body1'>Participant IDs</Typography>
          <Typography mb={2} variant='body2'>
            Comma or line-break delimited
          </Typography>
          <Typography mb={2} variant='body2'>
            Participant(s) in Goal Models cannot be rebalanceed in Bulk
          </Typography>
          <TextField
            minRows={8}
            multiline
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setRowBulkData(event.target.value);
              const splitList = event.target.value
                .split(/[\r?\n,]/)
                .filter(element => element);
              setParticipantIds(splitList);
            }}
            sx={{ marginBottom: theme => theme.spacing(2) }}
            value={rowBulkData}></TextField>
          <Grid container item>
            <LoadingButton
              loading={bulkRebalance.isLoading}
              onClick={handleSubmit}
              variant='contained'>
              REBALANCE
            </LoadingButton>
            <Button
              disabled={bulkRebalance.isLoading}
              onClick={() => {
                setRowBulkData('');
                setBulkRebalanceResults(undefined);
              }}
              sx={{ marginLeft: theme => theme.spacing(2) }}
              variant='text'>
              RESET
            </Button>
          </Grid>
        </Grid>
        <Divider flexItem orientation='vertical'></Divider>
        <Grid item xs={8}>
          {bulkRebalanceResults && (
            <Box>
              <Accordion>
                <AccordionSummary
                  aria-controls='panel1a-content'
                  id='panel1a-header'>
                  <Stack direction='row' spacing={1}>
                    <Stack direction='row'>
                      <CheckCircleOutlinedIcon color='success' />
                      <Typography color='lightgreen'>Success</Typography>
                    </Stack>
                    <Typography sx={{ color: 'text.secondary' }}>
                      <>
                        {bulkRebalanceResults.data.submitted.length}{' '}
                        participant(s)
                      </>
                    </Typography>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    {bulkRebalanceResults.data.submitted.join()}
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  aria-controls='panel2a-content'
                  id='panel2a-header'>
                  <Stack direction='row' spacing={1}>
                    <Stack direction='row'>
                      <WarningAmberOutlinedIcon color='error' />
                      <Typography color='red'>Failed</Typography>
                    </Stack>
                    <Typography sx={{ color: 'text.secondary' }}>
                      <>
                        {bulkRebalanceResults.data.failed.length} participant(s)
                      </>
                    </Typography>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    {bulkRebalanceResults.data.failed.map(detail => {
                      return (
                        <ListItem divider key={detail.participantId}>
                          {detail.participantId}: {detail.cause}
                        </ListItem>
                      );
                    })}
                  </List>
                </AccordionDetails>
              </Accordion>
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
};

BulkRebalanceTab.displayName = 'BulkRebalanceTab';
