import { useSnackbar } from '@/contexts/SnackBarContext';
import { useUserToken } from '@/contexts/UserTokenContext';
import { useGetPayrollProviders } from '@/hooks/useGetPayrollProviders.hook';
import { PlanService } from '@/services/Plan.service';
import SponsorService from '@/services/Sponsor.service';
import { OpenInNew } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Stack,
  styled
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  FormWiredAtWorkToggleRadioGroup,
  StatesDropdown
} from '@vestwell-frontend/elements';
import {
  makePatchQuestionnaireDTO,
  makePatchSponsorDTO
} from '@vestwell-frontend/helpers';
import {
  fiscalYearEnds,
  useValidateOnboardingQuestionnaire
} from '@vestwell-frontend/hooks';
import {
  Form,
  FormAutosaveObserver,
  FormCheckbox,
  FormDropdown,
  FormField,
  FormFieldsSyncObserver,
  FormFileUploader,
  FormListBuilder,
  FormRadioGroup,
  FormShowErrorsButton,
  ThemeProvider as FrontendThemeProvider,
  Header,
  IconTooltip,
  Link,
  RadioButton,
  Text
} from '@vestwell-frontend/ui';

import { FormikContextType, useFormikContext } from 'formik';
import { memo, ReactNode, useCallback, useMemo, useRef, useState } from 'react';

import { useOnboardingQuestionnaireDocumentsHook } from './useOnboardingQuestionnaireDocuments.hook';

const complianceIssueOptions = [
  { label: 'Missing 5500', value: 'missing_5500' },
  { label: 'Late Deposits', value: 'late_deposits' },
  {
    label: 'Missing Contributions',
    value: 'missing_contributions'
  },
  {
    label: 'Failed Testing',
    value: 'failed_testing'
  },
  { label: 'Other', value: 'other' }
];

const FormGrabber = memo(
  (props: { children: (formik: FormikContextType<any>) => ReactNode }) => {
    const ctx = useFormikContext();
    // eslint-disable-next-line react/prop-types -- cannot add propTypes to a memoized component
    return props.children(ctx);
  }
);

// The custom styled components in this file are used to make frontend work with vestwell-admin-ui
// This will be removed when we have time and resources to integrate the two systems properly
const StyledDialogContent = styled(DialogContent)(() => ({
  '& .MuiFormHelperText-root.Mui-error': {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'start',
    marginLeft: 0,
    marginRight: 0
  },
  '& .MuiFormLabel-asterisk': {
    display: 'inline'
  },
  '& .MuiInputLabel-root': {
    display: 'inline'
  },
  '& .MuiSvgIcon-root': {
    marginLeft: 0
  },
  '& .MuiTypography-root, & * .MuiTypography-root': {
    '&[data-variant="g2"]': {
      display: 'block'
    },
    marginBottom: 0
  },
  '& .sr-only': {
    borderWidth: 0,
    clip: 'rect(0, 0, 0, 0)',
    height: '1px',
    margin: '-1px',
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    whiteSpace: 'nowrap',
    width: '1px'
  }
}));

const StyledFormFileUploader = styled(FormFileUploader)(() => ({
  '& .MuiFormHelperText-root': {
    // TODO change to theme.palette.error.main when themes are integrated properly
    color: '#C00A2B'
  },
  '& .MuiTypography-root': {
    paddingLeft: 0
  },
  '& ul': {
    '& li': {
      paddingLeft: 0
    },
    paddingLeft: 0
  }
}));

const StyledFormRadioGroup = styled(FormRadioGroup)(() => ({
  '& .MuiFormLabel-root': {
    maxWidth: 'calc(100% * 2 / 3)'
  }
}));

type OnboardingQuestionnaireModalProps = {
  sponsorPlanId: number;
};

export const OnboardingQuestionnaireModal = (
  props: OnboardingQuestionnaireModalProps
): JSX.Element => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { userHasValidToken } = useUserToken();
  const $formikRef = useRef<FormikContextType<any>>(null);
  const snackbar = useSnackbar();

  const plan = useQuery(
    ['PlanService.getPlanById', props.sponsorPlanId],
    () => {
      return PlanService.getPlanById(props.sponsorPlanId);
    },
    {
      enabled: !!(props.sponsorPlanId && userHasValidToken) && isDialogOpen
    }
  );

  const questionnaire = useQuery(
    ['SponsorService.getSponsorQuestionnaireInformation'],
    () =>
      SponsorService.getSponsorQuestionnaireInformation({
        sponsorId: plan.data?.data?.relationships?.sponsor?.data?.id
      }),
    {
      enabled:
        !!plan.data?.data?.relationships?.sponsor?.data?.id && isDialogOpen
    }
  );

  const patchQuestionnaire = useMutation(
    ['SponsorService.patchSponsorQuestionnaire', props.sponsorPlanId],
    (args: { values: Record<string, any>; isSubmit: boolean }) => {
      return SponsorService.patchSponsorQuestionnaire({
        body: makePatchQuestionnaireDTO({
          isMepTpa,
          planName: plan.data?.data?.attributes?.name,
          questionnaireSubmittedAt: args.isSubmit ? new Date() : null,
          values: {
            ...args.values,
            currentComplianceIssues: args.values.currentComplianceIssues || []
          }
        }),
        sponsorId: plan.data?.data?.relationships?.sponsor?.data?.id
      });
    },
    {
      onSettled(data, error) {
        if (error) {
          snackbar.showSnackbar({
            message: 'Error saving data',
            severity: 'error'
          });
        } else {
          snackbar.showSnackbar({
            message: 'Your data has been saved',
            severity: 'success'
          });
        }
      }
    }
  );

  const updateSponsor = useMutation(
    ['SponsorService.updateSponsor', props.sponsorPlanId],
    (questionnaireForm: Record<string, any>) => {
      return SponsorService.updateSponsor(
        plan.data?.data?.relationships?.sponsor?.data?.id,
        {
          data: {
            attributes: makePatchSponsorDTO({
              isMepTpa,
              values: questionnaireForm
            }).data,
            id: plan.data?.data?.relationships?.sponsor?.data?.id,
            type: 'sponsor'
          }
        }
      );
    },
    {
      onSettled(data, error) {
        if (error) {
          snackbar.showSnackbar({
            message: 'Error saving data',
            severity: 'error'
          });
        } else {
          snackbar.showSnackbar({
            message: 'Your data has been saved',
            severity: 'success'
          });
        }
      }
    }
  );

  const queryClient = useQueryClient();

  const onSave = useCallback(
    async (args: { values: Record<string, any>; isSubmit: boolean }) => {
      if (adoptionAgreementDoc.status === 'in-memory' && !isTpa) {
        try {
          await adoptionAgreementDoc.onSave(
            args.values[adoptionAgreementDoc.name][0]
          );
        } catch {
          snackbar.showSnackbar({
            message: 'Error uploading document',
            severity: 'error'
          });
        }
      }

      if (priorYear5500Doc.status === 'in-memory' && !isTpa) {
        try {
          await priorYear5500Doc.onSave(args.values[priorYear5500Doc.name][0]);
        } catch {
          snackbar.showSnackbar({
            message: 'Error uploading document',
            severity: 'error'
          });
        }
      }

      try {
        await updateSponsor.mutateAsync(args.values);
        await patchQuestionnaire.mutateAsync(args);
      } catch (e) {
        snackbar.showSnackbar({
          message: 'Error saving data',
          severity: 'error'
        });
      }

      if (args.isSubmit) {
        setIsDialogOpen(false);
      }

      queryClient.invalidateQueries([
        'SponsorService.getSponsorQuestionnaireInformation'
      ]);
    },
    [patchQuestionnaire, updateSponsor]
  );

  const adoptionAgreementDoc = useOnboardingQuestionnaireDocumentsHook({
    formikRef: $formikRef,
    name: '$adoptionAgreementDoc',
    query: {
      documentKey: 'Onboarding/Prior Plan Adoption Agreement',
      entity: 'plan',
      entityId: props.sponsorPlanId.toString()
    }
  });

  const priorYear5500Doc = useOnboardingQuestionnaireDocumentsHook({
    formikRef: $formikRef,
    name: '$priorYear5500Doc',
    query: {
      documentKey: 'Onboarding/Prior Plan Adoption Agreement',
      entity: 'plan',
      entityId: props.sponsorPlanId.toString()
    }
  });

  const isTpa = useMemo(() => {
    return (
      questionnaire.data?.sponsorPlan?.[0]?.thirdPartyAdminPlan?.[0]
        .thirdPartyAdminId !== 1
    );
  }, [questionnaire.data]);

  const isMepTpa = useMemo(() => {
    return (
      questionnaire.data?.sponsorPlan?.[0]?.pooledPlan?.pooledPlanType ===
        'mep' && isTpa
    );
  }, [questionnaire.data, isTpa]);

  const schema = useValidateOnboardingQuestionnaire({ isMepTpa, isTpa });

  const sortPayrollSystemsByName = (prev, next) => {
    const lowerPrev = prev.name.toLowerCase();
    const lowerNext = next.name.toLowerCase();
    if (lowerNext === 'other') return -1;
    return lowerPrev < lowerNext ? -1 : lowerPrev > lowerNext ? 1 : 0;
  };

  const payrollProviders = useGetPayrollProviders();
  const payrollOptions = useMemo(() => {
    return (
      payrollProviders.data?.sort(sortPayrollSystemsByName).map(payroll => ({
        label: payroll?.name?.toString(),
        value: payroll?.id
      })) ?? []
    );
  }, [payrollProviders.data]);

  const controlGroupBusinesses = useMemo(() => {
    try {
      const parsedBusinesses = JSON.parse(
        questionnaire.data?.controlGroupBusinesses
      );
      return Array.isArray(parsedBusinesses) ? parsedBusinesses : [];
    } catch (e) {
      return [];
    }
  }, [questionnaire.data?.controlGroupBusinesses]);

  const initialComplianceIssues = useMemo(() => {
    // don't mind the complexity the lists are small and its only called once
    return (
      questionnaire.data?.currentComplianceIssues?.map(v => {
        return complianceIssueOptions.find(option => option.value === v);
      }) ?? []
    );
  }, [questionnaire.data?.currentComplianceIssues]);

  return (
    <>
      {questionnaire.data && (
        <Form
          enableReinitialize={false}
          initialValues={{
            $isContactSameAsAuthorizedChecked:
              questionnaire.data.authSignerFirstName ===
                questionnaire.data.dayToDayContactName &&
              questionnaire.data.authSignerLastName ===
                questionnaire.data.dayToDayContactLastName &&
              questionnaire.data.authorizedSignerEmail ===
                questionnaire.data.dayToDayContactEmail &&
              questionnaire.data.authSignerWorkPhone ===
                questionnaire.data.dayToDayContactNumber,

            authSignerFirstName: questionnaire.data.authSignerFirstName,
            authSignerLastName: questionnaire.data.authSignerLastName,
            authSignerWorkPhone: questionnaire.data.authSignerWorkPhone,
            authorizedSignerEmail: questionnaire.data.authorizedSignerEmail,
            companyEntityType: questionnaire.data.companyEntityType,
            companyName: questionnaire.data.companyName,
            companyPhoneNumber: questionnaire.data.companyPhoneNumber,
            dayToDayContactEmail: questionnaire.data.dayToDayContactEmail,
            dayToDayContactLastName: questionnaire.data.dayToDayContactLastName,
            dayToDayContactName: questionnaire.data.dayToDayContactName,
            dayToDayContactNumber: questionnaire.data.dayToDayContactNumber,
            doingBusinessAs: questionnaire.data.doingBusinessAs,
            employerIdentificationNumber:
              questionnaire.data.employerIdentificationNumber,
            fiscalYearEnd: questionnaire.data.fiscalYearEnd,
            physicalAddress1: questionnaire.data.address?.address1,
            physicalAddress2: questionnaire.data.address?.address2,
            physicalCity: questionnaire.data.address?.city,
            physicalState: questionnaire.data.address?.state,
            physicalZip: questionnaire.data.address?.zip,
            stateOfIncorporation: questionnaire.data.stateOfIncorporation,
            wiredAtWork: questionnaire.data.wiredAtWork,
            ...(!isTpa && {
              $adoptionAgreementDoc: adoptionAgreementDoc.initialValue,
              $priorYear5500Doc: priorYear5500Doc.initialValue,
              anticipatedHires: questionnaire.data.anticipatedHires,
              highlyCompensatedEmployees:
                questionnaire.data.highlyCompensatedEmployees,
              isConvert: 'true', // questionnaire.data.isConvert,
              ownerEmployees: questionnaire.data.ownerEmployees,
              payrollProviderId: questionnaire.data.payrollProviderId,
              payrollSystemOther: questionnaire.data.payrollProviderOther,
              relativesOfOwnerEmployees:
                questionnaire.data.relativesOfOwnerEmployees
            }),
            ...(!isMepTpa && {
              controlGroupBusinesses: controlGroupBusinesses,
              isPartOfControlGroup:
                questionnaire.data.isPartOfControlGroup ||
                questionnaire.data.hasOtherBusinesses,
              totalEmployeesNumber: questionnaire.data.totalEmployeesNumber
            }),
            ...(!isTpa &&
              questionnaire.data.isConvert !== 'true' && {
                contributionScenario: questionnaire.data.contributionScenario,
                currentComplianceIssues: initialComplianceIssues,
                existingPlanProvider: questionnaire.data.existingPlanProvider,
                existingPlanType:
                  questionnaire.data.existingPlanType ?? undefined,
                industryGroup: questionnaire.data.industryGroup,
                retirementObjectives: questionnaire.data.retirementObjectives
              })
          }}
          ref={$formikRef}
          validateOnBlur
          validateOnChange={false}
          validateOnMount
          validationSchema={schema}>
          <FormAutosaveObserver
            disabled
            // TODO fix props so that if disabled no interval is needed
            interval={Infinity}
            onSave={async (values: Record<string, any>) => {
              await onSave({
                isSubmit: false,
                values
              });
            }}>
            {autosave => (
              <FormGrabber>
                {formik => (
                  <Dialog
                    fullWidth
                    maxWidth='lg'
                    onClose={() => {
                      setIsDialogOpen(false);
                    }}
                    open={isDialogOpen}
                    style={{ display: isDialogOpen ? 'block' : 'none' }}>
                    <StyledDialogContent>
                      <Box>
                        <div>
                          <FrontendThemeProvider>
                            <FormFieldsSyncObserver
                              sync={{
                                authSignerFirstName: 'dayToDayContactName',
                                authSignerLastName: 'dayToDayContactLastName',
                                authSignerWorkPhone: 'dayToDayContactNumber',
                                authorizedSignerEmail: 'dayToDayContactEmail'
                              }}
                              toggleName='$isContactSameAsAuthorizedChecked'
                            />
                            <Stack spacing={10}>
                              <Header>
                                <Text variant='b2'>Employer Questionnaire</Text>
                              </Header>
                              <Stack alignItems='flex-start'>
                                <Text variant='d2'>Company Information</Text>
                                <Stack direction='row'>
                                  <FormField
                                    details='This can be found on your tax documents or origination documents'
                                    format='ein'
                                    label='EIN'
                                    name='employerIdentificationNumber'
                                    width={40}
                                  />
                                </Stack>
                                <Stack
                                  direction={{
                                    md: 'row',
                                    sm: 'column'
                                  }}
                                  spacing={10}>
                                  <FormField
                                    details='This name will be used for official plan documents'
                                    label='Company Legal Name'
                                    name='companyName'
                                    width={95}
                                  />
                                  <FormField
                                    details='If applicable'
                                    label='Doing Business As (DBA)'
                                    name='doingBusinessAs'
                                    width={95}
                                  />
                                </Stack>
                                <Stack
                                  direction={{
                                    md: 'row',
                                    sm: 'column'
                                  }}
                                  spacing={10}>
                                  <FormField
                                    details='Should not be a P.O. Box'
                                    label='Physical Address'
                                    name='physicalAddress1'
                                    width={95}
                                  />
                                  <FormField
                                    details='If applicable'
                                    label='Address Line 2'
                                    name='physicalAddress2'
                                    width={95}
                                  />
                                </Stack>
                                <Stack
                                  direction={{
                                    md: 'row',
                                    sm: 'column'
                                  }}
                                  spacing={10}>
                                  <FormField
                                    label='City'
                                    name='physicalCity'
                                    required
                                  />
                                  <StatesDropdown
                                    aria-required='true'
                                    label='State'
                                    name='physicalState'
                                  />
                                  <FormField
                                    format='zip'
                                    label='Zip'
                                    name='physicalZip'
                                    required
                                  />
                                </Stack>
                                <Stack
                                  alignItems='stretch'
                                  width={{
                                    sm: '100%'
                                  }}>
                                  <FormField
                                    format='phoneUs'
                                    label='Company Phone Number'
                                    name='companyPhoneNumber'
                                    width={56}
                                  />
                                  <FormDropdown
                                    info={
                                      "Federal law requires your plan documents to include your organization's entity type (e.g.: partnership, corporation, etc). If you are not sure what your organization's entity type is, please contact your accountant or financial advisor or review your organization's prior tax returns, IRS correspondence with your company's Employer Identification Number, or your state's secretary of state website."
                                    }
                                    label='Company Entity Type'
                                    name='companyEntityType'
                                    options={[
                                      {
                                        label: 'C-Corporation',
                                        value: 'ccorp'
                                      },
                                      {
                                        label: 'Corporation (Inc)',
                                        value: 'inc'
                                      },
                                      {
                                        label:
                                          'Limited Liability Company (LLC)-Taxed as C-Corp',
                                        value: 'llc-ccorp'
                                      },
                                      {
                                        label:
                                          'Limited Liability Company (LLC)- Taxed as Partnership',
                                        value: 'llc-p'
                                      },
                                      {
                                        label:
                                          'Limited Liability Company (LLC)-Taxed as S-Corp',
                                        value: 'llc-scorp'
                                      },
                                      {
                                        label: 'Limited Partnership (LP)',
                                        value: 'lp'
                                      },
                                      {
                                        label: 'Partnership',
                                        value: 'p'
                                      },
                                      {
                                        label:
                                          'Professional Services Corporation',
                                        value: 'psc'
                                      },
                                      {
                                        label: 'S-Corp',
                                        value: 'scorp'
                                      },
                                      {
                                        label: 'Sole Proprietorship',
                                        value: 'sp'
                                      },
                                      {
                                        label: '501(c)(3)',
                                        value: '501(c)(3)'
                                      },
                                      {
                                        label: 'Cooperative',
                                        value: 'cooperative'
                                      },
                                      {
                                        label: 'Other',
                                        value: 'other'
                                      }
                                    ]}
                                    placeholder='Company Entity Type'
                                  />
                                  <StatesDropdown
                                    displayFormat='long'
                                    label='State of Incorporation'
                                    name='stateOfIncorporation'
                                    width={56}
                                  />
                                  <FormDropdown
                                    label='Fiscal Year End'
                                    name='fiscalYearEnd'
                                    options={fiscalYearEnds}
                                  />
                                </Stack>
                                <Text variant='d2'>Operations Information</Text>
                                {/*
                          PayrollDropdown is in sponsor package in frontend, and includes a dependency on OnboardingContext
                          if we move it to ui, it creates a circular dependency from
                          ui to sponsor therefore roughly recreate it here
                        */}
                                {!isTpa && (
                                  <>
                                    <FormDropdown
                                      label='What payroll system do you use?'
                                      name='payrollProviderId'
                                      onChange={id => {
                                        formik.setFieldValue(
                                          'payrollProviderId',
                                          id
                                        );
                                      }}
                                      options={payrollOptions}
                                      placeholder='Select'
                                    />
                                    {formik.values.payrollProviderId === 6 && (
                                      <FormField
                                        label='Payroll Product Name'
                                        name='payrollSystemOther'
                                      />
                                    )}
                                  </>
                                )}
                                <FormWiredAtWorkToggleRadioGroup
                                  aria-required='true'
                                  fullWidth
                                  row
                                />
                                <Stack alignItems='center' direction='row'>
                                  <Text variant='d2'>Authorized Signer</Text>
                                  <IconTooltip>
                                    The individual authorized to accept and give
                                    Instructions on behalf of the Plan and Plan
                                    Sponsor. Authorized signers have permission
                                    to sign Plan documents, change Plan
                                    features, change the Plan Sponsor's account
                                    profile, submit or change Plan Data provided
                                    to us, review and correct eligibility or
                                    other Plan-related reports, handle annual
                                    compliance testing matters, and all other
                                    Plan activity that involves or requires the
                                    Plan Sponsor’s review.
                                  </IconTooltip>
                                </Stack>
                                <Stack
                                  direction={{
                                    md: 'row',
                                    sm: 'column'
                                  }}
                                  spacing={4}>
                                  <FormField
                                    label='First Name'
                                    name='authSignerFirstName'
                                    placeholder='First Name'
                                  />
                                  <FormField
                                    label='Last Name'
                                    name='authSignerLastName'
                                    placeholder='Last Name'
                                  />
                                  <FormField
                                    format='email'
                                    label='Email'
                                    name='authorizedSignerEmail'
                                    placeholder='Email'
                                    width={80}
                                  />
                                  <FormField
                                    format='phoneUs'
                                    label='Phone'
                                    name='authSignerWorkPhone'
                                    placeholder='Phone'
                                  />
                                </Stack>
                                <Stack alignItems='start' spacing={4}>
                                  <Stack
                                    direction={{
                                      md: 'row',
                                      sm: 'column'
                                    }}
                                    justifyContent='center'
                                    spacing={8}>
                                    <Text variant='d2'>
                                      Day-to-Day Plan Contact
                                    </Text>
                                    <Stack justifyContent='center'>
                                      <FormCheckbox
                                        label='Same as Authorized Signer'
                                        name='$isContactSameAsAuthorizedChecked'
                                      />
                                    </Stack>
                                  </Stack>
                                  <Stack
                                    direction={{
                                      md: 'row',
                                      sm: 'column'
                                    }}
                                    spacing={4}>
                                    <FormField
                                      disabled={
                                        formik.values
                                          .$isContactSameAsAuthorizedChecked
                                      }
                                      hideError={
                                        formik.values
                                          .$isContactSameAsAuthorizedChecked
                                      }
                                      label='First Name'
                                      name='dayToDayContactName'
                                      placeholder='First Name'
                                    />
                                    <FormField
                                      disabled={
                                        formik.values
                                          .$isContactSameAsAuthorizedChecked
                                      }
                                      hideError={
                                        formik.values
                                          .$isContactSameAsAuthorizedChecked
                                      }
                                      label='Last Name'
                                      name='dayToDayContactLastName'
                                      placeholder='Last Name'
                                    />
                                    <FormField
                                      disabled={
                                        formik.values
                                          .$isContactSameAsAuthorizedChecked
                                      }
                                      format='email'
                                      hideError={
                                        formik.values
                                          .$isContactSameAsAuthorizedChecked
                                      }
                                      label='Email'
                                      name='dayToDayContactEmail'
                                      placeholder='Email'
                                      width={80}
                                    />
                                    <FormField
                                      disabled={
                                        formik.values
                                          .$isContactSameAsAuthorizedChecked
                                      }
                                      // phone format masking interferes with this field when synced
                                      {...(formik.values
                                        .$isContactSameAsAuthorizedChecked
                                        ? null
                                        : { format: 'phoneUs' })}
                                      hideError={
                                        formik.values
                                          .$isContactSameAsAuthorizedChecked
                                      }
                                      label='Phone'
                                      name='dayToDayContactNumber'
                                      placeholder='Phone'
                                    />
                                  </Stack>
                                </Stack>
                                {!isMepTpa && (
                                  <>
                                    <Text variant='d2'>
                                      Plan / Ownership / Employment
                                    </Text>
                                    <Stack
                                      direction={{
                                        md: 'row',
                                        sm: 'column'
                                      }}
                                      spacing={3}>
                                      {!isTpa && (
                                        <Stack
                                          direction='column'
                                          width={{
                                            md: 405,
                                            sm: '100%'
                                          }}>
                                          <FormRadioGroup
                                            fullWidth
                                            label='Do you currently offer a retirement plan?'
                                            name='isConvert'
                                            onChange={() => {
                                              formik.setFieldValue(
                                                'currentComplianceIssues',
                                                formik.values
                                                  .currentComplianceIssues ?? []
                                              );
                                              setTimeout(() =>
                                                formik.validateForm()
                                              );
                                            }}>
                                            <Stack direction='row'>
                                              <RadioButton
                                                label='Yes'
                                                value='true'
                                              />
                                              <RadioButton
                                                label='No'
                                                value='false'
                                              />
                                            </Stack>
                                          </FormRadioGroup>
                                        </Stack>
                                      )}
                                      <Stack direction='column'>
                                        {formik.values.isConvert === 'true' &&
                                          !isTpa && (
                                            <Stack direction='column'>
                                              <Stack
                                                direction='row'
                                                spacing={4}>
                                                <FormDropdown
                                                  // manually add asterisk to label until we support yup.when validations
                                                  aria-required='true'
                                                  label='Plan Type *'
                                                  name='existingPlanType'
                                                  options={[
                                                    {
                                                      label: '401(k)',
                                                      value: '401k'
                                                    },
                                                    {
                                                      label: '403(b)',
                                                      value: '403b'
                                                    },
                                                    {
                                                      label: 'SIMPLE IRA',
                                                      value: 'SIMPLE_IRA'
                                                    },
                                                    {
                                                      label: 'SEP-IRA',
                                                      value: 'SEP_IRA'
                                                    },
                                                    {
                                                      label: 'Defined Benefit',
                                                      value: 'defined_benefit'
                                                    },
                                                    {
                                                      label: 'Cash Balance',
                                                      value: 'cash_balance'
                                                    },
                                                    {
                                                      label: 'Other',
                                                      value: 'other'
                                                    }
                                                  ]}
                                                  width={75}
                                                />
                                                <FormField
                                                  // manually add asterisk to label until we support yup.when validations
                                                  aria-required='true'
                                                  label='Current Provider *'
                                                  name='existingPlanProvider'
                                                  placeholder='Current Provider'
                                                  width={75}
                                                />
                                              </Stack>
                                              <FormCheckbox
                                                label='Are you currently aware of any compliance issues?'
                                                multiple
                                                name='currentComplianceIssues'
                                                options={complianceIssueOptions}
                                                row
                                              />
                                              <StyledFormFileUploader
                                                accept={[
                                                  '.jpeg',
                                                  '.jpg',
                                                  '.pdf'
                                                ]}
                                                caption='Please provide an executed copy of the plan document currently in use. This document must be no older than 6 years.'
                                                maxSize={6}
                                                name={adoptionAgreementDoc.name}
                                                onRemove={
                                                  adoptionAgreementDoc.onDeleteFromServer
                                                }
                                                onUpload={
                                                  adoptionAgreementDoc.onDocUploadedInMemory
                                                }
                                                readFileAs='base64'
                                                // manually add asterisk to label until FormFileUploader supports required check
                                                title='Adoption Agreement/Plan Document *'
                                              />
                                              <StyledFormFileUploader
                                                accept={[
                                                  '.jpeg',
                                                  '.jpg',
                                                  '.pdf'
                                                ]}
                                                caption={
                                                  <Text
                                                    // color='grey300'
                                                    maxWidth={720}
                                                    variant='i2'>
                                                    Please provide your plan's
                                                    most recent 5500 filing in
                                                    either jpg or pdf format.
                                                    You can search for your 5500
                                                    form here:{' '}
                                                    <Link
                                                      external
                                                      target='_blank'
                                                      to='https://www.efast.dol.gov/5500Search/'>
                                                      https://www.efast.dol.gov/5500Search/
                                                    </Link>
                                                    .
                                                  </Text>
                                                }
                                                maxSize={6}
                                                name={priorYear5500Doc.name}
                                                onRemove={
                                                  priorYear5500Doc.onDeleteFromServer
                                                }
                                                onUpload={
                                                  priorYear5500Doc.onDocUploadedInMemory
                                                }
                                                readFileAs='base64'
                                                title='Most Recent 5500 Filing'
                                              />
                                            </Stack>
                                          )}
                                      </Stack>
                                    </Stack>
                                    <Stack
                                      direction={{
                                        md: 'row',
                                        sm: 'column'
                                      }}
                                      spacing={3}>
                                      <Stack
                                        direction='column'
                                        width={{
                                          // md: theme.breakpoints.values.sm,
                                          sm: '100'
                                        }}>
                                        <FormRadioGroup
                                          info={
                                            <a
                                              href='https://support.vestwell.com/hc/en-us/articles/1500012490481-Controlled-Groups-and-Affiliated-Service-Groups'
                                              rel='noreferrer'
                                              target='_blank'>
                                              What is a controlled group?
                                            </a>
                                          }
                                          label='Is the company a member of a controlled group and/or affiliated service group?'
                                          name='isPartOfControlGroup'
                                          // validate after value change is processed but before next blur
                                          onChange={() =>
                                            setTimeout(() =>
                                              formik.validateForm()
                                            )
                                          }>
                                          <Stack direction='row'>
                                            <RadioButton
                                              label='Yes'
                                              value={true}
                                            />
                                            <RadioButton
                                              label='No'
                                              value={false}
                                            />
                                          </Stack>
                                        </FormRadioGroup>
                                      </Stack>
                                      {formik.values.isPartOfControlGroup && (
                                        <Stack direction='column'>
                                          <FormListBuilder
                                            fields={[
                                              {
                                                label: 'Company Name *',
                                                name: 'business',
                                                placeholder: 'Company Name',
                                                required: true,
                                                type: 'text'
                                              },
                                              {
                                                format: 'ein',
                                                label: 'EIN *',
                                                name: 'ein',
                                                placeholder: 'EIN',
                                                required: true
                                              }
                                            ]}
                                            fullWidth={false}
                                            label='Please add the name and EIN of each company owned:'
                                            name='controlGroupBusinesses'
                                          />
                                        </Stack>
                                      )}
                                    </Stack>
                                    <Stack width={{ md: 400, sm: '100%' }}>
                                      <FormField
                                        format='number'
                                        label='How many employees (paid via W2) are employed at the company today?'
                                        name='totalEmployeesNumber'
                                        width={40}
                                      />
                                    </Stack>
                                    {!isTpa && (
                                      <>
                                        <Text variant='e2'>
                                          How many employees...
                                        </Text>
                                        <Stack
                                          direction={{
                                            md: 'row',
                                            sm: 'column'
                                          }}
                                          spacing={3}
                                          width={{
                                            md: 800,
                                            sm: '100%'
                                          }}>
                                          <FormField
                                            format='number'
                                            label='were paid more than $150,000 last year?'
                                            name='highlyCompensatedEmployees'
                                            width={40}
                                          />
                                          <FormField
                                            format='number'
                                            label='are currently an owner of at least 5% of the company?'
                                            name='ownerEmployees'
                                            width={40}
                                          />
                                          <FormField
                                            format='number'
                                            label='are a parent, spouse, child or grandparent (but not
                          siblings or any other relations) of an owner of the
                          company?'
                                            name='relativesOfOwnerEmployees'
                                            width={40}
                                          />
                                        </Stack>
                                        <FormField
                                          format='number'
                                          label='How many employees (paid via W2) do you plan on hiring in the next 12 months?'
                                          name='anticipatedHires'
                                          width={40}
                                        />
                                      </>
                                    )}
                                    {!isTpa &&
                                      formik.values.isConvert !== 'true' && (
                                        <>
                                          <Text variant='d2'>
                                            Retirement Goals and Industry
                                            Information
                                          </Text>
                                          <FormRadioGroup
                                            label='Please choose your top objective for offering a retirement plan'
                                            name='retirementObjectives'>
                                            <RadioButton
                                              label='Maximize owner contributions into the plan'
                                              value='max_owner_contributions'
                                            />
                                            <RadioButton
                                              label='Offer a benefit that attracts and retains employees'
                                              value='offer_benefit'
                                            />
                                            <RadioButton
                                              label='Take advantage of new tax credits through SECURE 2.0'
                                              value='tax_credits'
                                            />
                                            <RadioButton
                                              label='Meet a state requirement to offer a retirement plan to employees'
                                              value='meet_state_requirement'
                                            />
                                          </FormRadioGroup>
                                          <StyledFormRadioGroup
                                            label='Which scenario best describes how you’re thinking about
                      your company contributing to employees retirement
                      accounts:'
                                            name='contributionScenario'>
                                            <RadioButton
                                              label='We will contribute modestly to encourage our employees to save for retirement'
                                              value='modest_contribution'
                                            />
                                            <RadioButton
                                              label='We want to offer a generous company contribution to the plan'
                                              value='generous_contribution'
                                            />
                                            <RadioButton
                                              label='We do not want to make a company contribution to the plan'
                                              value='no_contribution'
                                            />
                                            <RadioButton
                                              label='We are not sure yet'
                                              value='unsure'
                                            />
                                          </StyledFormRadioGroup>
                                          <FormRadioGroup
                                            label='Which industry group best describes your company?'
                                            name='industryGroup'>
                                            <RadioButton
                                              label='Law, Accounting, Engineering, Architecture, Technology, Medicine'
                                              value='law_accounting'
                                            />
                                            <RadioButton
                                              label='Insurance, Banking, Advertising, Marketing, Real Estate, Consulting'
                                              value='insurance_banking'
                                            />
                                            <RadioButton
                                              label='Chemicals, Utilities, Oil & Energy, Aerospace, Pharmaceuticals'
                                              value='chemicals_utilities'
                                            />
                                            <RadioButton
                                              label='Manufacturing, Machinery, Equipment, Construction, Environmental'
                                              value='manufacturing'
                                            />
                                            <RadioButton
                                              label='Education, Social Services, Non-Profits'
                                              value='education_nonprofits'
                                            />
                                            <RadioButton
                                              label='Hospitality, Restaurants, Healthcare, Automotive, Retail, Agriculture'
                                              value='hospitality_retail'
                                            />
                                            <RadioButton
                                              label='Other'
                                              value='other'
                                            />
                                          </FormRadioGroup>
                                        </>
                                      )}
                                  </>
                                )}
                              </Stack>
                            </Stack>
                          </FrontendThemeProvider>
                        </div>
                      </Box>
                    </StyledDialogContent>
                    <Divider />
                    <DialogActions>
                      <Stack
                        direction='row'
                        justifyContent='space-between'
                        sx={{ width: '100%' }}>
                        <Button onClick={async () => setIsDialogOpen(false)}>
                          Cancel
                        </Button>
                        <Stack direction='row' spacing={1}>
                          <FormShowErrorsButton variant='text' />
                          <Button
                            onClick={async () => {
                              autosave
                                .save()
                                .then(() => {
                                  snackbar.showSnackbar({
                                    message: 'Your data has been saved',
                                    severity: 'success'
                                  });
                                })
                                .catch(() => {
                                  snackbar.showSnackbar({
                                    message: 'Error saving data',
                                    severity: 'error'
                                  });
                                });
                            }}
                            type='button'
                            variant='contained'>
                            Save
                          </Button>
                          <Button
                            disabled={
                              !!questionnaire.data.questionnaireSubmittedAt ||
                              !!Object.keys(formik.errors).length
                            }
                            onClick={async () => {
                              await onSave({
                                isSubmit: true,
                                values: formik.values
                              });
                            }}
                            type='button'
                            variant='contained'>
                            Submit
                          </Button>
                        </Stack>
                      </Stack>
                    </DialogActions>
                  </Dialog>
                )}
              </FormGrabber>
            )}
          </FormAutosaveObserver>
        </Form>
      )}
      <IconButton
        aria-disabled={isDialogOpen}
        aria-label='open-sponsor-questionnaire-modal-button'
        color='primary'
        data-testid={`open-sponsor-questionnaire-modal-button-planId-${props.sponsorPlanId}`}
        disabled={isDialogOpen}
        onClick={() => setIsDialogOpen(true)}
        size='small'>
        <OpenInNew fontSize='inherit' />
      </IconButton>
    </>
  );
};
