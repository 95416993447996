import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { TransactionDto } from '@/models/ops/transactions/TransactionDTO.model';
import formatters from '@/utils/Formatters';
import { Divider, Stack } from '@mui/material';

import React from 'react';

export const transactionDetailCellRendererFields: Record<
  string,
  { label: string; valueFormatter: (value: any) => any; copyable?: boolean }
> = {
  confirmDate: {
    label: 'Confirm Date',
    valueFormatter: (value: string | null): string | null =>
      value ? formatters.formatFromIsoDateCustom(value, 'MM/DD/YYYY') : null
  },
  contributionYear: {
    label: 'Contribution Year',
    valueFormatter: (value: number): number => value
  },
  controlDate: {
    label: 'Control Date',
    valueFormatter: (value: string | null): string | null =>
      value ? formatters.formatFromIsoDateCustom(value, 'MM/DD/YYYY') : null
  },
  dividendEligibleDate: {
    label: 'Dividend Eligible Date',
    valueFormatter: (value: string | null): string | null =>
      value ? formatters.formatFromIsoDateCustom(value, 'MM/DD/YYYY') : null
  },
  orderId: {
    copyable: true,
    label: 'Order ID',
    valueFormatter: (value: string | null): string | null => value
  },
  reversedDate: {
    label: 'Reversed Date',
    valueFormatter: (value: string | null): string | null =>
      value ? formatters.formatFromIsoDateCustom(value, 'MM/DD/YYYY') : null
  },
  reversingTransactionId: {
    label: 'Reversing Transaction ID',
    valueFormatter: (value: string | null): string | null => value
  },
  settlementDate: {
    label: 'Settlement Date',
    valueFormatter: (value: string | null): string | null =>
      value ? formatters.formatFromIsoDateCustom(value, 'MM/DD/YYYY') : null
  },
  tracerId: {
    copyable: true,
    label: 'Tracer ID',
    valueFormatter: (value: string | null): string | null => value
  },
  tradeDate: {
    label: 'Trade Date',
    valueFormatter: (value: string | null): string | null =>
      value ? formatters.formatFromIsoDateCustom(value, 'MM/DD/YYYY') : null
  }
};

const sections: string[][] = [
  [
    'tradeDate',
    'settlementDate',
    'controlDate',
    'dividendEligibleDate',
    'confirmDate'
  ],
  ['contributionYear', 'orderId', 'tracerId']
];

const TransactionDetailCellRenderer = ({
  data: rowData
}: {
  data: TransactionDto;
}): JSX.Element => (
  <Stack
    direction='row'
    divider={<Divider flexItem orientation='vertical' />}
    spacing={2}
    sx={{ p: 2 }}>
    {sections.map((section: string[]) => (
      // eslint-disable-next-line react/jsx-key
      <TextStack direction='column'>
        <>
          {section.map((fieldId: string) => (
            <TextStackItem key={fieldId}>
              <TextLabel>
                {transactionDetailCellRendererFields[fieldId].label}
              </TextLabel>
              <TextValue
                copyable={
                  transactionDetailCellRendererFields[fieldId].copyable
                }>
                {transactionDetailCellRendererFields[fieldId].valueFormatter(
                  rowData[fieldId as keyof TransactionDto]
                )}
              </TextValue>
            </TextStackItem>
          ))}
        </>
      </TextStack>
    ))}
    {rowData.status === 'REVERSED' && (
      <TextStack direction='column'>
        <TextStackItem>
          <TextLabel>
            {transactionDetailCellRendererFields.reversingTransactionId.label}
          </TextLabel>
          <TextValue>
            {transactionDetailCellRendererFields.reversingTransactionId.valueFormatter(
              rowData.reversingTransactionId
            )}
          </TextValue>
        </TextStackItem>
        <TextStackItem>
          <TextLabel>
            {transactionDetailCellRendererFields.reversedDate.label}
          </TextLabel>
          <TextValue>
            {transactionDetailCellRendererFields.reversedDate.valueFormatter(
              rowData.reversedDate
            )}
          </TextValue>
        </TextStackItem>
      </TextStack>
    )}
  </Stack>
);
export default TransactionDetailCellRenderer;
