import CopyToClipboard from '@/components/copy-to-clipboard';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  Box,
  Divider,
  IconButton,
  Link,
  Stack,
  SxProps,
  Tooltip,
  Typography
} from '@mui/material';

import React, { Children, ReactElement } from 'react';
import { Link as RouterLink } from 'react-router-dom';

/**
 * If the first and only child of the children prop is a fragment
 * then get the children of that fragment and use them as the children instead
 * to fix Stack not displaying divider properly when the children are nested within a fragment
 */
const unwrapChildren = (children: React.ReactNode) => {
  let arrayChildren = Children.toArray(children);
  if (
    arrayChildren.length === 1 &&
    React.isValidElement(arrayChildren[0]) &&
    arrayChildren[0].type === React.Fragment
  ) {
    const fragment: React.ReactElement = arrayChildren[0];
    arrayChildren = Children.toArray(fragment.props.children);
  }
  return arrayChildren;
};

/**
 * Optional override of default TextLabel column width
 */
const TextLabelColumnWidthContext = React.createContext<string>('');

/**
 * Optional override of default TextValue left alignment when layout is column
 */
const TextValueAlignContext = React.createContext<string>('');

/**
 * Context allows the stack direction on the parent TextStack element
 * to dictate the layout and styling of the children accordingly.
 * An example is that a parent of "row" direction
 * has children TextStackItems with "column" and visa versa.
 */
const TextStackDirectionContext = React.createContext<'row' | 'column'>('row');
/**
 * This context allows for the spread to set the width of the child TextStackItems
 */
const TextStackSpreadContext = React.createContext<'fixed' | 'dynamic'>(
  'fixed'
);

interface TextLabelProps extends React.HTMLAttributes<HTMLBaseElement> {
  children?: React.ReactNode;
  fieldName?: string;
  /** TODO: To be used for popover display */
  // details?: React.ReactNode;
}
export type { TextLabelProps };

export const TextLabel: React.FunctionComponent<TextLabelProps> = (
  props: TextLabelProps
) => {
  const { fieldName, children } = props;

  return (
    <TextStackDirectionContext.Consumer>
      {direction => (
        <TextLabelColumnWidthContext.Consumer>
          {labelColumnWidth => (
            <Box
              data-testid={fieldName ? fieldName + '-text-label' : null}
              sx={{
                color: theme => theme.palette.grey[600],
                flexShrink: 0,
                fontSize: 14,
                lineHeight: 1.5,
                maxWidth: '100%',
                width: {
                  column: labelColumnWidth,
                  row: 'auto'
                }[direction]
              }}>
              {children}
            </Box>
          )}
        </TextLabelColumnWidthContext.Consumer>
      )}
    </TextStackDirectionContext.Consumer>
  );
};

/** Internal use within TextValue for displaying an optional link */
interface TextLinkProps extends React.HTMLAttributes<HTMLBaseElement> {
  children?: React.ReactNode;
  'data-testid'?: string;
  to: string;
  target?: string;
}

const TextLink: React.FunctionComponent<TextLinkProps> = (
  props: TextLinkProps
) => {
  const { children = 'View', to, target } = props;
  const isExternalURL = to.startsWith('http');
  const opensInNewTab = target?.toLowerCase() === '_blank';

  return (
    <>
      <Link
        color='primary'
        component={isExternalURL ? 'a' : RouterLink}
        data-testid={props['data-testid']}
        fontSize={12}
        href={to}
        lineHeight={2}
        target={target}
        to={to}>
        {children}
        {opensInNewTab && (
          <Box
            component='span'
            sx={{
              fontSize: 12,
              lineHeight: 2,
              pl: 0.5,
              verticalAlign: 'middle'
            }}>
            {/* Custom OpenInNew icon created by Derek in Figma to accomidate the < 20px font size of this use case */}
            <svg
              fill='currentColor'
              height='11'
              viewBox='0 0 10 11'
              width='10'
              xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M8 9.5H1V2.5H4V1.5H1C0.383333 1.5 0 1.88889 0 2.5V9.5C0 10.1111 0.383333 10.5 1 10.5H8C8.61111 10.5 9 10.1111 9 9.5V6.5H8V9.5ZM6 0.5V1.5H8.21667L3 6.71667L3.78333 7.5L9 2.28333V4.5H10V0.5H6Z'
                fill='#2196F3'
              />
            </svg>
          </Box>
        )}
        {!opensInNewTab && (
          <Box
            component='span'
            sx={{
              fontSize: 12,
              lineHeight: 2,
              pl: 0.25,
              verticalAlign: 'middle'
            }}>
            <KeyboardArrowRightIcon fontSize='inherit' />
          </Box>
        )}
      </Link>
    </>
  );
};

/** Internal use within TextValue for displaying an optional bit of detail text */
interface TextDetailProps extends React.HTMLAttributes<HTMLBaseElement> {
  children?: React.ReactNode;
}

const TextDetail: React.FunctionComponent<TextDetailProps> = (
  props: TextDetailProps
) => {
  const { children } = props;

  return (
    <Typography
      sx={{
        color: theme => theme.palette.grey[600],
        fontSize: 12,
        lineHeight: 2
      }}>
      {children}
    </Typography>
  );
};

interface TextValueProps extends React.HTMLAttributes<HTMLBaseElement> {
  /** Substitutes em dash and disabled color when undefined | null */
  children?: React.ReactNode;
  /** Enables copy to clipboard icon button */
  copyable?: boolean;
  /** Optional alternate to children for copy to clipboard to display next to the copy icon */
  copyLabel?: string;
  /** Optional alternate to children or label for copy to clipboard to copy */
  copyValue?: string;
  /** Optional test ID */
  'data-testid'?: string;
  /**
   * Any icon component, rendered before children.
   * Be sure to add fontSize="inherit" to the icon element for proper sizing and set desired color.
   * We can't add that for you because the icon component is memoized.
   */
  icon?: React.ReactElement;
  /**
   * Optional array of links to be displayed below the value separated by a pipe.
   */
  links?: {
    to: string;
    /** Optional label that defaults to "View" and uses React Router if url is not internet address */
    label?: string;
    /** Optional target in case you wish to open in a new tab with a value of "_blank" */
    target?: string;

    /** Optional test ID */
    'data-testid'?: string;
  }[];
  detail?: string;
  sx?: SxProps;
  fieldName?: string;
  /** Displays given text in a tooltip, opened via an appended Info icon button */
  tooltip?: string;
}
export type { TextValueProps };

export const TextValue: React.FunctionComponent<TextValueProps> = (
  props: TextValueProps
) => {
  const {
    children,
    copyable,
    copyLabel,
    copyValue,
    'data-testid': testId,
    icon,
    links = [],
    detail,
    sx = {},
    fieldName
  } = props;

  const hasValue =
    children !== undefined && children !== null && children !== false;

  return (
    <TextStackDirectionContext.Consumer>
      {direction => (
        <Stack
          data-testid={fieldName ? fieldName + '-text-value' : null}
          spacing={direction === 'row' ? 0.5 : 0.25}
          sx={sx}>
          <Stack
            alignItems='flex-start'
            direction='row'
            spacing={1}
            sx={{
              fontSize: { column: 14, row: 16 }[direction],
              lineHeight: 1.5
            }}>
            {icon && (
              <Stack // Stack component to prevent weird svg container height when using Box
                sx={{
                  fontSize: 20,
                  lineHeight: 1.5,
                  py: { column: 0, row: 0.25 }[direction]
                }}>
                {icon}
              </Stack>
            )}
            <Box data-testid={testId}>
              {/* If empty, show em dash with color disabled if no children */}
              {!hasValue && (
                <Typography
                  component='span'
                  sx={{
                    color: theme => theme.palette.grey[400]
                  }}>
                  {'\u2014'}
                </Typography>
              )}
              {/* else if passed a copyLabel then we just show children here because copy UI is shown below with its custom label */}
              {hasValue && (!copyable || (copyable && copyLabel)) && (
                <Typography
                  component='span'
                  sx={{
                    color: theme => theme.palette.grey[900],
                    fontSize: { column: 14, row: 16 }[direction],
                    lineHeight: 1.5
                  }}>
                  {children}
                </Typography>
              )}
              {/* else since no copyLabel was provided we show the copy UI right next to the children */}
              {hasValue && copyable && !copyLabel && (
                <Box
                  sx={{
                    // ensures the value lines up with the label considering the copy button is larger than line height
                    mt: { column: '-3px', row: '0' }[direction]
                  }}>
                  <CopyToClipboard copyValue={copyValue}>
                    <Typography
                      component='span'
                      sx={{
                        color: theme => theme.palette.grey[900],
                        fontSize: { column: 14, row: 16 }[direction],
                        lineHeight: 1.5
                      }}>
                      {children}
                    </Typography>
                  </CopyToClipboard>
                </Box>
              )}
              {hasValue && props.tooltip && (
                <Box
                  display='inline-block'
                  sx={{
                    // ensures the button lines up with the label considering it is larger than line height
                    mt: '-4px'
                  }}>
                  <Tooltip placement='top' title={props.tooltip}>
                    <IconButton
                      aria-label='Warning'
                      color='warning'
                      edge='start'
                      size='small'>
                      <InfoOutlinedIcon fontSize='small' />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            </Box>
          </Stack>
          {/*
           * Note we don't show this if there is no manually provided copyValue
           * and we can't fallback to children because it is undefined or null
           */}
          {(hasValue || copyValue) && copyable && copyLabel && (
            <CopyToClipboard copyValue={copyValue} size='small'>
              <Typography
                component='span'
                sx={{
                  fontSize: 12,
                  lineHeight: 1.5
                }}>
                {copyLabel}
              </Typography>
            </CopyToClipboard>
          )}
          {links.length > 0 && (
            <Stack
              direction='row'
              divider={
                <Typography
                  component='span'
                  sx={{
                    color: theme => theme.palette.grey[300],
                    fontSize: 12,
                    lineHeight: 2
                  }}>
                  |
                </Typography>
              }
              spacing={0.5}>
              {links.map(
                ({ to, 'data-testid': linkTestId, label = 'View', target }) => (
                  <TextLink
                    data-testid={linkTestId}
                    key={label}
                    target={target}
                    to={to}>
                    {label}
                  </TextLink>
                )
              )}
            </Stack>
          )}
          {detail && <TextDetail>{detail}</TextDetail>}
        </Stack>
      )}
    </TextStackDirectionContext.Consumer>
  );
};

interface TextStackItemProps extends React.HTMLAttributes<HTMLBaseElement> {
  children?: React.ReactNode;
  maxWidth?: string;
  style?: React.CSSProperties;
  fieldName?: string;
  /** Optional fieldName to set the data test ids from all the TextStack components inside TextStackItem*/
}
export type { TextStackItemProps };

/** Subcomponent of the TextStack which is expected to contain corresponding TextLabel and TextValue elements */
export const TextStackItem: React.FunctionComponent<TextStackItemProps> = (
  props: TextStackItemProps
) => {
  const { fieldName, children, maxWidth, style } = props;

  return (
    <TextStackDirectionContext.Consumer>
      {direction => (
        <TextStackSpreadContext.Consumer>
          {spread => (
            <TextValueAlignContext.Consumer>
              {textValueAlign => (
                <Stack
                  alignItems='flex-start'
                  component='li'
                  sx={{
                    listStyle: 'none',
                    maxWidth:
                      maxWidth || { column: '100%', row: '248px' }[direction],
                    width: {
                      dynamic: 'auto',
                      fixed: direction === 'row' ? '148px' : 'auto'
                    }[spread]
                  }} // remove bullet points from the li elements
                  data-testid={fieldName ? fieldName + '-text-item' : null}
                  direction={direction === 'row' ? 'column' : 'row'}
                  justifyContent={
                    direction === 'column' && textValueAlign === 'right'
                      ? 'space-between'
                      : undefined
                  }
                  spacing={direction === 'row' ? 0.5 : 2}
                  style={style}>
                  {React.Children.map(children, child => {
                    const newChild = React.cloneElement(
                      (child as ReactElement<any, string>) || <></>,
                      {
                        fieldName
                      }
                    );

                    return typeof newChild.type === 'function'
                      ? newChild
                      : child;
                  })}
                </Stack>
              )}
            </TextValueAlignContext.Consumer>
          )}
        </TextStackSpreadContext.Consumer>
      )}
    </TextStackDirectionContext.Consumer>
  );
};

interface TextStackProps extends React.HTMLAttributes<HTMLBaseElement> {
  children?:
    | React.ReactElement
    | (React.ReactElement | false | null | undefined)[]
    | null;
  /**
   * Determines the layout direction to display the child TextStackItems in.
   */
  direction?: 'row' | 'column';
  divider?: boolean;
  /**
   * Only applies to row direction.
   * "fixed" will result in a fixed width for each item.
   * "dynamic" will result in dynamic width for each item
   * depending on content and the width of the TextStack parent.
   */
  rowColumnWidth?: 'fixed' | 'dynamic';
  /**
   * Normally spacing is determined by the column alignment and width
   * but you can manually override it with this property
   */
  spacing?: string | number;
  sx?: SxProps;
  /** Override default column width of TextLabel */
  textLabelColumnWidth?: string;
  textValueAlign?: 'right' | 'left';
}

/** Displays a grouping of TextStackItems in either a row or a column depending on the direction specified */
const TextStack: React.FunctionComponent<TextStackProps> = (
  props: TextStackProps
) => {
  const {
    children,
    direction = 'row',
    divider = false,
    rowColumnWidth = 'fixed',
    id,
    spacing,
    sx,
    textLabelColumnWidth = '148px',
    textValueAlign = 'left'
  } = props;

  const arrayChildren = unwrapChildren(children).filter(
    child => child // filter out an empty children possibly from conditional elements
  );

  return (
    <TextStackDirectionContext.Provider value={direction}>
      <TextStackSpreadContext.Provider value={rowColumnWidth}>
        <TextLabelColumnWidthContext.Provider value={textLabelColumnWidth}>
          <TextValueAlignContext.Provider value={textValueAlign}>
            <Stack
              alignItems='stretch'
              component='ul'
              data-testid={id}
              direction={direction}
              divider={
                divider && (
                  <Divider
                    flexItem
                    orientation={
                      direction === 'row' ? 'vertical' : 'horizontal'
                    }
                  />
                )
              }
              id={id}
              justifyContent='flex-start'
              spacing={
                typeof spacing !== 'undefined'
                  ? spacing
                  : rowColumnWidth === 'fixed' || direction === 'column'
                    ? 1
                    : divider
                      ? 4
                      : 8
              }
              sx={{ m: 0, p: 0, ...(sx || {}) }} // override the fact that ul elements have padding and margin by default
            >
              {arrayChildren}
            </Stack>
          </TextValueAlignContext.Provider>
        </TextLabelColumnWidthContext.Provider>
      </TextStackSpreadContext.Provider>
    </TextStackDirectionContext.Provider>
  );
};

export default TextStack;
export type { TextStackProps };
