import { useSnackbar } from '@/contexts/SnackBarContext';
import { DocumentDto } from '@/models/DocumentDTO.model';
import { AlertDto } from '@/models/ops/alerts/AlertDTO.model';
import TransactionService from '@/services/ops/transactions/Transactions.service';
import {
  DeleteOutlined as DeleteOutlinedIcon,
  FileUploadOutlined as FileUploadOutlinedIcon,
  VisibilityOffOutlined as VisibilityOffOutlinedIcon,
  VisibilityOutlined as VisibilityOutlinedIcon
} from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Collapse,
  Divider,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { isArrayBuffer } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useBeforeUnload, useMeasure } from 'react-use';

type UploadCheckImageProps = {
  alert?: AlertDto;
};
export const UploadCheckImage = (props: UploadCheckImageProps): JSX.Element => {
  const { alert } = props;

  const [pdfViewerRef, { width }] = useMeasure<HTMLDivElement>();
  const [missingTransactionIdsError, setMissingTransactionIdsError] =
    useState<boolean>(false);
  const formRef = useRef<HTMLFormElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const { showSnackbar } = useSnackbar();
  const [selectedFileName, setSelectedFileName] = useState<string | null>(null);
  const [selectedFileObjectUrl, setSelectedFileObjectUrl] = useState<
    string | null
  >(null);
  const [showCheckImage, setShowCheckImage] = useState<boolean>(true);
  const theme = useTheme();
  const queryClient = useQueryClient();

  const isSessionTimerDialogOpenQuery = useQuery(['isSessionTimerDialogOpen'], {
    initialData: false
  });

  // TODO: should specify parent vs. sub-account transaction (currently assumes parent is first); see SUB-3272
  const originalParentAccountTransactionId =
    alert?.details?.event?.transactionIds[0];
  const updatedParentAccountTransactionId =
    alert?.details?.updatedParentAccountTransactionId;

  const checkImageTransactionId =
    updatedParentAccountTransactionId || originalParentAccountTransactionId;

  useEffect(() => {
    if (alert === undefined) return;
    if (!checkImageTransactionId) {
      setMissingTransactionIdsError(true);
    }
  }, [alert, checkImageTransactionId, setMissingTransactionIdsError]);

  const { data: checkImageDocument, isFetching } = useQuery<DocumentDto>(
    ['TransactionService.getCheckImageDocument', checkImageTransactionId],
    () => TransactionService.getCheckImageDocument(checkImageTransactionId),
    {
      enabled: checkImageTransactionId !== undefined,
      onError: (err: any) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Failed to get check image: ${message}`,
          severity: 'error'
        });
      }
    }
  );

  const { data: checkImage, isFetching: isFetchingImage } = useQuery(
    ['TransactionService.getCheckImage', checkImageTransactionId],
    () => TransactionService.getCheckImage(checkImageTransactionId),
    {
      enabled: Boolean(checkImageDocument) && !selectedFileObjectUrl
    }
  );

  const savedCheckImageObjectUrl = useMemo(() => {
    if (isArrayBuffer(checkImage)) {
      return window.URL.createObjectURL(
        new Blob([checkImage], { type: 'application/pdf' })
      );
    } else {
      return null;
    }
  }, [checkImage]);

  const deleteCheckImageMutation = useMutation(
    ['TransactionService.postCheckImage'],
    () => TransactionService.deleteCheckImage(checkImageTransactionId),
    {
      onError: (err: any) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Failed to delete check image: ${message}`,
          severity: 'error'
        });
      },
      onSuccess: () => {
        queryClient.removeQueries(
          ['TransactionService.getCheckImage', checkImageTransactionId],
          { exact: true }
        );
        queryClient.removeQueries(
          ['TransactionService.getCheckImageDocument', checkImageTransactionId],
          { exact: true }
        );
        showSnackbar({
          message: `Check Image deleted`,
          severity: 'success'
        });
      }
    }
  );

  const postCheckImageMutation = useMutation(
    ['TransactionService.postCheckImage'],
    (file: File) =>
      TransactionService.postCheckImage(checkImageTransactionId, file),
    {
      onError: (err: any) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Failed to upload check image: ${message}`,
          severity: 'error'
        });
      },
      onSuccess: async () => {
        await queryClient.refetchQueries([
          'TransactionService.getCheckImageDocument',
          checkImageTransactionId
        ]);
        showSnackbar({
          message: `Check Image uploaded`,
          severity: 'success'
        });
      }
    }
  );

  useBeforeUnload(
    postCheckImageMutation.status === 'loading' &&
      !isSessionTimerDialogOpenQuery.data
  );

  const hasPreview = savedCheckImageObjectUrl || selectedFileObjectUrl;

  const handleChange = () => {
    const fileList = inputRef.current?.files;

    if (fileList === null || fileList === undefined || fileList.length === 0) {
      return;
    }

    const file = fileList[0];

    setSelectedFileName(file.name);
    setSelectedFileObjectUrl(window.URL.createObjectURL(file));

    postCheckImageMutation.mutate(file);
  };

  const handleDelete = () => {
    formRef.current?.reset();
    setSelectedFileName(null);
    setSelectedFileObjectUrl(null);
    if (checkImageDocument) deleteCheckImageMutation.mutate();
  };

  return (
    <Card elevation={1}>
      <CardHeader
        sx={{ py: 1.5 }}
        title='Check Image'
        titleTypographyProps={{ variant: 'h6' }}
      />
      <Divider sx={{ borderColor: theme.palette.grey[300] }} />
      {(isFetching || isFetchingImage) && <LinearProgress />}
      {missingTransactionIdsError && (
        <Alert data-testid='missing-transaction-ids-alert' severity='error'>
          <AlertTitle>Cannot accept upload</AlertTitle>
          This alert is missing a required transaction ID
        </Alert>
      )}
      {hasPreview && (
        <>
          <Box
            alignItems='center'
            display='flex'
            justifyContent='space-between'
            px={2}
            py={0.5}>
            <Box>
              <Typography data-testid='document-filename' variant='body2'>
                {selectedFileName || checkImageDocument?.originalFileName}
              </Typography>
            </Box>
            <Box display='flex' gap={1}>
              {deleteCheckImageMutation.status === 'loading' ? (
                <Box
                  alignItems='center'
                  display='flex'
                  height={theme.typography.pxToRem(34)}
                  justifyContent='center'
                  width={theme.typography.pxToRem(34)}>
                  <CircularProgress size={theme.typography.pxToRem(18)} />
                </Box>
              ) : (
                <Tooltip title='Delete Image'>
                  <IconButton
                    data-testid='delete-image-button'
                    onClick={handleDelete}
                    size='small'>
                    <DeleteOutlinedIcon />
                  </IconButton>
                </Tooltip>
              )}
              {showCheckImage ? (
                <Tooltip title='Hide Image'>
                  <IconButton
                    data-testid='hide-image-button'
                    onClick={() => setShowCheckImage(false)}
                    size='small'>
                    <VisibilityOffOutlinedIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title='Show Image'>
                  <IconButton
                    data-testid='show-image-button'
                    onClick={() => setShowCheckImage(true)}
                    size='small'>
                    <VisibilityOutlinedIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </Box>
          <Collapse in={showCheckImage}>
            <Divider sx={{ borderColor: theme.palette.grey[300] }} />
            <Box lineHeight='0' ref={pdfViewerRef}>
              {/* eslint-disable-next-line jsx-a11y/alt-text */}
              <object
                data={
                  selectedFileObjectUrl || savedCheckImageObjectUrl || undefined
                }
                data-testid='pdf-viewer'
                height={80 * 8} // 640
                type='application/pdf'
                width={width}
              />
            </Box>
          </Collapse>
        </>
      )}
      {!isFetching &&
        !isFetchingImage &&
        !missingTransactionIdsError &&
        !hasPreview && (
          <CardContent>
            <form ref={formRef}>
              <Button
                component='label'
                data-testid='upload-button'
                size='small'
                startIcon={<FileUploadOutlinedIcon />}
                variant='contained'>
                Upload Image
                <input
                  accept='application/pdf'
                  data-testid='file-input'
                  hidden
                  onChange={handleChange}
                  ref={inputRef}
                  type='file'
                />
              </Button>
            </form>
          </CardContent>
        )}
    </Card>
  );
};

export default UploadCheckImage;
