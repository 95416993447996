import Card, { CardContent } from '@/components/card';
import LinearLoading from '@/components/linear-loading';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { PlanService } from '@/services/Plan.service';
import formatters from '@/utils/Formatters';
import { useQuery } from '@tanstack/react-query';

type PlanStatsProps = {
  sponsorPlanId: number;
};

export const PlanStats: React.FC<PlanStatsProps> = props => {
  const planStatsQuery = useQuery({
    queryFn: () => PlanService.getStats(props.sponsorPlanId),
    queryKey: ['PlanService.getStats', props.sponsorPlanId]
  });

  return (
    <Card data-testid='plan-stats-card'>
      <LinearLoading fadeIn={planStatsQuery.isLoading} />
      <CardContent>
        <TextStack
          divider
          rowColumnWidth='dynamic'
          sx={{ justifyContent: 'space-between', pl: 3, pr: 3 }}>
          <TextStackItem>
            <TextLabel>Current Plan Market Value</TextLabel>
            <TextValue data-testid='current-plan-market-value-text-value'>
              {planStatsQuery.data?.data?.attributes.totalPlanMarketValue
                ? formatters.formatDollars(
                    planStatsQuery.data.data.attributes.totalPlanMarketValue
                  )
                : null}
            </TextValue>
          </TextStackItem>
          <TextStackItem>
            <TextLabel>Next Payroll Date</TextLabel>
            <TextValue
              data-testid='nest-payroll-date-text-value'
              links={[
                {
                  to: `/plans/${props.sponsorPlanId}/contributions`
                }
              ]}>
              {planStatsQuery.data?.data?.attributes.nextPayrollDate
                ? formatters.formatFromIsoDateCustom(
                    planStatsQuery.data.data.attributes.nextPayrollDate,
                    'MM/DD/YYYY'
                  )
                : null}
            </TextValue>
          </TextStackItem>
          <TextStackItem>
            <TextLabel>Most Recent Submission</TextLabel>
            <TextValue data-testid='most-recent-submission-text-value'>
              {planStatsQuery.data?.data?.attributes
                .mostRecentEmployerContribution
                ? formatters.formatDollars(
                    planStatsQuery.data.data.attributes
                      .mostRecentEmployerContribution
                  )
                : null}
            </TextValue>
          </TextStackItem>
          <TextStackItem>
            <TextLabel>Eligible Participants</TextLabel>
            <TextValue
              data-testid='eligible-participants-date-text-value'
              links={[
                {
                  to: `/plans/${props.sponsorPlanId}/employees`
                }
              ]}>
              {planStatsQuery.data?.data?.attributes.eligibleParticipants}
            </TextValue>
          </TextStackItem>
          <TextStackItem>
            <TextLabel>Participants With Balance</TextLabel>
            <TextValue data-testid='participants-with-balance-text-value'>
              {planStatsQuery.data?.data?.attributes.participantsWithBalance}
            </TextValue>
          </TextStackItem>
          <TextStackItem>
            <TextLabel>Participation Rate</TextLabel>
            <TextValue data-testid='participation-rate-text-value'>
              {formatters.formatPercent(
                (planStatsQuery.data?.data?.attributes.participationRate || 0) *
                  100,
                { round: true }
              )}
            </TextValue>
          </TextStackItem>
        </TextStack>
      </CardContent>
    </Card>
  );
};
