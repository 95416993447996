import formatters from '@/utils/Formatters';

export type StatusBadgeProps = {
  label: string;
  color: LabelColor;
  values: string[];
};

export const statusLabels: StatusBadgeProps[] = [
  { color: 'success', label: 'Delivered', values: ['Delivery'] },
  { color: 'success', label: 'Open', values: ['Open', 'Click'] },
  { color: 'warning', label: 'Bounce', values: ['Bounce'] },
  { color: 'warning', label: 'Reported as Spam', values: ['Complaint'] },
  {
    color: 'lightWarning',
    label: 'Not Delivered',
    values: ['Rendering Failure', 'Send']
  }
];

type LabelColor =
  | 'info'
  | 'neutral'
  | 'success'
  | 'warning'
  | 'lightWarning'
  | undefined;

export const getLabelAndColor = (
  status: string
): { label: string; color: LabelColor } => {
  switch (status?.toLowerCase()) {
    case 'delivery':
      return { color: 'success', label: 'Delivered' };
    case 'click':
    case 'open':
      return { color: 'success', label: 'Open' };
    case 'bounce':
      return { color: 'warning', label: 'Bounce' };
    case 'complaint':
      return { color: 'warning', label: 'Reported as Spam' };
    case 'rendering failure':
    default:
      return { color: 'lightWarning', label: 'Not Delivered' };
  }
};

export const getInitiatorLabel = (idType: string, id: string) => {
  switch (idType) {
    case 'participant':
      return 'Saver';
    case 'sponsor':
      return 'Employer';
    case 'LDAP':
      return `Admin - ${id}`;
    default:
      return formatters.capitalizeFirstChar(idType || '--');
  }
};
