import { useSnackbar } from '@/contexts/SnackBarContext';
import { useTransactionCodes } from '@/hooks/ops/useTransactionCodes.hook';
import { AlertUpdateTransactionTypeRequest } from '@/models/ops/alerts/AlertUpdateTransactionTypeRequest.model';
import { TransactionDto } from '@/models/ops/transactions/TransactionDTO.model';
import AlertService from '@/services/ops/alerts/Alert.service';
import TransactionService from '@/services/ops/transactions/Transactions.service';
import { ArrowDropDownOutlined } from '@mui/icons-material';
import {
  Alert,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Menu,
  MenuItem,
  Stack
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { TransactionBaseType } from '@vestwell-sub-accounting/models/common/TransactionBaseType';
import { TransactionTypeCode } from '@vestwell-sub-accounting/models/common/TransactionTypeCode';

import {
  Dispatch,
  forwardRef,
  SetStateAction,
  useContext,
  useState
} from 'react';

import { AlertContext } from '../../AlertContext';
import { CardHeader } from './CardHeader.component';

const TransactionTypeOptions = ({
  setIsUpdating,
  transaction
}: {
  setIsUpdating: Dispatch<SetStateAction<boolean>>;
  transaction: TransactionDto;
}): JSX.Element => {
  const alert = useContext(AlertContext);
  const { showSnackbar } = useSnackbar();
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(menuAnchorEl);
  const queryClient = useQueryClient();

  const updateTransactionTypeMutation = useMutation(
    ['AlertService.updateTransactionType'],
    ({
      alertId,
      updateRequest
    }: {
      alertId: number;
      updateRequest: AlertUpdateTransactionTypeRequest;
    }) => {
      setIsUpdating(true);
      return AlertService.updateTransactionType(alertId, updateRequest);
    },
    {
      onError: (err: any) => {
        const message = err.response?.data ? err.response.data : err.message;

        setIsUpdating(false);
        showSnackbar({
          message: `Failed to update alert: ${message}`,
          severity: 'error'
        });
      },
      onSuccess: updatedAlert => {
        queryClient.invalidateQueries([
          'AlertService.getById',
          updatedAlert.id
        ]);
        queryClient.invalidateQueries(['AlertService.search']);
        queryClient.invalidateQueries(['TransactionService.search']);
        // skip transaction invalidation; new record was created
        setIsUpdating(false);
        showSnackbar({
          message: `Updated alert and associated transactions`,
          severity: 'success'
        });
      }
    }
  );

  const { data: depositTransactionCodes } = useTransactionCodes({
    transactionBaseType: TransactionBaseType.Deposit
  });

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleTransactionTypeCodeChange = (
    transactionTypeCode: TransactionTypeCode
  ) => {
    if (!alert?.id) return;
    updateTransactionTypeMutation.mutate({
      alertId: alert.id,
      updateRequest: { transactionTypeCode }
    });
    setMenuAnchorEl(null);
  };

  return (
    <Stack direction='row' flexWrap='wrap' spacing={2}>
      {transaction?.transactionTypeCode !==
        TransactionTypeCode.CashConversionDeposit && (
        <Button
          data-testid='change-to-conversion-button'
          onClick={() =>
            handleTransactionTypeCodeChange(
              TransactionTypeCode.CashConversionDeposit
            )
          }
          size='small'>
          Change to Conversion
        </Button>
      )}
      {transaction?.transactionTypeCode !==
        TransactionTypeCode.RolloverDeposit && (
        <Button
          data-testid='change-to-rollover-button'
          onClick={() =>
            handleTransactionTypeCodeChange(TransactionTypeCode.RolloverDeposit)
          }
          size='small'>
          Change to Rollover
        </Button>
      )}
      {transaction &&
        [
          TransactionTypeCode.CashConversionDeposit,
          TransactionTypeCode.RolloverDeposit
        ].includes(transaction.transactionTypeCode) && (
          <Button
            data-testid='change-to-unclassified-deposit-button'
            onClick={() =>
              handleTransactionTypeCodeChange(
                TransactionTypeCode.UnclassifiedDeposit
              )
            }>
            Change to Unclassified Deposit
          </Button>
        )}
      <Button
        aria-controls={isMenuOpen ? 'other-menu' : undefined}
        aria-expanded={isMenuOpen ? 'true' : undefined}
        aria-haspopup='true'
        data-testid='other-menu-button'
        endIcon={<ArrowDropDownOutlined />}
        id='other-menu-button'
        onClick={handleMenuOpen}
        size='small'>
        Other
      </Button>
      <Menu
        MenuListProps={{
          'aria-labelledby': 'other-menu-button'
        }}
        anchorEl={menuAnchorEl}
        data-testid='other-menu'
        id='other-menu'
        onClose={handleMenuClose}
        open={Boolean(menuAnchorEl)}>
        {depositTransactionCodes
          ?.map(tcode => tcode.transactionTypeCode)
          .filter(
            transactionTypeCode =>
              ![
                TransactionTypeCode.CashConversionDeposit,
                TransactionTypeCode.RolloverDeposit,
                TransactionTypeCode.UnclassifiedDeposit
              ].includes(transactionTypeCode)
          )
          .map(transactionTypeCode => (
            <MenuItem
              data-testid={`${transactionTypeCode}-menu-item"`}
              key={transactionTypeCode}
              onClick={() =>
                handleTransactionTypeCodeChange(transactionTypeCode)
              }>
              {transactionTypeCode}
            </MenuItem>
          ))}
        {/** Provide UnclassifiedDeposit option here when an "Other" selection is made to avoid stacking buttons */}
        {transaction &&
          ![
            TransactionTypeCode.CashConversionDeposit,
            TransactionTypeCode.RolloverDeposit,
            TransactionTypeCode.UnclassifiedDeposit
          ].includes(transaction.transactionTypeCode) && (
            <MenuItem
              data-testid={`${TransactionTypeCode.UnclassifiedDeposit}-menu-item"`}
              key={TransactionTypeCode.UnclassifiedDeposit}
              onClick={() =>
                handleTransactionTypeCodeChange(
                  TransactionTypeCode.UnclassifiedDeposit
                )
              }>
              {TransactionTypeCode.UnclassifiedDeposit}
            </MenuItem>
          )}
      </Menu>
    </Stack>
  );
};

export const ChooseTransactionType = forwardRef<HTMLDivElement>(
  (props, ref) => {
    const alert = useContext(AlertContext);
    const [isUpdating, setIsUpdating] = useState(false);

    const cashTransferWorkflowTracerId =
      alert?.details.cashTransferWorkflowTracerId;

    // a new transaction is created whenever updated transactions are reversed
    const parentAccountTransactionId =
      alert?.details?.updatedParentAccountTransactionId ||
      alert?.details?.event?.transactionIds[0];

    const { data: transaction, isFetching: isFetchingTransaction } = useQuery(
      ['TransactionService.get', parentAccountTransactionId],
      () =>
        TransactionService.get({
          sourceTransactionId: parentAccountTransactionId
        })
    );

    const isFetching =
      alert === undefined ||
      isFetchingTransaction ||
      !transaction ||
      isUpdating;

    return (
      <Card elevation={1} ref={ref}>
        <CardHeader title='Choose Transaction Type' />
        <CardContent sx={{ pb: 2, pt: 0, px: 1.5 }}>
          {isFetching ? (
            <CircularProgress size={24} />
          ) : cashTransferWorkflowTracerId ? (
            <Alert severity='warning'>
              Transaction type cannot be changed following a fund transfer.
            </Alert>
          ) : (
            <TransactionTypeOptions
              setIsUpdating={setIsUpdating}
              transaction={transaction}
            />
          )}
        </CardContent>
      </Card>
    );
  }
);

export default ChooseTransactionType;
