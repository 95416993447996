import { Stack } from '@mui/material';

import { Field, Form, FormikProps, useFormikContext } from 'formik';
import { TextField } from 'formik-mui';
import { isFunction } from 'lodash';
import React, { FC } from 'react';

import { FieldOptions, FieldOptionsConfig } from '../../contexts/DialogContext';

const renderField = (
  fieldOptions: FieldOptionsConfig,
  formProps: FormikProps<Record<string, any>>,
  name: string,
  key: string
) => {
  return (
    <React.Fragment key={key}>
      {!fieldOptions.isFieldAlwaysHidden &&
        ('component' in fieldOptions ? (
          isFunction(fieldOptions.component) ? (
            fieldOptions.component(formProps.values, formProps.errors)
          ) : (
            fieldOptions.component
          )
        ) : (
          <Field
            component={TextField}
            fullWidth
            label={fieldOptions.label}
            sx={fieldOptions.hidden ? { display: 'none' } : {}}
            value={formProps.values[name] || ''}
            {...fieldOptions.fieldProps}
            disabled={!!fieldOptions.isFieldDisabled}
            name={name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (fieldOptions.validate) {
                if (fieldOptions.validate(fieldOptions.maxLength, e)) {
                  formProps.handleChange(e);
                }
              } else {
                formProps.handleChange(e);
              }
            }}
            required={!!fieldOptions.isFieldRequired}
          />
        ))}
    </React.Fragment>
  );
};

type FormFieldsProps = {
  fields: FieldOptions<string>;
  stepNumber?: number;
  'data-testid'?: string;
};

export const FromFields: FC<FormFieldsProps> = props => {
  const formikCtx = useFormikContext<Record<string, any>>();

  return (
    <Form data-testid={props['data-testid']}>
      <Stack mb={2} spacing={2}>
        {Object.entries(props.fields ?? {}).map(([name, fieldOptions]) => {
          const key = `${props.stepNumber || 0}-${name}`;

          const showWhen = fieldOptions.showWhen || (() => true);

          return !showWhen(formikCtx.values) ? (
            <React.Fragment key={key} />
          ) : (
            renderField(fieldOptions, formikCtx, name, key)
          );
        })}
      </Stack>
    </Form>
  );
};
