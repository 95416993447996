import DOMInteraction from '@/utils/DOMInteraction';
import CheckIcon from '@mui/icons-material/Check';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Avatar,
  Box,
  Button,
  ButtonProps,
  Divider,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Stack,
  SxProps,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import MUICard from '@mui/material/Card';

import { kebabCase } from 'lodash';
import _uniqueId from 'lodash/uniqueId';
import React, { ReactElement } from 'react';

import CircularLoading from '../circular-loading';

/**
 * This context allows for the size to be passed down to the Card subcomponents.
 */
const CardSizeContext = React.createContext<'small' | 'medium'>('medium');

/** Internal use within CardHeader for displaying an optional toggle next to the title */
interface CardToggleProps extends React.HTMLAttributes<HTMLBaseElement> {
  'data-testid'?: string;
  options: { value: any; label: string }[];
  value: any;
  onChangeAction: (event: React.MouseEvent<HTMLElement>, value: any) => void;
}

const CardToggle: React.FunctionComponent<CardToggleProps> = (
  props: CardToggleProps
) => {
  const { 'data-testid': testId, options, value, onChangeAction } = props;

  const [internalValue, setInternalValue] = React.useState(value);

  const internalOnChangeAction = (
    event: React.MouseEvent<HTMLElement>,
    newInternalValue: string
  ) => {
    if (newInternalValue !== null) {
      // non-null check to prevent entering into a state where no value is selected
      setInternalValue(newInternalValue);
      onChangeAction(event, newInternalValue);
    }
  };

  return (
    <ToggleButtonGroup
      color='primary'
      data-testid={testId}
      exclusive
      onChange={internalOnChangeAction}
      size='small'
      value={internalValue}>
      {options.map(option => (
        <ToggleButton
          key={option.value}
          sx={{
            // to account for the 1px border
            px: 1.5,
            py: '5px'
          }}
          value={option.value}>
          {internalValue === option.value && (
            <Stack
              sx={{
                fontSize: 20,
                lineHeight: '24px'
              }}>
              <CheckIcon fontSize='inherit' />
            </Stack>
          )}
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: 500,
              lineHeight: '24px'
            }}>
            {option.label}
          </Typography>
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

/** Internal use within CardHeader for displaying an optional main action button on the right side of the header */
export interface CardMainActionButtonProps extends ButtonProps {
  tooltipTitle?: string;
  'data-testid'?: string;
  label: string | React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
  visible?: boolean;
  variant?: 'text' | 'outlined' | 'contained';
}

const CardMainActionButton: React.FunctionComponent<
  CardMainActionButtonProps
> = (props: CardMainActionButtonProps) => {
  const {
    tooltipTitle,
    'data-testid': testId,
    label,
    onClick,
    disabled,
    visible = true,
    variant = 'outlined',
    ...restProps
  } = props;

  const button = (
    <Button
      data-testid={testId}
      disabled={disabled}
      onClick={onClick}
      sx={{
        fontSize: 14,

        fontWeight: 500,

        lineHeight: '24px',
        // to account for the 1px border
        px: 1.5,
        py: '5px'
      }}
      variant={variant}
      {...restProps}>
      {label}
    </Button>
  );

  return visible ? (
    tooltipTitle ? (
      <Tooltip title={tooltipTitle}>{button}</Tooltip>
    ) : (
      button
    )
  ) : (
    <></>
  );
};

/** Internal use within CardHeader for displaying an optional menu popover action button on the right side of the header */
interface CardMenuActionButtonProps
  extends React.HTMLAttributes<HTMLBaseElement> {
  'data-testid'?: string;
  options: { value: any; label: string }[];
  onClick: (value: any) => void;
  onOpen?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onClose?: () => void;
}

const CardMenuActionButton: React.FunctionComponent<
  CardMenuActionButtonProps
> = (props: CardMenuActionButtonProps) => {
  const { 'data-testid': testId, options, onClick, onOpen, onClose } = props;

  const [id] = React.useState<string>(_uniqueId('card-menu-action-button-'));
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const internalOnOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    if (typeof onOpen === 'function') {
      onOpen(event);
    }
  };
  const internalOnClose = () => {
    setAnchorEl(null);
    if (typeof onClose === 'function') {
      onClose();
    }
  };
  const internalOnClick = (value: any) => {
    if (typeof onClick === 'function') {
      onClick(value);
    }
  };

  return (
    <>
      <IconButton
        aria-controls={open ? 'basic-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        aria-label='settings'
        data-testid={testId}
        id={id}
        onClick={internalOnOpen}
        sx={{ p: 0.75 }}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        MenuListProps={{
          'aria-labelledby': id
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom'
        }}
        id='basic-menu'
        onClose={internalOnClose}
        open={open}
        sx={{
          '& .MuiPaper-root': {
            minWidth: 180
          }
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top'
        }}>
        {options.map(option => (
          <MenuItem
            key={option.value}
            onClick={() => {
              internalOnClick(option.value);
              internalOnClose();
            }}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

interface CardHeaderProps extends React.HTMLAttributes<HTMLBaseElement> {
  'data-testid'?: string;
  title: string;
  titleDecoration?: ReactElement;
  titleStyle?: React.CSSProperties;
  subtitle?: string;
  /**
   * Any icon component, prepended to the title.
   * Be sure to add fontSize="inherit" to the icon element for proper sizing and set desired color.
   * We can't add that for you because the icon component is memoized.
   */
  icon?: React.ReactElement;
  /**
   * While loading, overlay a linear progress bar at the bottom of the header.
   */
  loading?: boolean;
  /**
   * A CardToggle component to display to the right of the title.
   */
  toggle?: CardToggleProps;
  /**
   * Optionally displays CardMainActionButton components on the right side of the header.
   * If a menuAction is provided, these buttons will be added to the left of that menu.
   */
  actionButtonsProps?: CardMainActionButtonProps[];
  /**
   * Optionally displays a CardMenuActionButton component on the right side of the header.
   */
  menuProps?: CardMenuActionButtonProps;
  /** TODO: To be used for popover display */
  // details?: React.ReactNode;

  className?: string;
  disableDivider?: boolean;
}
export type { CardHeaderProps };

export const CardHeader: React.FunctionComponent<CardHeaderProps> = (
  props: CardHeaderProps
) => {
  const {
    className = '',
    'data-testid': testId,
    title,
    subtitle,
    icon,
    loading,
    toggle,
    actionButtonsProps,
    children,
    menuProps,
    disableDivider = false,
    titleDecoration,
    titleStyle
  } = props;

  return (
    <CardSizeContext.Consumer>
      {size => (
        <Box position='relative'>
          <Stack
            alignItems='center'
            className={className}
            data-testid={testId}
            direction='row'
            justifyContent='space-between'
            spacing={2}
            sx={{
              minHeight: {
                medium: '72px',
                small: '64px'
              }[size],
              pl: 2,
              pr: menuProps ? 1 : 2,
              py: {
                medium: 1,
                small: 0.75
              }[size]
            }}>
            <Stack alignItems='center' direction='row' spacing={2}>
              {icon && (
                <Avatar
                  sx={{
                    fontSize: 40,
                    lineHeight: 1
                  }}>
                  {icon}
                </Avatar>
              )}
              <Stack>
                <Box display='flex' flexDirection='row' gap='15px'>
                  <Typography
                    component='h5'
                    id={`${kebabCase(title)}-heading`}
                    style={titleStyle}
                    sx={{
                      fontSize: { medium: 24, small: 20 }[size],
                      lineHeight: '32px'
                    }}>
                    {title}
                  </Typography>
                  {titleDecoration}
                </Box>

                {subtitle && (
                  <Typography
                    component='h6'
                    sx={{
                      fontSize: 14,
                      lineHeight: '20px'
                    }}>
                    {subtitle}
                  </Typography>
                )}
              </Stack>
              {toggle && (
                <CardToggle
                  {...toggle}
                  data-testid={testId ? `${testId}-toggle` : undefined}
                />
              )}
            </Stack>

            {(actionButtonsProps || menuProps || children) && (
              <Stack alignItems='center' direction='row' spacing={1}>
                {children}
                {actionButtonsProps &&
                  actionButtonsProps.map(actionButtonProps => {
                    return (
                      <CardMainActionButton
                        data-testid={
                          testId ? `${testId}-main-action` : undefined
                        }
                        key={
                          typeof actionButtonProps.label === 'string'
                            ? actionButtonProps.label
                            : DOMInteraction.JSXToTextContent(
                                actionButtonProps.label
                              )
                        }
                        {...actionButtonProps}
                      />
                    );
                  })}
                {menuProps && (
                  <CardMenuActionButton
                    data-testid={testId ? `${testId}-menu-action` : undefined}
                    {...menuProps}
                  />
                )}
              </Stack>
            )}
          </Stack>
          {loading && (
            <LinearProgress
              sx={{ bottom: 0, left: 0, position: 'absolute', right: 0 }}
            />
          )}
          {!disableDivider && (
            <Divider sx={{ borderColor: theme => theme.palette.grey[300] }} />
          )}
        </Box>
      )}
    </CardSizeContext.Consumer>
  );
};

interface CardPlaceholderProps extends React.HTMLAttributes<HTMLBaseElement> {
  'data-testid'?: string;
  /**
   * Any icon component.
   * Be sure to add fontSize="inherit" to the icon element for proper sizing and set desired color.
   * We can't add that for you because the icon component is memoized.
   */
  icon?: React.ReactElement;
  title?: string;
  subtitle?: string;
  /**
   * Content of the button shown below the possible icon, title, and/or subtitle.
   * You must also provide an onClickAction property for this to be rendered.
   */
  actionLabel?: React.ReactNode;
  /**
   * Action to be performed when the optional button is clicked.
   * You must also provide an actionLabel property for this to trigger the render of a button.
   */
  onClickAction?: () => void;
}
export type { CardPlaceholderProps };

export const CardPlaceholder: React.FunctionComponent<CardPlaceholderProps> = (
  props: CardPlaceholderProps
) => {
  const {
    'data-testid': testId,
    icon,
    title,
    subtitle,
    onClickAction,
    actionLabel,
    style
  } = props;

  return (
    <Stack
      alignItems='center'
      data-testid={testId}
      justifyContent='center'
      style={style}
      sx={{ minHeight: '119px', py: 4 }}>
      {icon && (
        <Stack
          sx={{
            color: theme => theme.palette.grey[300],
            fontSize: 42,
            mb: 1
          }}>
          {icon}
        </Stack>
      )}
      {title && <Typography variant='body1'>{title}</Typography>}
      {subtitle && (
        <Typography
          sx={{ color: theme => theme.palette.grey[700], mt: 0.25 }}
          variant='body2'>
          {subtitle}
        </Typography>
      )}
      {onClickAction && actionLabel && (
        <Button
          onClick={onClickAction}
          sx={{
            fontSize: 13,
            fontWeight: 500,
            lineHeight: '22px',
            mt: 1
          }}>
          {actionLabel}
        </Button>
      )}
    </Stack>
  );
};

interface CardContentProps extends React.HTMLAttributes<HTMLBaseElement> {
  children?: React.ReactNode;
  'data-testid'?: string;
  disablePadding?: boolean;
  /**
   * While loading, the content of the card will be unloaded and replaced with an overlay indicator.
   */
  loading?: boolean;
  /**
   * Optional alternative to loading prop to display the loading indicator as an overlay on top of the content.
   */
  overlayLoading?: boolean;
  sx?: SxProps<Theme>;
}
export type { CardContentProps };

export const CardContent: React.FunctionComponent<CardContentProps> = (
  props: CardContentProps
) => {
  const {
    'data-testid': testId,
    children,
    disablePadding = false,
    loading,
    overlayLoading,
    sx
  } = props;

  const theme = useTheme();

  return (
    <CardSizeContext.Consumer>
      {size => (
        <Box
          aria-busy={loading || overlayLoading}
          data-testid={testId}
          sx={{
            minHeight: overlayLoading ? '233px' : undefined,
            p: disablePadding === true ? 0 : 2,
            position: 'relative',
            zIndex: overlayLoading ? 1 : undefined, // ensures the overlay does not overlap parent or sibling elements of this card
            ...(typeof sx === 'function' ? sx(theme) : sx)
          }}>
          {(loading || overlayLoading) && (
            <Box
              sx={{
                background: overlayLoading ? '#fff' : undefined,
                bottom: overlayLoading ? 0 : undefined,
                left: overlayLoading ? 0 : undefined,
                position: overlayLoading ? 'absolute' : undefined,
                right: overlayLoading ? 0 : undefined,
                top: overlayLoading ? 0 : undefined,
                zIndex: overlayLoading ? 9999 : undefined
              }}>
              <Stack
                alignItems='center'
                justifyContent='center'
                sx={{
                  minHeight: '233px'
                }}>
                <CircularLoading size={size === 'small' ? '36px' : '40px'} />
              </Stack>
            </Box>
          )}
          {(!loading || overlayLoading) && children}
        </Box>
      )}
    </CardSizeContext.Consumer>
  );
};

interface CardProps extends React.HTMLAttributes<HTMLBaseElement> {
  children: React.ReactNode;
  'data-testid'?: string;
  size?: 'small' | 'medium';
  sx?: SxProps<Theme>;
}

const Card: React.FunctionComponent<CardProps> = (props: CardProps) => {
  const { children, 'data-testid': testId, size = 'medium', sx } = props;

  const theme = useTheme();

  return (
    <CardSizeContext.Provider value={size}>
      <MUICard
        data-testid={testId}
        sx={{
          borderColor: theme.palette.grey[300],
          minWidth: {
            sm: '360px'
          },
          width: {
            sm: 'auto',
            xs: '100%'
          },
          ...(typeof sx === 'function' ? sx(theme) : sx)
        }}
        variant='outlined'>
        {children}
      </MUICard>
    </CardSizeContext.Provider>
  );
};

export default Card;
export type { CardProps };
