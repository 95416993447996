import { Order } from '@/components/collapsible-table';
import { DocumentDto } from '@/models';
import {
  Program,
  ProgramFund,
  ProgramModelFactsheet,
  ProgramPlanDto,
  ProgramRequest
} from '@/models/ops/investments/Program.model';
import { SlotMetaData } from '@/routes/plans/plan-detail/PlanDocumentsTab/PlanDocumentsTab.component';
import ApiService from '@/services/Api.service';

type ProgramListDto = { data: Program[]; meta: { count: number } };

type ProgramPlansListDto = { data: ProgramPlanDto[]; meta: { count: number } };

export type InvestmentOption =
  | 'targetSeries'
  | 'riskSeries'
  | 'goalSeries'
  | 'fundLineup';

export type FundChangesPreview = {
  isRemoved?: boolean;
  oldFund: string;
  newFund: string;
  tradeDate: string;
  investmentOptions: InvestmentOption[];
};

export type SponsorPlan = {
  sponsorPlanId: number;
  sponsorPlanName: string;
  participantsAffected: number;
};

export type FundChangePreview = {
  fundChange: FundChangesPreview;
  plansAffected: SponsorPlan[];
};

export type FundChanges = FundChangesPreview & {
  fundChangeId: number;
};

class ProgramService {
  static async getProgramsPage(
    pageNumber: number,
    pageSize: number,
    query: string,
    orderBy: string,
    order: Order
  ): Promise<ProgramListDto> {
    const sort = (order === 'asc' ? '' : '-').concat(orderBy);
    return ApiService.getJson('/investments/program', {
      pageNumber,
      pageSize,
      query,
      sort
    }) as Promise<ProgramListDto>;
  }

  static async getProgramsPageWithPlanCount(
    pageNumber: number,
    pageSize: number,
    query: string,
    orderBy: string,
    order: Order
  ): Promise<ProgramListDto> {
    const sort = (order === 'asc' ? '' : '-').concat(orderBy);
    return ApiService.getJson('/investments/program', {
      include: 'planCount',
      pageNumber,
      pageSize,
      query,
      sort
    }) as Promise<ProgramListDto>;
  }

  static async getProgram(programId: number): Promise<Program> {
    return ApiService.getJson(`/investments/program/${programId}`);
  }

  static async postProgram(data: ProgramRequest): Promise<Program> {
    return ApiService.postJson(`investments/program`, data);
  }

  static async putProgram(
    programId: number,
    data: ProgramRequest
  ): Promise<Program> {
    return ApiService.putJson(`investments/program/${programId}`, data);
  }

  static async getModelProgramsPage(
    investmentOptionId: number,
    modelType: string,
    pageNumber: number,
    pageSize: number,
    query: string,
    orderBy: string,
    order: Order
  ): Promise<ProgramListDto> {
    const sort = (order === 'asc' ? '' : '-').concat(orderBy);
    let modelTypeParameter;
    switch (modelType) {
      case 'target':
        modelTypeParameter = { targetDateSeriesId: investmentOptionId };
        break;
      case 'risk':
        modelTypeParameter = { riskSeriesId: investmentOptionId };
        break;
      case 'fundLineup':
        modelTypeParameter = { fundLineupId: investmentOptionId };
        break;
      case 'goal':
        modelTypeParameter = { goalSeriesId: investmentOptionId };
        break;
      default:
        modelTypeParameter = {};
    }
    return ApiService.getJson(`/investments/program`, {
      pageNumber,
      pageSize,
      query,
      sort,
      ...modelTypeParameter
    }) as Promise<ProgramListDto>;
  }

  static async getFundChanges(
    programId: number
  ): Promise<{ data: FundChanges[] }> {
    return ApiService.getJson(`/investments/program/${programId}/fund-change`);
  }

  static async createFundChangePreview(
    programId: number,
    fundChangesPreview: FundChangesPreview[]
  ): Promise<FundChangePreview[]> {
    return ApiService.postJson(
      `/investments/program/${programId}/fund-change/preview`,
      fundChangesPreview
    );
  }

  static async createFundChange(
    programId: number,
    FundChangesPreview: FundChangesPreview
  ): Promise<FundChanges> {
    return ApiService.postJson(
      `/investments/program/${programId}/fund-change`,
      FundChangesPreview
    );
  }

  static async getAllProgramDocuments(
    programId: number
  ): Promise<SlotMetaData[]> {
    return ApiService.getJson(`investments/program/${programId}/documents/all`);
  }

  static async getDocumentForProgramId(
    programId: string | number,
    documentId: string | number
  ): Promise<DocumentDto> {
    return ApiService.getJson(
      `investments/program/${programId}/document/${documentId}`
    );
  }

  static async getProgramModelFactsheets(
    programId: number
  ): Promise<ProgramModelFactsheet[]> {
    return ApiService.getJson(
      `investments/program/${programId}/model-factsheets`
    );
  }

  static async uploadProgramDocument(
    programId: number,
    documentData: FormData
  ): Promise<any> {
    return ApiService.postFormData(
      `investments/program/${programId}/document`,
      documentData
    );
  }

  static async deleteProgramDocument(
    programId: number,
    documentId: string | number
  ): Promise<any> {
    return ApiService.deleteJson(
      `investments/program/${programId}/document/${documentId}`
    );
  }

  static async getProgramPlans(
    programId: number,
    pageNumber: number,
    pageSize: number,
    search: string,
    orderBy: string,
    order: Order,
    associated?: boolean
  ): Promise<ProgramPlansListDto> {
    const sort = (order === 'asc' ? '' : '-').concat(orderBy);
    return ApiService.getJson(`investments/program/${programId}/plans`, {
      associated,
      pageNumber,
      pageSize,
      search,
      sort
    });
  }

  static async getProgramFunds(programId: number): Promise<ProgramFund[]> {
    return ApiService.getJson(`/investments/program/${programId}/fund`);
  }
}

export type { ProgramListDto, ProgramPlansListDto };
export { ProgramService };
