import { DividendAccrualRateAddRequest } from '@/models/ops/dividend-accrual-rates/DividendAccrualRateAddRequest.model';
import { DividendAccrualRateDto } from '@/models/ops/dividend-accrual-rates/DividendAccrualRateDTO.model';
import { DividendAccrualRateSearchParams } from '@/models/ops/dividend-accrual-rates/DividendAccrualRateSearchParams.model';
import ApiService from '@/services/Api.service';

type ApiResponse = {
  results: DividendAccrualRateDto[];
  pagination: {
    pageSize: number;
    total: number;
  };
};

class DividendAccrualRateService {
  static async add(params: DividendAccrualRateAddRequest) {
    return ApiService.postJson('/dividend-accrual-rates/add', params);
  }

  static async search(
    params: DividendAccrualRateSearchParams
  ): Promise<ApiResponse> {
    for (const [key, value] of Object.entries(params)) {
      // filter undefined params to avoid request validation errors
      if (value === undefined || value === '')
        delete params[key as keyof DividendAccrualRateSearchParams];
    }

    return ApiService.getJson<ApiResponse>(`/dividend-accrual-rates`, {
      cusip: params.cusip,
      effectiveEndDate: params.effectiveEndDate,
      effectiveStartDate: params.effectiveStartDate,
      orderBy: params.orderBy,
      orderByDirection: params.orderByDirection,
      page: params.page,
      pageSize: params.pageSize
    });
  }
}

export default DividendAccrualRateService;
