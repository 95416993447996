import {
  FormHelperText,
  TextFieldProps,
  Theme,
  useFormControl
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  DatePickerProps,
  DateValidationError,
  DesktopDatePicker,
  LocalizationProvider
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import dayjs, { Dayjs } from 'dayjs';
import { FormikErrors, useFormikContext } from 'formik';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useToggle } from 'react-use';

const useStyles = makeStyles((theme: Theme) => ({
  inputTooltip: {
    fontSize: theme.spacing(1.5),
    lineHeight: theme.spacing(1.5),
    margin: 0,
    marginTop: theme.spacing(0.75),
    maxWidth: '100%',
    width: 'max-content'
  }
}));
interface MuiDatePickerProps
  extends Omit<DatePickerProps<any>, 'onChange' | 'renderInput' | 'value'> {
  'data-testid'?: string;
  errorMessage?: string | FormikErrors<any> | string[] | FormikErrors<any>[];
  handleError?: (
    errorReason: DateValidationError,
    value: dayjs.Dayjs | null
  ) => void;
  /** Useful when Formik errors are already rendered in a parent form */
  hideHelperText?: boolean;
  label?: string;
  name: string;
  onChange: (event: {
    target: {
      name: string;
      value: string;
    };
  }) => void;
  value?: string | null;
}

export const DatePicker: FC<MuiDatePickerProps & TextFieldProps> = ({
  'data-testid': testId,
  errorMessage = '',
  handleError,
  hideHelperText,
  label,
  name,
  onChange,
  size, // <FormControl> context does not provide this
  value,
  variant = 'standard',
  ...restProps
}) => {
  const { error: formikError } = useFormControl() || {};
  const formik = useFormikContext();
  const [cleared, toggleCleared] = useToggle(false);
  const [previousValue, setPreviousValue] = useState(value);
  const classes = useStyles();
  const [dayJsValue, setDayJsValue] = useState<Dayjs | null>(
    value ? dayjs(value) : null
  );

  if (value !== previousValue) {
    setPreviousValue(value);
    setDayJsValue(!value ? null : dayjs(value));
  }

  const getErrorMessage = (currValue: dayjs.Dayjs | null): string => {
    if (currValue !== null) {
      if (!currValue?.isValid())
        return 'Please enter a valid date as mm/dd/yyyy';

      if (restProps.shouldDisableDate && restProps.shouldDisableDate(currValue))
        return 'Please choose a valid date';

      if (restProps.disableFuture && currValue > dayjs()) {
        return 'Date cannot be in the future';
      }
    }

    return '';
  };

  const internalHandleError = (
    errorReason: DateValidationError,
    currValue: dayjs.Dayjs | null
  ) => {
    if (errorReason) {
      formik?.setFieldError(name, getErrorMessage(currValue));
    }
    if (handleError) handleError(errorReason, currValue);
  };

  useEffect(() => {
    if (cleared) {
      const timeout = setTimeout(() => {
        toggleCleared();
      }, 1500);

      return () => clearTimeout(timeout);
    }
    return () => {};
  }, [cleared]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        format='MM/DD/YYYY' // default value but explicitly defined here for clarity
        label={label}
        onChange={(newValue: Dayjs | null) => {
          setDayJsValue(newValue);

          if (getErrorMessage(newValue))
            return internalHandleError('invalidDate', newValue);

          formik?.setFieldError(name, undefined);

          onChange({
            target: {
              name,
              value: newValue === null ? '' : newValue.format('YYYY-MM-DD')
            }
          });
        }}
        onError={internalHandleError}
        slotProps={{
          field: { clearable: true, onClear: toggleCleared },
          textField: {
            InputLabelProps: {
              shrink: true
            },
            InputProps: {
              //@ts-expect-error
              'data-testid': testId
            },
            error:
              !!errorMessage || formikError || !!getErrorMessage(dayJsValue),
            fullWidth: true,
            name, // for testing only; Formik uses onChange name
            size,
            variant
          }
        }}
        value={dayJsValue}
        {...restProps}
      />
      {!hideHelperText && (
        <FormHelperText
          className={classes.inputTooltip}
          error={
            !!errorMessage ||
            restProps.error ||
            formikError ||
            !!getErrorMessage(dayJsValue)
          }>
          {errorMessage
            ? (errorMessage as unknown as ReactNode)
            : (getErrorMessage(dayJsValue) as unknown as ReactNode)}
        </FormHelperText>
      )}
    </LocalizationProvider>
  );
};

export default DatePicker;
export type { MuiDatePickerProps };
