import { PngStatusItem } from '@/models/ContributionDetailsDTO.model';
import formatters from '@/utils/Formatters';
import { InfoOutlined } from '@mui/icons-material';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import { Theme, Tooltip, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import makeStyles from '@mui/styles/makeStyles';

import clsx from 'clsx';
import React from 'react';

export interface PlanContributionDetailsTableProps {
  statusesData: PngStatusItem[] | undefined;
}

const useStyles = makeStyles((theme: Theme) => ({
  checkedIcon: {
    color: theme.palette.text.secondary,
    height: theme.spacing(2.5)
  },
  contentBody: {
    paddingBottom: theme.spacing(2)
  },
  detailsTable: {
    marginTop: theme.spacing(2)
  },
  loadingIcon: {
    color: theme.palette.info.main,
    height: theme.spacing(2.5)
  },
  noBorder: {
    border: 'none'
  },
  noData: {
    paddingTop: theme.spacing(5),
    textAlign: 'center'
  },
  root: {
    paddingBottom: theme.spacing(2),
    width: '100%'
  },
  secondaryFont: {
    color: theme.palette.text.secondary,
    fontSize: theme.spacing(1.6)
  },
  tableCell: {
    alignItems: 'center',
    border: 'none',
    display: 'flex'
  },
  tableContainter: {
    marginTop: theme.spacing(4),
    paddingBottom: theme.spacing(2.5)
  },
  tooltip: {
    '& .MuiTooltip-tooltip': {
      backgroundColor: 'white',
      boxShadow: `0px 5px 5px -3px ${grey[800]}`,
      color: grey[800],
      fontSize: theme.spacing(1.6),
      padding: theme.spacing(1.5)
    }
  },
  underline: {
    borderBottom: `1px solid ${grey[300]}`
  }
}));

const PlanContributionDetailsTable: React.FunctionComponent<
  PlanContributionDetailsTableProps
> = (props: PlanContributionDetailsTableProps) => {
  const classes = useStyles();

  const { statusesData } = props;

  if (!statusesData) {
    return (
      <Typography className='Plan-Contribution-Details_Error'>
        Error retrieving contributions
      </Typography>
    );
  }

  if (statusesData.length === 0) {
    return (
      <Typography
        className={clsx(classes.noData, 'Contribution-details_NoData')}
        variant='h6'>
        No contribution details statuses
      </Typography>
    );
  }

  return (
    <div
      className={classes.tableContainter}
      data-testid='contribution-details-card'>
      <div className={classes.underline} />
      <Table
        aria-label='contribution table'
        className={classes.detailsTable}
        size='small'>
        <TableBody>
          {statusesData?.map((row: PngStatusItem, index: number) => (
            <TableRow
              className='Contribution-detail_Data_Row'
              key={`${row.status}:${row.createdAt}:${index}`}>
              <TableCell className={classes.noBorder}>
                {row.status === 'HELD' && index === statusesData.length - 1 ? (
                  <DataUsageIcon className={classes.loadingIcon} />
                ) : (
                  <CheckCircleOutlinedIcon className={classes.checkedIcon} />
                )}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {formatters.formatContributionStatusesTable(row.status)}
                {row.status === 'HELD' && row.message && (
                  <Tooltip
                    classes={{
                      popper: classes.tooltip
                    }}
                    placement='top'
                    title={row.message}>
                    <InfoOutlined
                      fontSize='small'
                      sx={{ color: '#C77700', marginLeft: 1 }}
                    />
                  </Tooltip>
                )}
              </TableCell>
              <TableCell
                className={clsx(classes.noBorder, classes.secondaryFont)}>
                {formatters.formatFromIsoDate(row.createdAt)}
              </TableCell>
              <TableCell
                className={clsx(classes.noBorder, classes.secondaryFont)}>
                {formatters.formatContributionRole(row.initiator)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default PlanContributionDetailsTable;
