import { SubAccountSearchRequest } from '@/models/ops/accounts/SubAccountSearchRequest.model';
import { SubAccountDto } from '@/models/ops/SubAccountDTO.model';
import { PaginatedApiResponse } from '@/models/PaginatedApiResponse.model';
import ApiService from '@/services/Api.service';

export class SubAccountService {
  static async getById(id: number | string): Promise<SubAccountDto> {
    const result = await ApiService.getJson<SubAccountDto>(
      `sub-accounts/${id}`
    );
    return result;
  }

  static async searchSubAccounts(
    params: SubAccountSearchRequest
  ): Promise<PaginatedApiResponse<SubAccountDto[]>> {
    const result = await ApiService.getJson<
      PaginatedApiResponse<SubAccountDto[]>
    >(`sub-accounts/`, params);
    return result;
  }
}

export default SubAccountService;
