import Card from '@/components/card';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { useParentAccountById } from '@/hooks/ops/useParentAccountById.hook';
import { useSearchAlertTypeTotals } from '@/hooks/ops/useSearchAlertTypeTotals.hook';
import ReconExceptionService from '@/services/ops/recon-exceptions/ReconException.service';
import formatters from '@/utils/Formatters';
import {
  Alert,
  Button,
  CardContent,
  Link,
  Skeleton,
  Typography
} from '@mui/material';
import Stack from '@mui/material/Stack';
import { useQuery } from '@tanstack/react-query';
import { AlertStatus } from '@vestwell-sub-accounting/models/common/AlertStatus';

import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

export const AccountDetailsHeader: React.FC<{
  accountId: string;
}> = props => {
  const getParentAccountByIdQuery = useParentAccountById({
    accountId: props.accountId
  });

  const getAlertTypeTotals = useSearchAlertTypeTotals({
    alertStatus: [
      AlertStatus.Blocked,
      AlertStatus.InProcess,
      AlertStatus.Open,
      AlertStatus.OutreachNeeded,
      AlertStatus.Waiting
    ],
    parentAccountId: props.accountId
  });

  const getReconExceptionTotalCountsQuery = useQuery(
    ['ReconExceptionsService.getTotalCounts', props.accountId],
    async () => {
      return ReconExceptionService.getTotalCounts({
        parentAccountId: props.accountId
      });
    }
  );

  return (
    <Stack
      data-testid='account-detail-header-fields'
      direction='row'
      maxWidth='100%'
      overflow='scroll'
      spacing={2}>
      <Card data-testid='balance-card'>
        <CardContent>
          <Stack justifyContent='space-between' minHeight={192} spacing={2}>
            <Stack spacing={2}>
              <Typography variant='body1'>Balance</Typography>

              <TextStack
                direction='column'
                spacing={0.5}
                textValueAlign='right'>
                <TextStackItem>
                  <TextLabel>Market Value</TextLabel>
                  <TextValue>
                    {formatters.formatDollars(
                      getParentAccountByIdQuery.data?.totalMarketValue
                    )}
                  </TextValue>
                </TextStackItem>

                <TextStackItem>
                  <TextLabel>Total cash balance</TextLabel>
                  <TextValue>
                    {formatters.formatDollars(
                      getParentAccountByIdQuery.data?.cashBalance
                    )}
                  </TextValue>
                </TextStackItem>

                <TextStackItem>
                  <TextLabel>Sub account debit</TextLabel>
                  <TextValue>
                    {getParentAccountByIdQuery.data?.debitSubAccountsCount >=
                      0 &&
                      `${getParentAccountByIdQuery.data?.debitSubAccountsCount} sub acct.`}
                  </TextValue>
                </TextStackItem>
              </TextStack>
            </Stack>

            {(Number(getParentAccountByIdQuery.data?.debitSubAccountsCount) >
              0 ||
              parseFloat(getParentAccountByIdQuery.data?.cashBalance) < 0) && (
              <Alert
                action={
                  <Button
                    color='warning'
                    component={RouterLink}
                    data-testid='negative-balance-alert-view-button'
                    size='small'
                    to={`/ops/accounts/${getParentAccountByIdQuery.data?.parentAccountId}/sub-accounts`}>
                    View
                  </Button>
                }
                data-testid='negative-balance-alert'
                severity='warning'>
                Negative balance
              </Alert>
            )}
          </Stack>
        </CardContent>
      </Card>
      <Card data-testid='short-positions-card'>
        <CardContent>
          <Stack justifyContent='space-between' minHeight={192} spacing={2}>
            <Stack spacing={2}>
              <Typography variant='body1'>Short Positions</Typography>
              <TextStack
                direction='column'
                spacing={0.5}
                textValueAlign='right'>
                <TextStackItem>
                  <TextLabel>Parent account</TextLabel>
                  <TextValue>
                    {
                      getParentAccountByIdQuery.data
                        ?.parentAccountShortPositionsCount
                    }
                  </TextValue>
                </TextStackItem>

                <TextStackItem>
                  <TextLabel>Sub accounts short</TextLabel>
                  <TextValue>
                    {
                      getParentAccountByIdQuery.data
                        ?.subAccountShortPositionsCount
                    }
                  </TextValue>
                </TextStackItem>
              </TextStack>
            </Stack>

            {(getParentAccountByIdQuery.data?.subAccountShortPositionsCount >
              0 ||
              getParentAccountByIdQuery.data?.parentAccountShortPositionsCount >
                0) && (
              <Stack>
                <Alert
                  action={
                    <Button
                      color='warning'
                      component={RouterLink}
                      data-testid='short-positions-view-button'
                      size='small'
                      to={`/ops/accounts/${getParentAccountByIdQuery.data?.parentAccountId}/holdings`}>
                      View
                    </Button>
                  }
                  data-testid='short-positions-alert'
                  severity='warning'>
                  Short Positions
                </Alert>
              </Stack>
            )}
          </Stack>
        </CardContent>
      </Card>
      <Card data-testid='outstanding-tickets-card'>
        <CardContent>
          <Stack spacing={2}>
            <Typography variant='body1'>Outstanding Tickets</Typography>

            <TextStack direction='column' spacing={0.5} textValueAlign='right'>
              {getAlertTypeTotals.isFetching ? (
                <Skeleton height={40} variant='rectangular' />
              ) : (
                <TextStackItem>
                  <TextLabel>Alerts</TextLabel>
                  <TextValue>
                    <Link
                      component={RouterLink}
                      to={`/ops/accounts/${getParentAccountByIdQuery.data?.parentAccountId}/alerts`}>
                      {!getAlertTypeTotals.isFetching &&
                        getAlertTypeTotals.isSuccess &&
                        getAlertTypeTotals.data &&
                        `${Object.values(getAlertTypeTotals.data).reduce(
                          (sum, count) => (sum += count),
                          0
                        )} tickets`}
                      {!getAlertTypeTotals.isFetching &&
                        getAlertTypeTotals.isError &&
                        `--`}
                    </Link>
                  </TextValue>
                </TextStackItem>
              )}
              {getReconExceptionTotalCountsQuery.isFetching ? (
                <Skeleton height={40} variant='rectangular' />
              ) : (
                <TextStackItem>
                  <TextLabel>Recon</TextLabel>
                  <TextValue>
                    <Link
                      component={RouterLink}
                      to={`/ops/accounts/${getParentAccountByIdQuery.data?.parentAccountId}/recon`}>
                      {!getReconExceptionTotalCountsQuery.isFetching &&
                        getReconExceptionTotalCountsQuery.isSuccess &&
                        getReconExceptionTotalCountsQuery.data &&
                        `${getReconExceptionTotalCountsQuery.data?.counts[0].totalCount} tickets`}
                      {!getReconExceptionTotalCountsQuery.isFetching &&
                        getReconExceptionTotalCountsQuery.isError &&
                        `--`}
                    </Link>
                  </TextValue>
                </TextStackItem>
              )}
            </TextStack>
          </Stack>
        </CardContent>
      </Card>
      <Card data-testid='general-info-card'>
        <CardContent>
          <Stack spacing={2}>
            <TextStack direction='column' spacing={0.5}>
              <TextStackItem>
                <TextLabel>Type</TextLabel>
                <TextValue>
                  {formatters.displayCase(
                    getParentAccountByIdQuery.data?.accountType
                  )}
                </TextValue>
              </TextStackItem>
              <TextStackItem>
                <TextLabel>Custodian</TextLabel>
                <TextValue
                  copyLabel={`ID: ${getParentAccountByIdQuery.data?.custodianAccountNumber}`}
                  copyValue={
                    getParentAccountByIdQuery.data?.custodianAccountNumber
                  }
                  copyable>
                  {formatters.displayCase(
                    getParentAccountByIdQuery.data?.custodianId
                  )}
                </TextValue>
              </TextStackItem>
              <TextStackItem>
                <TextLabel>Created</TextLabel>
                <TextValue>
                  {getParentAccountByIdQuery.data?.createdAt
                    ? formatters.formatFromIsoDate(
                        getParentAccountByIdQuery.data?.createdAt
                      )
                    : null}
                </TextValue>
              </TextStackItem>
            </TextStack>
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
};
