import SimpleTabs from '@/components/simple-tabs';
import {
  CommunicationFilters,
  initialCommunicationFilters,
  PlanCommunications
} from '@/models/PlanCommunicationsDTO.model';
import { cleanEmailSubject, cleanTemplateName } from '@/services/mappers';
import { PlanService } from '@/services/Plan.service';
import { Box } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useQuery } from '@tanstack/react-query';

import dayjs from 'dayjs';
import { useMemo, useState } from 'react';

import PlanCommunicationFilters from './PlanCommunicationFilters.component';
import PlanCommunicationsTable from './PlanCommunicationsTable.component';

interface PlanCommunicationsTabProps {
  planId: number;
  sponsorId: number;
  parentTabPath: string;
}

interface DefaultTab {
  component: JSX.Element;
  label: string;
  value: string;
}

export enum CommunicationsTabsValues {
  EMPLOYERS = 'Employers',
  EMPLOYEES = 'Employees'
}

const CommonContentWrapper = (props: {
  children: React.ReactNode;
}): React.ReactElement => {
  return (
    <Box
      sx={{
        alignContent: 'flex-start',
        display: 'flex',
        justifyContent: 'flex-start',
        marginTop: -3,
        overflow: 'hidden'
      }}>
      {props.children}
    </Box>
  );
};

const PlanCommunicationsTab = (
  props: PlanCommunicationsTabProps
): JSX.Element => {
  const { planId, sponsorId } = props;
  const [value, setValue] = useState(CommunicationsTabsValues.EMPLOYEES);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [filters, setFilters] = useState<CommunicationFilters>(
    initialCommunicationFilters
  );

  const participantsEmailsQuery = useQuery(
    [
      'PlanService.getPlanParticipantsCommunications',
      planId,
      offset,
      limit,
      filters
    ],
    () =>
      PlanService.getPlanParticipantsCommunications(planId, {
        limit,
        offset,
        ...filters
      }),
    {
      enabled: Boolean(planId) && value === CommunicationsTabsValues.EMPLOYEES,
      refetchOnMount: false,
      select: (data: PlanCommunications) => {
        return {
          ...data,
          messages: data?.messages?.map(m => {
            return {
              ...m,
              sendDate: dayjs(m.sendDate).format('MM/DD/YYYY HH:mm:ss'),
              subject: cleanEmailSubject(m.subject),
              templateName: cleanTemplateName(m.templateName)
            };
          })
        };
      }
    }
  );

  const sponsorEmailsQuery = useQuery(
    [
      'PlanService.getPlanSponsorCommunications',
      planId,
      offset,
      limit,
      filters
    ],
    () =>
      PlanService.getPlanSponsorCommunications(planId, {
        limit,
        offset,
        ...filters
      }),
    {
      enabled: Boolean(planId) && value === CommunicationsTabsValues.EMPLOYERS,
      refetchOnMount: false,
      select: (data: PlanCommunications) => {
        return {
          ...data,
          messages: data?.messages?.map(m => {
            return {
              ...m,
              sendDate: dayjs(m.sendDate).format('MM/DD/YYYY HH:mm:ss'),
              subject: cleanEmailSubject(m.subject),
              templateName: cleanTemplateName(m.templateName)
            };
          })
        };
      }
    }
  );

  const updateFilters = (newFilters: CommunicationFilters) => {
    setFilters(newFilters);
    setOffset(0);
  };

  const tabs: DefaultTab[] = useMemo(
    () => [
      {
        component: (
          <CommonContentWrapper>
            <PlanCommunicationFilters
              entity={CommunicationsTabsValues.EMPLOYEES}
              filters={filters}
              planId={planId}
              updateFilters={updateFilters}
            />
            <PlanCommunicationsTable
              emailsData={participantsEmailsQuery.data?.messages}
              filters={filters}
              isError={participantsEmailsQuery.isError}
              isLoading={participantsEmailsQuery.isFetching}
              pageNumber={offset}
              rowsPerPage={limit}
              setFilters={setFilters}
              setPageNumber={setOffset}
              setRowsPerPage={setLimit}
              totalEmails={participantsEmailsQuery.data?.meta.total}
            />
          </CommonContentWrapper>
        ),
        hash: 'comm employees',
        label: CommunicationsTabsValues.EMPLOYEES,
        value: CommunicationsTabsValues.EMPLOYEES
      },
      {
        component: (
          <CommonContentWrapper>
            <PlanCommunicationFilters
              entity={CommunicationsTabsValues.EMPLOYERS}
              filters={filters}
              planId={planId}
              sponsorId={sponsorId}
              updateFilters={updateFilters}
            />
            <PlanCommunicationsTable
              emailsData={sponsorEmailsQuery.data?.messages}
              filters={filters}
              isError={sponsorEmailsQuery.isError}
              isLoading={sponsorEmailsQuery.isFetching}
              isSponsor
              pageNumber={offset}
              rowsPerPage={limit}
              setFilters={setFilters}
              setPageNumber={setOffset}
              setRowsPerPage={setLimit}
              totalEmails={sponsorEmailsQuery.data?.meta.total}
            />
          </CommonContentWrapper>
        ),
        hash: 'comm employers',
        label: CommunicationsTabsValues.EMPLOYERS,
        value: CommunicationsTabsValues.EMPLOYERS
      }
    ],
    [
      participantsEmailsQuery,
      sponsorEmailsQuery,
      sponsorId,
      planId,
      filters,
      limit,
      offset
    ]
  );

  const handleChange = (newValue: number) => {
    setValue(tabs[newValue]?.value as CommunicationsTabsValues);
    setFilters(initialCommunicationFilters);
    setLimit(10);
    setOffset(0);
  };

  return (
    <Box sx={{ border: `1px solid ${grey[300]}`, borderRadius: '4px' }}>
      <SimpleTabs
        data-testid='plan-communications-table-emails-tabs'
        enableNestedHash
        onChange={handleChange}
        parentTabPath={props.parentTabPath}
        tabs={tabs}
        tabsAriaLabel='communication-tabs'
      />
    </Box>
  );
};

export default PlanCommunicationsTab;
