import Badge from '@/components/badge';
import Card, {
  CardContent,
  CardHeader,
  CardPlaceholder
} from '@/components/card';
import CusipTickerSearch from '@/components/cusip-ticker-search/CusipTickerSearch';
import DataTable, {
  DataTableBadgeCell,
  DataTableMenuCell,
  DataTableStackCell
} from '@/components/data-table/DataTable.component';
import DatePicker from '@/components/date-picker';
import ViewChildrenIcon from '@/components/icon/ViewChildren';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { orderStatusColorMap } from '@/models/ops/common/OrderStatusColorMap.model';
import { ParentAccountOrderDto } from '@/models/ops/parent-account-orders/ParentAccountOrderDTO.model';
import { ParentAccountOrderSearchRequest } from '@/models/ops/parent-account-orders/ParentAccountOrderSearchRequest.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import ParentAccountOrderService from '@/services/ops/parent-account-orders/ParentAccountOrder.service';
import { userService } from '@/services/User.service';
import formatters from '@/utils/Formatters';
import { useUrlStateParams } from '@/utils/Url';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Tooltip
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { SecurityOrderType } from '@vestwell-sub-accounting/models/common/SecurityOrderType';
import { TradeType } from '@vestwell-sub-accounting/models/common/TradeType';
import { OrderStatus } from '@vestwell-sub-accounting/models/orderManagement/OrderStatus';

import { ColDef } from 'ag-grid-community';
import dayjs from 'dayjs';
import { Field, Form, Formik } from 'formik';
import React, { useMemo, useState } from 'react';

import DeferParentAccountOrderDialog from '../../order-management/parent-account-orders-detail/DeferParentAccountOrderDialog.component';
import { ExecuteParentAccountOrderDialog } from './ExecuteParentAccountOrderDialog.component';
import ParentAccountOrderDetailCellRenderer from './ParentAccountOrderDetailCellRenderer.component';
import ResubmitParentAccountOrderDialog from './ResubmitParentAccountOrderDialog.component';

type ParentAccountOrdersProps = {
  parentAccountId?: string;
  hideFilters?: boolean;
  hideHeader?: boolean;
  hideActions?: boolean;
  customDefaultFilters?: Partial<ParentAccountOrderSearchRequest>;
};

const ParentAccountOrders = ({
  parentAccountId,
  hideFilters = false,
  hideHeader = false,
  hideActions = false,
  customDefaultFilters
}: ParentAccountOrdersProps): JSX.Element => {
  const { showSnackbar } = useSnackbar();

  const hasWritePermissions = userService.hasPermission(
    FeatureLevelPermissions.WRITE_SUBA_TRANSACTIONS
  );
  const [selectedParentAccountOrder, setSelectedParentAccountOrder] =
    useState<ParentAccountOrderDto>();
  const [openDeferDialog, setOpenDeferDialog] = useState(false);
  const [openResubmitDialog, setOpenResubmitDialog] = useState(false);
  const [openExecuteDialog, setOpenExecuteDialog] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [isCusipValid, setIsCusipValid] = useState(true);

  const defaultQuery: ParentAccountOrderSearchRequest = {
    endDate: dayjs().format('YYYY-MM-DD'),
    startDate: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
    ...customDefaultFilters // overwrites the above defaults
  };

  const [query, setQuery] = useUrlStateParams<ParentAccountOrderSearchRequest>(
    defaultQuery,
    'query',
    value => {
      // remove any values that are the same as the default
      const cleanedQuery =
        formatters.objectDiff<ParentAccountOrderSearchRequest>(
          value,
          defaultQuery
        );
      return JSON.stringify(cleanedQuery) !== '{}' // only add query if it's not empty
        ? encodeURIComponent(JSON.stringify(cleanedQuery))
        : '';
    },
    value => {
      try {
        const urlQuery = JSON.parse(decodeURIComponent(value));
        return {
          ...defaultQuery,
          ...urlQuery
        };
      } catch {
        return defaultQuery;
      }
    }
  );

  const [page, setPage] = useUrlStateParams(
    1,
    'page',
    value => String(value),
    value => (!isNaN(Number(value)) ? Number(value) : 1)
  );
  const [pageSize, setPageSize] = useUrlStateParams(
    25,
    'pageSize',
    value => String(value),
    value => (!isNaN(Number(value)) ? Number(value) : 25)
  );
  const [orderBy, setOrderBy] = useUrlStateParams<
    keyof ParentAccountOrderDto | undefined
  >(
    undefined,
    'orderBy',
    value => String(value),
    value => (value ? (value as keyof ParentAccountOrderDto) : undefined)
  );
  const [orderByDirection, setOrderByDirection] = useUrlStateParams<
    'asc' | 'desc' | undefined
  >(
    undefined,
    'orderByDirection',
    value => String(value),
    value => (value === 'asc' || value === 'desc' ? value : undefined)
  );

  const { data, isFetching } = useQuery(
    [
      'ParentAccountOrderService.search',
      query,
      page,
      pageSize,
      orderBy,
      orderByDirection
    ],
    () => {
      const queryWithPagination = {
        ...query,
        cusip: query.cusip || undefined,
        endDate: query.endDate || undefined,
        orderBy: orderBy || undefined,

        orderByDirection: orderByDirection || undefined,

        page,

        pageSize,

        parentAccountId,
        // if blank then don't pass it
        startDate: query.startDate || undefined
      };
      return ParentAccountOrderService.search(queryWithPagination);
    },
    {
      keepPreviousData: true,
      onError: (err: any) => {
        showSnackbar({
          message: `Parent Account Order search failed: ${err?.message}`,
          severity: 'error'
        });
      },
      staleTime: Infinity
    }
  );

  const columnDefs: ColDef[] = [
    {
      autoHeight: true,
      cellRenderer: (cellData: { data: ParentAccountOrderDto }) => {
        return (
          <Tooltip title='View Sub Account Orders'>
            <IconButton
              href={`/ops/parent-account-orders/${cellData.data.id}`}
              size='small'
              target='_blank'>
              <ViewChildrenIcon sx={{ fontSize: 24 }} />
            </IconButton>
          </Tooltip>
        );
      },
      cellStyle: { paddingRight: 0 },
      field: 'id',
      headerName: '',
      suppressColumnsToolPanel: true,
      width: 85
    },
    {
      autoHeight: true,
      cellRenderer: (cellData: { data: ParentAccountOrderDto }) => (
        <>
          {!hideActions && hasWritePermissions && (
            <DataTableMenuCell>
              {cellData.data.orderStatus === OrderStatus.Rejected && (
                <MenuItem
                  onClick={() => {
                    setSelectedParentAccountOrder(cellData.data);
                    setOpenDeferDialog(true);
                  }}>
                  Defer
                </MenuItem>
              )}
              {cellData.data.orderStatus === OrderStatus.Rejected && (
                <MenuItem
                  onClick={() => {
                    setSelectedParentAccountOrder(cellData.data);
                    setOpenResubmitDialog(true);
                  }}>
                  Resubmit Order
                </MenuItem>
              )}
              {[
                OrderStatus.Rejected,
                OrderStatus.Accepted,
                OrderStatus.Submitted
              ].includes(cellData.data.orderStatus) && (
                <MenuItem
                  onClick={() => {
                    setSelectedParentAccountOrder(cellData.data);
                    setOpenExecuteDialog(true);
                  }}>
                  Manually Execute
                </MenuItem>
              )}
            </DataTableMenuCell>
          )}
        </>
      ),
      cellStyle: { paddingLeft: 0, paddingRight: 0 },
      field: 'id',
      headerName: '',
      suppressColumnsToolPanel: true,
      width: 45
    },
    {
      autoHeight: true,
      cellRenderer: (cellData: { data: ParentAccountOrderDto }) => {
        return (
          <DataTableStackCell
            primary={formatters.formatSecurityName(
              cellData.data.security?.symbol,
              cellData.data.security?.cusip
            )}
            secondary={cellData.data.security?.description}
          />
        );
      },
      field: 'security.symbol',
      headerName: 'Security'
    },
    {
      autoHeight: true,
      field: 'tradeType',
      headerName: 'Trade Type',
      sortable: true,
      valueFormatter: ({ value }: { value: string }) => {
        // fetch friendly display name
        const displayTradeType = formatters.getValueKey(TradeType, value);
        return formatters.displayCase(displayTradeType);
      }
    },
    {
      autoHeight: true,
      cellRenderer: (cellData: { data: ParentAccountOrderDto }) => {
        const displayOrderStatus = formatters.getValueKey(
          OrderStatus,
          cellData.data.orderStatus
        );
        return (
          <>
            {cellData.data.orderStatus && (
              <DataTableBadgeCell
                color={orderStatusColorMap[cellData.data.orderStatus]}>
                {formatters.displayCase(displayOrderStatus)}
              </DataTableBadgeCell>
            )}
          </>
        );
      },
      field: 'orderStatus',
      headerName: 'Status',
      sortable: true
    },
    {
      autoHeight: true,
      field: 'securityOrderType',
      headerName: 'Order Type',
      sortable: true,
      valueFormatter: ({ value }: { value: string }) => {
        // fetch friendly display name
        const displaySecurityOrderType = formatters.snakeToCamelCase(
          formatters.getValueKey(SecurityOrderType, value)
        ); // snake case to camel case is necessary until the SecurityOrderType enum has its keys updated to camel case to match the convention
        return formatters.displayCase(displaySecurityOrderType);
      }
    },
    {
      autoHeight: true,
      cellRenderer: (cellData: { data: ParentAccountOrderDto }) => {
        let displayUnitsAmount = '';
        if (
          [
            SecurityOrderType.DOLLAR_ALL,
            SecurityOrderType.DOLLAR_ORDER
          ].includes(cellData.data.securityOrderType)
        ) {
          displayUnitsAmount = cellData.data.orderAmount
            ? formatters.formatDollars(cellData.data.orderAmount)
            : '';
        } else {
          displayUnitsAmount = cellData.data.orderUnits
            ? formatters.formatDecimal(cellData.data.orderUnits, 3)
            : '';
        }
        return displayUnitsAmount;
      },
      field: 'orderUnits',
      headerName: 'Units/Amount'
    },
    {
      field: 'tradeDate',
      headerName: 'Trade Date',
      sortable: true,
      valueFormatter: ({ value }: { value: string }) =>
        value ? formatters.formatFromIsoDateCustom(value, 'MM/DD/YYYY') : ''
    }
  ];

  const handleSubmit = (formData: ParentAccountOrderSearchRequest) => {
    // copy new form data into query on top of the existing query to preserve the non-form query props
    setQuery(prev => ({
      ...prev,
      ...formData
    }));
  };

  const handleSortChanged = (
    newSort: { colId: string; sort?: 'asc' | 'desc' }[]
  ) => {
    if (!newSort || newSort.length === 0) {
      setOrderBy(undefined);
      setOrderByDirection(undefined);
    } else {
      setOrderBy(newSort[0].colId as keyof ParentAccountOrderDto);
      setOrderByDirection(newSort[0].sort);
    }
  };

  const handlePageChanged = (newPage: number) => {
    setPage(newPage);
  };

  const handlePageSizeChanged = (newPageSize: number) => {
    setPageSize(newPageSize);
  };

  const detailCellRenderer = useMemo(() => {
    return ParentAccountOrderDetailCellRenderer;
  }, []);

  return (
    <>
      {selectedParentAccountOrder && (
        <DeferParentAccountOrderDialog
          onClose={() => {
            setOpenDeferDialog(false);
            setSelectedParentAccountOrder(undefined);
          }}
          open={openDeferDialog}
          parentAccountOrder={selectedParentAccountOrder}
        />
      )}
      {selectedParentAccountOrder && (
        <ResubmitParentAccountOrderDialog
          onClose={() => {
            setOpenResubmitDialog(false);
            setSelectedParentAccountOrder(undefined);
          }}
          open={openResubmitDialog}
          parentAccountOrder={selectedParentAccountOrder}
        />
      )}
      {selectedParentAccountOrder && (
        <ExecuteParentAccountOrderDialog
          onClose={() => {
            setOpenExecuteDialog(false);
            setSelectedParentAccountOrder(undefined);
          }}
          open={openExecuteDialog}
          parentAccountOrder={selectedParentAccountOrder}
        />
      )}

      <Card>
        {!hideHeader && (
          <CardHeader
            data-testid='parent-account-order-header'
            loading={data?.results && isFetching}
            title='Parent Account Orders'
          />
        )}
        <CardContent
          disablePadding
          overlayLoading={data?.results === undefined}>
          <DataTable
            columnDefs={columnDefs}
            columnSizing='flex'
            data-testid='data-parent-account-orders-table'
            detailCellRenderer={detailCellRenderer}
            emptyPlaceholderComponent={
              <Stack
                alignItems='center'
                data-testid='no-data-parent-account-orders-table'
                justifyContent='center'
                sx={{ height: '100%' }}>
                <CardPlaceholder
                  icon={<SearchIcon fontSize='inherit' />}
                  subtitle='No results found.'
                />
              </Stack>
            }
            filterSidePanelComponent={
              !hideFilters ? (
                <Formik
                  initialValues={{
                    cusip: '',
                    endDate: dayjs().format('YYYY-MM-DD'),
                    orderStatus: [],
                    startDate: dayjs()
                      .subtract(1, 'month')
                      .format('YYYY-MM-DD'),
                    tradeType: []
                  }}
                  onSubmit={(values: ParentAccountOrderSearchRequest) =>
                    handleSubmit(values)
                  }>
                  {({
                    isValid,
                    setFieldValue,
                    setFieldError,
                    submitForm,
                    values
                  }) => (
                    <Form data-testid='filter-form'>
                      <Stack
                        alignItems='flex-start'
                        justifyContent='flex-start'
                        spacing={2}>
                        <CusipTickerSearch
                          data-testid='cusip-symbol-input'
                          helperTextPlaceholder
                          initialValue={values.cusip}
                          onChange={value => {
                            setFieldValue('cusip', value);
                            setIsCusipValid(true);
                            if (value === '') submitForm();
                          }}
                          onError={err => {
                            setFieldError('cusip', err.message);
                            setIsCusipValid(false);
                          }}
                          onValidating={value => setIsValidating(value)}
                        />

                        <FormControl size='small' sx={{ width: 240 }}>
                          <InputLabel id='tradeType-label' shrink>
                            Trade Type
                          </InputLabel>
                          <Field
                            MenuProps={{
                              'data-testid': 'menu-tradeType'
                            }}
                            as={Select}
                            displayEmpty
                            input={<OutlinedInput label='Trade Type' notched />}
                            label='Trade Type'
                            labelId='tradeType-label'
                            multiple
                            name='tradeType'
                            renderValue={(selected: TradeType[]) => {
                              if (!selected?.length) {
                                return <>Any</>;
                              }
                              return selected
                                .map(value =>
                                  formatters.getValueKey(TradeType, value)
                                )
                                .join(', ');
                            }}>
                            {Object.values(TradeType).map(value => {
                              const displayTradeType = formatters.getValueKey(
                                TradeType,
                                value
                              );
                              return (
                                <MenuItem key={value} value={value}>
                                  <Checkbox
                                    checked={values.tradeType?.includes(value)}
                                    sx={{ py: 0 }}
                                  />
                                  <ListItemText>
                                    {formatters.displayCase(displayTradeType)}
                                  </ListItemText>
                                </MenuItem>
                              );
                            })}
                          </Field>
                        </FormControl>

                        <FormControl size='small' sx={{ width: 240 }}>
                          <InputLabel id='orderStatus-label' shrink>
                            Status
                          </InputLabel>
                          <Field
                            MenuProps={{
                              'data-testid': 'menu-orderStatus'
                            }}
                            as={Select}
                            displayEmpty
                            input={<OutlinedInput label='Status' notched />}
                            label='Status'
                            labelId='orderStatus-label'
                            multiple
                            name='orderStatus'
                            renderValue={(selected: OrderStatus[]) => {
                              if (!selected?.length) {
                                return <>Any</>;
                              }
                              return (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: 0.5
                                  }}>
                                  {selected.map(value => {
                                    const displayOrderStatus =
                                      formatters.getValueKey(
                                        OrderStatus,
                                        value
                                      );
                                    return (
                                      <Badge
                                        color={orderStatusColorMap[value]}
                                        key={value}
                                        size='small'>
                                        {formatters.displayCase(
                                          displayOrderStatus
                                        )}
                                      </Badge>
                                    );
                                  })}
                                </Box>
                              );
                            }}>
                            {Object.values(OrderStatus)
                              .sort() // close enough to sorting by key so lets call it good
                              .map(value => {
                                const displayOrderStatus =
                                  formatters.getValueKey(OrderStatus, value);
                                return (
                                  <MenuItem key={value} value={value}>
                                    <Checkbox
                                      checked={values.orderStatus?.includes(
                                        value
                                      )}
                                      sx={{ py: 0 }}
                                    />
                                    <ListItemText>
                                      {formatters.displayCase(
                                        displayOrderStatus
                                      )}
                                    </ListItemText>
                                  </MenuItem>
                                );
                              })}
                          </Field>
                        </FormControl>

                        <Divider flexItem textAlign='left'>
                          Trade Date
                        </Divider>

                        <FormControl>
                          <Field
                            as={DatePicker}
                            autoComplete='off'
                            data-testid='startDate'
                            disableFuture
                            label='From'
                            name='startDate'
                            size='small' // FormControl doesn't pass to our DatePicker
                            sx={{ width: 240 }}
                            variant='outlined'
                          />
                        </FormControl>

                        <FormControl>
                          <Field
                            as={DatePicker}
                            autoComplete='off'
                            data-testid='endDate'
                            disableFuture
                            label='To'
                            name='endDate'
                            size='small' // FormControl doesn't pass to our DatePicker
                            sx={{ width: 240 }}
                            variant='outlined'
                          />
                        </FormControl>

                        <Divider flexItem textAlign='left'>
                          Other
                        </Divider>

                        <FormControl sx={{ width: 240 }} variant='outlined'>
                          <Field
                            as={TextField}
                            data-testid='ourParentOrderId-input'
                            name='ourParentOrderId'
                            placeholder='Order ID'
                            size='small'
                          />
                        </FormControl>

                        <FormControl sx={{ width: 240 }} variant='outlined'>
                          <Field
                            as={TextField}
                            data-testid='omsBatchId-input'
                            name='omsBatchId'
                            placeholder='OMS Batch ID'
                            size='small'
                          />
                        </FormControl>

                        <Button
                          data-testid='submit'
                          disabled={!isValid || isValidating || !isCusipValid}
                          type='submit'
                          variant='outlined'>
                          Apply
                        </Button>
                      </Stack>
                    </Form>
                  )}
                </Formik>
              ) : undefined
            }
            onPageChanged={handlePageChanged}
            onPageSizeChanged={handlePageSizeChanged}
            onSortChanged={handleSortChanged}
            page={page}
            pageSize={pageSize}
            pagination
            paginationSource='server'
            paginationTotal={data?.pagination?.total}
            rowData={data?.results || []}
            sort={
              orderBy
                ? [
                    {
                      colId: orderBy,
                      sort: orderByDirection
                    }
                  ]
                : []
            }
          />
        </CardContent>
      </Card>
    </>
  );
};
export default ParentAccountOrders;
