import { TransactionDto } from '@/models/ops/transactions/TransactionDTO.model';
import TransactionService from '@/services/ops/transactions/Transactions.service';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { AccountLevel } from '@vestwell-sub-accounting/models/accountsAndLedgers/AccountLevel';
import { PositionDateType } from '@vestwell-sub-accounting/models/accountsAndLedgers/PositionDateType';
import { TransactionBaseType } from '@vestwell-sub-accounting/models/common/TransactionBaseType';

export const useGetPlanDepositTransactions = (
  cashTransferWorkflowTracerId: string,
  planId: string
): UseQueryResult<(TransactionDto | undefined)[]> => {
  return useQuery(
    [
      'TransactionService.search',
      AccountLevel.ParentAccount,
      cashTransferWorkflowTracerId
    ],
    async () => {
      const parentAccountTransactionId = await TransactionService.search({
        accountLevel: AccountLevel.ParentAccount,
        dateType: PositionDateType.Trade,
        planId: planId,
        tracerId: cashTransferWorkflowTracerId,
        transactionBaseType: [TransactionBaseType.Deposit]
      });

      const subAccountTransactionId = await TransactionService.search({
        accountLevel: AccountLevel.SubAccount,
        dateType: PositionDateType.Trade,
        planId,
        tracerId: cashTransferWorkflowTracerId,
        transactionBaseType: [TransactionBaseType.Deposit]
      });

      return [
        parentAccountTransactionId.results[0],
        subAccountTransactionId.results[0]
      ];
    },
    {
      enabled: Boolean(cashTransferWorkflowTracerId && planId)
    }
  );
};
