export enum DocumentStatuses {
  NEW = 'NEW',
  UPLOAD_SUCCESSFUL = 'UPLOAD_SUCCESSFUL',
  UPLOAD_FAILED = 'UPLOAD_FAILED'
}
export const DOCUMENT_TYPES = {
  CASH_ALLOCATION: 'cashAllocation',
  DEFERRAL_RATES: 'deferralRates',
  HISTORICAL_YEARS_OF_SERVICE: 'historicalYearsOfService',
  INVESTMENTS_FUND_LINEUPS: 'investmentsFundLineups',
  INVESTMENTS_MODELS: 'investmentsModels',
  LOANS: 'loans',
  ROTH_COST_BASIS: 'rothCostBasis'
};

export const DOCUMENT_CATEGORY_IDS = {
  CASH_ALLOCATION: 13,
  DEFERRAL_RATES: 14,
  LOANS: 18,
  ROTH_COST_BASIS: 19
};

export const CONVERSION_TYPES = {
  CASH_ALLOCATION: 'CashAllocation',
  DEFERRAL_RATES: 'DeferralRates',
  HISTORICAL_YEARS_OF_SERVICE: 'HistoricalYearsOfService',
  LOANS: 'LoanConversion',
  ROTH_COST_BASIS: 'RothCostBasis'
};

export const conversionTypeToDocumentTypeMap = new Map<string, string>([
  [CONVERSION_TYPES.CASH_ALLOCATION, DOCUMENT_TYPES.CASH_ALLOCATION],
  [CONVERSION_TYPES.DEFERRAL_RATES, DOCUMENT_TYPES.DEFERRAL_RATES],
  [
    CONVERSION_TYPES.HISTORICAL_YEARS_OF_SERVICE,
    DOCUMENT_TYPES.HISTORICAL_YEARS_OF_SERVICE
  ],
  [CONVERSION_TYPES.LOANS, DOCUMENT_TYPES.LOANS],
  [CONVERSION_TYPES.ROTH_COST_BASIS, DOCUMENT_TYPES.ROTH_COST_BASIS]
]);

export const onboardingDocumentCategoryIdToDocumentTypeMap = new Map<
  number,
  string
>([
  [13, DOCUMENT_TYPES.CASH_ALLOCATION],
  [14, DOCUMENT_TYPES.DEFERRAL_RATES],
  [15, DOCUMENT_TYPES.INVESTMENTS_MODELS],
  [16, DOCUMENT_TYPES.INVESTMENTS_FUND_LINEUPS],
  [17, DOCUMENT_TYPES.HISTORICAL_YEARS_OF_SERVICE],
  [18, DOCUMENT_TYPES.LOANS],
  [19, DOCUMENT_TYPES.ROTH_COST_BASIS]
]);
