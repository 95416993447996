import { DividendActivityQueueDto } from '@/models/ops/dividends/dividend-activity-queue-DTO.model';
import { DividendActivityQueueRequest } from '@/models/ops/dividends/dividend-activity-queue-request.model';
import { DividendParentAccountTransactionDto } from '@/models/ops/dividends/DividendParentAccountTransactionDTO.model';
import { DividendSearchParentAccountTransactionsRequest } from '@/models/ops/dividends/DividendSearchParentAccountTransactionsRequest.model';
import { PaginatedApiResponse } from '@/models/PaginatedApiResponse.model';
import ApiService from '@/services/Api.service';

class DividendService {
  static async searchParentAccountTransactions(
    params: DividendSearchParentAccountTransactionsRequest
  ): Promise<PaginatedApiResponse<DividendParentAccountTransactionDto[]>> {
    try {
      const result = await ApiService.getJson<
        PaginatedApiResponse<DividendParentAccountTransactionDto[]>
      >(`dividends/search-parent-account-transactions`, params);
      return result;
    } catch (err: any) {
      console.error(err);
      /**
       * err.response.data should contain the friendly error message
       * sent from the original data source
       */
      throw err.response?.data ? err.response?.data : err.message;
    }
  }

  static async searchDividendActivityQueue(
    params: DividendActivityQueueRequest
  ) {
    try {
      const result = await ApiService.getJson<
        PaginatedApiResponse<DividendActivityQueueDto[]>
      >(`dividends/search-dividend-activity-queue`, params);
      return result;
    } catch (err: any) {
      console.error(err);
      /**
       * err.response.data should contain the friendly error message
       * sent from the original data source
       */
      throw err.response?.data ? err.response?.data : err.message;
    }
  }
}

export default DividendService;
