import { GroupsWithDates } from '@/routes/plans/plan-detail/PlanDocumentsTab/PlanDocumentsGroup.component';

import * as yup from 'yup';

import formatters from '../Formatters';

export function getBulkUploadPlanDocValidationSchema(
  groups: GroupsWithDates[]
): yup.AnyObjectSchema {
  return yup.object().shape({
    documentKeys: yup
      .array()
      .of(yup.string())
      .required()
      .test(
        'unique-document-keys',
        'Document keys must be unique',
        value => new Set(value).size === value.length
      ),
    effectiveDate: yup
      .string()
      .required()
      .test(
        'effective-date-in-use',
        'This effective date is already in use',
        value => {
          return !groups
            ?.filter(group => group.groupingId > 0 && group.effectiveDate)
            .some(group => {
              return (
                formatters.formatFromIsoDateCustom(
                  group.effectiveDate?.toString(),
                  'MM/DD/YYYY'
                ) === formatters.formatFromIsoDateCustom(value, 'MM/DD/YYYY')
              );
            });
        }
      )
  });
}
