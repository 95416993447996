import { redirectToErrorPage } from '@/components/error-detail/ErrorDetailPage.component';
import LinearLoading from '@/components/linear-loading';
import { useUserToken } from '@/contexts/UserTokenContext';
import { FirmDto } from '@/models';
import FirmDetailsAdvisorsComponent from '@/routes/firms/firm-details/FirmDetailsAdvisors.component';
import FirmDetailsHeader from '@/routes/firms/firm-details/FirmDetailsHeader.component';
import FirmDetailsInfoComponent from '@/routes/firms/firm-details/FirmDetailsInfo.component';
import FirmService from '@/services/Firm.service';
import { Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import React from 'react';
import { useParams } from 'react-router-dom';

type FirmDetailRouteProps = {
  firmId: string;
};

const FirmDetailRoute: React.FC<FirmDetailRouteProps> = () => {
  const { firmId } = useParams<FirmDetailRouteProps>();
  const { userHasValidToken } = useUserToken();

  const firm = useQuery<FirmDto>(
    ['FirmService.getFirmById', firmId],
    async () => {
      return FirmService.getFirmById(firmId);
    },
    {
      enabled: Boolean(firmId && userHasValidToken),
      staleTime: Infinity
    }
  );

  if (firm.error) {
    return redirectToErrorPage(firm.error as Error);
  }

  return (
    <>
      {firm.isFetching && <LinearLoading />}
      {firm.isSuccess && (
        <Grid container data-testid='firm-details' direction='column'>
          <FirmDetailsHeader
            companyName={firm.data?.data?.attributes?.companyName}
            firmId={firm.data?.data?.id}
          />
          <FirmDetailsInfoComponent attributes={firm.data?.data?.attributes} />
          <FirmDetailsAdvisorsComponent firmId={firm.data?.data?.id} />
        </Grid>
      )}
    </>
  );
};

export default FirmDetailRoute;
