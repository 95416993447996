import Badge from '@/components/badge';
import CusipTickerSearch from '@/components/cusip-ticker-search/CusipTickerSearch';
import DatePicker from '@/components/date-picker/DatePicker';
import { useTransactionCodes } from '@/hooks/ops/useTransactionCodes.hook';
import formatters from '@/utils/Formatters';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack
} from '@mui/material';
import { AccountLevel } from '@vestwell-sub-accounting/models/accountsAndLedgers/AccountLevel';
import { PositionDateType } from '@vestwell-sub-accounting/models/accountsAndLedgers/PositionDateType';
import { TransactionStatus } from '@vestwell-sub-accounting/models/accountsAndLedgers/TransactionStatus';
import { TransactionBaseType } from '@vestwell-sub-accounting/models/common/TransactionBaseType';
import { TransactionTypeCode } from '@vestwell-sub-accounting/models/common/TransactionTypeCode';

import { Field, Form, useFormikContext } from 'formik';
import { useState } from 'react';

export type SearchFormValues = {
  cusipOrSymbol: string;
  transactionBaseType: TransactionBaseType[];
  transactionTypeCode: TransactionTypeCode | '';
  status: TransactionStatus[];
  dateType: PositionDateType | '';
  beginningDate?: string;
  endingDate: string;
  transferSubAccountId: string | null;
  amountFrom: string | null;
  amountTo: string | null;
  unitsFrom: string | null;
  unitsTo: string | null;
  sourceTransactionId: string | null;
  tracerId: string | null;
};

type TransactionsFiltersFormProps = {
  accountLevel: AccountLevel;
};
export const TransactionsFiltersForm = ({
  accountLevel
}: TransactionsFiltersFormProps): JSX.Element => {
  const { setFieldValue, setFieldError, submitForm, values } =
    useFormikContext<SearchFormValues>();
  const [isCusipValid, setIsCusipValid] = useState(true);
  const [isValidating, setIsValidating] = useState(false);

  const { data: transactionCodes, isFetching: isFetchingTransactionCodes } =
    useTransactionCodes({
      transactionBaseType: values.transactionBaseType
    });

  return (
    <Form data-testid='filter-form'>
      <Stack alignItems='flex-start' justifyContent='flex-start' spacing={2}>
        <CusipTickerSearch
          data-testid='cusip-symbol-input'
          helperTextPlaceholder
          initialValue={values.cusipOrSymbol}
          onChange={value => {
            setFieldValue('cusipOrSymbol', value);
            setIsCusipValid(true);
            // if field is cleared submit the form to refresh results
            if (value === '') submitForm();
          }}
          onError={err => {
            setFieldError('cusipOrSymbol', err.message);
            setIsCusipValid(false);
          }}
          onValidating={setIsValidating}
        />

        <FormControl fullWidth size='small' variant='outlined'>
          <InputLabel id='tracer-id-input-label'>Tracer ID</InputLabel>
          <Field
            as={OutlinedInput}
            data-testid='tracer-id-input'
            label='Tracer ID'
            name='tracerId'
          />
        </FormControl>

        <FormControl fullWidth size='small' sx={{ width: 240 }}>
          <InputLabel id='menu-status-label' shrink>
            Status
          </InputLabel>
          <Field
            MenuProps={{
              'data-testid': 'menu-status',
              label: 'Status Menu'
            }}
            as={Select}
            data-testid='query-status'
            displayEmpty
            input={<OutlinedInput label='Status' notched />}
            label='Status'
            labelId='menu-status-label'
            multiple
            name='status'
            renderValue={(selected: TransactionStatus[]) => {
              if (selected.length === 0) {
                return <>Any</>;
              }

              return (
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 0.5
                  }}>
                  {selected.map(value => {
                    return (
                      <Badge
                        color={
                          {
                            CANCELLED: 'neutral',
                            CONFIRMED: 'success',
                            PENDING: 'neutral',
                            REVERSED: 'info'
                          }[value] as 'neutral' | 'success'
                        }
                        key={value}
                        size='small'>
                        {formatters.capitalizeFirstChar(value.toLowerCase())}
                      </Badge>
                    );
                  })}
                </Box>
              );
            }}>
            {Object.values(TransactionStatus).map(value => (
              <MenuItem key={value} value={value}>
                <Checkbox
                  checked={values.status.includes(value)}
                  sx={{ py: 0 }}
                />
                <ListItemText>
                  {formatters.capitalizeFirstChar(value.toLowerCase())}
                </ListItemText>
              </MenuItem>
            ))}
          </Field>
        </FormControl>

        <Divider flexItem textAlign='left'>
          Type
        </Divider>

        <FormControl fullWidth size='small' sx={{ width: 240 }}>
          <InputLabel id='menu-transactionBaseType-label' shrink>
            Base Type
          </InputLabel>
          <Field
            MenuProps={{
              'data-testid': 'menu-transactionBaseType',
              label: 'Base Type Menu'
            }}
            as={Select}
            data-testid='query-transactionBaseType'
            displayEmpty
            input={<OutlinedInput label='Base Type' notched />}
            label='Base Type'
            labelId='menu-transactionBaseType-label'
            multiple
            name='transactionBaseType'
            renderValue={(selected: TransactionBaseType[]) => {
              if (selected.length === 0) {
                return <>Any</>;
              }

              return selected
                .map(value => {
                  return formatters.getValueKey(TransactionBaseType, value);
                })
                .join(', ');
            }}>
            {Object.values(TransactionBaseType).map(value => {
              const displayBaseType = formatters.getValueKey(
                TransactionBaseType,
                value
              );
              return (
                <MenuItem key={value} value={value}>
                  <Checkbox
                    checked={values.transactionBaseType.includes(value)}
                    sx={{ py: 0 }}
                  />
                  <ListItemText>
                    {formatters.displayCase(displayBaseType)}
                  </ListItemText>
                </MenuItem>
              );
            })}
          </Field>
        </FormControl>

        <FormControl fullWidth size='small'>
          <InputLabel id='menu-transactionTypeCode-label' shrink>
            Transaction Type
          </InputLabel>
          <Field
            MenuProps={{
              'data-testid': 'menu-transactionTypeCode'
            }}
            as={Select}
            data-testid='query-transactionTypeCode'
            disabled={isFetchingTransactionCodes || !transactionCodes}
            displayEmpty
            input={<OutlinedInput label='Transaction Type' notched />}
            label='Transaction Type'
            labelId='menu-transactionTypeCode-label'
            name='transactionTypeCode'>
            <MenuItem value=''>Any</MenuItem>
            {transactionCodes.map(value => (
              <MenuItem
                key={value.transactionTypeCode}
                value={value.transactionTypeCode}>
                {formatters.displayCase(value.transactionTypeCode)}
              </MenuItem>
            ))}
          </Field>
        </FormControl>

        <Divider flexItem textAlign='left'>
          Date
        </Divider>

        <FormControl fullWidth size='small'>
          <InputLabel id='menu-dateType-label' shrink>
            Type
          </InputLabel>
          <Field
            MenuProps={{
              'data-testid': 'menu-dateType'
            }}
            as={Select}
            data-testid='query-dateType'
            displayEmpty
            label='Type'
            labelId='menu-dateType-label'
            name='dateType'>
            {Object.values(PositionDateType).map(value => (
              <MenuItem key={value} value={value}>
                {formatters.capitalizeFirstChar(value.toLowerCase())}
              </MenuItem>
            ))}
          </Field>
        </FormControl>

        <FormControl fullWidth>
          <Field
            as={DatePicker}
            data-testid='beginning-date-input'
            label='From'
            name='beginningDate'
            size='small'
            variant='outlined'
          />
        </FormControl>

        <FormControl fullWidth>
          <Field
            as={DatePicker}
            data-testid='ending-date-input'
            label='To'
            name='endingDate'
            size='small'
            variant='outlined'
          />
        </FormControl>

        <Divider flexItem id='amount-group-label' textAlign='left'>
          Amount
        </Divider>

        <FormControl fullWidth size='small' variant='outlined'>
          <InputLabel id='amount-from-input-label'>From</InputLabel>
          <Field
            as={OutlinedInput}
            inputProps={{
              'aria-labelledby': 'amount-group-label amount-from-input-label',
              'data-testid': 'amount-from-input',
              step: '0.01'
            }}
            label='From'
            name='amountFrom'
            startAdornment={<InputAdornment position='start'>$</InputAdornment>}
            type='number'
          />
        </FormControl>

        <FormControl fullWidth size='small' variant='outlined'>
          <InputLabel id='amount-to-input-label'>To</InputLabel>
          <Field
            as={OutlinedInput}
            inputProps={{
              'aria-labelledby': 'amount-group-label amount-to-input-label',
              'data-testid': 'amount-to-input',
              step: '0.01'
            }}
            label='To'
            name='amountTo'
            startAdornment={<InputAdornment position='start'>$</InputAdornment>}
            type='number'
          />
        </FormControl>

        <Divider flexItem id='units-group-label' textAlign='left'>
          Units
        </Divider>

        <FormControl fullWidth size='small' variant='outlined'>
          <InputLabel id='units-from-input-label'>From</InputLabel>
          <Field
            as={OutlinedInput}
            inputProps={{
              'aria-labelledby': 'units-group-label units-from-input-label',
              'data-testid': 'units-from-input',
              step: '0.001'
            }}
            label='From'
            name='unitsFrom'
            type='number'
          />
        </FormControl>

        <FormControl fullWidth size='small' variant='outlined'>
          <InputLabel id='units-to-input-label'>To</InputLabel>
          <Field
            as={OutlinedInput}
            inputProps={{
              'aria-labelledby': 'units-group-label units-to-input-label',
              'data-testid': 'units-to-input',
              step: '0.01'
            }}
            label='To'
            name='unitsTo'
            type='number'
          />
        </FormControl>

        <Divider flexItem textAlign='left'>
          Other Filters
        </Divider>
        {accountLevel === AccountLevel.SubAccount && (
          <FormControl fullWidth size='small' variant='outlined'>
            <InputLabel htmlFor='transferSubAccountId'>
              Transfer Sub Account ID
            </InputLabel>
            <Field
              as={OutlinedInput}
              data-testid='transfer-sub-account-id-input'
              label='Transfer Sub Account ID'
              name='transferSubAccountId'
            />
          </FormControl>
        )}
        <FormControl fullWidth size='small' variant='outlined'>
          <InputLabel htmlFor='sourceTransactionId'>
            Source Transaction ID
          </InputLabel>
          <Field
            as={OutlinedInput}
            data-testid='sourceTransactionId-input'
            label='Source Transaction ID'
            name='sourceTransactionId'
          />
        </FormControl>

        <Button
          data-testid='filter-form-apply-button'
          disabled={isValidating || !isCusipValid}
          type='submit'
          variant='outlined'>
          Apply
        </Button>
      </Stack>
    </Form>
  );
};
