import AccessControl from '@/components/access-control/AccessControl.component';
import CollapsibleTable from '@/components/collapsible-table';
import { CreateAdvisorModal } from '@/components/entity-modals/EntityModals.component';
import { AdvisorByFirmDto, AdvisorsByFirmDto, FirmDto } from '@/models';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import FirmDetailsAdvisorsTableCellComponent from '@/routes/firms/firm-details/FirmDetailsAdvisorsTableCell.component';
import FirmService from '@/services/Firm.service';
import { Search } from '@mui/icons-material';
import { Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import React, { useState } from 'react';
import { useDebounce } from 'use-debounce';

interface FirmDetailsAdvisorsProps {
  firmId: FirmDto['data']['id'];
}

const FirmDetailsAdvisors: React.FC<FirmDetailsAdvisorsProps> = ({
  firmId
}) => {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(25);
  const [filter, setFilter] = useState('');

  const [debouncedFilter] = useDebounce(filter, 300);

  const advisors = useQuery<AdvisorsByFirmDto>(
    ['FirmService.getFirmAdvisors', firmId, page, size, debouncedFilter],
    async () => {
      return FirmService.getFirmAdvisors({
        filter: debouncedFilter,
        firmId,
        page,
        size
      });
    },
    {
      keepPreviousData: true,
      staleTime: Infinity
    }
  );

  const columns: GridColDef<AdvisorByFirmDto>[] = [
    {
      field: 'advisorId',
      headerName: 'Advisor ID'
    },
    {
      field: 'name',
      headerName: 'Advisor Name'
    },
    {
      field: 'email',
      headerName: 'Email'
    },
    {
      field: 'registrationStatus',
      headerName: 'Registration Status'
    },
    {
      field: 'createdAt',
      headerName: 'Creation Date'
    },
    {
      field: 'actions',
      headerName: 'Actions'
    }
  ];

  return (
    <Grid container>
      <Grid
        border='1px solid rgba(0, 0, 0, 0.12)'
        borderRadius='0.25rem 0.25rem'
        item
        mb='1.5rem'
        xs={12}>
        <Grid container direction='column'>
          <Grid item>
            <Grid
              borderBottom='1px solid rgba(0, 0, 0, 0.12)'
              container
              justifyContent='space-between'
              p='1rem'>
              <Typography variant='h5'>All Advisors</Typography>
              <AccessControl requires={[FeatureLevelPermissions.WRITE_ADVISOR]}>
                <CreateAdvisorModal firmId={firmId} />
              </AccessControl>
            </Grid>
          </Grid>

          <Grid item>
            <Grid container>
              <Grid item lg={4} md={6} p='0.5rem 1rem 1rem 1rem' xs={12}>
                <TextField
                  InputProps={{
                    'aria-placeholder': 'Search by id, name email, status',
                    onChange: event => {
                      setFilter(event.target.value || '');
                    },
                    placeholder: 'Search by id, name email, status',
                    startAdornment: (
                      <InputAdornment position='start'>
                        <Search />
                      </InputAdornment>
                    )
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <CollapsibleTable
              backgroundPaperElevation={0}
              cellComponent={FirmDetailsAdvisorsTableCellComponent}
              columns={columns}
              pager={{
                metaCount: advisors.data?.totalCount || 0,
                onPageNumberChanged: (pageNumber: number) => {
                  return setPage(pageNumber + 1);
                },
                onRowsPerPageChanged: (rowsPerPage: number) => {
                  return setSize(rowsPerPage);
                },
                pageNumber: page - 1,
                rowsPerPage: size
              }}
              primaryKey='advisorId'
              tableData={advisors.data?.advisors || []}
              useSquareBottomContainer
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FirmDetailsAdvisors;
