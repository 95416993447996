import Badge from '@/components/badge';
import Card, {
  CardContent,
  CardHeader,
  CardPlaceholder
} from '@/components/card';
import DataTable, {
  DataTableBadgeCell,
  DataTableStackCell
} from '@/components/data-table/DataTable.component';
import DatePicker from '@/components/date-picker';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { executionStatusColorMap } from '@/models/ops/workflows/ExecutionStatusColorMap.model';
import { TradeRequest } from '@/models/ops/workflows/TradeRequest.model';
import { TradeRequestWorkflowVariations } from '@/models/ops/workflows/TradeRequestWorkflowVariations.model';
import { WorkflowDto } from '@/models/ops/workflows/WorkflowDTO.model';
import { WorkflowSearchRequest } from '@/models/ops/workflows/WorkflowSearchRequest.model';
import { workflowStatusColorMap } from '@/models/ops/workflows/WorkflowStatusColorMap.model';
import { WorkflowStatusEnumsObject } from '@/models/ops/workflows/WorkflowStatusEnumObject.model';
import WorkflowService from '@/services/ops/workflows/Workflow.service';
import formatters from '@/utils/Formatters';
import { useUrlStateParams } from '@/utils/Url';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { AccountLevel } from '@vestwell-sub-accounting/models/accountsAndLedgers/AccountLevel';
import { SubAccountType } from '@vestwell-sub-accounting/models/common/SubAccountType';
import { WorkflowExecutionStatusEnum } from '@vestwell-sub-accounting/models/conductor/WorkflowExecutionStatus.model';
import { WorkflowName } from '@vestwell-sub-accounting/models/conductor/WorkflowName.model';
import { WorkflowStatus as WorkflowStatusEnums } from '@vestwell-sub-accounting/models/conductor/WorkflowStatus.model';

import { ColDef } from 'ag-grid-community';
import dayjs from 'dayjs';
import { Field, Form, Formik } from 'formik';

export const parentAccountOnlyWorkflowTypes = [
  WorkflowName.newPlan,
  WorkflowName.investmentUpdate
];

type WorkflowSearchFormValues = WorkflowSearchRequest & {
  // this used to provide a single dropdown that controls both workflowName and tradeCalculatorMethod query params
  aggregateWorkflowTypeFilter?:
    | (WorkflowName | TradeRequestWorkflowVariations)[]
    | undefined;
};

type ConductorProps = {
  accountId: string;
  accountLevel?: AccountLevel;
  subAccountType?: SubAccountType;
};

const Conductor = ({
  accountId,
  accountLevel = AccountLevel.ParentAccount,
  subAccountType
}: ConductorProps): JSX.Element => {
  const { showSnackbar } = useSnackbar();

  const defaultQueryParams: WorkflowSearchRequest = {
    endDate: dayjs().format('YYYY-MM-DD'),
    startDate: dayjs().subtract(1, 'month').format('YYYY-MM-DD')
  };

  const [query, setQuery] = useUrlStateParams<WorkflowSearchRequest>(
    defaultQueryParams,
    'query',
    value => {
      // remove any values that are the same as the default
      const cleanedQuery = formatters.objectDiff<WorkflowSearchRequest>(
        value,
        defaultQueryParams
      );
      return JSON.stringify(cleanedQuery) !== '{}' // only add query if it's not empty
        ? encodeURIComponent(JSON.stringify(cleanedQuery))
        : '';
    },
    value => {
      try {
        const urlQuery = JSON.parse(decodeURIComponent(value));
        return {
          ...defaultQueryParams,
          ...urlQuery
        };
      } catch {
        return defaultQueryParams;
      }
    }
  );

  const [page, setPage] = useUrlStateParams(
    1,
    'page',
    value => String(value),
    value => (!isNaN(Number(value)) ? Number(value) : 1)
  );
  const [pageSize, setPageSize] = useUrlStateParams(
    25,
    'pageSize',
    value => String(value),
    value => (!isNaN(Number(value)) ? Number(value) : 25)
  );
  const [orderBy, setOrderBy] = useUrlStateParams<
    keyof WorkflowDto | undefined
  >(
    undefined,
    'orderBy',
    value => String(value),
    value => (value ? (value as keyof WorkflowDto) : undefined)
  );
  const [orderByDirection, setOrderByDirection] = useUrlStateParams<
    'asc' | 'desc' | undefined
  >(
    undefined,
    'orderByDirection',
    value => String(value),
    value => (value === 'asc' || value === 'desc' ? value : undefined)
  );

  const workflowSearchQuery = useQuery(
    [
      'WorkflowService.search',
      accountId,
      query,
      page,
      pageSize,
      orderBy,
      orderByDirection
    ],
    () => {
      const queryWithPagination = {
        ...query,
        accountId,
        endDate: query.endDate || undefined,
        page,
        pageSize,
        // if blank then don't pass it
        startDate: query.startDate || undefined,
        tracerId: query.tracerId || undefined
      };
      if (orderBy) {
        queryWithPagination.orderBy = orderBy;
      }
      if (orderByDirection) {
        queryWithPagination.orderByDirection = orderByDirection;
      }
      return WorkflowService.search(queryWithPagination);
    },
    {
      keepPreviousData: true,
      onError: (err: any) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Workflow search failed: ${message}`,
          severity: 'error'
        });
      },
      staleTime: Infinity
    }
  );

  const columnDefs: ColDef[] = [
    {
      autoHeight: true,
      cellRenderer: (cellData: { data: WorkflowDto }) => {
        return (
          <DataTableStackCell
            primary={
              cellData.data.workflowName === WorkflowName.tradeRequest
                ? formatters.displayCase(
                    `${cellData.data.workflowName} - ${
                      (cellData.data.input as TradeRequest)
                        ?.tradeCalculatorMethod
                    }`
                  )
                : formatters.displayCase(cellData.data.workflowName)
            }
            primaryLinkProps={{
              to: `/ops/workflows/${cellData.data.id}`
            }}
            secondary={`Tracer ID: ${cellData.data.tracerId}`}
          />
        );
      },
      field: 'workflowName',
      headerName: 'Request',
      sortable: true
    },
    {
      autoHeight: true,
      cellRenderer: (cellData: { data: WorkflowDto }) => {
        // fetch friendly display name for status
        const displayExecutionStatus = formatters.getValueKey(
          WorkflowExecutionStatusEnum,
          cellData.data.executionStatus
        );

        return (
          <DataTableBadgeCell
            color={executionStatusColorMap[cellData.data.executionStatus]}>
            {formatters.displayCase(displayExecutionStatus)}
          </DataTableBadgeCell>
        );
      },
      field: 'executionStatus',
      headerName: 'Execution Status',
      sortable: true
    },
    {
      autoHeight: true,
      cellRenderer: (cellData: { data: WorkflowDto }) => {
        const displayWorkflowStatus = formatters.getValueKey(
          WorkflowStatusEnumsObject,
          cellData.data.workflowStatus
        );
        return (
          <>
            {cellData.data.workflowStatus && (
              <DataTableBadgeCell
                color={workflowStatusColorMap[cellData.data.workflowStatus]}>
                {formatters.displayCase(displayWorkflowStatus)}
              </DataTableBadgeCell>
            )}
          </>
        );
      },
      field: 'workflowStatus',
      headerName: 'Workflow Status',
      sortable: true
    },
    {
      field: 'updatedAt',
      headerName: 'Updated At',
      sortable: true,
      valueFormatter: ({ value }: { value: string }) =>
        formatters.formatFromIsoDate(value)
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      sortable: true,
      valueFormatter: ({ value }: { value: string }) =>
        formatters.formatFromIsoDate(value)
    }
  ];

  const handleSubmit = (formData: WorkflowSearchFormValues) => {
    // set page back to 1 when the query changes, because you can get into a bad state where you are 5 pages in
    // and the new query only has 1 page of results and the UI will show no results with no way to change the page
    setPage(1);
    setQuery({ ...formData });
  };

  const handleSortChanged = (
    newSort: { colId: string; sort?: 'asc' | 'desc' }[]
  ) => {
    if (!newSort || newSort.length === 0) {
      setOrderBy(undefined);
      setOrderByDirection(undefined);
    } else {
      setOrderBy(newSort[0].colId as keyof WorkflowDto);
      setOrderByDirection(newSort[0].sort);
    }
  };

  const handlePageChanged = (newPage: number) => {
    setPage(newPage);
  };

  const handlePageSizeChanged = (newPageSize: number) => {
    setPageSize(newPageSize);
  };

  return (
    <Card>
      <CardHeader
        data-testid='conductor-header'
        loading={
          !workflowSearchQuery.isInitialLoading &&
          workflowSearchQuery.isFetching
        }
        title='Conductor Requests'
      />
      <CardContent
        disablePadding
        overlayLoading={workflowSearchQuery.isInitialLoading}>
        <DataTable
          columnDefs={columnDefs}
          data-testid='data-workflows-table'
          emptyPlaceholderComponent={
            <Stack
              alignItems='center'
              data-testid='no-data-workflows-table'
              justifyContent='center'
              sx={{ height: '100%' }}>
              <CardPlaceholder
                icon={<SearchIcon fontSize='inherit' />}
                subtitle='No results found.'
              />
            </Stack>
          }
          filterSidePanelComponent={
            <Formik
              initialValues={{
                ...query,
                executionStatus: Array.isArray(query.executionStatus)
                  ? query.executionStatus
                  : (query.executionStatus?.split(
                      ','
                    ) as WorkflowExecutionStatusEnum[]) || [],
                workflowName: Array.isArray(query.workflowName)
                  ? query.workflowName
                  : (query.workflowName?.split(',') as WorkflowName[]) || [],
                workflowStatus: Array.isArray(query.workflowStatus)
                  ? query.workflowStatus
                  : (query.workflowStatus?.split(
                      ','
                    ) as WorkflowStatusEnums[]) || []
              }}
              onSubmit={(values: WorkflowSearchFormValues) =>
                handleSubmit(values)
              }>
              {({ values }) => (
                <Form data-testid='filter-form'>
                  <Stack
                    alignItems='flex-start'
                    justifyContent='flex-start'
                    spacing={2}>
                    <FormControl sx={{ width: 240 }} variant='outlined'>
                      <Field
                        InputProps={{
                          endAdornment: <SearchIcon />
                        }}
                        as={TextField}
                        data-testid='tracerId-input'
                        name='tracerId'
                        placeholder='Enter a Tracer ID'
                        size='small'
                      />
                    </FormControl>

                    <FormControl size='small' sx={{ width: 240 }}>
                      <InputLabel id='aggregateWorkflowTypeFilter-label' shrink>
                        Type
                      </InputLabel>
                      <Field
                        MenuProps={{
                          'data-testid': 'menu-aggregateWorkflowTypeFilter'
                        }}
                        as={Select}
                        displayEmpty
                        input={<OutlinedInput label='Type' notched />}
                        label='Type'
                        labelId='aggregateWorkflowTypeFilter-label'
                        multiple
                        name='workflowName'
                        renderValue={(selected: WorkflowName[]) => {
                          if (!selected?.length) {
                            return <>Any</>;
                          }
                          return (
                            <Box
                              sx={{
                                whiteSpace: 'normal'
                              }}>
                              {selected.map((value, valueIndex) => {
                                const displayWorkflowName =
                                  formatters.getValueKey(WorkflowName, value);
                                return `${formatters.displayCase(
                                  displayWorkflowName
                                )}${
                                  valueIndex < selected.length - 1 ? ', ' : ''
                                }`;
                              })}
                            </Box>
                          );
                        }}>
                        {Object.values(WorkflowName)
                          .sort()
                          .map(value => {
                            if (
                              accountLevel === AccountLevel.SubAccount &&
                              parentAccountOnlyWorkflowTypes.includes(value)
                            ) {
                              // don't show the parent only options for sub accounts
                              return null;
                            }
                            if (
                              accountLevel === AccountLevel.SubAccount &&
                              value === WorkflowName.depositRequest &&
                              subAccountType !== SubAccountType.moneyIn
                            ) {
                              // Deposit Request - only if the subAccount.accountType = moneyIn
                              return null;
                            }
                            const displayWorkflowName = formatters.getValueKey(
                              WorkflowName,
                              value
                            );
                            return (
                              <MenuItem key={value} value={value}>
                                <Checkbox
                                  checked={values.workflowName?.includes(value)}
                                  sx={{ py: 0 }}
                                />
                                <ListItemText>
                                  {formatters.displayCase(displayWorkflowName)}
                                </ListItemText>
                              </MenuItem>
                            );
                          })}
                      </Field>
                    </FormControl>

                    <FormControl size='small' sx={{ width: 240 }}>
                      <InputLabel id='executionStatus-label' shrink>
                        Execution Status
                      </InputLabel>
                      <Field
                        MenuProps={{
                          'data-testid': 'menu-executionStatus'
                        }}
                        as={Select}
                        displayEmpty
                        input={
                          <OutlinedInput label='Execution Status' notched />
                        }
                        label='Execution Status'
                        labelId='executionStatus-label'
                        multiple
                        name='executionStatus'
                        renderValue={(
                          selected: WorkflowExecutionStatusEnum[]
                        ) => {
                          if (!selected?.length) {
                            return <>Any</>;
                          }
                          return (
                            <Box
                              sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 0.5
                              }}>
                              {selected.map(value => {
                                const displayExecutionStatus =
                                  formatters.getValueKey(
                                    WorkflowExecutionStatusEnum,
                                    value
                                  );
                                return (
                                  <Badge
                                    color={executionStatusColorMap[value]}
                                    key={value}
                                    size='small'>
                                    {formatters.displayCase(
                                      displayExecutionStatus
                                    )}
                                  </Badge>
                                );
                              })}
                            </Box>
                          );
                        }}>
                        {Object.values(WorkflowExecutionStatusEnum).map(
                          value => {
                            const displayStatus = formatters.getValueKey(
                              WorkflowExecutionStatusEnum,
                              value
                            );
                            return (
                              <MenuItem key={value} value={value}>
                                <Checkbox
                                  checked={values.executionStatus?.includes(
                                    value
                                  )}
                                  sx={{ py: 0 }}
                                />
                                <ListItemText>
                                  {formatters.displayCase(displayStatus)}
                                </ListItemText>
                              </MenuItem>
                            );
                          }
                        )}
                      </Field>
                    </FormControl>

                    <FormControl size='small' sx={{ width: 240 }}>
                      <InputLabel id='workflowStatus-label' shrink>
                        Workflow Status
                      </InputLabel>
                      <Field
                        MenuProps={{
                          'data-testid': 'menu-workflowStatus'
                        }}
                        as={Select}
                        displayEmpty
                        input={
                          <OutlinedInput label='Workflow Status' notched />
                        }
                        label='Workflow Status'
                        labelId='workflowStatus-label'
                        multiple
                        name='workflowStatus'
                        renderValue={(selected: WorkflowStatusEnums[]) => {
                          if (!selected?.length) {
                            return <>Any</>;
                          }
                          return (
                            <Box
                              sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 0.5
                              }}>
                              {selected.map(value => {
                                const displayWorkflowStatus =
                                  formatters.getValueKey(
                                    WorkflowStatusEnumsObject,
                                    value
                                  );
                                return (
                                  <Badge
                                    color={workflowStatusColorMap[value]}
                                    key={value}
                                    size='small'>
                                    {formatters.displayCase(
                                      displayWorkflowStatus
                                    )}
                                  </Badge>
                                );
                              })}
                            </Box>
                          );
                        }}>
                        {Object.values(WorkflowStatusEnumsObject)
                          .sort() // close enough to sorting by key so lets call it good
                          .map(value => {
                            const displayWorkflowStatus =
                              formatters.getValueKey(
                                WorkflowStatusEnumsObject,
                                value
                              );
                            return (
                              <MenuItem key={value} value={value}>
                                <Checkbox
                                  checked={values.workflowStatus?.includes(
                                    value
                                  )}
                                  sx={{ py: 0 }}
                                />
                                <ListItemText>
                                  {formatters.displayCase(
                                    displayWorkflowStatus
                                  )}
                                </ListItemText>
                              </MenuItem>
                            );
                          })}
                      </Field>
                    </FormControl>

                    <FormControl>
                      <Field
                        as={DatePicker}
                        autoComplete='off'
                        data-testid='startDate'
                        disableFuture
                        label='From'
                        name='startDate'
                        size='small' // FormControl doesn't pass to our DatePicker
                        sx={{ width: 240 }}
                        variant='outlined'
                      />
                    </FormControl>

                    <FormControl>
                      <Field
                        as={DatePicker}
                        autoComplete='off'
                        data-testid='endDate'
                        disableFuture
                        label='To'
                        name='endDate'
                        size='small' // FormControl doesn't pass to our DatePicker
                        sx={{ width: 240 }}
                        variant='outlined'
                      />
                    </FormControl>

                    <Button
                      data-testid='submit'
                      type='submit'
                      variant='outlined'>
                      Apply
                    </Button>
                  </Stack>
                </Form>
              )}
            </Formik>
          }
          onPageChanged={handlePageChanged}
          onPageSizeChanged={handlePageSizeChanged}
          onSortChanged={handleSortChanged}
          page={page}
          pageSize={pageSize}
          pagination
          paginationSource='server'
          paginationTotal={workflowSearchQuery.data?.pagination?.total}
          rowData={workflowSearchQuery.data?.results || []}
          sort={
            orderBy
              ? [
                  {
                    colId: orderBy,
                    sort: orderByDirection
                  }
                ]
              : []
          }
        />
      </CardContent>
    </Card>
  );
};
export default Conductor;
