import {
  FUND,
  MANAGED,
  RISK,
  TARGET
} from '@/models/ops/investments/Program.model';
import InvestmentService, { ModelType } from '@/services/Investment.service';
import { Button, Theme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation } from '@tanstack/react-query';

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';

import ModelInformationEditFields from './models/ModelInformationEditFields.component';

const useStyles = makeStyles((theme: Theme) => ({
  actions: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2)
  },
  fieldContainer: {
    marginBottom: theme.spacing(5)
  },
  radioGroup: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  textfield: {
    width: '100%'
  }
}));

interface ModelModalProps {
  open: boolean;
  onClose: () => void;
}

const getModelType = (model: ModelType) => {
  switch (model) {
    case TARGET:
      return 'target-series';
    case FUND:
      return 'fund-lineup';
    case RISK:
      return 'risk-series';
    case MANAGED:
    default:
      return 'managed-account';
  }
};

const ModelModal = (props: ModelModalProps): JSX.Element => {
  const { open, onClose } = props;
  const [selection, setSelection] = useState<ModelType>(FUND);
  const [isNextStep, toggleNextStep] = useToggle(false);
  const [modelSeriesName, setModelSeriesName] = useState('');
  const [modelSeriesDescription, setModelSeriesDescription] = useState('');
  const navigate = useNavigate();

  const classes = useStyles();

  useEffect(() => {
    if (!open) {
      toggleNextStep(false);
      setModelSeriesName('');
      setModelSeriesDescription('');
    }
  }, [open, toggleNextStep]);

  const { mutateAsync: submitModelData, isLoading: isSubmitting } = useMutation(
    ['InvestmentService.postModel'],
    () => {
      const goeAccountId = selection === MANAGED ? 1 : undefined;
      return InvestmentService.postModelSeries(selection, {
        description: modelSeriesDescription,
        goeAccountId,
        name: modelSeriesName
      });
    }
  );

  const handleCreate = async () => {
    const res = await submitModelData();
    navigate(
      `/ops/investments/${getModelType(selection)}/${
        res.fundLineupId ||
        res.goalSeriesId ||
        res.riskSeriesId ||
        res.targetSeriesId
      }`
    );
    onClose();
  };

  return (
    <Dialog
      fullWidth
      maxWidth='sm'
      onClose={() => (isSubmitting ? () => {} : onClose())}
      open={open}>
      <DialogTitle>{`Create ${
        isNextStep ? selection : 'New Investment Types'
      }`}</DialogTitle>
      <DialogContent>
        {isNextStep ? (
          <ModelInformationEditFields
            description={modelSeriesDescription}
            isSubmitting={isSubmitting}
            name={modelSeriesName}
            onDescriptionChange={setModelSeriesDescription}
            onNameChange={setModelSeriesName}
          />
        ) : (
          <FormControl>
            <RadioGroup
              className={classes.radioGroup}
              name='create-new'
              onChange={(event: { currentTarget: { value: ModelType } }) => {
                setSelection(event.currentTarget.value);
              }}
              value={selection}>
              <FormControlLabel
                control={<Radio data-testid='createFundLineupRadio' />}
                label={FUND}
                value={FUND}
              />
              <FormControlLabel
                control={<Radio data-testid='createTargetRadio' />}
                label={TARGET}
                value={TARGET}
              />
              <FormControlLabel
                control={<Radio data-testid='createRiskRadio' />}
                label={RISK}
                value={RISK}
              />
              <FormControlLabel
                control={<Radio data-testid='createMangedAccountsRadio' />}
                label={MANAGED}
                value={MANAGED}
              />
            </RadioGroup>
          </FormControl>
        )}
      </DialogContent>

      <DialogActions sx={{ padding: '1.5rem' }}>
        <Button color='primary' disableRipple onClick={onClose} variant='text'>
          Cancel
        </Button>
        <Button
          aria-label={isNextStep ? `Create ` : 'Continue'}
          color='primary'
          disableRipple
          disabled={isSubmitting || (isNextStep && !modelSeriesName)}
          onClick={() => (isNextStep ? handleCreate() : toggleNextStep(true))}
          variant='contained'>
          {isNextStep ? `Create` : 'Continue'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModelModal;
