import Badge from '@/components/badge';
import Card, {
  CardContent,
  CardHeader,
  CardPlaceholder
} from '@/components/card';
import CircularLoading from '@/components/circular-loading';
import Comments from '@/components/comments/Comments.component';
import CopyToClipboard from '@/components/copy-to-clipboard';
import { redirectToErrorPage } from '@/components/error-detail/ErrorDetailPage.component';
import OpenInNewIcon from '@/components/icon/OpenInNewIcon';
import JSONViewer from '@/components/json-viewer';
import LinearLoading from '@/components/linear-loading';
import Link from '@/components/link/Link.component';
import NavigationBreadcrumbs from '@/components/navigation-breadcrumbs';
import SimpleTable from '@/components/simple-table';
import { SimpleTableColumnDef } from '@/components/simple-table/SimpleTable.component';
import SimpleTabs, { SimpleTabsProps, TabData } from '@/components/simple-tabs';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { BreakageStatusColorMap } from '@/models/ops/recon/BreakageStatusColorMap.model';
import { UpdateReconExceptionRequest } from '@/models/ops/recon/UpdateReconExceptionRequest.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import RelatedReconExceptions from '@/routes/ops/recon/common/RelatedExceptions.component';
import ToggleFreezeDialog from '@/routes/ops/recon/common/ToggleFreezeReconExceptionDialog.component';
import AuthZService from '@/services/AuthZ.service';
import ReconExceptionService from '@/services/ops/recon-exceptions/ReconException.service';
import { userService } from '@/services/User.service';
import formatters from '@/utils/Formatters';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AccountLevel } from '@vestwell-sub-accounting/models/accountsAndLedgers/AccountLevel';
import { CommentType } from '@vestwell-sub-accounting/models/conductor/CommentType';
import { BreakageDataType } from '@vestwell-sub-accounting/models/recon/BreakageDataType';
import { BreakageProcess } from '@vestwell-sub-accounting/models/recon/BreakageProcess';
import { BreakageStatus } from '@vestwell-sub-accounting/models/recon/BreakageStatus';

import Decimal from 'decimal.js';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import Transactions from '../../accounts/common/Transactions.component';
import ParentToCustodianCashBalances from '../common/ParentToCustodianCashBalances.component';
import ParentToCustodianPositions from '../common/ParentToCustodianPositions.component';

type ReconExceptionsDetailRouteProps = {
  exceptionId: string;
};

export const ReconExceptionsDetailRoute: React.FunctionComponent = () => {
  const location = useLocation();
  const [passedStateSearch] = useState(location.state?.search); // stash in state to avoid later state changes from wiping the original passed search
  const { exceptionId } = useParams<ReconExceptionsDetailRouteProps>();
  const { showSnackbar } = useSnackbar();
  const [errorStatus, setErrorStatus] = React.useState<number | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const statusMenuOpen = Boolean(anchorEl);
  const [selectedStatus, setSelectedStatus] = useState<BreakageStatus>();
  const [openToggleFreezeDialog, setOpenToggleFreezeDialog] = useState(false);
  const [newNotes, setNewNotes] = useState('');
  const [isEditingNotes, setIsEditingNotes] = useState(false);

  const [assignee, setAssignee] = React.useState<{
    label: string;
    id: string;
  } | null>(null);
  const [assigneeInputValue, setAssigneeInputValue] = React.useState('');

  const hasWritePermissions = userService.hasPermission(
    FeatureLevelPermissions.WRITE_SUBA_RECON
  );

  const queryClient = useQueryClient();
  const getReconExceptionByIdQuery = useQuery(
    ['ReconExceptionService.getById', exceptionId],
    () => ReconExceptionService.getById(Number(exceptionId)),
    {
      onError: (err: any) => {
        console.error(err.response?.data ? err.response.data : err.message);
        if (err?.response?.status) setErrorStatus(err.response.status);
      },
      onSuccess: () => {
        setErrorStatus(null);
      }
    }
  );

  const getVestwellStaffQuery = useQuery(
    ['AuthZService.getVestwellStaff'],
    async () => {
      const staff = await AuthZService.getVestwellStaff();
      return AuthZService.formatVestwellStaffList(staff);
    },
    {
      onError: (err: any) => {
        console.error(err.response?.data ? err.response.data : err.message);
        if (err?.response?.status) setErrorStatus(err.response.status);
      },
      onSuccess: () => {
        setErrorStatus(null);
      }
    }
  );

  useEffect(() => {
    if (getReconExceptionByIdQuery.data?.assignee) {
      const recordAssignee = getVestwellStaffQuery.data?.find(user => {
        return user.userId === getReconExceptionByIdQuery.data.assignee;
      });

      setAssignee(
        recordAssignee
          ? {
              id: String(recordAssignee.userId),
              label: recordAssignee.label
            }
          : null
      );
    }
  }, [getReconExceptionByIdQuery.data, getVestwellStaffQuery.data]);

  useEffect(() => {
    // initialize the new notes field with the value from the new api response
    setNewNotes(getReconExceptionByIdQuery.data?.notes || '');
    setIsEditingNotes(false);
    setSelectedStatus(getReconExceptionByIdQuery.data?.status);
  }, [getReconExceptionByIdQuery.data]);

  const updateExceptionNotesMutation = useMutation(
    ['ReconExceptionService.update.notes'],
    (params: UpdateReconExceptionRequest) => {
      if (!getReconExceptionByIdQuery.data) {
        throw new Error('No exception data found');
      }
      return ReconExceptionService.update(
        getReconExceptionByIdQuery.data.id,
        params
      );
    },
    {
      onError: () => {
        showSnackbar({
          message: `Error updating exception`,
          severity: 'error'
        });

        getReconExceptionByIdQuery.refetch();
      },
      onSuccess: async () => {
        await getReconExceptionByIdQuery.refetch();

        showSnackbar({
          message: `Exception updated`,
          severity: 'success'
        });

        await queryClient.invalidateQueries(['ReconExceptionService.search']);
      }
    }
  );

  const updateExceptionAssigneeStatusMutation = useMutation(
    ['ReconExceptionService.update.assigneeStatus'],
    (params: UpdateReconExceptionRequest) => {
      return ReconExceptionService.update(
        getReconExceptionByIdQuery.data.id,
        params
      );
    },
    {
      onError: () => {
        showSnackbar({
          message: `Error updating exception`,
          severity: 'error'
        });

        getReconExceptionByIdQuery.refetch();
      },
      onSuccess: async () => {
        showSnackbar({
          message: `Exception updated`,
          severity: 'success'
        });

        await queryClient.invalidateQueries(['ReconExceptionService.search']);
      }
    }
  );

  const getTabIdx = (availableTabs: TabData[]) => {
    const index = availableTabs.findIndex(
      tab => location.pathname === tab.path
    );

    return index < 0 ? 0 : index;
  };
  const tabElements: TabData[] = useMemo(
    // memo to prevent tabs being unloaded and reloaded when this component state changes
    () => {
      const calculatedTabElements: TabData[] = [];
      // don't add tabs until data is loaded
      if (getReconExceptionByIdQuery.data) {
        // Transactions tab conditionally added
        if (
          [
            BreakageDataType.Dividends,
            BreakageDataType.Position,
            BreakageDataType.Balance
          ].includes(getReconExceptionByIdQuery.data.dataType) &&
          getReconExceptionByIdQuery.data.parentAccount?.parentAccountId
        ) {
          calculatedTabElements.push({
            component: (
              <>
                <Stack
                  alignItems='center'
                  direction='row'
                  justifyContent='end'
                  sx={{
                    border: '1px solid #e0e0e0',
                    borderBottom: '0px',
                    px: 3,
                    py: 2
                  }}>
                  <Button
                    endIcon={<OpenInNewIcon />}
                    href={`/ops/accounts/${
                      getReconExceptionByIdQuery.data?.parentAccount
                        ?.parentAccountId
                    }/transactions?query=${encodeURIComponent(
                      JSON.stringify({
                        accountLevel: AccountLevel.ParentAccount,
                        // only filter by cusip if the exception is a position or dividend
                        cusip: [
                          BreakageDataType.Dividends,
                          BreakageDataType.Position
                        ].includes(getReconExceptionByIdQuery.data.dataType)
                          ? getReconExceptionByIdQuery.data.security?.cusip ||
                            undefined
                          : undefined,
                        status: []
                      })
                    )}`}
                    rel='noopener noreferrer'
                    size='small'
                    target='_blank'>
                    View On Account
                  </Button>
                </Stack>
                <Transactions
                  accountId={
                    getReconExceptionByIdQuery.data.parentAccount
                      .parentAccountId
                  }
                  customDefaultFilters={{
                    // only filter by cusip if the exception is a position or dividend
                    cusip: [
                      BreakageDataType.Dividends,
                      BreakageDataType.Position
                    ].includes(getReconExceptionByIdQuery.data.dataType)
                      ? getReconExceptionByIdQuery.data.security?.cusip ||
                        undefined
                      : undefined,
                    status: []
                  }}
                  customOrder={{
                    column: 'tradeDate',
                    direction: 'desc'
                  }}
                  forcedAccountLevel={AccountLevel.ParentAccount}
                  hideActions
                  hideDetailCell
                  hideFilters
                  hideHeader
                  includeColumns={
                    ![BreakageDataType.Balance].includes(
                      getReconExceptionByIdQuery.data.dataType
                    )
                      ? [
                          'status',
                          'transactionBaseType',
                          'units',
                          'securityUnitPrice',
                          'amount',
                          'tradeDate',
                          'sourceTransactionId'
                        ]
                      : undefined
                  }
                  preventSearchInUrl
                />
              </>
            ),
            label: 'Related Transactions',
            path: `/ops/recon-exceptions/${getReconExceptionByIdQuery.data.id}/related-transactions`
          });
        }

        calculatedTabElements.push({
          component: (
            <ParentToCustodianCashBalances
              exceptionDate={`${getReconExceptionByIdQuery.data.exceptionDate}`}
              parentAccountId={`${getReconExceptionByIdQuery.data.parentAccount?.parentAccountId}`}
            />
          ),
          label: 'Parent to Custodian Cash Balances',
          path: `/ops/recon-exceptions/${getReconExceptionByIdQuery.data.id}/parent-to-custodian-cash-balances`
        });

        if (
          getReconExceptionByIdQuery.data.dataType === BreakageDataType.Position
        ) {
          calculatedTabElements.push({
            component: (
              <ParentToCustodianPositions
                cusip={`${getReconExceptionByIdQuery.data.security.cusip}`}
                exceptionDate={`${getReconExceptionByIdQuery.data.exceptionDate}`}
                parentAccountId={`${getReconExceptionByIdQuery.data.parentAccount?.parentAccountId}`}
              />
            ),
            label: 'Parent to Custodian Positions',
            path: `/ops/recon-exceptions/${getReconExceptionByIdQuery.data.id}/parent-to-custodian-positions`
          });
        }

        // always include the related exceptions tab
        calculatedTabElements.push({
          component: (
            <RelatedReconExceptions
              currentExceptionId={Number(getReconExceptionByIdQuery.data.id)}
              cusip={`${getReconExceptionByIdQuery.data.security.cusip}`}
              dataType={getReconExceptionByIdQuery.data.dataType}
              exceptionDate={`${getReconExceptionByIdQuery.data.exceptionDate}`}
              parentAccountId={`${getReconExceptionByIdQuery.data.parentAccount?.parentAccountId}`}
            />
          ),
          label: 'Related Exceptions',
          path: `/ops/recon-exceptions/${getReconExceptionByIdQuery.data.id}/related-exceptions`
        });
      }

      return calculatedTabElements;
    },
    [getReconExceptionByIdQuery.data, getReconExceptionByIdQuery.data?.status]
  );

  const tabs: SimpleTabsProps = {
    defaultTabIdx: getTabIdx(tabElements),
    tabs: tabElements,
    tabsAriaLabel: 'recon-exception-detail-tabs'
  };

  const hasRequiredPermissions = userService.hasPermission(
    FeatureLevelPermissions.READ_SUBA_RECON
  );

  if (!hasRequiredPermissions) {
    return redirectToErrorPage(new Error('403'));
  }

  if (errorStatus === 404) {
    return redirectToErrorPage(new Error('404'));
  }

  // fetch friendly display name for data type
  const displayDataType = formatters.getValueKey(
    BreakageDataType,
    getReconExceptionByIdQuery.data?.dataType
  );
  // fetch friendly display name for process
  const displayProcess = formatters.getValueKey(
    BreakageProcess,
    getReconExceptionByIdQuery.data?.process
  );

  // breadcrumb items
  const paths = [{ name: 'All Reconciliations', to: '/ops/recon-exceptions' }];
  if (passedStateSearch) {
    paths.push({
      name: 'Search Results',
      to: `/ops/recon-exceptions${passedStateSearch}`
    });
  }

  // format friendly parent account name
  const parentAccountName =
    getReconExceptionByIdQuery.data?.parentAccount?.accountType ===
      'SuperOmnibus' ||
    getReconExceptionByIdQuery.data?.parentAccount?.accountType === 'House'
      ? getReconExceptionByIdQuery.data?.parentAccount?.accountName
      : getReconExceptionByIdQuery.data?.parentAccount?.plan?.name;

  type ExceptionCompareData = {
    primary: string;
    secondary?: string;
    value?: string | number | null;
    price?: string | number | null;
    units?: string | number | null;
  };

  let exceptionCompareData: ExceptionCompareData[];

  const createExceptionCompareData = (
    initialData: Partial<ExceptionCompareData>
  ): ExceptionCompareData[] => {
    if (exceptionCompareData) {
      // already exists, it was created already
      const [first, ...rest] = exceptionCompareData;

      return [
        {
          ...first,
          ...initialData // adding the new columns
        },
        ...rest
      ];
    } else {
      // creating for the first time
      return [
        {
          primary: 'Parent',
          secondary: parentAccountName,
          ...initialData
        }
      ];
    }
  };

  const enrichExceptionCompareData = ({
    compareData,
    enrichedData
  }: {
    compareData: ExceptionCompareData[];
    enrichedData: Partial<ExceptionCompareData>[];
  }) => {
    const subAccountCompare = [
      BreakageProcess.ParentToSubAccountPositions,
      BreakageProcess.ParentToSubAccountBalances
    ].includes(getReconExceptionByIdQuery.data?.process);

    if (compareData.length > 1) {
      // it has been enriched already
      // add more fields (columns) to exising data
      return [
        compareData[0],
        {
          ...compareData[1],
          ...enrichedData[0]
        },
        {
          ...compareData[2],
          ...enrichedData[1]
        }
      ];
    } else {
      // not enriched yet
      // create the enriched data rows
      return [
        compareData[0],
        {
          primary: subAccountCompare
            ? 'Sum of SubAccounts'
            : getReconExceptionByIdQuery.data?.parentAccount?.custodianId || '',
          secondary: subAccountCompare
            ? ''
            : `ID: ${getReconExceptionByIdQuery.data?.parentAccount?.custodianAccountNumber}`,
          ...enrichedData[0]
        },
        {
          primary: 'Difference',
          ...enrichedData[1]
        }
      ];
    }
  };

  const exceptionCompareDataColumnDefs: SimpleTableColumnDef[] = [
    {
      cellRenderer: ({ data }: Record<string, ExceptionCompareData>) => (
        <>
          <Typography>{formatters.displayCase(data.primary)}</Typography>
          <Typography variant='body2'>{data.secondary}</Typography>
        </>
      ),
      field: 'primary',
      header: `${formatters.displayCase(displayProcess)}`,
      maxWidth: 400,
      sx: {
        py: 0.75
      }
    }
  ];

  // add units to exceptionCompareData and columnDefs for applicable exception types
  if (
    getReconExceptionByIdQuery.data?.dataType &&
    [
      BreakageDataType.Position,
      BreakageDataType.FundActivity,
      BreakageDataType.Dividends
    ].includes(getReconExceptionByIdQuery.data.dataType)
  ) {
    exceptionCompareData = createExceptionCompareData({
      units: getReconExceptionByIdQuery.data?.parentUnits
    });

    if (
      ![
        BreakageProcess.ShortParentAccountPosition,
        BreakageProcess.ShortSubAccountPosition
      ].includes(getReconExceptionByIdQuery.data?.process)
    ) {
      exceptionCompareData = enrichExceptionCompareData({
        compareData: exceptionCompareData,
        enrichedData: [
          { units: getReconExceptionByIdQuery.data?.comparisonUnits },
          { units: getReconExceptionByIdQuery.data?.unitsDifference }
        ]
      });
    }

    exceptionCompareDataColumnDefs.push({
      field: 'units',
      header: 'Units',
      minWidth: 150,
      sx: {
        fontSize: 16,
        lineHeight: '24px'
      },
      type: 'numericColumn',
      valueFormatter: ({ value }: Record<string, number>) =>
        formatters.formatDecimal(value || 0, 3)
    });
  }

  // add price to exceptionCompareData and columnDefs for applicable exception types
  if (
    getReconExceptionByIdQuery.data?.dataType &&
    [BreakageDataType.FundActivity, BreakageDataType.Dividends].includes(
      getReconExceptionByIdQuery.data.dataType
    )
  ) {
    exceptionCompareData = createExceptionCompareData({
      price: getReconExceptionByIdQuery.data?.parentPrice
    });

    exceptionCompareData = enrichExceptionCompareData({
      compareData: exceptionCompareData,
      enrichedData: [
        { price: getReconExceptionByIdQuery.data?.comparisonPrice },
        {
          price: formatters.calculateDifference(
            getReconExceptionByIdQuery.data?.parentPrice,
            getReconExceptionByIdQuery.data?.comparisonPrice
          )
        }
      ]
    });

    exceptionCompareDataColumnDefs.push({
      field: 'price',
      header: 'Price',
      minWidth: 150,
      sx: {
        fontSize: 16,
        lineHeight: '24px'
      },
      type: 'numericColumn',
      valueFormatter: ({ value }: Record<string, number>) =>
        formatters.formatDollars(value || 0, 3)
    });
  }

  // add value to exceptionCompareData and columnDefs for applicable exception types
  if (
    getReconExceptionByIdQuery.data?.dataType &&
    [
      BreakageDataType.Balance,
      BreakageDataType.FundActivity,
      BreakageDataType.Dividends
    ].includes(getReconExceptionByIdQuery.data.dataType)
  ) {
    exceptionCompareData = createExceptionCompareData({
      value: getReconExceptionByIdQuery.data?.parentValue
    });

    if (
      ![
        BreakageProcess.DebitParentAccountBalances,
        BreakageProcess.DebitSubAccountBalances
      ].includes(getReconExceptionByIdQuery.data?.process)
    ) {
      exceptionCompareData = enrichExceptionCompareData({
        compareData: exceptionCompareData,
        enrichedData: [
          { value: getReconExceptionByIdQuery.data?.comparisonValue },
          { value: getReconExceptionByIdQuery.data?.valueDifference }
        ]
      });
    }

    exceptionCompareDataColumnDefs.push({
      field: 'value',
      header: 'Amount',
      minWidth: 150,
      sx: {
        fontSize: 16,
        lineHeight: '24px'
      },
      type: 'numericColumn',
      valueFormatter: ({ value }: Record<string, number>) =>
        formatters.formatDollars(value || 0, 3)
    });
  }

  const handleStatusMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const doStatusUpdate = (value: BreakageStatus) => {
    setSelectedStatus(value);
    setAnchorEl(null);
    updateExceptionAssigneeStatusMutation.mutate({ status: value });
  };

  const onToggleFreeze = () => {
    getReconExceptionByIdQuery.refetch();
  };

  if (errorStatus === 404) {
    return redirectToErrorPage(new Error('404'));
  }

  type SubAccountBalancesAdditionalDetails = {
    subAccountId: string;
    confirmedBalance: string;
  };

  type ShortSubAccountPositionAdditionalDetails = {
    subAccountId: string;
    confirmedUnitsHeld: string;
  };

  return (
    <>
      {getReconExceptionByIdQuery.isInitialLoading ||
      getVestwellStaffQuery.isInitialLoading ? (
        <LinearLoading data-testid='recon-exception-detail-linear-loading' />
      ) : (
        <>
          <ToggleFreezeDialog
            onClose={() => {
              setOpenToggleFreezeDialog(false);
            }}
            onToggleFreeze={onToggleFreeze}
            open={openToggleFreezeDialog}
            reconException={getReconExceptionByIdQuery.data}
          />
          <Box
            data-testid='recon-exception-detail-header'
            display='flex'
            justifyContent='space-between'>
            <NavigationBreadcrumbs
              data-testid='recon-exception-detail-breadcrumbs'
              paths={paths}
            />
          </Box>
          <Stack direction='row' justifyContent='space-between'>
            <Box>
              <Stack alignItems='center' direction='row' spacing={5.625}>
                <Box>
                  <Typography role='heading' variant='h4'>
                    Exception
                  </Typography>
                </Box>
                {getReconExceptionByIdQuery.data.lockAccountFlag && (
                  <Box data-testid='recon-exception-frozen-indicator'>
                    <Badge color='warning'>Account Frozen</Badge>
                  </Box>
                )}
              </Stack>
              <Box alignItems='center' display='flex' justifyContent='left'>
                <Typography data-testid='detail_id' variant='subtitle1'>
                  ID: {exceptionId}
                </Typography>

                <CopyToClipboard
                  copyName='Exception ID'
                  copyValue={String(exceptionId)}
                />
              </Box>
            </Box>
            <Stack alignItems='flex-start' direction='row' spacing={2}>
              <Stack alignItems='center' direction='row' spacing={2}>
                {updateExceptionAssigneeStatusMutation.isLoading && (
                  <CircularLoading />
                )}
                {getVestwellStaffQuery.data && (
                  <FormControl
                    size='small'
                    sx={{ maxWidth: '100%', width: 205 }}>
                    <Autocomplete
                      data-testid='recon-exception-assignee-autocomplete'
                      disablePortal
                      inputValue={assigneeInputValue}
                      isOptionEqualToValue={(option, value) =>
                        option.label === value.label
                      }
                      onBlur={() => {
                        if (
                          assigneeInputValue === '' &&
                          getReconExceptionByIdQuery.data.assignee
                        ) {
                          // this must be firing after the user emptied the assignee field and then exited the field
                          // there was an assignee assignee so now it is safe to say they are wanting to clear the assignee
                          setAssignee(null);
                          updateExceptionAssigneeStatusMutation.mutate({
                            assignee: ''
                          });
                        }
                      }}
                      onChange={(
                        event,
                        newValue: {
                          label: string;
                          id: string;
                        },
                        reason
                      ) => {
                        if (reason === 'clear' && event.type !== 'click') {
                          // this must be firing just from the user backspacing the input field so don't update the assignee yet
                          return;
                        }
                        setAssignee(newValue);
                        updateExceptionAssigneeStatusMutation.mutate({
                          assignee: newValue?.id || ''
                        });
                      }}
                      onInputChange={(_event, newInputValue) => {
                        setAssigneeInputValue(newInputValue);
                      }}
                      options={getVestwellStaffQuery.data.map(user => ({
                        id: String(user.userId),
                        label: user.label
                      }))}
                      readOnly={!hasWritePermissions}
                      renderInput={params => (
                        <TextField
                          {...params}
                          data-testid='recon-exception-assignee-input-field'
                          helperText={
                            assignee ? null : 'Required to close exception'
                          }
                          label='Assignee'
                        />
                      )}
                      size='small'
                      value={assignee}
                    />
                  </FormControl>
                )}
              </Stack>
              {hasWritePermissions && (
                <Box>
                  <Button
                    aria-controls={
                      statusMenuOpen ? 'recon-exception-status-menu' : undefined
                    }
                    aria-expanded={statusMenuOpen ? 'true' : undefined}
                    aria-haspopup='true'
                    data-testid='recon-exception-status-button'
                    endIcon={<ArrowDropDownIcon />}
                    id='recon-exception-status-button'
                    onClick={handleStatusMenuClick}
                    sx={{
                      '&:hover': {
                        background: theme =>
                          ({
                            info: theme.palette.grey[300],
                            neutral: theme.palette.grey[200],
                            success: theme.palette.success.dark,
                            warning: theme.palette.warning.dark
                          })[
                            BreakageStatusColorMap[
                              selectedStatus ||
                                getReconExceptionByIdQuery.data?.status
                            ]
                          ]
                      },
                      background: theme =>
                        ({
                          info: theme.palette.grey[300],
                          neutral: theme.palette.grey[200],
                          success: theme.palette.success.dark,
                          warning: theme.palette.warning.dark
                        })[
                          BreakageStatusColorMap[
                            selectedStatus ||
                              getReconExceptionByIdQuery.data?.status
                          ]
                        ],
                      color: theme =>
                        ({
                          info: theme.palette.grey[900],
                          neutral: theme.palette.grey[900],
                          success: '#fff',
                          warning: '#fff'
                        })[
                          BreakageStatusColorMap[
                            selectedStatus ||
                              getReconExceptionByIdQuery.data?.status
                          ]
                        ]
                    }}
                    variant='contained'>
                    {formatters.displayCase(
                      formatters.getValueKey(
                        BreakageStatus,
                        selectedStatus ||
                          getReconExceptionByIdQuery.data?.status
                      )
                    )}
                  </Button>
                  <Menu
                    MenuListProps={{
                      'aria-labelledby': 'recon-exception-status-button'
                    }}
                    anchorEl={anchorEl}
                    data-testid='recon-exception-status-menu'
                    id='recon-exception-status-menu'
                    onClose={() => setAnchorEl(null)}
                    open={statusMenuOpen}>
                    {Object.values(BreakageStatus).map(value => {
                      const displayStatus = formatters.getValueKey(
                        BreakageStatus,
                        value
                      );
                      return (
                        <MenuItem
                          disabled={
                            value === BreakageStatus.Closed && !assignee
                          }
                          key={value}
                          onClick={() => doStatusUpdate(value)}
                          selected={value === selectedStatus}
                          value={value}>
                          {formatters.displayCase(displayStatus)}
                        </MenuItem>
                      );
                    })}
                  </Menu>
                </Box>
              )}
              {/* TODO: Commented out for now per https://vestwell.atlassian.net/browse/SUB-2401 until this prop actually starts getting used in suba core */}
              {/* {hasWritePermissions && (<Button
                  data-testid="recon-exception-freeze-toggle-button"
                  variant={
                    getReconExceptionByIdQuery.data.lockAccountFlag
                      ? 'outlined'
                      : 'contained'
                  }
                  onClick={() => setOpenToggleFreezeDialog(true)}
                  color="warning"
                  sx={{
                    // setting color as well as sx background ensures the color is appropriate on hover
                    background: !getReconExceptionByIdQuery.data.lockAccountFlag
                      ? (theme) => theme.palette.warning.dark
                      : undefined,
                    color: !getReconExceptionByIdQuery.data.lockAccountFlag
                      ? '#fff'
                      : undefined,
                  }}
                >
                  {getReconExceptionByIdQuery.data.lockAccountFlag ? 'Unfreeze' : 'Freeze'}
                </Button>)} */}
            </Stack>
          </Stack>

          <TextStack
            divider
            id='recon-exception-detail-header-fields'
            rowColumnWidth='dynamic'
            sx={{ m: '30px 0', p: 0 }}>
            <>
              <TextStackItem>
                <TextLabel>Break Type</TextLabel>
                <TextValue detail={formatters.displayCase(displayProcess)}>
                  {formatters.displayCase(displayDataType)}
                </TextValue>
              </TextStackItem>

              <TextStackItem>
                <TextLabel>Parent Account</TextLabel>
                <TextValue
                  copyLabel={`ID: ${getReconExceptionByIdQuery.data.parentAccount?.parentAccountId}`}
                  copyValue={
                    getReconExceptionByIdQuery.data.parentAccount
                      ?.parentAccountId
                  }
                  copyable
                  icon={
                    getReconExceptionByIdQuery.data.lockAccountFlag ? (
                      <Stack
                        alignItems='center'
                        data-testid='recon-exception-frozen-indicator-parent-account'
                        justifyContent='center'
                        sx={{
                          background: theme => theme.palette.warning.lightBg,
                          borderRadius: 100,
                          color: theme => theme.palette.warning.dark,
                          fontSize: 12,
                          p: 0.5
                        }}>
                        <AcUnitIcon fontSize='inherit' />
                      </Stack>
                    ) : undefined
                  }
                  links={
                    getReconExceptionByIdQuery.data.parentAccount
                      ?.parentAccountId
                      ? [
                          {
                            target: '_blank',
                            to: `/ops/accounts/${getReconExceptionByIdQuery.data.parentAccount.parentAccountId}`
                          }
                        ]
                      : undefined
                  }>
                  {parentAccountName}
                </TextValue>
              </TextStackItem>
              {getReconExceptionByIdQuery.data.dataType &&
                ![BreakageDataType.Balance].includes(
                  getReconExceptionByIdQuery.data.dataType
                ) && (
                  <TextStackItem>
                    <TextLabel>Fund</TextLabel>
                    <TextValue
                      copyLabel={`CUSIP: ${getReconExceptionByIdQuery.data.security?.cusip}`}
                      copyValue={
                        getReconExceptionByIdQuery.data.security?.cusip ||
                        undefined
                      }
                      copyable
                      links={
                        getReconExceptionByIdQuery.data.security?.cusip
                          ? [
                              {
                                target: '_blank',
                                to: `/ops/securities/${getReconExceptionByIdQuery.data.security.cusip}`
                              }
                            ]
                          : undefined
                      }>
                      {formatters.displayCase(
                        getReconExceptionByIdQuery.data.security?.symbol
                      )}
                    </TextValue>
                  </TextStackItem>
                )}
              <TextStackItem>
                <TextLabel>Exception Date</TextLabel>
                <TextValue>
                  {getReconExceptionByIdQuery.data.exceptionDate
                    ? formatters.formatFromIsoDateCustom(
                        getReconExceptionByIdQuery.data.exceptionDate,
                        'MM/DD/YYYY'
                      )
                    : null}
                </TextValue>
              </TextStackItem>
            </>
          </TextStack>

          <Stack
            alignItems='stretch'
            direction={{ md: 'row', xs: 'column' }}
            spacing={4}
            sx={{ mt: 5 }}>
            <Card
              data-testid='recon-exception-details'
              sx={theme => ({
                [theme.breakpoints.up('md')]: {
                  minHeight: 760,
                  width: '60%'
                }
              })}>
              <CardHeader title='Exception Details' />
              <CardContent
                disablePadding
                loading={updateExceptionNotesMutation.isLoading}>
                <SimpleTable
                  applyComparisonStyle
                  columnDefs={exceptionCompareDataColumnDefs}
                  data={exceptionCompareData}
                  data-testid='recon-exception-compare-table'
                  sx={{ width: 'max-content' }}
                />

                <Box
                  data-testid='recon-exception-notes'
                  sx={{ maxWidth: '100%', mb: 4, ml: 2, width: 560 }}>
                  {!isEditingNotes || !hasWritePermissions ? (
                    <Box>
                      <InputLabel
                        id='recon-exception-note-label'
                        sx={{ fontSize: 14 }}>
                        Note
                      </InputLabel>
                      <Stack alignItems='center' direction='row' spacing={2}>
                        {getReconExceptionByIdQuery.data.notes && (
                          <Box sx={{ whiteSpace: 'pre-line' }}>
                            {getReconExceptionByIdQuery.data.notes}
                          </Box>
                        )}
                        {hasWritePermissions && (
                          <Button
                            data-testid='recon-exception-notes-edit-button'
                            onClick={() => setIsEditingNotes(true)}
                            variant='text'>
                            {getReconExceptionByIdQuery.data.notes
                              ? 'Edit'
                              : 'Add Note'}
                          </Button>
                        )}
                      </Stack>
                    </Box>
                  ) : (
                    <Box>
                      <FormControl
                        fullWidth
                        size='small'
                        sx={{ mb: 1 }}
                        variant='outlined'>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ sx: { p: 1, px: 1.5 } }}
                          data-testid='recon-exception-notes-text-field'
                          id='recon-exception-note'
                          label='Note'
                          multiline
                          name='recon-exception-note'
                          onChange={evt => {
                            setNewNotes(evt.target.value);
                          }}
                          value={newNotes}
                        />
                      </FormControl>
                      <Stack direction='row' spacing={2}>
                        <Button
                          data-testid='recon-exception-notes-cancel-button'
                          onClick={() => {
                            setNewNotes(
                              getReconExceptionByIdQuery.data.notes || ''
                            );
                            setIsEditingNotes(false);
                          }}
                          variant='text'>
                          Cancel
                        </Button>
                        <Button
                          data-testid='recon-exception-notes-save-button'
                          onClick={() => {
                            updateExceptionNotesMutation.mutate({
                              notes: newNotes
                            });
                            setIsEditingNotes(false);
                          }}
                          variant='text'>
                          Save Note
                        </Button>
                      </Stack>
                    </Box>
                  )}
                </Box>

                {([BreakageDataType.Balance].includes(
                  getReconExceptionByIdQuery.data?.dataType
                ) &&
                  [BreakageProcess.DebitParentAccountBalances].includes(
                    getReconExceptionByIdQuery.data?.process
                  )) ||
                (getReconExceptionByIdQuery.data?.dataType ===
                  BreakageDataType.Position &&
                  [BreakageProcess.ShortParentAccountPosition].includes(
                    getReconExceptionByIdQuery.data?.process
                  )) ? null : (
                  <Box
                    data-testid='recon-exception-additional-details'
                    sx={{ pb: 2, pl: 2 }}>
                    <Typography
                      color={theme => theme.palette.grey[700]}
                      component='h3'
                      data-testid='additional-details-heading'
                      sx={{ fontSize: 14, mb: 1 }}
                      variant='caption'>
                      Additional Details
                    </Typography>

                    {(getReconExceptionByIdQuery.data?.dataType ===
                      BreakageDataType.Balance &&
                      getReconExceptionByIdQuery.data?.process ===
                        BreakageProcess.DebitSubAccountBalances) ||
                    (getReconExceptionByIdQuery.data?.dataType ===
                      BreakageDataType.Position &&
                      getReconExceptionByIdQuery.data?.process ===
                        BreakageProcess.ShortSubAccountPosition) ? (
                      <SimpleTable
                        columnDefs={[
                          {
                            cellRenderer: ({
                              data
                            }: {
                              data:
                                | SubAccountBalancesAdditionalDetails
                                | ShortSubAccountPositionAdditionalDetails;
                            }) => (
                              <Link
                                target='_blank'
                                to={
                                  'confirmedBalance' in data
                                    ? `/ops/accounts/${getReconExceptionByIdQuery.data.parentAccount.parentAccountId}/sub-accounts/${data.subAccountId}`
                                    : `/ops/accounts/${
                                        getReconExceptionByIdQuery.data
                                          .parentAccount.parentAccountId
                                      }/sub-accounts/${
                                        data.subAccountId
                                      }/transactions?query=${encodeURIComponent(
                                        JSON.stringify({
                                          cusip:
                                            getReconExceptionByIdQuery.data
                                              ?.security?.cusip
                                        })
                                      )}`
                                }>
                                {data.subAccountId}
                              </Link>
                            ),
                            field: 'subAccountId',
                            header: 'Sub Accounts',
                            maxWidth: 400,
                            minWidth: 400
                          },
                          {
                            cellRenderer: ({
                              data
                            }: {
                              data:
                                | SubAccountBalancesAdditionalDetails
                                | ShortSubAccountPositionAdditionalDetails;
                            }) =>
                              'confirmedBalance' in data
                                ? formatters.formatDollars(
                                    new Decimal(
                                      data.confirmedBalance
                                    ).toNumber()
                                  )
                                : new Decimal(data.confirmedUnitsHeld).toFixed(
                                    3
                                  ),
                            field: 'confirmedBalance',
                            header: `${getReconExceptionByIdQuery.data?.dataType === BreakageDataType.Balance ? 'Amount' : 'Units'}`
                          }
                        ]}
                        data={(() => {
                          try {
                            return (
                              getReconExceptionByIdQuery.data
                                ?.additionalDetails &&
                              JSON.parse(
                                getReconExceptionByIdQuery.data
                                  .additionalDetails
                              )
                            );
                          } catch (error) {
                            showSnackbar({
                              message: `Could not parse additional details`,
                              severity: 'error'
                            });
                            console.error(
                              'Could not parse additional details: \n %s',
                              getReconExceptionByIdQuery.data
                                ?.additionalDetails,
                              error
                            );
                            return [];
                          }
                        })()}
                        data-testid='additional-details-table'
                      />
                    ) : (
                      <Box
                        sx={{
                          background: theme => theme.palette.grey[50],
                          fontSize: 12,
                          height: 275,
                          overflow: 'auto',
                          p: 1
                        }}>
                        {getReconExceptionByIdQuery.data.additionalDetails && (
                          <JSONViewer
                            json={
                              getReconExceptionByIdQuery.data.additionalDetails
                            }
                          />
                        )}
                        {!getReconExceptionByIdQuery.data.additionalDetails && (
                          <Stack
                            alignItems='center'
                            data-testid='no-additional-details'
                            justifyContent='center'
                            sx={{ height: '100%' }}>
                            <CardPlaceholder subtitle='No additional details.' />
                          </Stack>
                        )}
                      </Box>
                    )}
                  </Box>
                )}
              </CardContent>
            </Card>

            <Box
              sx={theme => ({
                [theme.breakpoints.up('md')]: { width: '40%' },
                position: 'relative'
              })}>
              <Card
                data-testid='recon-exception-comments'
                sx={theme => ({
                  [theme.breakpoints.up('md')]: {
                    bottom: 0,

                    left: 0,
                    // absolute to ensure this card is always the same height as the other card
                    // and does not cause the page to grow when lots of comments are loaded
                    position: 'absolute',
                    right: 0,
                    top: 0
                  }
                })}>
                <CardHeader title='Comments' />
                <CardContent
                  disablePadding
                  sx={theme => ({
                    [theme.breakpoints.up('md')]: {
                      height: 'calc(100% - 72px)' // 72px = card header height
                    }
                  })}>
                  <Box
                    sx={{
                      height: '100%',
                      overflow: 'auto'
                    }}>
                    <Comments
                      commentLinkBase={`/ops/recon-exceptions/${getReconExceptionByIdQuery.data?.id}`}
                      hasWritePermissions={hasWritePermissions}
                      type={CommentType.Reconciliation}
                      typeId={getReconExceptionByIdQuery.data.id}
                    />
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </Stack>

          {tabs.tabs.length > 0 && (
            <Box
              data-testid='recon-exception-detail-related-record-tables'
              sx={{ mt: 5 }}>
              <SimpleTabs
                data-testid='recon-exception-detail-tabs'
                {...tabs}
                style={{
                  border: '1px solid #e0e0e0',
                  borderBottom: '0px',
                  borderTopLeftRadius: '4px',
                  borderTopRightRadius: '4px',
                  marginBottom: '0'
                }}
              />
            </Box>
          )}
        </>
      )}
    </>
  );
};
