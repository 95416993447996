import { ProgramFund } from '@/routes/ops/investments/FundChangesTab/types';
import { FundChangePreview } from '@/services/ops/investments/Program.service';
import { SecurityMasterService } from '@/services/SecurityMaster.service';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  IconButton,
  Link,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';

import { sumBy } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useToggle } from 'react-use';

export const FundChangePreviewRow: FC<FundChangePreview> = (
  props
): JSX.Element => {
  const { fundChange, plansAffected } = props;

  const [isExpanded, toggleExpanded] = useToggle(false);
  const [securities, setSecurities] = useState<ProgramFund[] | null>();

  useEffect(() => {
    SecurityMasterService.getSecuritiesByCusips([
      fundChange.oldFund,
      fundChange.newFund
    ]).then(securitiesByCusips => {
      setSecurities(securitiesByCusips);
    });
  }, [fundChange]);

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton onClick={toggleExpanded}>
            {isExpanded ? (
              <KeyboardArrowDownIcon />
            ) : (
              <KeyboardArrowRightIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {fundChange.tradeDate}
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {securities ? (
            <>
              {`${securities.find(
                (security: ProgramFund) => security.cusip === fundChange.oldFund
              )?.symbol} | ${fundChange.oldFund}`}
            </>
          ) : (
            <Skeleton variant='text' />
          )}
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {securities ? (
            <>
              {`${securities.find(
                (security: ProgramFund) => security.cusip === fundChange.newFund
              )?.symbol} | ${fundChange.newFund}`}
            </>
          ) : (
            <Skeleton variant='text' />
          )}
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{`${sumBy(
          plansAffected,
          'participantsAffected'
        )} participants / ${plansAffected.length} plan(s)`}</TableCell>
      </TableRow>
      {isExpanded && (
        <TableRow sx={{ backgroundColor: 'var(--mui-palette-grey-50)' }}>
          <TableCell />
          <TableCell colSpan={3}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>Plan</TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>Plan Name</TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    Participants Affected
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {plansAffected.map((plan, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>
                        <Link
                          href={`/plans/${plan.sponsorPlanId}`}
                          target='_blank'>
                          {plan.sponsorPlanId}
                        </Link>
                      </TableCell>
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>
                        {plan.sponsorPlanName}
                      </TableCell>
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>
                        {plan.participantsAffected}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableCell>
          <TableCell />
        </TableRow>
      )}
    </>
  );
};

FundChangePreviewRow.displayName = 'FundChangePreviewRow';
