import { useSnackbar } from '@/contexts/SnackBarContext';
import { useUserToken } from '@/contexts/UserTokenContext';
import ParentAccountService from '@/services/ops/accounts/ParentAccount.service';
import { useQuery } from '@tanstack/react-query';

export const useParentAccountById = (params: { accountId: string }) => {
  const { showSnackbar } = useSnackbar();
  const { userHasValidToken } = useUserToken();

  return useQuery(
    ['ParentAccountService.getById', params.accountId],
    () => ParentAccountService.getById(params.accountId),
    {
      enabled: params.accountId && userHasValidToken,
      onError: (err: any) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Failed to fetch Parent Account: ${message}`,
          severity: 'error'
        });
      }
    }
  );
};
