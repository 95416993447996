import CircularLoading from '@/components/circular-loading';
import TextStack, { TextStackItem, TextValue } from '@/components/text-stack';
import { ParentAccountDto } from '@/models/ops/ParentAccountDTO.model';
import { SubAccountDto } from '@/models/ops/SubAccountDTO.model';
import ParentAccountService from '@/services/ops/accounts/ParentAccount.service';
import SubAccountsService from '@/services/ops/accounts/SubAccount.service';
import { Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { AccountLevel } from '@vestwell-sub-accounting/models/accountsAndLedgers/AccountLevel';

import React from 'react';

type TransactionAccountSummaryProps = {
  accountId: string;
  accountLevel: AccountLevel;
};

const TransactionAccountSummary = ({
  accountId,
  accountLevel
}: TransactionAccountSummaryProps): JSX.Element => {
  const { data: accountQueryData, isFetching } = useQuery(
    ['TransactionAccountSummary-getById', accountId],
    async () => {
      const account =
        accountLevel === AccountLevel.ParentAccount
          ? await ParentAccountService.getById(accountId)
          : await SubAccountsService.getById(accountId);
      return account;
    },

    {
      staleTime: Infinity
    }
  );

  let accountName = '';
  if (accountQueryData) {
    if (accountLevel === AccountLevel.ParentAccount) {
      const account = accountQueryData as ParentAccountDto;
      if (['SuperOmnibus', 'House'].includes(account.accountType)) {
        accountName = account.accountName;
      } else if (account.plan?.name) {
        accountName = account.plan.name;
      }
    } else {
      const account = accountQueryData as SubAccountDto;
      if (account.participant?.firstName || account.participant?.lastName) {
        accountName = `${account.participant.firstName} ${account.participant.lastName}`;
      }
    }
  }

  return (
    <Box
      data-testid='transaction-plan-info'
      sx={{
        bgcolor: theme => theme.palette.primary.light,
        px: 3.25,
        py: 2
      }}>
      {isFetching && <CircularLoading size='45px' />}
      {!isFetching && (
        <TextStack
          data-testid='transaction-account-detail-header-fields'
          divider
          rowColumnWidth='dynamic'>
          <TextStackItem>
            <TextValue detail={`ID: ${accountId}`}>{accountName}</TextValue>
          </TextStackItem>
        </TextStack>
      )}
    </Box>
  );
};
export default TransactionAccountSummary;
