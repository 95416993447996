import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { WorkflowContext } from '@/routes/ops/conductor/common/WorkflowContext';
import formatters from '@/utils/Formatters';

import { useContext } from 'react';

export const InvestContributionInputFields = () => {
  const workflow = useContext(WorkflowContext);

  return (
    <TextStack direction='column' divider>
      <TextStackItem>
        <TextLabel>Core Request</TextLabel>
        <TextValue
          links={
            workflow.coreRequest?.context
              ? [
                  {
                    target: '_blank',
                    to: `/plans/${workflow.coreRequest.context.planId}/contributions/${workflow.coreRequest.context.ucid}/overview`
                  }
                ]
              : undefined
          }>
          {`${workflow?.coreRequest?.expectedPayrollDate || ''} ${
            workflow?.coreRequest?.isOffCycle ? 'Off Cycle' : 'Contribution'
          }`.trim()}
        </TextValue>
      </TextStackItem>

      {workflow?.planId && workflow.plan.name && (
        <TextStackItem>
          <TextLabel>Plan</TextLabel>
          <TextValue
            links={[
              {
                label: `ID: ${workflow.planId}`,
                target: '_blank',
                to: `/plans/${workflow.planId}/plan`
              }
            ]}>
            {workflow.plan.name}
          </TextValue>
        </TextStackItem>
      )}

      <TextStackItem>
        <TextLabel>Sub Account</TextLabel>
        <TextValue
          links={
            workflow?.parentAccount && workflow?.subAccount
              ? [
                  {
                    label: `ID: ${workflow.subAccount.subAccountId}`,
                    target: '_blank',
                    to: `/ops/accounts/${workflow.parentAccount.parentAccountId}/sub-accounts/${workflow.subAccount.subAccountId}`
                  }
                ]
              : undefined
          }>
          {formatters.formatSubAccountName(workflow?.subAccount)}
        </TextValue>
      </TextStackItem>

      <TextStackItem>
        <TextLabel>Created</TextLabel>
        <TextValue>
          {formatters.formatFromIsoDate(workflow?.createdAt)}
        </TextValue>
      </TextStackItem>
    </TextStack>
  );
};
