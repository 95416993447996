import Constants from '@/consts/planDesign.constants';
import { FundingSource } from '@/models/PlanDesign.model';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { isBoolean, isNumber, isString } from 'lodash';

// moshe: consider renaming this file PlanDesignHelper

dayjs.extend(utc);

type ValueOf<T> = T[keyof T];

const ConversionTypeNameMap = {
  false: 'Starter Plan',
  true: 'Conversion'
} as const;

class Helper {
  static convertFromBooleanToYesNo(
    value: boolean | undefined
  ): string | undefined {
    if (isBoolean(value)) {
      return value ? 'Yes' : 'No';
    }
    return undefined;
  }

  static convertFromYesNoToBoolean(
    value: string | undefined
  ): boolean | undefined {
    return isString(value) && ['Yes', 'No'].includes(value)
      ? value === 'Yes'
      : undefined;
  }

  static convertFromBooleanToAllowDontAllow(
    value: boolean | undefined
  ): string | undefined {
    if (isBoolean(value)) {
      return value ? 'Allow' : "Don't allow";
    }

    return undefined;
  }

  static convertFromAllowDontAllowToBoolean(
    value: string | undefined
  ): boolean | undefined {
    return isString(value) && Constants.allowDontAllowOptions.includes(value)
      ? value === 'Allow'
      : undefined;
  }

  static convertFromBooleanToExcludedIncluded(
    value: boolean | undefined
  ): string | undefined {
    if (isBoolean(value)) {
      return value ? 'Excluded' : 'Included';
    }
    return undefined;
  }

  static convertFromExcludedIncludedToBoolean(
    value: string | undefined
  ): boolean | undefined {
    return isString(value) ? value === 'Excluded' : undefined;
  }

  static transformDate(
    value: string | undefined,
    format = 'MM/DD/YY'
  ): string | undefined {
    return value ? dayjs.utc(value).format(format) : undefined;
  }

  static transformDateToApiState(
    value: string | null | undefined,
    format = 'YYYY-MM-DD'
  ): string | null | undefined {
    return value === null || value === ''
      ? null
      : value
      ? dayjs(value).format(format)
      : undefined;
  }

  static transformDateToTimestamp(
    value: string | null | undefined
  ): string | null | undefined {
    return value === null || value === ''
      ? null
      : value
      ? dayjs(value).format('YYYY-MM-DD HH:mm:ss')
      : undefined;
  }

  static addPercentSign(value: number | undefined): string | undefined {
    return isNumber(value) ? `${value.toString()}%` : undefined;
  }

  static convertValueToApiState<T>(value: T): T | undefined {
    return value === null || value === '' ? null : value;
  }

  static getConversionTypeName(
    value: string | undefined
  ): ValueOf<typeof ConversionTypeNameMap> | undefined {
    switch (value) {
      case 'true':
        return 'Conversion';

      case 'false':
        return 'Starter Plan';

      default:
        return undefined;
    }
  }

  static getConversionTypeForName(
    name: string | undefined
  ): keyof typeof ConversionTypeNameMap | undefined {
    switch (name) {
      case 'Conversion':
        return 'true';

      case 'New': // moshe: for CreatePlanModal -- this translation should probably be handled in that component
      case 'Starter Plan':
        return 'false';

      default:
        return undefined;
    }
  }

  static convertValueFromNumericStringToNumber(
    value: string | number | undefined
  ): number | undefined {
    return isNumber(value)
      ? value
      : isString(value)
      ? parseInt(value, 10)
      : undefined;
  }

  static convertValueFromNumericStringToFloat(
    value: string | undefined
  ): number | undefined {
    if (isString(value)) {
      return parseFloat(value);
    }
    return undefined;
  }

  static getVestingScheduleName(
    fundingSourceName: string,
    fundingSources: FundingSource[]
  ): string | undefined {
    const id = fundingSources?.find(
      s => s.fundingSourceName === fundingSourceName
    )?.vestingScheduleId;
    return Constants.vestingSchedules.find(vs => vs.id === id)?.name ?? '--';
  }
}

export default Helper;
