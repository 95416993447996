import AccessControl from '@/components/access-control/AccessControl.component';
import LinearLoading from '@/components/linear-loading';
import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { GetLogsResponseDto } from '@/models/LogDTO.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { UsersItemDto } from '@/models/UsersDTO.model';
import { LogService } from '@/services/Log.service';
import UserManagementService from '@/services/UserManagement.service';
import formatters from '@/utils/Formatters';
import { email } from '@/utils/validations/commonYupValidations';
import { WarningAmberOutlined } from '@mui/icons-material';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { FC, useCallback, useRef, useState } from 'react';
import * as yup from 'yup';

export interface UserManagememtUpdateEmailProps {
  selectedUser: UsersItemDto;
  setSelectedUser: (user: UsersItemDto) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  alignButton: {
    marginTop: theme.spacing(1)
  },
  button: {
    height: theme.spacing(5),
    marginLeft: '20px'
  },
  contentBody: {
    display: 'overflow',
    padding: theme.spacing(3)
  },
  currentEmail: {
    color: 'black',
    paddingLeft: theme.spacing(12.5)
  },
  dialog: {
    paddingTop: '10px',
    width: theme.spacing(65)
  },
  errorMsg: {
    color: theme.palette.error.main,
    fontSize: '12px'
  },
  header: {
    marginBottom: theme.spacing(3)
  },
  textField: {
    width: '100%'
  },
  updatedEmail: {
    color: 'black',
    marginTop: theme.spacing(3),
    paddingLeft: theme.spacing(15)
  }
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:last-child td, &:last-child th': { border: 0 }
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(1, 2)
}));

const updateEmailValidationSchema = (): yup.AnyObjectSchema => {
  return yup.object().shape({
    email
  });
};

const securityCodeValidationSchema = (): yup.AnyObjectSchema => {
  return yup.object().shape({
    securityCode: yup
      .string()
      .trim()
      .length(6, 'Security code must be 6 digit number')
  });
};

export const UserManagementUpdateEmail: FC<
  UserManagememtUpdateEmailProps
> = props => {
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();
  const { selectedUser, setSelectedUser } = props;
  const [newEmail, setNewEmail] = useState('');
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();

  const formikEmailRef = useRef(null);
  const formikCodeRef = useRef(null);

  let updatedUser;

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const emailChanges = useQuery<GetLogsResponseDto[]>(
    ['LogService.getLogs', selectedUser?.userId?.toString()],
    () => {
      return LogService.getLogs({
        action: 'USER_EMAIL_CHANGED',
        entityId: selectedUser?.userId?.toString(),
        entityType: 'user'
      });
    }
  );

  const postCode = useMutation(
    (email: string) => {
      return UserManagementService.postUserEmailChangeAdmin(
        email,
        selectedUser.userId
      );
    },
    {
      onError: (e: any) => {
        if (e.statusCode === 409) {
          formikEmailRef.current?.setErrors({
            email: 'This email already exists with an existing user'
          });
        }
      },
      onSuccess: data => {
        handleOpen();
        console.log(data?.code);
      }
    }
  );

  const updateEmail = useMutation(
    (securityCode: string) => {
      return UserManagementService.patchUserEmailAdmin(
        newEmail,
        selectedUser?.email,
        securityCode,
        selectedUser?.userId
      );
    },
    {
      onError: () => {
        formikCodeRef.current.setErrors({
          securityCode: 'The security code entered is incorrect'
        });
      },
      onSuccess: async () => {
        formikEmailRef.current?.resetForm();
        showSnackbar({
          message: `Success! Login email for user ID ${selectedUser.userId} has been updated to ${newEmail}`,
          severity: 'success'
        });
        updatedUser = {
          ...selectedUser,
          email: newEmail
        };
        setSelectedUser(updatedUser);
        setNewEmail('');
        await queryClient.refetchQueries([
          'LogService.getLogs',
          selectedUser?.userId?.toString()
        ]);
      }
    }
  );

  const onSendCode = useCallback(async (value: { email: string }) => {
    setNewEmail(value.email.trim());
    await postCode.mutateAsync(value.email.trim());
  }, []);

  const onSubmit = useCallback(async (values: { securityCode: string }) => {
    await updateEmail.mutateAsync(values?.securityCode);
    handleClose();
  }, []);

  if (emailChanges.isLoading) {
    return <LinearLoading />;
  }

  return (
    <AccessControl
      prohibitedAccessComponent={
        <Alert icon={<WarningAmberOutlined />} severity='warning'>
          You do not have permissions to update login email, please contact a
          service manager.
        </Alert>
      }
      requires={[FeatureLevelPermissions.WRITE_USER_MANAGEMENT_LOGIN_EMAIL]}>
      <Formik
        initialValues={{ email: newEmail, oldEmail: selectedUser.email }}
        innerRef={formikEmailRef}
        onSubmit={onSendCode}
        validationSchema={updateEmailValidationSchema}>
        {formik => {
          return (
            <Form data-testid='user-management-update-email'>
              <Grid container>
                <Grid item lg={4}>
                  <Field
                    as={TextField}
                    className={classes.textField}
                    data-testid='user-management-update-email-field'
                    error={
                      formik.errors?.email && Boolean(formik.errors?.email)
                    }
                    label='New Login Email'
                    name='email'
                    values={formik.values.email}
                    variant='outlined'
                  />
                  <ErrorMessage
                    name='email'
                    render={errorMessage => (
                      <div className={classes.errorMsg}>{errorMessage}</div>
                    )}
                  />
                </Grid>
                <Grid className={classes.alignButton} item lg={4}>
                  <Button
                    className={classes.button}
                    data-testid='user-management-update-email-button'
                    disabled={
                      Boolean(!formik.values?.email) ||
                      Boolean(formik.errors?.email) ||
                      formik.isSubmitting
                    }
                    role='button'
                    type='submit'
                    variant='outlined'>
                    Update
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      {!!emailChanges.data?.length && (
        <>
          <Typography my={2}>Login Email History</Typography>
          <TableContainer data-testid='userManagementLoginEmailChanges'>
            <Table aria-label='table'>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Email</StyledTableCell>
                  <StyledTableCell align='left'>By</StyledTableCell>
                  <StyledTableCell align='left'>Date</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {emailChanges.data?.map((item, index) => (
                  <StyledTableRow key={item.createdAt}>
                    <StyledTableCell
                      component='th'
                      data-testid='userManagementLoginEmailChangesEmail'
                      scope='row'>
                      {index === emailChanges.data?.length - 1 ? (
                        <>
                          {item.newValue ?? EMPTY_FIELD_PLACEHOLDER}
                          <Typography variant='body2'>
                            from: {item.oldValue ?? EMPTY_FIELD_PLACEHOLDER}
                          </Typography>
                        </>
                      ) : (
                        item.newValue ?? EMPTY_FIELD_PLACEHOLDER
                      )}
                    </StyledTableCell>
                    <StyledTableCell
                      align='left'
                      data-testid='userManagementLoginEmailChangesBy'>
                      {item.actorUserType === 'ldap'
                        ? 'Admin'
                        : 'User' ?? EMPTY_FIELD_PLACEHOLDER}
                      <Typography variant='body2'>
                        {item.actorUserId ?? EMPTY_FIELD_PLACEHOLDER}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell
                      align='left'
                      data-testid='userManagementLoginEmailChangesDate'>
                      {formatters.formatFromIsoDateCustom(
                        item.createdAt,
                        'MM/DD/YY'
                      ) ?? EMPTY_FIELD_PLACEHOLDER}
                      <Typography variant='body2'>
                        {formatters.formatFromIsoDateCustom(
                          item.createdAt,
                          'HH:mma'
                        ) ?? EMPTY_FIELD_PLACEHOLDER}
                      </Typography>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      <Dialog
        data-testid='user-management-confirmation-dialog'
        onClose={handleClose}
        open={open}>
        <Formik
          initialValues={{
            securityCode: ''
          }}
          innerRef={formikCodeRef}
          onSubmit={onSubmit}
          validationSchema={securityCodeValidationSchema}>
          {formik => {
            return (
              <Form>
                <DialogTitle>Update Login Email</DialogTitle>
                <DialogContent>
                  <Grid container direction='column' spacing={2}>
                    <Grid item>
                      <Typography>
                        Enter the 6-digit security code sent to{' '}
                        <strong>{newEmail}</strong>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Field
                        as={TextField}
                        className={classes.textField}
                        data-testid='user-management-security-code-field'
                        error={
                          formik.errors?.securityCode &&
                          Boolean(formik.errors?.securityCode)
                        }
                        label='Security Code'
                        name='securityCode'
                        values={formik.values.securityCode}
                        variant='outlined'
                      />
                      <ErrorMessage
                        name='securityCode'
                        render={errorMessage => (
                          <div className={classes.errorMsg}>{errorMessage}</div>
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Typography>The code will expire in 2 minutes</Typography>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button
                    data-testid='user-management-cancel-update-email-button'
                    onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    data-testid='user-management-update-confirmation-button'
                    disabled={
                      formik.isSubmitting || !!formik.errors.securityCode
                    }
                    role='button'
                    type='submit'
                    variant='contained'>
                    Confirm
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    </AccessControl>
  );
};
