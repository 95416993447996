import { useSnackbar } from '@/contexts/SnackBarContext';
import { AlertSearchRequest } from '@/models/ops/alerts/AlertSearchRequest.model';
import AlertService from '@/services/ops/alerts/Alert.service';
import { useQuery } from '@tanstack/react-query';

export const useSearchAlertTypeTotals = (query: AlertSearchRequest) => {
  const { showSnackbar } = useSnackbar();
  return useQuery(
    ['AlertService.searchTypeTotals', { ...query, alertType: undefined }],
    async () => {
      return AlertService.searchTypeTotals({ ...query, alertType: undefined });
    },
    {
      keepPreviousData: true,
      onError: (err: any) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Alert search type totals failed: ${message}`,
          severity: 'error'
        });
      }
    }
  );
};
