import Badge from '@/components/badge';
import Card, { CardContent, CardPlaceholder } from '@/components/card';
import DataTable, {
  DataTableBadgeCell,
  DataTableStackCell
} from '@/components/data-table/DataTable.component';
import MyTabs from '@/components/my-tabs/MyTabs.component';
import { SierraWorkflowStatus } from '@/components/sierra-workflow-status';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { useSearchAlertTypeTotals } from '@/hooks/ops/useSearchAlertTypeTotals.hook';
import { AlertDto } from '@/models/ops/alerts/AlertDTO.model';
import { AlertSearchRequest } from '@/models/ops/alerts/AlertSearchRequest.model';
import { AlertStatusColorMap } from '@/models/ops/alerts/AlertStatusColorMap.model';
import {
  DepositType,
  depositTypeNames
} from '@/models/ops/alerts/DepositType.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import AuthZService from '@/services/AuthZ.service';
import AlertService from '@/services/ops/alerts/Alert.service';
import { userService } from '@/services/User.service';
import formatters from '@/utils/Formatters';
import { useUrlStateParams } from '@/utils/Url';
import SearchIcon from '@mui/icons-material/Search';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { AlertPriority } from '@vestwell-sub-accounting/models/common/AlertPriority';
import { AlertStatus } from '@vestwell-sub-accounting/models/common/AlertStatus';
import { AlertSubType } from '@vestwell-sub-accounting/models/common/AlertSubType';
import { AlertType } from '@vestwell-sub-accounting/models/common/AlertType';
import { SecurityOrderType } from '@vestwell-sub-accounting/models/common/SecurityOrderType';
import { WorkflowName } from '@vestwell-sub-accounting/models/conductor/WorkflowName.model';

import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { Field, Form, Formik } from 'formik';
import { isEqual } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import AlertDetails from '../conductor/common/AlertDetails.component';
import AlertDetailsDrawer from '../conductor/common/AlertDetailsDrawer.component';
import AlertDetailsTabs from '../conductor/common/AlertDetailsTabs.component';
import UpdateAlertForm from '../conductor/common/UpdateAlertForm.component';

type SearchFormValues = {
  query: string;
  alertStatus: AlertStatus[];
  priority: AlertPriority | '';
  alertSubType: AlertSubType | '';
};

const alertStatusesExceptClosed = Object.values(AlertStatus).filter(
  status => status !== AlertStatus.Closed
);

type SearchAlertsProps = {
  parentAccountId?: string;
};

export const SearchAlerts: React.FC<SearchAlertsProps> = props => {
  const { showSnackbar } = useSnackbar();
  const gridRef = useRef<AgGridReact>(null);

  const [isAlertDetailDrawerOpen, setIsAlertDetailDrawerOpen] = useState(false);
  const [selectedAlertId, setSelectedAlertId] = useState<number | undefined>();

  const [alertType, setAlertType] = useState<AlertType | undefined>();
  const [currentSearchParameters] = useSearchParams();
  const defaultQueryParams: AlertSearchRequest = useMemo(() => {
    const urlAlertType = currentSearchParameters.get('alert-type-tab');
    const validAlertType =
      urlAlertType &&
      Object.values(AlertType).find(type => type === urlAlertType)
        ? (urlAlertType as AlertType)
        : undefined;
    setAlertType(validAlertType);
    return {
      alertStatus: [...alertStatusesExceptClosed],
      alertType: validAlertType
    };
  }, [currentSearchParameters]);

  const [query, setQuery] = useUrlStateParams<AlertSearchRequest>(
    defaultQueryParams,
    'query',
    value => {
      // remove any values that are the same as the default
      const cleanedQuery = formatters.objectDiff<AlertSearchRequest>(
        value,
        defaultQueryParams
      );
      delete cleanedQuery.alertType; // don't include alertType in the query string since it is maintained by the MyTabs component
      return JSON.stringify(cleanedQuery) !== '{}' // only add query if it's not empty
        ? encodeURIComponent(JSON.stringify(cleanedQuery))
        : '';
    },
    value => {
      try {
        const urlQuery = JSON.parse(decodeURIComponent(value));
        return {
          ...defaultQueryParams,
          ...urlQuery
        };
      } catch {
        return defaultQueryParams;
      }
    }
  );

  const [page, setPage] = useUrlStateParams(
    1,
    'page',
    value => String(value),
    value => (!isNaN(Number(value)) ? Number(value) : 1)
  );
  const [pageSize, setPageSize] = useUrlStateParams(
    25,
    'pageSize',
    value => String(value),
    value => (!isNaN(Number(value)) ? Number(value) : 25)
  );
  const [orderBy, setOrderBy] = useUrlStateParams<keyof AlertDto | undefined>(
    undefined,
    'orderBy',
    value => String(value),
    value => (value ? (value as keyof AlertDto) : undefined)
  );
  const [orderByDirection, setOrderByDirection] = useUrlStateParams<
    'asc' | 'desc' | undefined
  >(
    undefined,
    'orderByDirection',
    value => String(value),
    value => (value === 'asc' || value === 'desc' ? value : undefined)
  );

  useEffect(() => {
    setPage(1);
  }, [alertType]);

  const myUserId = userService.getUser()?.sub;
  const [assignee, setAssignee] = useState<{
    label: string;
    id: string;
  } | null>(
    query?.assignee ? { id: query.assignee, label: query.assignee } : null
  );
  const [assigneeInputValue, setAssigneeInputValue] = useState('');
  const [assignedToMeChecked, setAssignedToMeChecked] = useState(
    myUserId === assignee?.id
  );

  const searchAlertWithPaginationQuery = useQuery(
    [
      'AlertService.search',
      query,
      page,
      pageSize,
      orderBy,
      orderByDirection,
      props.parentAccountId
    ],
    () => {
      const queryWithPagination = {
        ...query,
        page,
        pageSize
      };
      if (orderBy) {
        queryWithPagination.orderBy = orderBy;
      }
      if (orderByDirection) {
        queryWithPagination.orderByDirection = orderByDirection;
      }
      if (props.parentAccountId) {
        queryWithPagination.parentAccountId = props.parentAccountId;
      }
      return AlertService.search(queryWithPagination);
    },
    {
      keepPreviousData: true,
      onError: (err: any) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Alert search failed: ${message}`,
          severity: 'error'
        });
      },
      staleTime: Infinity
    }
  );

  const searchTypeTotalsQuery = useSearchAlertTypeTotals({
    ...query,
    parentAccountId: props.parentAccountId || undefined
  });

  const getVestWellStaffQuery = useQuery(
    ['AuthZService.getVestwellStaff'],
    async () => {
      const staff = await AuthZService.getVestwellStaff();
      return AuthZService.formatVestwellStaffList(staff);
    }
  );

  useEffect(() => {
    // runs after the vestwell staff have been fetched (doesn't use onSuccess because that doesn't fire on cache hit)
    // if an assignee is set in the query (likely from the url on init), set the assignee state from the matching vestwell staff
    if (assignee?.id) {
      const matchingStaff = getVestWellStaffQuery.data?.find(
        staff => staff.userId === assignee.id
      );
      if (matchingStaff?.userId) {
        setAssignee({
          id: matchingStaff.userId,
          label: matchingStaff.label
        });
      }
    }
    setAssignedToMeChecked(myUserId === assignee?.id);
  }, [getVestWellStaffQuery.data]);

  const handleSubmit = (formData: SearchFormValues) => {
    const newQuery = {} as AlertSearchRequest;

    if (formData.query) {
      newQuery.query = formData.query;
    }
    if (formData.alertStatus) {
      newQuery.alertStatus = formData.alertStatus;
    }
    if (formData.priority) {
      newQuery.priority = formData.priority;
    }
    if (formData.alertSubType) {
      newQuery.alertSubType = formData.alertSubType;
    }

    // autocomplete doesn't really work with formik, so we have to track the assignee filter state separate from the formData
    if (assignee?.id) {
      newQuery.assignee = assignee.id;
      setAssignedToMeChecked(myUserId === assignee?.id);
    } else {
      setAssignedToMeChecked(false);
    }

    setPage(1);
    setQuery(prev => ({ ...newQuery, alertType: prev?.alertType }));
  };

  /**
   * Used to convert the query object into the recognized props and value formats of the filter form.
   * Specifically the incoming query from the url on page load
   */
  const queryToInputValues = (
    incomingQuery: AlertSearchRequest
  ): Partial<SearchFormValues> => {
    const inputValues: Partial<SearchFormValues> = {};
    if (!incomingQuery) {
      return inputValues;
    }
    if (incomingQuery.query) {
      inputValues.query = incomingQuery.query;
    }
    if (incomingQuery.alertStatus) {
      inputValues.alertStatus = Array.isArray(incomingQuery.alertStatus)
        ? incomingQuery.alertStatus
        : (incomingQuery.alertStatus.split(',') as AlertStatus[]);
    }
    if (incomingQuery.priority) {
      inputValues.priority = incomingQuery.priority;
    }
    if (incomingQuery.alertSubType) {
      inputValues.alertSubType = incomingQuery.alertSubType;
    }

    return inputValues;
  };

  const custodianAccountSelector = (cellData: { data: AlertDto }) => {
    switch (cellData.data.alertType) {
      case AlertType.Trading:
      case AlertType.MoneyIn:
        switch (cellData.data.alertSubType) {
          case AlertSubType.CashTransferRejected:
            return {
              custodianAccountNumber:
                cellData.data.details?.fromParentAccount
                  ?.custodianAccountNumber,
              parentAccountId: cellData.data.details?.fromParentAccount?.id
            };
          default:
            return {
              custodianAccountNumber:
                cellData.data.details?.trade?.custodianAccountNumber,
              parentAccountId:
                cellData.data.parentAccountId ||
                cellData.data.details?.parentAccountId
            };
        }

      default:
        return {
          custodianAccountNumber:
            cellData.data.custodianAccountNumber ||
            cellData.data.details?.custodianAccountNumber,
          parentAccountId:
            cellData.data.parentAccountId ||
            cellData.data.details?.parentAccountId
        };
    }
  };

  const columnDefs = useMemo(() => {
    const columnDefOptions: ColDef[] = [
      {
        autoHeight: true,
        cellRenderer: (cellData: { data: AlertDto }) => {
          // fetch friendly display name for alert sub type
          const displaySubType = formatters.getValueKey(
            AlertSubType,
            cellData.data.alertSubType
          );
          return (
            <DataTableStackCell
              primary={`${cellData.data.id} - ${cellData.data.alertName}`}
              primaryLinkProps={{
                onClick: () => {
                  setSelectedAlertId(cellData.data.id);
                  setIsAlertDetailDrawerOpen(true);
                },
                to: null
              }}
              secondary={formatters.displayCase(displaySubType)}
            />
          );
        },
        field: 'id',
        headerName: 'Alert',
        minWidth: 255,
        sortable: true
      },
      {
        autoHeight: true,
        cellRenderer: (cellData: { data: AlertDto }) => {
          const workflowName =
            cellData.data?.details?.workflowExecution?.workflowName;

          return (
            <DataTableStackCell
              primary={
                formatters.displayCase(
                  workflowName === WorkflowName.tradeRequest
                    ? cellData.data?.details?.tradeRequestQueue
                        ?.tradeCalculatorMethod
                    : workflowName
                ) || '\u2014'
              }
            />
          );
        },
        field: 'flowType',
        headerName: 'Flow Type',
        minWidth: 180,
        sortable: false
      },
      {
        autoHeight: true,
        cellRenderer: (cellData: { data: AlertDto }) => {
          const planId =
            cellData.data.planId || cellData.data.details?.plan?.id || '\u2014';
          const planName = cellData.data.plan?.name || '\u2014';

          return (
            <DataTableStackCell
              primary={planId}
              primaryLinkProps={{
                to: planId !== '\u2014' ? `/plans/${planId}/plan` : undefined
              }}
              secondary={planName}
            />
          );
        },
        field: 'planId',
        headerName: 'Plan',
        minWidth: 180,
        sortable: false
      },
      {
        autoHeight: true,
        cellRenderer: (cellData: { data: AlertDto }) => {
          const custodianAccount = custodianAccountSelector(cellData);
          return (
            <DataTableStackCell
              primary={custodianAccount.custodianAccountNumber}
              secondary={`Parent Acct. ID: ${custodianAccount.parentAccountId}`}
              secondaryLinkProps={{
                to: custodianAccount.parentAccountId
                  ? `/ops/accounts/${custodianAccount.parentAccountId}`
                  : undefined
              }}
            />
          );
        },
        field: 'custodianAccountNumber',
        headerName: 'Custodian Account',
        minWidth: 180,
        sortable: false
      },
      {
        autoHeight: true,
        cellRenderer: (cellData: { data: AlertDto }) => {
          return cellData.data.alertSubType === AlertSubType.CashTransfer ? (
            <DataTableStackCell
              primary={
                cellData.data.details?.toParentAccount?.plan?.setupType ||
                cellData.data.details?.toParentAccount?.accountName ||
                '\u2014'
              }
              secondary={cellData.data.details?.toParentAccount?.subAccountId}
            />
          ) : null;
        },
        field: 'toAccount',
        headerName: 'To Account',
        minWidth: 180,
        sortable: false
      },
      {
        autoHeight: true,
        cellRenderer: (cellData: { data: AlertDto }) => {
          return (
            <DataTableStackCell
              primary={
                cellData.data?.details?.event?.body?.[0]?.amount
                  ? formatters.formatDollars(
                      cellData.data.details.event.body[0].amount
                    )
                  : '\u2014'
              }
              secondary={
                depositTypeNames[
                  cellData.data?.details?.event?.body?.[0]
                    ?.depositType as DepositType
                ] || '\u2014'
              }
            />
          );
        },
        field: 'depositInfo',
        headerName: 'Deposit Info',
        minWidth: 180,
        sortable: false
      },
      {
        autoHeight: true,
        cellRenderer: (cellData: { data: AlertDto }) => (
          <SierraWorkflowStatus
            executionStatus={
              cellData.data?.details?.workflowExecution?.executionStatus ??
              'Execution status not found'
            }
            workflowStatus={
              cellData.data?.details?.workflowStatus?.workflowStatus ??
              'Worflow status not found'
            }
          />
        ),
        field: 'currentStatus',
        headerName: 'Execution/Workflow Statuses',
        minWidth: 175,
        sortable: true
      },
      {
        autoHeight: true,
        cellRenderer: (cellData: { data: AlertDto }) => {
          // fetch friendly display name for status
          const displayStatus = formatters.getValueKey(
            AlertStatus,
            cellData.data.alertStatus
          );

          return (
            <DataTableBadgeCell
              color={AlertStatusColorMap[cellData.data.alertStatus]}>
              {formatters.displayCase(displayStatus)}
            </DataTableBadgeCell>
          );
        },
        field: 'alertStatus',
        headerName: 'Status',
        minWidth: 175,
        sortable: true
      },
      {
        autoHeight: true,
        cellRenderer: (cellData: { data: AlertDto }) => {
          const security = cellData.data.details?.security ?? {};
          return (
            <DataTableStackCell
              primary={
                (security.symbol || '\u2014') +
                ' | ' +
                (security.cusip || '\u2014')
              }
              secondary={security.description || '\u2014'}
            />
          );
        },
        field: 'security',
        headerName: 'Security',
        minWidth: 200,
        sortable: false
      },
      {
        autoHeight: true,
        cellRenderer: (cellData: { data: AlertDto }) => {
          return (
            <DataTableStackCell
              primary={
                formatters.displayCase(
                  cellData.data.details?.trade?.status || ''
                ) || '\u2014'
              }
              secondary={cellData.data.details?.trade?.statusReason || '\u2014'}
            />
          );
        },
        field: 'alertTradingStatus',
        headerName: 'Status',
        minWidth: 175,
        sortable: true
      },
      {
        autoHeight: true,
        cellRenderer: (cellData: { data: AlertDto }) => {
          return (
            <DataTableStackCell
              primary={
                formatters.formatFromIsoDateCustom(
                  cellData.data.details?.trade?.tradeDate,
                  'MM/DD/YYYY'
                ) || '\u2014'
              }
              secondary={
                SecurityOrderType.DOLLAR_ORDER ===
                cellData.data.details?.trade?.tradeOrderType
                  ? formatters.formatDollars(
                      cellData.data.details?.trade?.tradeAmount
                    )
                  : formatters.formatDecimal(
                      cellData.data.details?.trade?.tradeUnits,
                      3
                    ) || '\u2014'
              }
            />
          );
        },
        field: 'tradeDetails',
        headerName: 'Trade Details',
        minWidth: 200,
        sortable: false
      },
      {
        cellRenderer: (cellData: { data: AlertDto }) => {
          if (!cellData.data.assignee) {
            return null;
          }
          const matchedAssignee = getVestWellStaffQuery.data?.find(
            staffUser => staffUser.userId === cellData.data.assignee
          );
          return (
            <DataTableStackCell
              primary={matchedAssignee?.label || cellData.data.assignee}
            />
          );
        },
        field: 'assignee',
        headerName: 'Assignee',
        maxWidth: 160,
        sortable: false
      },
      {
        field: 'priority',
        headerName: 'Priority',
        minWidth: 125,
        sortable: true,
        valueFormatter: ({ value }: { value: string }) =>
          formatters.displayCase(value)
      },
      {
        autoHeight: true,
        cellRenderer: (cellData: { data: AlertDto }) => (
          <Typography
            maxWidth={200}
            sx={{ wordBreak: 'break-word' }}
            variant='body1'>
            {formatters.displayCase(
              cellData.data?.details?.workflowExecution?.failureCause?.message
            )}
          </Typography>
        ),
        field: 'reason',
        maxWidth: 200,
        sortable: true,
        wrapText: true
      },
      {
        field: 'createdAt',
        headerName: 'Created At',
        maxWidth: 200,
        sortable: true,
        valueFormatter: ({ value }: { value: string }) =>
          formatters.formatFromIsoDate(value)
      }
    ];

    const unmappedTypeColumns = [
      'id',
      'alertStatus',
      'assignee',
      'priority',
      'createdAt'
    ];

    const columnTypeMap: Partial<Record<AlertType, string[]>> = {
      [AlertType.Conversion]: [
        'id',
        'planId',
        'custodianAccountNumber',
        'depositInfo',
        'alertStatus',
        'assignee',
        'priority',
        'createdAt'
      ],
      [AlertType.Rollovers]: [
        'id',
        'planId',
        'custodianAccountNumber',
        'depositInfo',
        'alertStatus',
        'assignee',
        'priority',
        'createdAt'
      ],
      [AlertType.Conductor]: [
        'id',
        'flowType',
        'currentStatus',
        'alertStatus',
        'assignee',
        'priority',
        'reason',
        'createdAt'
      ],
      [AlertType.Trading]: [
        'id',
        'planId',
        'custodianAccountNumber',
        'security',
        'alertTradingStatus',
        'tradeDetails',
        'assignee',
        'priority',
        'createdAt'
      ],
      [AlertType.MoneyIn]: [
        'id',
        'planId',
        'custodianAccountNumber',
        'toAccount',
        'depositInfo',
        'alertStatus',
        'assignee',
        'priority',
        'createdAt'
      ]
    };

    return columnDefOptions.filter(colDef => {
      if (!colDef.field) return true;

      if (!alertType || !Array.isArray(columnTypeMap[alertType])) {
        // if type is not set or if this type has not been mapped then show the default unmapped type columns
        return unmappedTypeColumns.includes(colDef.field);
      }

      return (columnTypeMap[alertType] as string[]).includes(colDef.field); // have to assert because TS doesn't know that we've already checked that this is an array
    });
  }, [alertType, AlertStatusColorMap, getVestWellStaffQuery.data]);

  const handleSortChanged = (
    newSort: { colId: string; sort?: 'asc' | 'desc' }[]
  ) => {
    if (!newSort || newSort.length === 0) {
      setOrderBy(undefined);
      setOrderByDirection(undefined);
    } else {
      setOrderBy(newSort[0].colId as keyof AlertDto);
      setOrderByDirection(newSort[0].sort);
    }
  };

  const handlePageChanged = (newPage: number) => {
    setPage(newPage);
  };

  const handlePageSizeChanged = (newPageSize: number) => {
    setPageSize(newPageSize);
  };

  const hasWritePermissions = userService.hasPermission(
    FeatureLevelPermissions.WRITE_SUBA_ALERTS
  );

  return (
    <>
      <AlertDetailsDrawer
        alertId={selectedAlertId}
        hasWritePermissions={hasWritePermissions}
        onClose={() => {
          setIsAlertDetailDrawerOpen(false);
          setSelectedAlertId(undefined);
        }}
        open={isAlertDetailDrawerOpen}>
        <Box px={3}>
          <Stack spacing={2}>
            <UpdateAlertForm
              hasWritePermissions={hasWritePermissions}
              vestwellStaff={getVestWellStaffQuery.data}
            />
            <AlertDetails />
            <AlertDetailsTabs
              disableStateInUrl={true}
              hasWritePermissions={hasWritePermissions}
            />
          </Stack>
        </Box>
      </AlertDetailsDrawer>
      <Card>
        <MyTabs
          actions={
            <FormControlLabel
              control={
                <Switch
                  checked={assignedToMeChecked}
                  inputProps={{ 'aria-label': 'assigned to me' }}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setAssignedToMeChecked(event.target.checked);

                    setPage(1);
                    if (event.target.checked) {
                      setQuery(prev => {
                        return {
                          ...prev,
                          assignee: myUserId
                        };
                      });
                      const myUser = getVestWellStaffQuery.data?.find(
                        staffUser => staffUser.userId === myUserId
                      );
                      if (myUser?.userId) {
                        setAssignee({
                          id: myUser.userId,
                          label: myUser.label
                        });
                      }
                    } else {
                      setQuery(prev => {
                        return {
                          ...prev,
                          assignee: undefined
                        };
                      });
                      setAssignee(null);
                    }
                  }}
                  size='small'
                />
              }
              label={
                <Typography sx={{ fontSize: 14 }}>Assigned to me</Typography>
              }
            />
          }
          defaultDisplayTabs={[
            AlertType.MoneyOut,
            AlertType.MoneyIn,
            AlertType.Rollovers,
            AlertType.Conversion
          ]}
          loading={
            (searchAlertWithPaginationQuery.data?.results &&
              searchAlertWithPaginationQuery.isFetching) ||
            searchTypeTotalsQuery.isFetching
          }
          name='alert-type'
          onChange={newTab => {
            // confirm the newTab is a valid AlertType
            let newAlertType: AlertType | undefined;
            if (Object.values(AlertType).includes(newTab as AlertType)) {
              newAlertType = newTab as AlertType;
            }

            setAlertType(newAlertType);

            // set the search filters based on the selected tab
            setQuery(prev => {
              return {
                ...prev,
                alertType: newAlertType
              };
            });
          }}
          tabLabelFormatter={tab =>
            formatters.displayCase(formatters.getValueKey(AlertType, tab))
          }
          tabOptions={Object.values(AlertType)}
          totals={searchTypeTotalsQuery.data}
        />
        <Divider sx={{ borderColor: theme => theme.palette.grey[300] }} />
        <CardContent
          disablePadding
          overlayLoading={getVestWellStaffQuery.isInitialLoading}>
          <DataTable
            columnDefs={columnDefs}
            data-testid='data-alerts-table'
            emptyPlaceholderComponent={
              <Stack
                alignItems='center'
                data-testid='no-data-alerts-table'
                justifyContent='center'
                sx={{ height: '100%' }}>
                <CardPlaceholder
                  icon={<SearchIcon fontSize='inherit' />}
                  subtitle={
                    !searchAlertWithPaginationQuery.data?.results
                      ? 'Search results will be displayed here.'
                      : 'No results found.'
                  }
                />
              </Stack>
            }
            filterSidePanelComponent={
              <Formik
                initialValues={{
                  alertStatus: [...alertStatusesExceptClosed],
                  alertSubType: '',
                  priority: '',
                  query: '',
                  ...queryToInputValues(query)
                }}
                onSubmit={handleSubmit}>
                {({ values }) => (
                  <Form data-testid='filter-form'>
                    <Stack
                      alignItems='flex-start'
                      justifyContent='flex-start'
                      spacing={2}>
                      <FormControl fullWidth size='small' variant='outlined'>
                        <Field
                          InputProps={{
                            endAdornment: <SearchIcon />
                          }}
                          as={TextField}
                          data-testid='query-input'
                          helperText='Search by an alert ID, ARN, or a tracer ID'
                          name='query'
                          placeholder='Search'
                          size='small'
                        />
                      </FormControl>

                      <FormControl fullWidth size='small' sx={{ width: 256 }}>
                        <InputLabel id='menu-status-label' shrink>
                          Status
                        </InputLabel>
                        <Field
                          MenuProps={{
                            'data-testid': 'menu-status'
                          }}
                          as={Select}
                          data-testid='query-status'
                          displayEmpty
                          input={<OutlinedInput label='Status' notched />}
                          label='Status'
                          labelId='menu-status-label'
                          multiple
                          name='alertStatus'
                          renderValue={(selected: AlertStatus[]) => {
                            if (selected.length === 0) {
                              return <>Any</>;
                            }

                            const sortedSelected = [...selected].sort();
                            const sortedStatusesExceptClosed = [
                              ...alertStatusesExceptClosed
                            ].sort();
                            if (
                              isEqual(
                                sortedSelected,
                                sortedStatusesExceptClosed
                              )
                            ) {
                              return <>All except Closed</>;
                            }

                            return (
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                  gap: 0.5
                                }}>
                                {selected.map(value => {
                                  const displayStatus = formatters.getValueKey(
                                    AlertStatus,
                                    value
                                  );
                                  return (
                                    <Badge
                                      color={AlertStatusColorMap[value]}
                                      key={value}
                                      size='small'>
                                      {formatters.displayCase(displayStatus)}
                                    </Badge>
                                  );
                                })}
                              </Box>
                            );
                          }}>
                          {Object.values(AlertStatus).map(value => {
                            const displayStatus = formatters.getValueKey(
                              AlertStatus,
                              value
                            );
                            return (
                              <MenuItem key={value} value={value}>
                                <Checkbox
                                  checked={values.alertStatus.includes(value)}
                                  sx={{ py: 0 }}
                                />
                                <ListItemText>
                                  {formatters.displayCase(displayStatus)}
                                </ListItemText>
                              </MenuItem>
                            );
                          })}
                        </Field>
                      </FormControl>

                      {getVestWellStaffQuery.data && (
                        <FormControl fullWidth size='small'>
                          <Autocomplete
                            data-testid='alert-assignee'
                            disablePortal
                            inputValue={assigneeInputValue}
                            isOptionEqualToValue={(option, value) =>
                              option.label === value.label
                            }
                            onChange={(
                              _event,
                              newValue: { label: string; id: string }
                            ) => {
                              setAssignee(newValue);
                            }}
                            onInputChange={(_event, newInputValue) => {
                              setAssigneeInputValue(newInputValue);
                            }}
                            options={getVestWellStaffQuery.data.map(user => ({
                              id: String(user.userId),
                              label: user.label
                            }))}
                            renderInput={params => (
                              <TextField
                                {...params}
                                InputLabelProps={{ shrink: true }}
                                label='Assignee'
                                placeholder='Any'
                              />
                            )}
                            size='small'
                            value={assignee}
                          />
                        </FormControl>
                      )}

                      <FormControl fullWidth size='small'>
                        <InputLabel id='menu-priority-label' shrink>
                          Priority
                        </InputLabel>
                        <Field
                          MenuProps={{
                            'data-testid': 'menu-priority'
                          }}
                          as={Select}
                          data-testid='query-priority'
                          displayEmpty
                          input={<OutlinedInput label='Priority' notched />}
                          label='Priority'
                          labelId='menu-priority-label'
                          name='priority'>
                          <MenuItem value=''>Any</MenuItem>
                          {Object.values(AlertPriority).map(value => {
                            const displayPriority = formatters.getValueKey(
                              AlertPriority,
                              value
                            );
                            return (
                              <MenuItem key={value} value={value}>
                                {formatters.displayCase(displayPriority)}
                              </MenuItem>
                            );
                          })}
                        </Field>
                      </FormControl>

                      <FormControl fullWidth size='small'>
                        <InputLabel id='menu-sub-type-label' shrink>
                          Sub Type
                        </InputLabel>
                        <Field
                          MenuProps={{
                            'data-testid': 'menu-sub-type'
                          }}
                          as={Select}
                          data-testid='query-sub-type'
                          displayEmpty
                          input={<OutlinedInput label='Sub Type' notched />}
                          label='Sub Type'
                          labelId='menu-sub-type-label'
                          name='alertSubType'>
                          <MenuItem value=''>Any</MenuItem>
                          {Object.values(AlertSubType).map(value => {
                            const displaySubType = formatters.getValueKey(
                              AlertSubType,
                              value
                            );
                            return (
                              <MenuItem key={value} value={value}>
                                {formatters.displayCase(displaySubType)}
                              </MenuItem>
                            );
                          })}
                        </Field>
                      </FormControl>

                      <Button type='submit' variant='outlined'>
                        Apply
                      </Button>
                    </Stack>
                  </Form>
                )}
              </Formik>
            }
            gridRef={gridRef}
            onPageChanged={handlePageChanged}
            onPageSizeChanged={handlePageSizeChanged}
            onSortChanged={handleSortChanged}
            page={page}
            pageSize={pageSize}
            pagination
            paginationSource='server'
            paginationTotal={
              searchAlertWithPaginationQuery.data?.pagination?.total
            }
            rowData={
              searchAlertWithPaginationQuery.data?.results
                ? searchAlertWithPaginationQuery.data.results
                : []
            }
            sort={
              orderBy
                ? [
                    {
                      colId: orderBy,
                      sort: orderByDirection
                    }
                  ]
                : []
            }
          />
        </CardContent>
      </Card>
    </>
  );
};
